"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.labelsTypes = exports.genLabel = exports._alphabet = exports.LabelType = void 0;
require("core-js/modules/es.object.values.js");
var heavenlyStems = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'];
var earthlyBranches = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];
// 天干就是Heavenly Stems，地支就是Earthly Branches，他们两辆配对组成的“六十干支”，这里被译作sexagenary cycle。
var LabelType;
exports.LabelType = LabelType;
(function (LabelType) {
  LabelType["Index"] = "index";
  LabelType["Alphabet"] = "alphabet";
  LabelType["HeavenlyStems"] = "heavenlyStems";
  LabelType["EarthlyBranches"] = "earthlyBranches";
  LabelType["SexagenaryCycle"] = "sexagenaryCycle";
})(LabelType || (exports.LabelType = LabelType = {}));
var labelsTypes = Object.values(LabelType);
exports.labelsTypes = labelsTypes;
var genLabel = function genLabel(type, index) {
  switch (type) {
    case LabelType.Alphabet:
      return String.fromCharCode(index % 25 + 65);
    case LabelType.HeavenlyStems:
      return heavenlyStems[index % heavenlyStems.length];
    case LabelType.EarthlyBranches:
      return earthlyBranches[index % earthlyBranches.length];
    case LabelType.SexagenaryCycle:
      //  阳干配阳支，阴干配阴支：
      var x = Math.floor(index / 6);
      var y = x % 2 === 0 ? 2 * index % 12 : 2 * index % 12 + 1;
      return heavenlyStems[x] + earthlyBranches[y];
    default:
      return String(index);
  }
};
exports.genLabel = genLabel;
var _alphabet = function _alphabet(index) {
  return genLabel(LabelType.Alphabet, index);
};
exports._alphabet = _alphabet;