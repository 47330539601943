"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._fill = void 0;
require("core-js/modules/es.array.fill.js");
var _fill2 = require("@/algorithm/fill");
var _pathMode = require("@/types/pathMode");
var _canvas = require("./canvas");
var _path2 = require("./path");
// 填充

var _fill = function _fill(ctx, options, props) {
  var _options$points = options.points,
    points = _options$points === void 0 ? [] : _options$points,
    _options$fill = options.fill,
    fill = _options$fill === void 0 ? {} : _options$fill;
  var pattern = fill.pattern,
    r = fill.r,
    a = fill.a;
  var fn = _fill2.fillPatternPointsMap[pattern];
  var ps = fn({
    points: points,
    r: r,
    a: a
  });
  ctx.save();
  (0, _canvas._props)(ctx, props);
  (0, _path2._path)(ctx, {
    pathMode: _pathMode.PathMode.LINES,
    points: ps
  }, props);
  ctx.stroke();
  ctx.restore();
};
exports._fill = _fill;