"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._form = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
var _dom = require("./dom");
var _popover2 = require("./popover");
var _array = require("../../math/array");
var _fromItem = require("./fromItem");
var _propsData = require("../../common/propsData");
var _formBtns = function _formBtns(fields, form, op) {
  var btns = _dom.dom.div(op.map(function (t) {
    return _dom.dom.div(t.text, {
      class: "form-btn".concat(t.className ? ' ' + t.className : '', " ").concat(t.popover ? ' popover' : ''),
      type: 'button'
    }, {
      click: function click(e) {
        // 弹窗
        if (t.popover) {
          document.body.appendChild((0, _popover2._popover)(t.popover, e.target));
        }
        var scope = (0, _propsData.makeModel)(fields, form);
        t.click.call(scope, e, scope);
      }
    });
  }), {
    class: 'form-btn-group'
  });
  return btns;
};
// 表单
var _form = function _form(options, events) {
  var name = options.name,
    title = options.title,
    _options$fields = options.fields,
    fields = _options$fields === void 0 ? [] : _options$fields,
    _options$op = options.op,
    op = _options$op === void 0 ? [] : _options$op;
  var form = _dom.dom.form([], {
    name: name
  }, events);
  var formTitle = _dom.dom.div(title, {
    class: 'form-title'
  });
  var formBody;
  // 分组
  if (fields.some(function (t) {
    return t.group;
  })) {
    var group = (0, _array.groupBy)(fields, function (t) {
      return t.group || 'none';
    });
    var formItemsGroup = Object.keys(group).map(function (key) {
      var fields = group[key];
      var formItems = fields.filter(function (t) {
        return t.field;
      }).map(function (field) {
        return (0, _fromItem._formItem)(field, options);
      });
      var itcon = _dom.dom.i('', {
        class: 'icon-arrow'
      });
      var groupTitle = _dom.dom.div([key, itcon], {
        class: 'form-item-group__title'
      }, {
        click: function click(e) {
          var el = e.target;
          var groupContainer = (0, _dom._closest)(el, '.form-item-group');
          if (groupContainer) {
            (0, _dom._toggleClassName)(groupContainer, 'active');
            groupContainer.scrollIntoView();
          }
        }
      });
      var groupBody = _dom.dom.div(formItems, {
        class: 'form-item-group__body'
      });
      return _dom.dom.div(key === 'none' ? groupBody : [groupTitle, groupBody], {
        class: "form-item-group ".concat(key, " ").concat(key === 'none' ? 'active' : '')
      });
    });
    formBody = _dom.dom.div([formItemsGroup], {
      class: 'form-body'
    });
  } else {
    var formItems = fields.filter(function (t) {
      return t.field;
    }).map(function (field) {
      return (0, _fromItem._formItem)(field, options);
    });
    formBody = _dom.dom.div([formItems], {
      class: 'form-body'
    });
  }
  //
  var btns = _formBtns(fields, form, op);
  var formFoot = _dom.dom.div(btns, {
    class: 'form-foot'
  });
  return (0, _dom._append)(form, [formTitle, formBody, formFoot]);
};
exports._form = _form;