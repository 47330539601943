"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ellipse2path = ellipse2path;
exports.line2path = line2path;
exports.parsePath = parsePath;
exports.polygon2path = polygon2path;
exports.polyline2path = polyline2path;
exports.rect2path = rect2path;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.slice.js");
var PATH_COMMANDS = {
  M: ['x', 'y'],
  m: ['dx', 'dy'],
  H: ['x'],
  h: ['dx'],
  V: ['y'],
  v: ['dy'],
  L: ['x', 'y'],
  l: ['dx', 'dy'],
  Z: [],
  C: ['x1', 'y1', 'x2', 'y2', 'x', 'y'],
  c: ['dx1', 'dy1', 'dx2', 'dy2', 'dx', 'dy'],
  S: ['x2', 'y2', 'x', 'y'],
  s: ['dx2', 'dy2', 'dx', 'dy'],
  Q: ['x1', 'y1', 'x', 'y'],
  q: ['dx1', 'dy1', 'dx', 'dy'],
  T: ['x', 'y'],
  t: ['dx', 'dy'],
  A: ['rx', 'ry', 'rotation', 'large-arc', 'sweep', 'x', 'y'],
  a: ['rx', 'ry', 'rotation', 'large-arc', 'sweep', 'dx', 'dy']
};
function parsePath(path) {
  var items = path.replace(/[\n\r]/g, '').replace(/-/g, ' -').replace(/(\d*\.)(\d+)(?=\.)/g, '$1$2 ').trim().split(/\s*,|\s+/);
  var segments = [];
  var currentCommand = '';
  var currentElement = {};
  var _loop = function _loop() {
    var it = items.shift();
    if (PATH_COMMANDS.hasOwnProperty(it)) {
      currentCommand = it;
    } else {
      items.unshift(it);
    }
    currentElement = {
      type: currentCommand
    };
    PATH_COMMANDS[currentCommand].forEach(function (prop) {
      it = items.shift(); // TODO sanity check
      currentElement[prop] = it;
    });
    if (currentCommand === 'M') {
      currentCommand = 'L';
    } else if (currentCommand === 'm') {
      currentCommand = 'l';
    }
    segments.push(currentElement);
  };
  while (items.length > 0) {
    _loop();
  }
  return segments;
}
function rect2path(x, y, width, height, rx, ry) {
  /*
   * rx 和 ry 的规则是：
   * 1. 如果其中一个设置为 0 则圆角不生效
   * 2. 如果有一个没有设置则取值为另一个
   */
  rx = rx || ry || 0;
  ry = ry || rx || 0;
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(x - y + width - height + rx - ry)) return;
  rx = rx > width / 2 ? width / 2 : rx;
  ry = ry > height / 2 ? height / 2 : ry;
  //如果其中一个设置为 0 则圆角不生效
  if (0 == rx || 0 == ry) {
    // var path =
    // 'M' + x + ' ' + y +
    // 'H' + (x + width) + 不推荐用绝对路径，相对路径节省代码量
    // 'V' + (y + height) +
    // 'H' + x +
    // 'z';
    var path = 'M' + x + ' ' + y + 'h' + width + 'v' + height + 'h' + -width + 'z';
  } else {
    var path = 'M' + x + ' ' + (y + ry) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + rx + ' ' + -ry + 'h' + (width - rx - rx) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + rx + ' ' + ry + 'v' + (height - ry - ry) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + -rx + ' ' + ry + 'h' + (rx + rx - width) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + -rx + ' ' + -ry + 'z';
  }
  return path;
}
function ellipse2path(cx, cy, rx, ry) {
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(cx - cy + rx - ry)) return;
  var path = 'M' + (cx - rx) + ' ' + cy + 'a' + rx + ' ' + ry + ' 0 1 0 ' + 2 * rx + ' 0' + 'a' + rx + ' ' + ry + ' 0 1 0 ' + -2 * rx + ' 0' + 'z';
  return path;
}
function line2path(x1, y1, x2, y2) {
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(x1 - y1 + x2 - y2)) return;
  x1 = x1 || 0;
  y1 = y1 || 0;
  x2 = x2 || 0;
  y2 = y2 || 0;
  var path = 'M' + x1 + ' ' + y1 + 'L' + x2 + ' ' + y2;
  return path;
}
// polygon折线转换
//points = [x1, y1, x2, y2, x3, y3 ...];
function polyline2path(points) {
  var path = 'M' + points.slice(0, 2).join(' ') + 'L' + points.slice(2).join(' ');
  return path;
}
// polygon多边形转换
//points = [x1, y1, x2, y2, x3, y3 ...];
function polygon2path(points) {
  var path = 'M' + points.slice(0, 2).join(' ') + 'L' + points.slice(2).join(' ') + 'z';
  return path;
}