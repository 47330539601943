"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._svg = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _svg2 = require("./core/svg");
var _renderShape = require("./renderShape");
var _dom = require("@/ui/core/dom");
var _store = require("@/store");
var _math = require("@/math");
var _layout2 = require("@/common/layout");
var _utils = require("@/utils");
var _common = require("@/common");
var _shape = require("@/types/shape");
// svg包围
var _svg = function _svg(options) {
  var name = options.name,
    width = options.width,
    height = options.height,
    _options$shapes = options.shapes,
    shapes = _options$shapes === void 0 ? [] : _options$shapes,
    _options$pattern = options.pattern,
    pattern = _options$pattern === void 0 ? [] : _options$pattern,
    props = options.props,
    axis = options.axis,
    layout = options.layout,
    o = options.o;
  var screen = (0, _store._screen)(width, height);
  height = screen.height;
  width = screen.width;
  if (!o) {
    o = screen.o;
  }
  var svg = (0, _svg2.createSvg)('svg', {
    width: width,
    height: height,
    xmlns: _svg2.svgns,
    'xmlns:xlink': _svg2.xlinkns,
    viewBox: "0,0,".concat(width, ",").concat(height)
    // preserveAspectRatio: "XMidYMid meet"
  });

  var fn = function fn(t) {
    if (!t) return;
    var shape = t.shape;
    if (shape === _shape.Shape.LSystemPlant && (layout == null || layout === '0')) {
      // 植物lsystem的原点下移
      if (typeof height === 'number') {
        o = (0, _math.translateY)(o, height / 2 - 100);
      }
    }
    var svgDom = (0, _renderShape.renderShape)(Object.assign(Object.assign({
      o: o
    }, t), {
      name: name,
      width: width,
      height: height
    }), Object.assign(Object.assign({}, props), t.props));
    (0, _dom._append)(svg, svgDom);
  };
  // const axisMap = {
  //   axis, grid, polar
  // }
  // Object.keys(axisMap).forEach(t => {
  //   axisMap[t] && fn({
  //     shape: t,
  //     ...axisMap[t]
  //   })
  // })
  if ((0, _common.isVisible)(axis)) {
    var type;
    if ((0, _utils.isObject)(axis)) {
      type = axis.type;
    } else {
      type = axis;
    }
    if (type !== _shape.Shape.None) {
      fn(Object.assign({
        shape: type
      }, axis));
    }
  }
  pattern.forEach(function (t) {
    return fn(t);
  });
  (0, _layout2._layout)({
    layout: layout,
    width: width,
    height: height,
    o: o
  }, shapes, fn);
  return svg;
};
exports._svg = _svg;