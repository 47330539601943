"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._triangleLeaf = exports._leaves = exports._leaf = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _math = require("@/math");
var _polygon2 = require("../polygon");
var _svg = require("../core/svg");
var _polar2 = require("@/algorithm/polar");
var _color = require("@/color");
var _color2 = require("@/types/color");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var squarePoints = function squarePoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a;
  var p = (0, _math._polar)(o, r, a);
  // 线段
  var points = [o, p];
  // 中点
  var mp = (0, _math._mid)(o, p);
  // 垂线
  var vPoints = points.map(function (t, i) {
    return (0, _math._polar)(mp, r / 2, a + 90 + 180 * i);
  });
  // 4个点
  points.push.apply(points, _toConsumableArray((0, _math._move)([p, o], vPoints[0], vPoints[1])));
  return points;
};
// 菱形树叶
var _leaf = function _leaf(options, props) {
  var _options$r2 = options.r,
    r = _options$r2 === void 0 ? 100 : _options$r2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? 0 : _options$a2,
    vertex = options.vertex,
    fractal = options.fractal,
    _options$direct = options.direct,
    direct = _options$direct === void 0 ? 1 : _options$direct;
  var points = squarePoints(options);
  var children = [];
  var square = (0, _polygon2._square)({
    points: points
  }, Object.assign({
    fill: 'none',
    stroke: 'green'
  }, props));
  children.push(square);
  // 顶点
  if (vertex) {
    var cirlces = points.map(function (t) {
      return (0, _svg._circle)({
        o: t,
        r: 3
      }, {
        fill: 'blue'
      });
    });
    children.push.apply(children, _toConsumableArray(cirlces));
  }
  // 分形迭代
  if (fractal) {
    if (fractal.fractalLevel < 0) return;
    var angle = (0, _math._random)(30, 45); //45
    var leaf1 = _leaf({
      o: direct ? points[3] : points[2],
      r: r / 2,
      a: a - angle,
      fractal: {
        fractalLevel: fractal.fractalLevel - 1
      },
      direct: 1
    });
    var leaf2 = _leaf({
      o: direct ? points[2] : points[1],
      r: r / 2,
      a: a + angle,
      fractal: {
        fractalLevel: fractal.fractalLevel - 1
      },
      direct: 0
    });
    children.push(leaf1, leaf2);
  }
  return (0, _svg._g)(children);
};
exports._leaf = _leaf;
var _leaves = function _leaves(options) {
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [400, 300] : _options$o2,
    _options$r3 = options.r,
    r = _options$r3 === void 0 ? 100 : _options$r3,
    _options$a3 = options.a,
    a = _options$a3 === void 0 ? 0 : _options$a3,
    _options$n = options.n,
    n = _options$n === void 0 ? 6 : _options$n,
    _options$assemble = options.assemble,
    assemble = _options$assemble === void 0 ? true : _options$assemble;
  var ps = (0, _polar2.polarPoints)({
    o: o,
    r: r,
    n: n,
    a: a
  });
  return ps.map(function (t, i) {
    return _leaf(Object.assign(Object.assign({}, options), {
      o: assemble ? o : t,
      r: r,
      a: a + i * 360 / n
    }));
  });
};
exports._leaves = _leaves;
var trianglePoints = function trianglePoints(options) {
  var _options$o3 = options.o,
    o = _options$o3 === void 0 ? [400, 300] : _options$o3,
    _options$r4 = options.r,
    r = _options$r4 === void 0 ? 100 : _options$r4,
    _options$a4 = options.a,
    a = _options$a4 === void 0 ? 0 : _options$a4;
  var p = (0, _math._polar)(o, r, a);
  // 线段
  var points = [o, p];
  // 中点
  var mp = (0, _math._mid)(o, p);
  // 垂线
  var vPoints = points.map(function (t, i) {
    return (0, _math._polar)(mp, r / 2, a + 90 + 180 * i);
  });
  // 3个点
  points.push.apply(points, _toConsumableArray((0, _math._move)([p], vPoints[0], vPoints[1])));
  return points;
};
// 三角叶
var _triangleLeaf = function _triangleLeaf(options, props) {
  var _options$o4 = options.o,
    o = _options$o4 === void 0 ? [400, 300] : _options$o4,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 3 : _options$n2,
    _options$r5 = options.r,
    r = _options$r5 === void 0 ? 100 : _options$r5,
    _options$a5 = options.a,
    a = _options$a5 === void 0 ? 0 : _options$a5,
    vertex = options.vertex,
    assemble = options.assemble,
    _options$color = options.color,
    color = _options$color === void 0 ? _color2.Color.ColorCircle : _options$color;
  var children = [];
  var colors = (0, _color._colors)(color, n);
  var fn = function fn(_ref) {
    var o = _ref.o,
      r = _ref.r,
      a = _ref.a,
      stroke = _ref.stroke;
    var points = trianglePoints({
      o: o,
      r: r,
      a: a
    });
    var pg = (0, _polygon2._polygon)({
      points: points
    }, Object.assign({
      fill: 'none',
      stroke: stroke
    }, props));
    children.push(pg);
    // 顶点
    if (vertex) {
      var cirlces = points.map(function (t) {
        return (0, _svg._circle)({
          o: t,
          r: 3
        }, {
          fill: 'blue'
        });
      });
      children.push.apply(children, _toConsumableArray(cirlces));
    }
    // return pg
  };

  var ps = (0, _polar2.polarPoints)({
    o: o,
    r: r,
    n: n,
    a: a
  });
  ps.forEach(function (t, i) {
    return fn(Object.assign(Object.assign({}, options), {
      o: assemble ? o : t,
      r: r,
      a: a + i * 360 / n,
      stroke: colors[i]
    }));
  });
  return (0, _svg._g)(children);
};
exports._triangleLeaf = _triangleLeaf;