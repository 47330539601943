"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sierpinskiTrianglePoints = exports.sierpinskiPoints = exports.sierpinski3Points = exports.bottomControlGearTypes = void 0;
var _math = require("@/math");
var _traversal2 = require("./traversal");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/*绘制谢尔宾斯基三角形的方法
  p：正三角形中心点坐标，r:顶点到中心点距离，depth：递归深度*/
var sierpinskiTrianglePoints = function sierpinskiTrianglePoints(_ref) {
  var o = _ref.o,
    r = _ref.r,
    depth = _ref.depth,
    _ref$a = _ref.a,
    a = _ref$a === void 0 ? 0 : _ref$a,
    _ref$k = _ref.k,
    k = _ref$k === void 0 ? 1 : _ref$k;
  //  三角形边长
  var len = r * Math.sqrt(3);
  //记录当前端点，默认为左下角顶点坐标
  var currentPoint = [o[0] - len / 2, o[1] + r / 2];
  //记录当前方向角
  var currentAngle = a;
  //旋转方法，将下次画线的方向逆时针旋转
  function turn(angle) {
    currentAngle += angle;
  }
  //开始画折线，如果深度是偶数便可直接绘制折线，否则需要以斜60度为初始方向
  var points = [currentPoint];
  var _iter = function _iter(depth, length, angle) {
    if (depth === 0) {
      currentPoint = (0, _math._move)(currentPoint, [0, 0], [(0, _math._k)(length * (0, _math._cos)(currentAngle)), -k * (0, _math._k)(length * (0, _math._sin)(currentAngle))]);
      points.push(currentPoint);
    } else {
      //递归画三段折线
      _iter(depth - 1, length / 2, -angle);
      turn(angle);
      _iter(depth - 1, length / 2, angle);
      turn(angle);
      _iter(depth - 1, length / 2, -angle);
    }
  };
  if (depth % 2 == 0) {
    _iter(depth, len, -60);
  } else {
    turn(60);
    _iter(depth, len, -60);
  }
  return points;
};
exports.sierpinskiTrianglePoints = sierpinskiTrianglePoints;
var bottomControlGearTypes = ['bottom', 'middle', 'top'];
// 顶部控制点
exports.bottomControlGearTypes = bottomControlGearTypes;
var _topControl = function _topControl(_ref2, o, amplitude) {
  var _ref3 = _slicedToArray(_ref2, 2),
    from = _ref3[0],
    to = _ref3[1];
  var o1 = (0, _math._o)([from, to]);
  return amplitude ? (0, _math._lerp)(o1, o, amplitude) : o1;
};
// 底部控制点
var _bottomControl = function _bottomControl(_ref4, bottomControlGear) {
  var _ref5 = _slicedToArray(_ref4, 4),
    from = _ref5[0],
    c1 = _ref5[1],
    c2 = _ref5[2],
    to = _ref5[3];
  var ps;
  switch (bottomControlGear) {
    case 'bottom':
      ps = [from, to];
      break;
    case 'middle':
      ps = [from, c1, c2, to];
      break;
    case 'top':
      ps = [c1, c2];
      break;
    default:
      ps = [from, c1, c2, to];
  }
  return (0, _math._o)(ps);
};
var _sierpinski = function _sierpinski(_ref6, o, depth, bottomControlGear, amplitude) {
  var _ref7 = _slicedToArray(_ref6, 2),
    from = _ref7[0],
    to = _ref7[1];
  var o1 = _topControl([from, to], o, amplitude);
  var c1 = (0, _math._mid)(from, o1);
  var c2 = (0, _math._mid)(to, o1);
  if (depth === 0) {
    return [from];
  } else if (depth === 1) {
    return [from, c1, c2];
  } else {
    var o2 = _bottomControl([from, c1, c2, to], bottomControlGear);
    depth -= 1;
    var ps = [];
    ps.push.apply(ps, _toConsumableArray(_sierpinski([from, c1], o2, depth, bottomControlGear, amplitude)));
    ps.push.apply(ps, _toConsumableArray(_sierpinski([c1, c2], o1, depth, bottomControlGear, amplitude)));
    ps.push.apply(ps, _toConsumableArray(_sierpinski([c2, to], o2, depth, bottomControlGear, amplitude)));
    return ps;
  }
};
// 边与中心点 组成一个三角形
var sierpinskiPoints = function sierpinskiPoints(_ref8) {
  var points = _ref8.points,
    endToEnd = _ref8.endToEnd,
    _ref8$depth = _ref8.depth,
    depth = _ref8$depth === void 0 ? 0 : _ref8$depth,
    _ref8$loop = _ref8.loop,
    loop = _ref8$loop === void 0 ? false : _ref8$loop,
    bottomControlGear = _ref8.bottomControlGear,
    amplitude = _ref8.amplitude,
    discrete = _ref8.discrete;
  var o = (0, _math._o)(points);
  var result = [];
  var len = points.length;
  var iter = function iter(_ref9) {
    var _ref9$points = _slicedToArray(_ref9.points, 2),
      t = _ref9$points[0],
      next = _ref9$points[1],
      index = _ref9.index;
    var points = _sierpinski([t, next], o, depth, bottomControlGear, amplitude);
    // 让不同颜色，保持收尾相接
    if (endToEnd) {
      if (index < len) {
        var lastPoints = result[result.length - 1];
        lastPoints && lastPoints.push(points[0]);
      }
      if (index === len - 1) {
        points.push(result[0][0]);
      }
    }
    result.push(points);
  };
  var n = 2;
  (0, _traversal2._traversal)({
    points: points,
    n: n,
    loop: loop,
    iter: iter,
    discrete: discrete
  });
  return result;
};
exports.sierpinskiPoints = sierpinskiPoints;
var sierpinski3Points = function sierpinski3Points(_ref10) {
  var points = _ref10.points,
    _ref10$depth = _ref10.depth,
    depth = _ref10$depth === void 0 ? 0 : _ref10$depth,
    _ref10$loop = _ref10.loop,
    loop = _ref10$loop === void 0 ? false : _ref10$loop;
  var result = [];
  var iter = function iter(_ref11) {
    var _ref11$points = _slicedToArray(_ref11.points, 3),
      p1 = _ref11$points[0],
      p2 = _ref11$points[1],
      p3 = _ref11$points[2];
    var ps = _sierpinski([p1, p2], p3, depth, 'bottom');
    result.push(ps);
  };
  var n = 4;
  (0, _traversal2._traversal)({
    points: points,
    loop: loop,
    n: n,
    iter: iter
  });
  return result;
};
exports.sierpinski3Points = sierpinski3Points;