"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._progress = void 0;
require("core-js/modules/es.function.name.js");
var _dom = require("./dom");
var _math = require("../../math");
var _input = require("./input");
var _progress = function _progress(options, events) {
  var label = options.label,
    value = options.value,
    max = options.max,
    name = options.name;
  var hidden = (0, _input._hidden)(value, {
    name: name
  });
  var pLabel = _dom.dom.div(label, {
    class: 'progress-info-label'
  });
  var pValue = _dom.dom.div(value, {
    class: 'progress-info-value'
  });
  var info = _dom.dom.div([pLabel, pValue, hidden], {
    class: 'progress-info'
  });
  var thumb = _dom.dom.div('', {
    class: 'progress-thumb'
  }, {
    // mousedown: (e) => {
    //     e.stopPropagation();
    //     document.body.addEventListener('mousemove', changeProgressValue, false);
    // },
    // mouseup: (e) => {
    //     e.stopPropagation();
    //     document.body.removeEventListener('mousemove', changeProgressValue, false);
    // },
    // mouseleave: (e) => {
    //     e.stopPropagation();
    //     document.body.removeEventListener('mousemove', changeProgressValue, false);
    // }
  });
  var activeBar = _dom.dom.div(thumb, {
    class: 'progress-bar-active',
    style: {
      width: "".concat(value * 100, "%")
    }
  }, {
    // click: (e) => {
    //     e.stopPropagation();
    //     changeProgressValue(e)
    // },
  });
  var changeProgressValue = function changeProgressValue(e) {
    var _bar$getBoundingClien = bar.getBoundingClientRect(),
      left = _bar$getBoundingClien.left,
      width = _bar$getBoundingClien.width;
    var dis = e.clientX - left;
    var value = (0, _math._k)(dis / width, 1);
    if (value <= 0) value = 0;
    if (value >= 1) value = 1;
    (0, _dom._attr)(hidden, {
      value: value
    });
    pValue.innerHTML = '' + value;
    (0, _dom._style)(activeBar, {
      width: "".concat(value * 100, "%")
    });
    if (events.change && typeof events.change === 'function') {
      events.change();
    }
  };
  var isMouseDown = false;
  var bar = _dom.dom.div(activeBar, {
    class: 'progress-bar'
  }, {
    mousedown: function mousedown(e) {
      e.stopPropagation();
      if (e.button == 0) {
        // 判断点击左键
        isMouseDown = true;
        changeProgressValue(e);
      }
    }
  });
  document.addEventListener('mousemove', function (e) {
    if (isMouseDown) {
      changeProgressValue(e);
    }
  });
  document.addEventListener('mouseup', function (e) {
    if (e.button == 0) {
      isMouseDown = false;
    }
  });
  return _dom.dom.div([info, bar], {
    class: 'form-item-progress'
  });
};
exports._progress = _progress;