"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SVGTagName = exports.FSVG = void 0;
var _box = require("./box");
var _group = require("./group");
var _path = require("./path");
var _line = require("./line");
var _rect = require("./rect");
var _circle = require("./circle");
var _ellipse = require("./ellipse");
var SVGTagName;
exports.SVGTagName = SVGTagName;
(function (SVGTagName) {
  SVGTagName["Rect"] = "rect";
  SVGTagName["Path"] = "path";
  SVGTagName["G"] = "g";
  SVGTagName["Line"] = "line";
  SVGTagName["Circle"] = "circle";
  SVGTagName["Ellipse"] = "ellipse";
})(SVGTagName || (exports.SVGTagName = SVGTagName = {}));
/**
 * FSVG
 *
 * simple SVGElement encapsulation
 */
function create(el) {
  var tagName = el.tagName;
  if (tagName === SVGTagName.Rect) {
    return new FSVG.Rect(el);
  } else if (tagName === SVGTagName.Circle) {
    return new FSVG.Circle(el);
  } else if (tagName === SVGTagName.Ellipse) {
    return new FSVG.Ellipse(el);
  } else if (tagName === SVGTagName.G) {
    return new FSVG.Group(el);
  } else if (tagName === SVGTagName.Path) {
    return new FSVG.Path(el);
  } else {
    throw new Error("Can not creat ".concat(tagName, " instance, no match class."));
  }
}
var FSVG = {
  create: create,
  Rect: _rect.Rect,
  Box: _box.Box,
  Group: _group.Group,
  Path: _path.Path,
  Line: _line.Line,
  Text: Text,
  Circle: _circle.Circle,
  Ellipse: _ellipse.Ellipse
  //   Div,
};
exports.FSVG = FSVG;