"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._circle = void 0;
require("core-js/modules/es.array.fill.js");
var _canvas = require("./canvas");
var _circle = function _circle(ctx, options, props) {
  var o = options.o,
    r = options.r,
    centre = options.centre;
  ctx.save();
  ctx.lineWidth = 1;
  (0, _canvas._props)(ctx, props);
  ctx.beginPath();
  ctx.arc(o[0], o[1], r, 0, Math.PI * 2, false);
  ctx.stroke();
  ctx.fill();
  ctx.restore();
  if (centre) {
    _circle(ctx, {
      o: o,
      r: 3
    }, {
      fill: 'red',
      stroke: 'none'
    });
  }
};
exports._circle = _circle;