"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPanelByName = exports.getGraphicDataList = exports.getGraphicDataByName = exports.getGraphicData = exports.getDataLength = exports.getCurrentPanel = exports.getCurrentGraphicData = exports.getCheckedOpenList = exports.getCanvas = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.function.name.js");
var _saver = require("@/saver");
var _store = require("@/store");
var _dom = require("../core/dom");
var getGraphicData = function getGraphicData(panel) {
  if (panel) {
    var name = panel.getAttribute('name');
    var node = panel.firstChild;
    var width = node.getAttribute('width');
    var height = node.getAttribute('height');
    // 可视大小
    // const { width, height } = firstchild.getBoundingClientRect()
    var size = {
      width: parseFloat(width) || 100,
      height: parseFloat(height) || 100
    };
    if (node instanceof HTMLCanvasElement) {
      var data = node;
      var type = _saver.GraphicDataType.canvas;
      return {
        data: data,
        type: type,
        size: size,
        name: name
      };
    } else {
      var _data = panel.innerHTML;
      var _type = _saver.GraphicDataType.svg;
      return {
        data: _data,
        type: _type,
        size: size,
        name: name
      };
    }
  }
};
exports.getGraphicData = getGraphicData;
var getGraphicDataByName = function getGraphicDataByName(name) {
  var panel = (0, _dom._query)(".screen-viewer .panel[name=".concat(name, "]"));
  return getGraphicData(panel);
};
exports.getGraphicDataByName = getGraphicDataByName;
var getGraphicDataList = function getGraphicDataList() {
  var panels = (0, _dom._queryAll)('.screen-viewer .panel');
  return Array.from(panels).map(function (t) {
    return getGraphicData(t);
  });
};
exports.getGraphicDataList = getGraphicDataList;
var getCanvas = function getCanvas(panel) {
  var firstchild = panel.firstChild;
  return firstchild;
};
exports.getCanvas = getCanvas;
var getDataLength = function getDataLength(_ref) {
  var data = _ref.data,
    type = _ref.type;
  if (type === _saver.GraphicDataType.svg) {
    return data.length;
  }
  if (type === _saver.GraphicDataType.canvas) {
    return data.toDataURL().length;
  }
};
exports.getDataLength = getDataLength;
var getCheckedOpenList = function getCheckedOpenList() {
  var checkboxList = (0, _dom._queryAll)('.graphic-open-list input[type="checkbox"]');
  return checkboxList.filter(function (t) {
    return t.checked;
  }).map(function (t) {
    return getGraphicDataByName(t.getAttribute('name'));
  });
};
exports.getCheckedOpenList = getCheckedOpenList;
var getCurrentPanel = function getCurrentPanel() {
  var page = _store.store.getPage();
  var name = page.name;
  var panel = (0, _dom._query)(".screen-viewer .panel[name=".concat(name, "]"));
  return panel;
};
exports.getCurrentPanel = getCurrentPanel;
var getCurrentGraphicData = function getCurrentGraphicData() {
  var panel = getCurrentPanel();
  return getGraphicData(panel);
};
exports.getCurrentGraphicData = getCurrentGraphicData;
var getPanelByName = function getPanelByName(name) {
  var panel = (0, _dom._query)(".screen-viewer .panel[name=".concat(name, "]"));
  return panel;
};
exports.getPanelByName = getPanelByName;