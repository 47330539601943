"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._lottie = void 0;
var _lottieWeb = _interopRequireDefault(require("lottie-web"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _lottie = function _lottie(data) {
  var callback = function callback(panel) {
    _lottieWeb.default.loadAnimation({
      container: panel,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: data //t.content //
      // assetsPath: CDN_URL,
    });
  };

  return callback;
  // return dom.div('',{
  //     class: name
  // })
  // animation.play();
};
// animation.pause();
exports._lottie = _lottie;