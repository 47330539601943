"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.layoutTypes = exports.layoutPoints = void 0;
var _math = require("../math");
var _square = require("./square");
var layoutTypes = ['0', '2x2', '4x4'];
// 排版布局
exports.layoutTypes = layoutTypes;
var layoutPoints = function layoutPoints(layout, options) {
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o;
  var r = Math.floor(Math.min(width, height) / 4);
  var arr = [];
  switch (layout) {
    case '2x2':
      arr = (0, _square._squarePoints)(o, r);
      break;
    case '4x4':
      arr = (0, _math.plainMatrix)((0, _square._squarePoints4_4)(o, r));
      break;
    default:
      arr = [o];
  }
  return arr;
};
exports.layoutPoints = layoutPoints;