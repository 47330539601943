"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderContent = void 0;
require("core-js/modules/es.function.name.js");
var _ = require(".");
var _grid2 = require("./core/grid");
var _svg2 = require("../svg");
var _canvas2 = require("../canvas");
var _webgl2 = require("../webgl");
var _rotute = require("./rotute");
var _lottie2 = require("@/lottie");
var renderContent = function renderContent(t) {
  var name = t.name,
    content = t.content,
    textContent = t.textContent,
    contentType = t.contentType,
    render = t.render;
  // 更新路由
  (0, _rotute.updateRoute)(t);
  var contentConfig = content;
  contentConfig.name = name;
  var isHtml;
  var str = '';
  switch (contentType.toLowerCase()) {
    case 'form':
      str = (0, _._form)(contentConfig);
      break;
    case 'grid':
      str = (0, _grid2._grid)(contentConfig, contentConfig.data);
      break;
    // case 'list':
    //   str = _list(contentConfig, render)
    //   break
    case 'html':
      str = textContent;
      isHtml = true;
      break;
    case 'svg':
      str = (0, _svg2._svg)(contentConfig);
      break;
    case 'canvas':
      str = (0, _canvas2._canvas)(contentConfig);
      break;
    // case 'btns':
    //   str = contentConfig.map(t => _btn(t))
    //   break
    case 'webgl':
      str = (0, _webgl2._webgl)(contentConfig);
      break;
    case 'lottie':
      str = (0, _lottie2._lottie)(contentConfig);
      break;
    default:
      str = textContent;
  }
  return {
    content: str,
    isHtml: isHtml
  };
};
exports.renderContent = renderContent;