"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._waveMaze = exports._maze = exports._circleMaze = void 0;
var _circleMaze2 = require("@/algorithm/maze/circleMaze");
var _maze2 = require("@/algorithm/maze/maze");
var _waveMaze2 = require("@/algorithm/maze/waveMaze");
var _helper = require("../helper");
// 迷宫
var _maze = function _maze(options, props) {
  var matrix = (0, _maze2.mazePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._maze = _maze;
var _circleMaze = function _circleMaze(options, props) {
  var matrix = (0, _circleMaze2.circleMazePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._circleMaze = _circleMaze;
var _waveMaze = function _waveMaze(options, props) {
  var matrix = (0, _waveMaze2.waveMazePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._waveMaze = _waveMaze;