"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.markInfo = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.concat.js");
var _math = require("../math");
var _vec = require("../math/vec");
var _labels = require("../common/labels");
var _shapeRect = require("./shapeRect");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var markInfo = function markInfo(points, options) {
  var _options$angle = options.angle,
    angle = _options$angle === void 0 ? true : _options$angle,
    _options$edge = options.edge,
    edge = _options$edge === void 0 ? true : _options$edge,
    _options$r = options.r,
    r = _options$r === void 0 ? 20 : _options$r,
    _options$radius = options.radius,
    radius = _options$radius === void 0 ? false : _options$radius,
    _options$labels = options.labels,
    labels = _options$labels === void 0 ? false : _options$labels,
    _options$boundingRect = options.boundingRect,
    boundingRect = _options$boundingRect === void 0 ? false : _options$boundingRect,
    _options$boundingSize = options.boundingSize,
    boundingSize = _options$boundingSize === void 0 ? false : _options$boundingSize;
  var len = points.length;
  var edgeInfo = [];
  var es1 = [];
  var es2 = [];
  var angleInfo = [];
  var polygonSweepFlag = (0, _vec.checkPolygonSweepFlag)(points);
  var radiusInfo = [];
  var o = (0, _math._o)(points);
  var labelsInfo = [];
  var boundingSizeInfo = [];
  var boundingRectPoints = [];
  points.forEach(function (t, i) {
    // 逆时针 ===1
    var sweepFlag = (0, _vec.checkSweepFlag)(points, i);
    var prev = points[i - 1 < 0 ? len - 1 : i - 1];
    var next = points[(i + 1) % len];
    var a = (0, _math._angleRad)(t, next);
    var ap = (0, _math._angleRad)(t, prev);
    var ia = (0, _math._angleToRad)(prev, t, next);
    // 夹角
    if (angle) {
      var _pn = (0, _math._polarRad)(t, r, a);
      var _PP = (0, _math._polarRad)(t, r, ap);
      angleInfo.push({
        points: [_PP, t, _pn],
        a: a,
        ia: ia,
        sweepFlag: sweepFlag,
        o: t,
        r: r,
        labelPos: (0, _math._polarRad)(t, r * 2, sweepFlag === 0 ? a - ia / 2 : a + ia / 2),
        label: (0, _math._deg)(ia) + '°'
      });
    }
    // 半径
    if (radius) {
      radiusInfo.push({
        points: [o, t],
        labelPos: (0, _math._mid)(o, t),
        label: (0, _math._dis)(o, t)
      });
    }
    if (labels) {
      labelsInfo.push({
        labelPos: (0, _math._moveDis)(t, o, r),
        label: (0, _labels._alphabet)(i)
      });
    }
    // 逆时针
    var k = polygonSweepFlag === 1 ? -1 : 1;
    var pn = (0, _math._polarRad)(t, r * 2, a - k * Math.PI / 2);
    es1.push([t, pn]);
    var PP = (0, _math._polarRad)(t, r * 2, ap + k * Math.PI / 2);
    es2.push([t, PP]);
  });
  // 边长度
  if (edge) {
    var _es = function _es(es1, es2) {
      es1.forEach(function (t, i) {
        var next = es2[(i + 1) % len];
        var m = (0, _math._mid)(t[1], next[1]);
        var d = (0, _math._dis)(t[1], next[1]);
        edgeInfo.push({
          points: [].concat(_toConsumableArray(t), [next[1], next[0]]),
          label: d,
          labelPos: m
        });
      });
    };
    _es(es1, es2);
  }
  if (boundingSize) {
    var rect = (0, _shapeRect.getBoundingClientRectPoints)(points);
    var left = rect.left,
      right = rect.right,
      top = rect.top,
      bottom = rect.bottom;
    var by = bottom[1] + r * 3;
    boundingSizeInfo.push({
      points: [left, [left[0], by], [right[0], by], right],
      label: right[0] - left[0],
      labelPos: [(right[0] + left[0]) / 2, by]
    });
    var lx = left[0] - r * 3;
    boundingSizeInfo.push({
      points: [bottom, [lx, bottom[1]], [lx, top[1]], top],
      label: bottom[1] - top[1],
      labelPos: [lx, (bottom[1] + top[1]) / 2]
    });
  }
  if (boundingRect) {
    var _rect = (0, _shapeRect.getBounds)(points);
    var _left = _rect.left,
      _right = _rect.right,
      _top = _rect.top,
      _bottom = _rect.bottom;
    var midx = (_left + _right) / 2;
    var midy = (_top + _bottom) / 2;
    boundingRectPoints = [[_left, _top], [midx, _top], [_right, _top], [_right, midy], [_right, _bottom], [midx, _bottom], [_left, _bottom], [_left, midy]];
  }
  return {
    angleInfo: angleInfo,
    edgeInfo: edgeInfo,
    radiusInfo: radiusInfo,
    labelsInfo: labelsInfo,
    boundingSizeInfo: boundingSizeInfo,
    boundingRectPoints: boundingRectPoints
  };
};
exports.markInfo = markInfo;