"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fractalConfig = void 0;
var _sierpinskiTriangle = require("./sierpinskiTriangle");
var _koch = require("./koch");
var _hexaflake = require("./hexaflake");
var _leaf2 = require("./leaf");
var _wanhuachi2 = require("./wanhuachi");
var _square = require("./square");
var _isometric2 = require("./isometric");
var _tree2 = require("./tree");
var _plant2 = require("./plant");
var _dragon2 = require("./dragon");
var _peano2 = require("./peano");
var _shape = require("@/types/shape");
var _fractalConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var fractalConfig = (_fractalConfig = {}, _defineProperty(_fractalConfig, _shape.Shape.SquareFractal, _square._squareFractal), _defineProperty(_fractalConfig, _shape.Shape.Isometric, _isometric2._isometric), _defineProperty(_fractalConfig, _shape.Shape.SierpinskiTriangle, _sierpinskiTriangle.sierpinskiTriangle), _defineProperty(_fractalConfig, _shape.Shape.Koch, _koch.koch), _defineProperty(_fractalConfig, _shape.Shape.Mitsubishi, _koch.mitsubishi), _defineProperty(_fractalConfig, _shape.Shape.Hexaflake, _hexaflake.hexaflake), _defineProperty(_fractalConfig, _shape.Shape.Leaf, _leaf2._leaf), _defineProperty(_fractalConfig, _shape.Shape.Leaves, _leaf2._leaves), _defineProperty(_fractalConfig, _shape.Shape.TriangleLeaf, _leaf2._triangleLeaf), _defineProperty(_fractalConfig, _shape.Shape.Wanhuachi, _wanhuachi2._wanhuachi), _defineProperty(_fractalConfig, _shape.Shape.Tree, _tree2._tree), _defineProperty(_fractalConfig, _shape.Shape.Plant, _plant2._plant), _defineProperty(_fractalConfig, _shape.Shape.Dragon, _dragon2._dragon), _defineProperty(_fractalConfig, _shape.Shape.Peano, _peano2._peano), _fractalConfig);
exports.fractalConfig = fractalConfig;