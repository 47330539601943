"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.map.js");
var _ui = require("@/types/ui");
var axis = ['svgAxis', 'svgGrid', 'svgPolar', 'svgGridPoints'];
var svgPattern = ['svgCirclesPattern', 'svgRectsPattern', 'svgGridRect', 'svgGridPolygon'];
// 'svgCircle', 'svgRect', 'svgLines',
// 'svgtriangle', 'svgPolygon', 'svgPolygonColor', 'svgPolygonLink','svgCurves',  'svgSawtooths',
// 'svgFerrisWheel', 'svgComplex',
var polygon = ['svgRay', 'svgRayCurve', 'svgIsometric', 'svgPolarShape', 'svgSpiralShape', 'svgRingShape', 'svgRayShape', 'svgGridShape', 'svgIsometricShape', 'svgPolygonShape'];
var fractal = ['svgEdgeMirror', 'svgVertexMirror', 'svgEdgeFractal', 'svgVertexFractal', 'svgEdgeMirrorLink', 'svgFractalGroup', 'svgFractalFull', 'svgFractalRadio', 'svgEdgeMid', 'svgYangehui', 'svgLeaves', 'svgTriangleLeaf', 'svgSquareFractal', 'svgSierpinskiTriangle', 'svgMitsubishi', 'svgHexaflake', 'svgTree', 'svgPlant', 'svgDragon', 'svgOneline'];
// const curve = ['svgCurve', 'svgWave', 'svgSawtooth', 'svgSemicircle', 'svgElliptical', 'svgEllipticalLink',
//     'svgSin']
var star = ['svgStar5', 'svgStar6', 'svgStar7', 'svgStar8', 'svgStar12'];
var icon = ['svgIconPlay', 'svgIconPrev', 'svgIconPause', 'svgIconStop'];
var transform = ['svgMisplaced5', 'svgMisplaced6', 'svgMisplaced7', 'svgMisplaced8', 'svgMisplaced9'];
var pathpoints = ['svgPolygonPath', 'svgPolygonPath2', 'svgPolygonPath3', 'svgPolygonPath4', 'svgPolygonPath5'];
// const game = [ 'svgMaze2']
// , 'svgMotion', 'svgLayout'
var canvasShape = ['canvasCircle', 'canvasRect', 'canvasPolygon', 'canvasPolygonMirror', 'canvasPolygonEdgeMirror', 'canvasPolarShape', 'canvasSpiralShape', 'canvasRingShape', 'canvasRayShape'];
var webglShape = ['glTriangle', 'glSquare', 'glCube']; //'webgl',
var canvasPlant = ['canvasPlant', 'canvasBush', 'canvasAquatic', 'canvasSaupe', 'canvasShrub', 'canvasAquaticPlant', 'canvasLsystemTree'];
var canvasButterfly = ['canvasButterfly', 'canvasButterfly2', 'canvasButterfly3', 'canvasButterfly4', 'canvasButterfly5'];
var sygPlant = ['svgLsystemTree', 'svgLsystem_2', 'svgAquaticPlant', 'svgShrub', 'svgSaupe', 'svgAquatic', 'svgBush', 'svgTree', 'svgTreeMode'];
// 'svgDragonCurve','svgTerdragon','svgMosaic','svgBlocks','svgLeaf', 'svgPenroseTiling',
var canvasStar = ['canvasStar5', 'canvasStar6', 'canvasStar7', 'canvasStar8', 'canvasStar12'];
var canvasGougu = ['canvasGougu', 'canvasGougu2', 'canvasGougu3', 'canvasGougu4'];
var canvasRough = ['canvasRough', 'canvasRough2'];
var svgKoch = ['svgKoch', 'svgKoch2', 'svgKoch3', 'svgKoch4', 'svgKoch5', 'svgKoch6', 'svgKoch7', 'svgKoch8', 'svgKoch9', 'svgKoch10'];
var svgSierpinski = ['svgSierpinski', 'svgSierpinski2', 'svgSierpinski3', 'svgSierpinski4', 'svgSierpinski5', 'svgSierpinski6', 'svgSierpinski7', 'svgSierpinski8', 'svgSierpinski9'];
var svgPeano = ['svgPeano', 'svgPeano2', 'svgPeano3'];
var svgButterfly = ['svgButterfly', 'svgButterfly2', 'svgButterfly3', 'svgButterfly4', 'svgButterfly5'];
var svgGougu = ['svgGougu', 'svgGougu2', 'svgGougu3', 'svgGougu4'];
var svgCurve = ['svgPolarShape', 'svgHeart', 'svgRose', 'svgFibonacci', 'svgSpiral', 'svgWanhuachi', 'svgMaze', 'svgCircleMaze', 'svgWaveMaze'];
var lottieGas = ['lottieGas', 'lottieEarphone'];
var items = {
  axis: axis,
  svgPattern: svgPattern,
  polygon: polygon,
  fractal: fractal,
  star: star,
  icon: icon,
  transform: transform,
  pathpoints: pathpoints,
  // game,
  canvasShape: canvasShape,
  webglShape: webglShape,
  canvasPlant: canvasPlant,
  canvasButterfly: canvasButterfly,
  sygPlant: sygPlant,
  canvasStar: canvasStar,
  canvasGougu: canvasGougu,
  canvasRough: canvasRough,
  svgKoch: svgKoch,
  svgSierpinski: svgSierpinski,
  svgPeano: svgPeano,
  svgButterfly: svgButterfly,
  svgGougu: svgGougu,
  svgCurve: svgCurve,
  lottieGas: lottieGas
};
var genTitle = function genTitle(name, type) {
  var reg = new RegExp("^".concat(type === 'webgl' ? 'gl' : type, "(.+)"));
  var str = name.replace(reg, "$1");
  return str.slice(0, 1).toLowerCase() + str.slice(1);
};
var _itemMap = function _itemMap(name, type) {
  var children = items[name].map(function (t) {
    return {
      title: genTitle(t, type),
      name: t,
      url: t,
      contentType: type
    };
  });
  return {
    name: name,
    title: name,
    children: children
  };
};
var svg = {
  title: 'svg',
  name: 'svg',
  children: ['svgCurve', 'sygPlant', 'svgPattern', 'svgKoch', 'svgSierpinski', 'svgPeano', 'svgButterfly', 'svgGougu'].map(function (t) {
    return _itemMap(t, _ui.ContentType.SVG);
  })
};
// 'game', 'polygon',
// if (location.href.indexOf('localhost') >= 0) {
//     svg.children = svg.children.concat(['axis', 'polygon', 'fractal', 'star', 'icon',
//         'transform', 'pathpoints'].map(t => _itemMap(t, 'svg')))
// }
var canvas = {
  title: 'canvas',
  name: 'canvas',
  children: ['canvasShape', 'canvasPlant', 'canvasButterfly', 'canvasStar', 'canvasGougu', 'canvasRough'].map(function (t) {
    return _itemMap(t, _ui.ContentType.Canvas);
  })
};
var webgl = {
  title: 'webgl',
  name: 'webgl',
  children: ['webglShape'].map(function (t) {
    return _itemMap(t, _ui.ContentType.Webgl);
  })
};
var lottie = {
  title: 'lottie',
  name: 'lottie',
  children: ['lottieGas'].map(function (t) {
    return _itemMap(t, _ui.ContentType.Lottie);
  }),
  contentType: _ui.ContentType.Lottie
};
var editor = {
  title: 'editor',
  name: 'editor',
  contentType: _ui.ContentType.Editor
  // children:[]
};

var menu = [svg, canvas, webgl, lottie, editor];
console.log(menu);
var _default = {
  menu: menu,
  activeName: '',
  target: '.main .screen-viewer'
};
exports.default = _default;