"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.registerShortcut = void 0;
var _ToolAbstract = require("../tools/ToolAbstract");
var registerShortcut = function registerShortcut(editor) {
  // 注册全局快捷键
  // 考虑使用 hotkeys-js https://www.npmjs.com/package/hotkeys-js 貌似很好用
  editor.shortcut.register('Undo', 'Cmd+Z', function () {
    editor.executeCommand('undo');
  }); // 撤销
  editor.shortcut.register('Undo', 'Ctrl+Z', function () {
    editor.executeCommand('undo');
  });
  editor.shortcut.register('Redo', 'Cmd+Shift+Z', function () {
    editor.executeCommand('redo');
  }); // 重做
  editor.shortcut.register('Redo', 'Ctrl+Shift+Z', function () {
    editor.executeCommand('redo');
  });
  editor.shortcut.register('Delete', ['Backspace', 'Delete'], function () {
    // 删除
    if (editor.activeElementsManager.isNotEmpty()) {
      editor.executeCommand('removeElements');
    }
  });
  editor.shortcut.register('Rect', 'R', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Rect);
  });
  editor.shortcut.register('Circle', 'C', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Circle);
  });
  editor.shortcut.register('Ellipse', 'E', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Ellipse);
  });
  editor.shortcut.register('Pencil', 'P', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Pencil);
  });
  editor.shortcut.register('Pen', 'V', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Pen);
  });
  editor.shortcut.register('Select', 'S', function () {
    editor.setCurrentTool(_ToolAbstract.ToolType.Select);
  });
  editor.shortcut.register('Undo', 'Ctrl+G', function () {
    editor.executeCommand('addGroup');
  });
};
exports.registerShortcut = registerShortcut;