"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._line = void 0;
var _line2 = _interopRequireDefault(require("./glsl/line.frag"));
var _line3 = _interopRequireDefault(require("./glsl/line.vert"));
var _webgl = require("./webgl");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _line = function _line(gl) {
  var program = (0, _webgl.defineProgram)(gl, _line3.default, _line2.default);
};
exports._line = _line;