"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._rect = exports._polygon = void 0;
var _polar = require("@/algorithm/polar");
var _helper = require("../helper");
var _polygon = function _polygon(ctx, options, props) {
  var matrix = (0, _polar.polarPoints)(options);
  // let { transform, mirror, pathMode = 'LINE_LOOP' } = options
  // // // 变形函数
  // matrix = _transform(matrix, transform)
  // matrix = _mirrorPoints(matrix, mirror)
  // const color = _mirrorColor(mirror)
  (0, _helper.renderMatrix)({
    matrix: matrix,
    ctx: ctx,
    options: options,
    // options: {
    //     ...options,
    //     pathMode,
    //     mirror: null,
    //     transform:null
    //     // color
    // },
    props: props
  });
  //
  // ctx.save();
  // _props(ctx, props)
  // matrix.forEach(points => {
  //     _path(ctx, {
  //         ...options,
  //         points,
  //         pathMode,
  //         mirror: null,
  //         color
  //     }, props)
  // })
  // ctx.restore();
};
exports._polygon = _polygon;
var _rect = function _rect(ctx, options, props) {
  options.n = 4;
  _polygon(ctx, options, props);
};
exports._rect = _rect;