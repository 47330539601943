"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._wanhuachi = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
var _path = require("../core/path");
var _svg = require("../core/svg");
// 圆的摆线原理
// 两圆内切，o1固定，o2内切滚动
var _wanhuachi = function _wanhuachi(options, props) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$r2 = options.r2,
    r2 = _options$r2 === void 0 ? 50 : _options$r2,
    _options$n = options.n,
    n = _options$n === void 0 ? 6 : _options$n,
    _options$m = options.m,
    m = _options$m === void 0 ? 10 : _options$m;
  var children = [];
  var outterCircle = (0, _svg._circle)({
    o: o,
    r: r
  }, {
    fill: 'none',
    stroke: 'black'
  });
  children.push(outterCircle);
  var ps = Array.from({
    length: n * m
  }, function (t, i) {
    var a2 = a + 360 * i / n;
    var o2 = (0, _math._polar)(o, r - r2, a2);
    // let innerCircle = _circle({
    //     o: o2,
    //     r: r2
    // }, {
    //     fill: 'none',
    //     stroke: 'black'
    // })
    var p = (0, _math._polar)(o2, r2, a2 + a2 * r / r2);
    // let circles = [p].map(t => _circle({
    //     o: t, r: 3
    // }, {
    //     fill: 'blue'
    // }))
    return p;
    // innerCircle,
    // children.push(...circles)
  });

  var path = (0, _path.pointsPath)({
    points: ps
  }, Object.assign({
    fill: 'none',
    stroke: 'blue'
  }, props));
  children.push(path);
  return children;
};
exports._wanhuachi = _wanhuachi;