"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.swapArr = exports.sortByOrder = exports.shuffle = exports.paritySort = exports.newSeq = exports.neighborSwap = exports.misplaced = exports.intervalSort = exports.diagonal = void 0;
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.array.find-index.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.to-string.js");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
//两个元素交换位置
var swapArr = function swapArr(arr, x, y) {
  // arr[x] = arr.splice(y, 1, arr[x])[0];
  arr.splice.apply(arr, [x, 1].concat(_toConsumableArray(arr.splice(y, 1, arr[x]))));
  return arr;
};
//从第几个开始重新编号,循环
exports.swapArr = swapArr;
var newSeq = function newSeq(arr, index) {
  arr.splice.apply(arr, [0, 0].concat(_toConsumableArray(arr.splice(index, arr.length - index))));
  return arr;
};
//邻居互换  数组,固定序号
exports.newSeq = newSeq;
var neighborSwap = function neighborSwap(arr, index) {
  if (index === undefined) {
    for (var i = 0; i < arr.length; i = i + 2) {
      swapArr(arr, i, i + 1);
    }
    return arr;
  } else {
    var fix = arr.splice(index, 1)[0];
    for (var _i = 0; _i < arr.length; _i = _i + 2) {
      swapArr(arr, _i, _i + 1);
    }
    arr.splice(index, 0, fix);
    return arr;
  }
};
//隔位重排
exports.neighborSwap = neighborSwap;
var intervalSort = function intervalSort(arr, index) {
  if (index !== undefined) {
    newSeq(arr, index);
  }
  for (var i = 0; i < arr.length - 2; i++) {
    swapArr(arr, i + 1, i + 2);
  }
  return arr;
};
//错位排序
exports.intervalSort = intervalSort;
var misplaced = function misplaced(arr) {
  for (var i = 0; i < arr.length / 2 + 1; i++) {
    var cur = arr.pop();
    arr.splice(i * 2 + 1, 0, cur);
  }
  return arr;
};
//奇偶数排序oddEvenSort
exports.misplaced = misplaced;
var paritySort = function paritySort(arr) {
  var seq = arr.map(function (t, i) {
    return i;
  });
  seq.sort(function (a, b) {
    if (a % 2 === 0) {
      if (b % 2 !== 0) {
        return -1;
      }
      return 0;
    } else {
      return 1;
    }
  });
  return seq.map(function (t) {
    return arr[t];
  });
};
// 对角点diagonal point
exports.paritySort = paritySort;
var diagonal = function diagonal(points) {
  var n = points.length;
  var mid = Math.floor(n / 2);
  var ps = [];
  for (var i = 0; i < mid; i++) {
    ps[ps.length] = points[i];
    ps[ps.length] = points[mid + i];
  }
  return ps;
};
//数组乱序 random
exports.diagonal = diagonal;
var shuffle = function shuffle(arr, index) {
  if (index === undefined) {
    for (var i = arr.length - 1; i >= 0; i--) {
      var rIndex = Math.floor(Math.random() * (i + 1));
      // 打印交换值
      var temp = arr[rIndex];
      arr[rIndex] = arr[i];
      arr[i] = temp;
    }
    return arr;
  }
  var res = [];
  // 取出固定值
  var fix = arr.splice(index, 1)[0];
  for (var _i2 = arr.length - 1; _i2 >= 0; _i2--) {
    var _rIndex = Math.floor(Math.random() * (_i2 + 1));
    res.push(arr[_rIndex]);
    arr.splice(_rIndex, 1);
  }
  // 将固定值放入指定位置
  res.splice(index, 0, fix);
  return res;
};
// 按指定顺序排序
// 未指定排最后
exports.shuffle = shuffle;
var sortByOrder = function sortByOrder(arr, order) {
  return arr.sort(function (a, b) {
    var aIndex = order.findIndex(function (t) {
      return new RegExp("^".concat(t, "$")).test(a);
    }); // ||
    var bIndex = order.findIndex(function (t) {
      return new RegExp("^".concat(t, "$")).test(b);
    }); //||
    if (aIndex === -1) return 1;
    if (bIndex === -1) return 1;
    return aIndex - bIndex;
    // if (order.indexOf(a) === -1) return 1
    // return order.indexOf(a) - order.indexOf(b)
  });
};
exports.sortByOrder = sortByOrder;