"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._listItem = exports._list = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var _dom = require("./dom");
var _listItem = function _listItem(item, render, active, events) {
  var children = render ? render(item) : JSON.stringify(item);
  return children ? (0, _dom.createElement)('li', {
    class: "item ".concat(active ? ' active' : '')
  }, events, children) : '';
};
// 列表
exports._listItem = _listItem;
var _list = function _list(items, render, options, events) {
  var ul = (0, _dom.createElement)('ul', options);
  (0, _dom._append)(ul, items.map(function (t) {
    var active = t.name === options.activeName;
    return _listItem(t, render, active, events);
  }));
  return ul;
};
exports._list = _list;