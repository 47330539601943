"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toMatrix = exports.noseToTail = exports.groupBy = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
// 数组切割
var toMatrix = function toMatrix(arr, n, noseToTail) {
  var matrix = [];
  // arr.length > 62500会溢出
  // let fn = (arr) => {
  //     matrix.push(arr.slice(0, n))
  //     if (arr.length > n) {
  //         fn(arr.slice(n))
  //     }
  // }
  // fn(arr, n)
  // return matrix
  var len = arr.length;
  for (var i = 0; i < len; i++) {
    if (i % n === 0) {
      if (noseToTail) {
        // 首位相接
        matrix.length && matrix[matrix.length - 1].push(arr[i]);
      }
      matrix[matrix.length] = [arr[i]];
    } else {
      matrix[matrix.length - 1].push(arr[i]);
    }
  }
  return matrix;
};
// 首位相接
exports.toMatrix = toMatrix;
var noseToTail = function noseToTail(arr) {
  var len = arr.length;
  arr.forEach(function (t, i) {
    if (i < len - 1) {
      var next = arr[i + 1];
      t.push(next[0]);
    }
  });
  return arr;
};
// 数组分组
exports.noseToTail = noseToTail;
var groupBy = function groupBy(arr, fn) {
  var group = {};
  arr.forEach(function (t, i) {
    var type = fn(t, i);
    group[type] = group[type] || [];
    group[type].push(t);
  });
  return group;
};
exports.groupBy = groupBy;