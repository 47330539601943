"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTopPoint = exports.getRightPoint = exports.getLeftPoint = exports.getBounds = exports.getBoundingClientRectPoints = exports.getBottomPoint = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 四至
var getLeft = function getLeft(points) {
  return Math.min.apply(Math, _toConsumableArray(points.map(function (t) {
    return t[0];
  })));
};
var getLeftPoint = function getLeftPoint(points) {
  var leftX = getLeft(points);
  return points.filter(function (t) {
    return t[0] === leftX;
  })[0];
};
exports.getLeftPoint = getLeftPoint;
var getRight = function getRight(points) {
  return Math.max.apply(Math, _toConsumableArray(points.map(function (t) {
    return t[0];
  })));
};
var getRightPoint = function getRightPoint(points) {
  var rightX = getRight(points);
  return points.filter(function (t) {
    return t[0] === rightX;
  })[0];
};
exports.getRightPoint = getRightPoint;
var getTop = function getTop(points) {
  return Math.min.apply(Math, _toConsumableArray(points.map(function (t) {
    return t[1];
  })));
};
var getTopPoint = function getTopPoint(points) {
  var topY = getTop(points);
  return points.filter(function (t) {
    return t[1] === topY;
  })[0];
};
exports.getTopPoint = getTopPoint;
var getBottom = function getBottom(points) {
  return Math.max.apply(Math, _toConsumableArray(points.map(function (t) {
    return t[1];
  })));
};
var getBottomPoint = function getBottomPoint(points) {
  var bottomY = getBottom(points);
  return points.filter(function (t) {
    return t[1] === bottomY;
  })[0];
};
exports.getBottomPoint = getBottomPoint;
var getBounds = function getBounds(points) {
  var bottom = getBottom(points),
    left = getLeft(points),
    top = getTop(points),
    right = getRight(points);
  return {
    bottom: bottom,
    left: left,
    top: top,
    right: right,
    width: Math.abs(left - right),
    height: Math.abs(top - bottom)
  };
};
exports.getBounds = getBounds;
var getBoundingClientRectPoints = function getBoundingClientRectPoints(points) {
  var bottom = getBottomPoint(points),
    left = getLeftPoint(points),
    top = getTopPoint(points),
    right = getRightPoint(points);
  return {
    bottom: bottom,
    left: left,
    top: top,
    right: right,
    width: Math.abs(left[0] - right[0]),
    height: Math.abs(top[1] - bottom[1])
  };
};
exports.getBoundingClientRectPoints = getBoundingClientRectPoints;