"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._triangles = exports._squares = exports._rayOne = exports._ray = exports._points = exports._ploygon = exports._pathMode = exports._link = exports._lines = exports._lineLoop = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.slice.js");
var _pathMode2 = require("@/types/pathMode");
var _array = require("@/math/array");
var _index = require("@/math/index");
var _curve2 = require("./curve");
var _circle2 = require("./circle");
var _arrayMix = require("@/math/arrayMix");
var _link2 = require("@/algorithm/link");
var _traversal2 = require("@/algorithm/traversal");
var _pathModeConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 点
var _points = function _points(_ref, options) {
  var points = _ref.points;
  var _ref2 = options !== null && options !== void 0 ? options : {},
    _ref2$r = _ref2.r,
    r = _ref2$r === void 0 ? 3 : _ref2$r;
  return points.map(function (t) {
    return (0, _circle2._circle)({
      o: t,
      r: r
    });
  }).join(' ');
};
// 线段
exports._points = _points;
var _lines = function _lines(_ref3) {
  var points = _ref3.points,
    curve = _ref3.curve;
  return (0, _curve2._curve)({
    points: points,
    curve: curve,
    step: 2,
    discrete: true
  });
};
// 中心射线
exports._lines = _lines;
var _ray = function _ray(_ref4) {
  var points = _ref4.points,
    curve = _ref4.curve;
  var o = (0, _index._o)(points);
  var ps = (0, _arrayMix.rayMix)(o, points);
  return (0, _curve2._curve)({
    points: ps,
    curve: curve,
    step: 2
  });
};
exports._ray = _ray;
var _rayOne = function _rayOne(_ref5) {
  var points = _ref5.points,
    curve = _ref5.curve;
  var o = (0, _index._o)(points);
  return (0, _curve2._curve)({
    points: [o, points[0]],
    curve: curve
  });
};
// 带状
exports._rayOne = _rayOne;
var _lineStrip = function _lineStrip(_ref6) {
  var points = _ref6.points,
    curve = _ref6.curve;
  return (0, _curve2._curve)({
    points: points,
    curve: curve
  });
};
// LINE_LOOP
var _lineLoop = function _lineLoop(_ref7) {
  var points = _ref7.points,
    curve = _ref7.curve;
  return (0, _curve2._curve)({
    points: points,
    curve: curve,
    loop: true
  });
};
// 扇
exports._lineLoop = _lineLoop;
var _rayFan = function _rayFan(_ref8) {
  var points = _ref8.points,
    curve = _ref8.curve;
  return _ray({
    points: points,
    curve: curve
  }) + _lineLoop({
    points: points
  });
};
// 多边形
var _ploygon = function _ploygon(_ref9) {
  var points = _ref9.points,
    n = _ref9.n,
    curve = _ref9.curve;
  var matrix = (0, _array.toMatrix)(points, n);
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve,
      loop: true
    });
  }).join(' ');
};
// 三角形
exports._ploygon = _ploygon;
var _triangles = function _triangles(_ref10) {
  var points = _ref10.points,
    curve = _ref10.curve;
  return _ploygon({
    points: points,
    n: 3,
    curve: curve
  });
};
exports._triangles = _triangles;
var _serial = function _serial(points, n, loop, curve) {
  var iter = function iter(_ref11) {
    var points = _ref11.points;
    return (0, _curve2._curve)({
      points: points,
      curve: curve,
      loop: true
    });
  };
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: iter,
    loop: loop
  });
};
var _triangleSerial = function _triangleSerial(_ref12) {
  var points = _ref12.points,
    curve = _ref12.curve;
  var n = 3;
  return _serial(points, n, false, curve);
};
var _squareSerial = function _squareSerial(_ref13) {
  var points = _ref13.points,
    curve = _ref13.curve;
  var n = 4;
  return _serial(points, n, false, curve);
};
var _triangleSerialLoop = function _triangleSerialLoop(_ref14) {
  var points = _ref14.points,
    curve = _ref14.curve;
  var n = 3;
  return _serial(points, n, true, curve);
};
var _squareSerialLoop = function _squareSerialLoop(_ref15) {
  var points = _ref15.points,
    curve = _ref15.curve;
  var n = 4;
  return _serial(points, n, true, curve);
};
var _curveMode = function _curveMode(points) {
  var loop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var n = 3;
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: function iter(_ref16) {
      var _ref16$points = _slicedToArray(_ref16.points, 3),
        p1 = _ref16$points[0],
        p2 = _ref16$points[1],
        p3 = _ref16$points[2];
      return (0, _curve2._quadraticCurveTo)([p2, p3]);
    },
    init: function init(_ref17) {
      var point = _ref17.point;
      return (0, _curve2._moveTo)(point);
    },
    loop: loop
  }).join(' ');
};
var _curveStrip = function _curveStrip(_ref18) {
  var points = _ref18.points;
  return _curveMode(points, false);
};
//
var _curveLoop = function _curveLoop(_ref19) {
  var points = _ref19.points;
  return _curveMode(points, true);
};
// 离散
var _discreteBezier = function _discreteBezier(_ref20) {
  var points = _ref20.points;
  var n = 4;
  var discrete = true;
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: function iter(_ref21) {
      var _ref21$points = _slicedToArray(_ref21.points, 4),
        p1 = _ref21$points[0],
        p2 = _ref21$points[1],
        p3 = _ref21$points[2],
        p4 = _ref21$points[3];
      return (0, _curve2._moveTo)(p1) + ' ' + (0, _curve2._bezierCurveTo)([p2, p3, p4]);
    },
    discrete: discrete
  }).join(' ');
};
// 连续
var _bezierMode = function _bezierMode(points) {
  var loop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var n = 4;
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: function iter(_ref22) {
      var _ref22$points = _slicedToArray(_ref22.points, 4),
        p1 = _ref22$points[0],
        p2 = _ref22$points[1],
        p3 = _ref22$points[2],
        p4 = _ref22$points[3];
      return (0, _curve2._bezierCurveTo)([p2, p3, p4]);
    },
    init: function init(_ref23) {
      var point = _ref23.point;
      return (0, _curve2._moveTo)(point);
    },
    loop: loop
  }).join(' ');
};
var _bezierStrip = function _bezierStrip(_ref24) {
  var points = _ref24.points;
  return _bezierMode(points, false);
};
var _bezierLoop = function _bezierLoop(_ref25) {
  var points = _ref25.points;
  return _bezierMode(points, true);
};
var _triangleStrip = function _triangleStrip(_ref26) {
  var points = _ref26.points,
    curve = _ref26.curve;
  var matrix = (0, _link2.stripPoints)(points, 3);
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve,
      loop: true
    });
  }).join(' ');
};
var _triangleStripLoop = function _triangleStripLoop(_ref27) {
  var points = _ref27.points,
    curve = _ref27.curve;
  var matrix = (0, _link2.stripLoopPoints)(points, 3);
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve,
      loop: true
    });
  }).join(' ');
};
var _triangleFan = function _triangleFan(_ref28) {
  var points = _ref28.points,
    curve = _ref28.curve;
  var matrix = (0, _link2.stripFanPoints)(points, 2);
  var last = matrix.pop();
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve
    });
  }).join(' ') + (0, _curve2._curve)({
    points: last,
    curve: curve,
    loop: true
  });
};
// 四边形
var _squares = function _squares(_ref29) {
  var points = _ref29.points,
    curve = _ref29.curve;
  return _ploygon({
    points: points,
    n: 4,
    curve: curve
  });
};
exports._squares = _squares;
var _squareStrip = function _squareStrip(_ref30) {
  var points = _ref30.points,
    curve = _ref30.curve;
  var matrix = (0, _link2.stripPoints)(points, 4);
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve,
      loop: true
    });
  }).join(' ');
};
var _squareStripLoop = function _squareStripLoop(_ref31) {
  var points = _ref31.points,
    curve = _ref31.curve;
  var matrix = (0, _link2.stripLoopPoints)(points, 4);
  return matrix.map(function (ps) {
    return (0, _curve2._curve)({
      points: ps,
      curve: curve,
      loop: true
    });
  }).join(' ');
};
// 扇线
var _lineFan = function _lineFan(_ref32) {
  var points = _ref32.points,
    curve = _ref32.curve;
  var _points2 = _toArray(points),
    p = _points2[0],
    rest = _points2.slice(1);
  var ps = (0, _arrayMix.rayMix)(p, rest);
  return (0, _curve2._curve)({
    points: ps,
    curve: curve
  });
};
var _link = function _link(_ref33) {
  var points = _ref33.points,
    curve = _ref33.curve;
  var lps = (0, _link2.linkPoints)(points);
  return (0, _curve2._curve)({
    points: lps,
    curve: curve,
    step: 2
  });
};
// interface IPathModeOptions
exports._link = _link;
var pathModeConfig = (_pathModeConfig = {}, _defineProperty(_pathModeConfig, _pathMode2.PathMode.POINTS, _points), _defineProperty(_pathModeConfig, _pathMode2.PathMode.LINE_STRIP, _lineStrip), _defineProperty(_pathModeConfig, _pathMode2.PathMode.LINE_LOOP, _lineLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.LINES, _lines), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLES, _triangles), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLE_SERIAL, _triangleSerial), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLE_SERIAL_LOOP, _triangleSerialLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.SQUARES, _squares), _defineProperty(_pathModeConfig, _pathMode2.PathMode.LINE_FAN, _lineFan), _defineProperty(_pathModeConfig, _pathMode2.PathMode.RAY, _ray), _defineProperty(_pathModeConfig, _pathMode2.PathMode.RAY_FAN, _rayFan), _defineProperty(_pathModeConfig, _pathMode2.PathMode.LINK, _link), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLE_STRIP, _triangleStrip), _defineProperty(_pathModeConfig, _pathMode2.PathMode.SQUARE_STRIP, _squareStrip), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLE_STRIP_LOOP, _triangleStripLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.SQUARE_STRIP_LOOP, _squareStripLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.SQUARE_SERIAL, _squareSerial), _defineProperty(_pathModeConfig, _pathMode2.PathMode.SQUARE_SERIAL_LOOP, _squareSerialLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.TRIANGLE_FAN, _triangleFan), _defineProperty(_pathModeConfig, _pathMode2.PathMode.CURVE_STRIP, _curveStrip), _defineProperty(_pathModeConfig, _pathMode2.PathMode.CURVE_LOOP, _curveLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.BEZIER_STRIP, _bezierStrip), _defineProperty(_pathModeConfig, _pathMode2.PathMode.BEZIER_LOOP, _bezierLoop), _defineProperty(_pathModeConfig, _pathMode2.PathMode.DISCRETE_BEZIER, _discreteBezier), _pathModeConfig);
var _pathMode = function _pathMode(_ref34) {
  var pathMode = _ref34.pathMode,
    points = _ref34.points,
    curve = _ref34.curve,
    closed = _ref34.closed;
  if (closed && !pathMode) {
    pathMode = _pathMode2.PathMode.LINE_LOOP;
  }
  var fn = pathModeConfig[pathMode] || _lineStrip;
  return fn({
    points: points,
    curve: curve
  });
};
exports._pathMode = _pathMode;