"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._checkbox_native = exports._checkboxItem = exports._checkbox = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.function.name.js");
var _dom = require("./dom");
var _checkbox_native = function _checkbox_native(val, props, events) {
  var options = props.options;
  if (Array.isArray(options)) {
    var checkboxs = options.map(function (t) {
      return _dom.dom.input(t.label, Object.assign(Object.assign({}, t), {
        type: 'checkbox'
      }), events);
    });
    var group = _dom.dom.div(checkboxs, {
      class: 'checkbox_group'
    });
    return group;
  } else {
    return _dom.dom.input('', Object.assign(Object.assign({}, props), {
      type: 'checkbox'
    }), events);
  }
};
exports._checkbox_native = _checkbox_native;
var _checkboxItem = function _checkboxItem(val, props, events) {
  var checked = props.checked,
    name = props.name;
  var isChecked = checked ? ' is-checked' : '';
  var inner = _dom.dom.span('', {
    class: 'checkbox__inner'
  }, {
    click: function click(e) {
      var el = e.target;
      var parent = (0, _dom._closest)(el, '.checkbox__input');
      (0, _dom._toggleClassName)(parent, 'is-checked');
      var input = (0, _dom._query)('input', parent);
      input.checked = parent.classList.contains('is-checked');
      e.stopPropagation();
      e.preventDefault();
      // return false
    }
  });

  var input = _dom.dom.input('', {
    type: 'checkbox',
    checked: checked ? true : undefined,
    name: name
  }, {});
  var checkboxInput = _dom.dom.span([inner, input], {
    class: 'checkbox__input' + isChecked
  });
  var label = _dom.dom.span(val, {
    class: 'checkbox__label'
  }, events);
  return _dom.dom.label([checkboxInput, label], {
    class: 'checkbox' //+ isChecked
  });
};
exports._checkboxItem = _checkboxItem;
var _checkbox = function _checkbox(val, props, events) {
  var _ref = props !== null && props !== void 0 ? props : {},
    options = _ref.options,
    name = _ref.name;
  if (Array.isArray(options)) {
    var checkboxs = options.map(function (t) {
      return _checkboxItem(t.label, {
        checked: t.value === val,
        name: name
      });
    });
    var group = _dom.dom.div(checkboxs, {
      class: 'checkbox_group'
    }, events);
    return group;
  } else {
    return _checkboxItem('', {});
  }
};
exports._checkbox = _checkbox;