"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapPropsData = exports.mapFormFields = exports.makeModel = exports.contentAssignModel = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.number.constructor.js");
var _types = require("./types");
var _arraySort = require("../math/arraySort");
var _utils = require("../utils");
var _ui = require("../ui");
var _eventBus = require("../ui/eventBus");
var _color = require("@/types/color");
var getValue = function getValue() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var prop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var index = prop.indexOf(_types.propsSplitChar);
  if (index === -1) {
    return obj ? obj[prop] : '';
  } else {
    return getValue(obj[prop.slice(0, index)], prop.slice(index + _types.propsSplitChar.length));
  }
};
var setValue = function setValue(obj, prop, value) {
  var index = prop.indexOf(_types.propsSplitChar);
  if (index === -1) {
    obj[prop] = value;
  } else {
    if (!obj[prop.slice(0, index)]) {
      obj[prop.slice(0, index)] = {};
    }
    setValue(obj[prop.slice(0, index)], prop.slice(index + _types.propsSplitChar.length), value);
  }
};
var _push = function _push(list, key) {
  if (Array.isArray(key)) {
    key.forEach(function (t) {
      return _push(list, t);
    });
  } else {
    !list.includes(key) && list.push(key);
  }
};
// 保留属性
var keepProps = function keepProps(obj, t) {
  return ['number', 'string', 'boolean'].includes((0, _utils._type)(obj[t]));
};
var mapPropsData = function mapPropsData(shapeObj) {
  // 默认参数 旋转角度
  if (_types.rotatableShape.includes(shapeObj.shape)) {
    if (shapeObj.a === undefined) {
      shapeObj.a = 0;
    }
  }
  // 加颜色参数
  if (!shapeObj.color && !shapeObj.props && ['polygon', 'star', 'spiral', 'triangleLeaf'].includes(shapeObj.shape) && ['fractal', 'pathPoints', 'vertex'].some(function (t) {
    return shapeObj[t];
  })) {
    shapeObj.color = _color.Color.ColorCircle;
  }
  var keys = Object.keys(shapeObj).filter(function (t) {
    return keepProps(shapeObj, t);
  });
  // 多层级属性
  _types.multiLevelProps.forEach(function (t) {
    var obj = shapeObj[t];
    if ((0, _utils.isObject)(obj)) {
      var props = Object.keys(obj);
      if (props.length) {
        props.filter(function (u) {
          return keepProps(obj, u);
        }).forEach(function (u) {
          _push(keys, "".concat(t).concat(_types.propsSplitChar).concat(u));
        });
      } else {
        // 布尔型
        _push(keys, t);
      }
    } else if (obj) {
      // 字符
      _push(keys, t);
    }
  });
  // if (layoutable) {
  //     // 坐标轴
  //     _push(keys, ['axis', 'layout'])
  // }
  keys = (0, _arraySort.sortByOrder)(keys, _types.keysOrder);
  return keys;
};
exports.mapPropsData = mapPropsData;
var mapFormFields = function mapFormFields(content) {
  var shapes = content.shapes,
    axis = content.axis;
  var shapeObj = shapes[0] || {};
  var keys = mapPropsData(shapeObj);
  return keys.map(function (field) {
    var value = field === 'axis' ? getValue(axis, field) : getValue(shapeObj, field);
    var type = (0, _utils._type)(value);
    // 空对象转布尔
    if (type === 'object' && Object.keys(value).length === 0) {
      type = 'checkbox';
      value = true;
    }
    if (type === 'number' && /(alpha|opacity)/.test(field)) {
      type = 'progress';
    }
    var item = Object.assign(Object.assign({}, _types.defaultProps.find(function (t) {
      return t.field === field;
    })), {
      field: field,
      label: field,
      value: value,
      type: type,
      disabled: /([a-z]+_)?shape$/.test(field)
    });
    // 字段分组
    (0, _types._groupField)(field, item);
    // 下拉选项
    (0, _types._selectOptions)(field, item);
    return item;
  });
};
// 深度覆盖
exports.mapFormFields = mapFormFields;
var deepAssign = function deepAssign(obj, obj2) {
  Object.keys(obj).forEach(function (t) {
    if (obj2.hasOwnProperty(t)) {
      if ((0, _utils._type)(obj[t]) === 'object' && (0, _utils._type)(obj2[t]) === 'object') {
        Object.assign(obj[t], obj2[t]);
      } else {
        obj[t] = obj2[t];
      }
    }
  });
  return obj;
};
var contentAssignModel = function contentAssignModel(content, model) {
  var newModel = {};
  Object.keys(model).forEach(function (t) {
    return setValue(newModel, t, model[t]);
  });
  var shapes = content.shapes.filter(function (t) {
    return model.shape === t.shape;
  });
  shapes.forEach(function (t) {
    return deepAssign(t, newModel);
  });
  // 坐标轴
  content.axis = newModel.axis;
  // 排版
  content.layout = newModel.layout;
  // 画图
  return content;
};
exports.contentAssignModel = contentAssignModel;
var makeModel = function makeModel(fields, form) {
  var model = {};
  fields.filter(function (t) {
    return t.field;
  }).forEach(function (t) {
    //['text','number','select'].includes(t.type)
    var key = t.field;
    var item = (0, _ui._query)("[name=".concat(key, "]"), form);
    if (item) {
      var type = (0, _ui._attr)(item, 'type');
      switch (type) {
        case 'number':
          model[key] = Number(item.value);
          break;
        case 'checkbox':
          model[key] = item.checked;
          break;
        default:
          model[key] = item.value;
      }
    }
    // model[key] = type === 'number' ? Number(item.value) : item.value
  });

  var scope = {
    model: model,
    fields: fields,
    form: form,
    emit: _eventBus.emit //事件
  };

  return scope;
};
exports.makeModel = makeModel;