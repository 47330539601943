"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._treeMode = exports._treeCircle = exports._tree = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
var _path = require("../core/path");
var _color = require("@/color");
var _svg = require("../core/svg");
var _tree2 = require("@/algorithm/tree");
var _helper = require("../helper");
// 二叉树
var _tree = function _tree(options, props) {
  // let { depth = 5, color = "none", alpha = 1, a = 0, edge } = options
  var matrix = (0, _tree2.treePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._tree = _tree;
var _treeMode = function _treeMode(options, props) {
  var matrix = (0, _tree2.treeModePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._treeMode = _treeMode;
var _treeCircle = function _treeCircle(options, props) {
  var _options$depth = options.depth,
    depth = _options$depth === void 0 ? 5 : _options$depth,
    _options$color = options.color,
    color = _options$color === void 0 ? 'none' : _options$color;
  var ps = (0, _tree2.treePoints)(options);
  // 彩色
  if (color && color !== 'none') {
    var colors = (0, _color._colors)(color, depth + 1, 1);
    var children = ps.map(function (t, i) {
      return (0, _path.pointsPath)({
        points: t,
        closed: false
      }, Object.assign(Object.assign({}, props), {
        fill: 'none',
        stroke: colors[i]
      }));
    });
    return (0, _svg._g)(children);
  } else {
    // 单色
    return (0, _path.pointsPath)({
      points: (0, _math.plainMatrix)(ps),
      closed: false
    }, Object.assign({
      stroke: 'red',
      fill: 'none'
    }, props));
  }
};
exports._treeCircle = _treeCircle;