"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._popover = void 0;
var _dom = require("./dom");
var _transform = require("@/types/transform");
var removepopover = function removepopover() {
  var popover = (0, _dom._query)('div.popover');
  if (popover) {
    popover.remove();
    document.body.removeEventListener('mousedown', removepopoverEvent);
  }
};
var removepopoverEvent = function removepopoverEvent(e) {
  var el = e.target;
  var parent = (0, _dom._closest)(el, '.popover');
  if (!parent) {
    removepopover();
  }
};
var _popover = function _popover(options, target) {
  removepopover();
  document.body.addEventListener('mousedown', removepopoverEvent);
  var pos = target.getBoundingClientRect();
  var title = options.title,
    content = options.content;
  var children = [_dom.dom.div(title, {
    class: 'popover__title'
  }), _dom.dom.div(content, {
    class: 'popover__content'
  })];
  var popover = _dom.dom.div(children, {
    class: 'popover',
    style: {
      top: pos.top + pos.height + 'px',
      left: pos.left + 'px',
      transform: _transform.Transform.None
    }
  });
  return popover;
};
exports._popover = _popover;