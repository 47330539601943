"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._labels = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _labels2 = require("@/common/labels");
var _math = require("@/math");
var _svg = require("../core/svg");
var _text2 = require("@/types/text");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 标注
var _labels = function _labels(_ref, opitons, props) {
  var points = _ref.points,
    render = _ref.render;
  var type = props.type;
  var o = (0, _math._o)(points);
  return points.map(function (t, index) {
    var text = (0, _labels2.genLabel)(type, index);
    var pos = (0, _math._moveDis)(t, o, 10);
    var _pos = _slicedToArray(pos, 2),
      x = _pos[0],
      y = _pos[1];
    return (0, _svg._text)({
      shape: 'text',
      x: x,
      y: y,
      text: render ? render(index) : text
    }, Object.assign(Object.assign({
      name: 'text',
      fontSize: 12,
      textAnchor: _text2.TextAnchor.Middle,
      dominantBaseline: 'middle'
    }, props), opitons));
  });
};
exports._labels = _labels;