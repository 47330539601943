"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.starPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _link = require("./link");
var _segCross = require("./segCross");
// 芒星
var starPoints = function starPoints(points) {
  var len = points.length;
  var segs = (0, _link.edgeExtensionSegs)(points, len > 6 ? 0 : 1);
  var lcps = (0, _segCross.neighbourSegCrossPoints)(segs);
  var result = [];
  points.forEach(function (t, i) {
    result.push(t);
    result.push(lcps[i - 1 >= 0 ? i - 1 : len - 1]);
  });
  return result;
};
exports.starPoints = starPoints;