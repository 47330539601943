"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patternConfig = void 0;
var _chequer2 = require("./chequer");
var _stripe2 = require("./stripe");
var _gradient2 = require("./gradient");
var _shape = require("@/types/shape");
var _patternConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
// 填充图案函数
var patternConfig = (_patternConfig = {}, _defineProperty(_patternConfig, _shape.Shape.Chequer, _chequer2._chequer), _defineProperty(_patternConfig, _shape.Shape.Stripe, _stripe2._stripe), _defineProperty(_patternConfig, _shape.Shape.Gradient, _gradient2._gradient), _defineProperty(_patternConfig, _shape.Shape.Radial, _gradient2._radial), _defineProperty(_patternConfig, _shape.Shape.Dot, _chequer2._dot), _defineProperty(_patternConfig, _shape.Shape.DiagonalStripe, _stripe2._diagonalStripe), _patternConfig);
exports.patternConfig = patternConfig;