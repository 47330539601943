"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rosePoints = void 0;
var _traversal = require("./traversal");
// 玫瑰线的参数方程：
// 玫瑰线的极坐标方程为 ρ=a* sin(nθ), ρ=a*cos(nθ)；
// 直角坐标方程为 x=a* sin(nθ)* cos(θ), y=a*sin(nθ)* sin(θ)。
// 玫瑰线的三种特征：
// 1、叶子数n为奇数，叶片数=n，θ角在0-π内玫瑰线是闭合的；
// n为偶数，叶片数=2n，θ角取值在0-2π内玫瑰线才是闭合和完整的；
// 2、当n为非整数的有理数时，设为L/W,且L/W为简约分数,此时,L与W不可能同时为偶数。
// L决定玫瑰线的叶子数,W决定玫瑰线的闭合周期(Wπ或2Wπ,见特性3)及叶子的宽度,W越大,叶子越宽。
// 但W也会同时影响叶子数的多少,对同一奇数值L,在W分别取奇数和偶数值时,叶子数也是不同的。
// 3、当L或W中有一个为偶数时,玫瑰线的叶子数为2L,闭合周期为2Wπ。
// 当L或W同为奇数时,玫瑰线的叶子数为L,闭合周期为Wπ。
// 换句话说,生成偶数个叶子的玫瑰线, L或W中必须有且只有一个为偶数值,且L为叶子数的一半,而生成奇数个叶子的玫瑰线, L和W都必须为奇数,且L值就是叶子数。
var rosePoints = function rosePoints(options) {
  var o = options.o,
    r = options.r,
    _options$n = options.n,
    n = _options$n === void 0 ? 200 : _options$n,
    _options$w = options.w,
    w = _options$w === void 0 ? 4 : _options$w,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$m = options.m,
    m = _options$m === void 0 ? 1 : _options$m;
  var _x = function _x(t) {
    return Math.sin(w * t) * Math.cos(t);
  };
  var _y = function _y(t) {
    return Math.sin(w * t) * Math.sin(t);
  };
  var _r = function _r(i) {
    return r * (m - i) / m;
  };
  return (0, _traversal._multiplePolar)({
    o: o,
    r: _r,
    a: a,
    n: n,
    m: m,
    _x: _x,
    _y: _y
  });
};
exports.rosePoints = rosePoints;