"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._peano = void 0;
var _peano2 = require("@/algorithm/peano");
var _array = require("@/math/array");
var _helper = require("../helper");
var _peano = function _peano(options, props) {
  var n = options.n,
    o = options.o,
    r = options.r,
    depth = options.depth,
    a = options.a,
    noseToTail = options.noseToTail;
  var points = (0, _peano2.peanoPoints)({
    o: o,
    r: r,
    depth: depth,
    a: a
  });
  var len = points.length;
  var matrix = (0, _array.toMatrix)(points, len / n, noseToTail);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._peano = _peano;