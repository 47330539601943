"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fillVerticalPoints = exports.fillRayPoints = exports.fillPatterns = exports.fillPatternPointsMap = exports.fillPatternMap = exports.fillHorizontalPoints = exports.fillGridPoints = exports.fillGridLineStripPoints = exports.fillBackslashPoints = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
var _shapeRect = require("./shapeRect");
var _segCross = require("./segCross");
var _pathMode = require("@/types/pathMode");
var _math = require("@/math");
var _array = require("@/math/array");
var _vec = require("@/math/vec");
var _arrayMix = require("@/math/arrayMix");
var _color = require("@/types/color");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 横线
var fillHorizontalPoints = function fillHorizontalPoints(_ref) {
  var points = _ref.points,
    gridDensity = _ref.gridDensity;
  var rect = (0, _shapeRect.getBounds)(points);
  var left = rect.left,
    right = rect.right,
    top = rect.top,
    bottom = rect.bottom,
    width = rect.width,
    height = rect.height;
  var n = height / gridDensity;
  var segs = [];
  var ps = [];
  var offset = gridDensity / 2;
  for (var i = 0; i < n; i++) {
    var p = (0, _math._lerp)(top, bottom, i / n);
    var seg = [[left - offset, p + offset], [right + offset, p + offset]];
    segs[segs.length] = seg;
  }
  var ps2 = (0, _segCross.edgeCrossPoints)(points, segs);
  ps.push.apply(ps, _toConsumableArray(ps2));
  return ps;
};
// 条纹
exports.fillHorizontalPoints = fillHorizontalPoints;
var _strip = function _strip(ps) {
  var len = ps.length;
  var pp = [];
  for (var i = 0; i <= len - 4; i += 4) {
    var t = ps[i];
    var next = ps[i + 1];
    var next2 = ps[i + 2];
    var next3 = ps[i + 3];
    var oop = (0, _vec.oppositeDirection)([t, next], [next3, next2]);
    if (oop) {
      pp.push(t, next, next3, next2);
    } else {
      pp.push(t, next, next2, next3);
    }
  }
  return pp;
};
// 横条纹
var fillHorizontalStripPoints = function fillHorizontalStripPoints(_ref2) {
  var points = _ref2.points,
    gridDensity = _ref2.gridDensity;
  var ps = fillHorizontalPoints({
    points: points,
    gridDensity: gridDensity
  });
  return _strip(ps);
};
// 竖线
var fillVerticalPoints = function fillVerticalPoints(_ref3) {
  var points = _ref3.points,
    gridDensity = _ref3.gridDensity;
  var rect = (0, _shapeRect.getBounds)(points);
  var left = rect.left,
    right = rect.right,
    top = rect.top,
    bottom = rect.bottom,
    width = rect.width,
    height = rect.height;
  var n = width / gridDensity;
  var segs = [];
  var offset = gridDensity / 2;
  for (var i = 0; i < n; i++) {
    var p = (0, _math._lerp)(left, right, i / n);
    segs[segs.length] = [[p + offset, top - offset], [p + offset, bottom + offset]];
  }
  var ps = (0, _segCross.edgeCrossPoints)(points, segs);
  return ps;
};
// 竖条纹
exports.fillVerticalPoints = fillVerticalPoints;
var fillVerticalStripPoints = function fillVerticalStripPoints(_ref4) {
  var points = _ref4.points,
    gridDensity = _ref4.gridDensity;
  var ps = fillVerticalPoints({
    points: points,
    gridDensity: gridDensity
  });
  return _strip(ps);
};
// 网格线
var fillGridPoints = function fillGridPoints(_ref5) {
  var points = _ref5.points,
    gridDensity = _ref5.gridDensity;
  var ps = fillVerticalPoints({
    points: points,
    gridDensity: gridDensity
  });
  var ps2 = fillHorizontalPoints({
    points: points,
    gridDensity: gridDensity
  });
  return [].concat(_toConsumableArray(ps), _toConsumableArray(ps2));
};
exports.fillGridPoints = fillGridPoints;
var fillGridLineStripPoints = function fillGridLineStripPoints(_ref6) {
  var points = _ref6.points,
    gridDensity = _ref6.gridDensity;
  var ps = fillVerticalPoints({
    points: points,
    gridDensity: gridDensity
  });
  var ps2 = fillHorizontalPoints({
    points: points,
    gridDensity: gridDensity
  });
  return [ps, ps2];
};
// 斜线
// /---backslash 反斜线 \---slash 正斜线
exports.fillGridLineStripPoints = fillGridLineStripPoints;
var fillBackslashPoints = function fillBackslashPoints(_ref7) {
  var points = _ref7.points,
    gridDensity = _ref7.gridDensity;
  var rect = (0, _shapeRect.getBounds)(points);
  var left = rect.left,
    right = rect.right,
    top = rect.top,
    bottom = rect.bottom,
    width = rect.width,
    height = rect.height;
  var n = width / gridDensity;
  var m = height / gridDensity;
  var segs = [];
  var leftTop = [left - gridDensity, top - gridDensity];
  var leftBottom = [left - gridDensity, bottom + gridDensity];
  var rightTop = [right + gridDensity, top - gridDensity];
  var rightBottom = [right + gridDensity, bottom - gridDensity];
  for (var i = 1; i <= Math.max(n, m); i++) {
    var p = (0, _math._lerp)(leftTop, leftBottom, i / m);
    var p2 = (0, _math._lerp)(leftTop, rightTop, i / n);
    segs[segs.length] = [p, p2];
    var p3 = (0, _math._lerp)(rightBottom, leftBottom, i / m);
    var p4 = (0, _math._lerp)(rightBottom, rightTop, i / n);
    segs[segs.length] = [p3, p4];
  }
  var ps = (0, _segCross.edgeCrossPoints)(points, segs);
  return ps;
};
// 射线
exports.fillBackslashPoints = fillBackslashPoints;
var fillRayPoints = function fillRayPoints(_ref8) {
  var points = _ref8.points,
    _ref8$rayDensity = _ref8.rayDensity,
    rayDensity = _ref8$rayDensity === void 0 ? 10 : _ref8$rayDensity;
  var o = (0, _math._o)(points);
  var _getBounds = (0, _shapeRect.getBounds)(points),
    width = _getBounds.width;
  var r = width;
  var n = 360 / rayDensity;
  var segs = [];
  for (var i = 0; i < n; i++) {
    var p = (0, _math._polar)(o, r + 10, rayDensity * i);
    segs.push([o, p]);
  }
  var ps = (0, _segCross.edgeCrossPoints)(points, segs);
  return (0, _arrayMix.rayMix)(o, ps);
};
exports.fillRayPoints = fillRayPoints;
var fillRayStripPoints = function fillRayStripPoints(_ref9) {
  var points = _ref9.points;
  var o = (0, _math._o)(points);
  var len = points.length;
  var ps = [];
  points.forEach(function (t, i) {
    if (i % 2 === 0) {
      var next = points[(i + 1) % len];
      ps.push(o, t, next);
    }
  });
  return ps;
};
var vertexLightPoints = function vertexLightPoints(_ref10) {
  var points = _ref10.points,
    _ref10$offset = _ref10.offset,
    offset = _ref10$offset === void 0 ? 0.3 : _ref10$offset;
  var len = points.length;
  var ps = [];
  points.forEach(function (t, i) {
    var to = (Math.floor((len - 1) / 2) + i) % len;
    var p = (0, _math._lerp)(points[to], points[(to + 1) % len], offset);
    ps.push(t, p);
  });
  return ps;
};
var vertexLightCrossPoints = function vertexLightCrossPoints(_ref11) {
  var points = _ref11.points,
    _ref11$offset = _ref11.offset,
    offset = _ref11$offset === void 0 ? 0.3 : _ref11$offset;
  var len = points.length;
  var ps = [];
  var segs = [];
  points.forEach(function (t, i) {
    var to = (Math.floor((len - 1) / 2) + i) % len;
    var p = (0, _math._lerp)(points[to], points[(to + 1) % len], offset);
    segs.push([t, p]);
  });
  var nscps = (0, _segCross.neighbourSegCrossPoints)(segs);
  nscps.forEach(function (t, i) {
    ps.push(points[i], t);
  });
  return ps;
};
var vertexLightAreaPoints = function vertexLightAreaPoints(_ref12) {
  var points = _ref12.points,
    _ref12$offset = _ref12.offset,
    offset = _ref12$offset === void 0 ? 0.3 : _ref12$offset;
  var len = points.length;
  var ps = [];
  var segs = [];
  points.forEach(function (t, i) {
    var to = (Math.floor((len - 1) / 2) + i) % len;
    var p = (0, _math._lerp)(points[to], points[(to + 1) % len], offset);
    segs.push([t, p]);
  });
  var nscps = (0, _segCross.neighbourSegCrossPoints)(segs);
  nscps.forEach(function (t, i) {
    var next = points[(i + 1) % len];
    ps.push(points[i], t, next);
  });
  return ps;
};
// const vertexLightAreaMtrixPoints = ({ points, offset = 0.3 }) => {
//     let len = points.length
//     let ps = []
//     let segs = []
//     points.forEach((t, i) => {
//         let to = (Math.floor((len - 1) / 2) + i) % len
//         let p = _lerp(points[to], points[(to + 1) % len], offset)
//         segs.push([t, p])
//     })
//     let nscps = neighbourSegCrossPoints(segs)
//     nscps.forEach((t, i) => {
//         let next = points[(i + 1) % len]
//         ps.push([points[i], t, next])
//     })
//     return ps
// }
// const edgeMidFractal = () => { }
// horizontal stripe 横纹
// 竖线为vertical line
// 十字线
var fillPatternPointsMap = {
  none: function none() {
    return [];
  },
  horizontal: fillHorizontalPoints,
  horizontalStrip: fillHorizontalStripPoints,
  horizontalLineStrip: fillHorizontalPoints,
  vertical: fillVerticalPoints,
  verticalStrip: fillVerticalStripPoints,
  verticalLineStrip: fillVerticalPoints,
  grid: fillGridPoints,
  gridLineStrip: fillGridLineStripPoints,
  backslash: fillBackslashPoints,
  ray: fillRayPoints,
  rayStrip: fillRayStripPoints,
  vertexLight: vertexLightPoints,
  vertexLightCross: vertexLightCrossPoints,
  vertexLightArea: vertexLightAreaPoints
  // vertexLightColorArea: vertexLightAreaMtrixPoints
};
exports.fillPatternPointsMap = fillPatternPointsMap;
var fillPatterns = Object.keys(fillPatternPointsMap).concat(['taichi']);
exports.fillPatterns = fillPatterns;
var fillPatternPathModeMap = {
  rayStrip: _pathMode.PathMode.LINE_LOOP,
  vertexLightArea: _pathMode.PathMode.TRIANGLES,
  // vertexLightColorArea: PathMode.LINE_LOOP,
  default: _pathMode.PathMode.LINES,
  horizontalStrip: _pathMode.PathMode.SQUARES,
  verticalStrip: _pathMode.PathMode.SQUARES,
  verticalLineStrip: _pathMode.PathMode.LINE_STRIP,
  horizontalLineStrip: _pathMode.PathMode.LINE_STRIP,
  gridLineStrip: _pathMode.PathMode.LINE_STRIP
  // ray: PathMode.POINTS
};

var fillPatternMap = function fillPatternMap(_ref13) {
  var pattern = _ref13.pattern,
    points = _ref13.points,
    gridDensity = _ref13.gridDensity,
    rayDensity = _ref13.rayDensity,
    offset = _ref13.offset;
  var fn = fillPatternPointsMap[pattern];
  var ps = fn({
    points: points,
    gridDensity: gridDensity,
    rayDensity: rayDensity,
    offset: offset
  });
  var pathMode = fillPatternPathModeMap[pattern] || fillPatternPathModeMap['default'];
  var color;
  if (pathMode === _pathMode.PathMode.TRIANGLES) {
    ps = (0, _array.toMatrix)(ps, 3);
    color = {
      type: _color.Color.ColorCircle,
      fill: 'black'
    };
  }
  return {
    points: ps,
    pathMode: pathMode,
    color: color
  };
};
exports.fillPatternMap = fillPatternMap;