"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeSvgElementByPoints = exports.makeSvgElementByMatrix = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _common = require("@/common");
var _transform2 = require("@/math/transform");
var _pathShape = require("../pathShape");
var makeSvgElementByMatrix = function makeSvgElementByMatrix(matrix, options, props) {
  var edge = options.edge,
    mirror = options.mirror,
    transform = options.transform;
  var n = matrix.length;
  var color = (0, _common.isVisible)(mirror) && mirror && mirror.color ? (0, _common._mirrorColor)(mirror) : options.color;
  var _props = (0, _common._colorProps)(color, n);
  return matrix.map(function (t, i) {
    var ps = (0, _transform2._transform)(t, transform);
    return (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
      points: ps,
      mirror: null,
      edge: Object.assign(Object.assign(Object.assign({}, props), edge), _props(i))
    }), props);
  });
};
exports.makeSvgElementByMatrix = makeSvgElementByMatrix;
var makeSvgElementByPoints = function makeSvgElementByPoints(points, options, props) {
  var transform = options.transform;
  var points2 = points.map(function (t) {
    return (0, _transform2._transform)(t, transform);
  });
  return (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
    points: points2
  }), props);
};
exports.makeSvgElementByPoints = makeSvgElementByPoints;