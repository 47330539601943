"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._dot = exports._chequer = void 0;
require("core-js/modules/es.function.name.js");
var _svg = require("../core/svg");
// 格子图案
// fill="url(#shape-chequer)"
var _chequer = function _chequer(options) {
  var name = options.name,
    _options$id = options.id,
    id = _options$id === void 0 ? 'shape-chequer' : _options$id,
    _options$r = options.r,
    r = _options$r === void 0 ? 10 : _options$r,
    _options$color = options.color1,
    color1 = _options$color === void 0 ? 'red' : _options$color,
    _options$color2 = options.color2,
    color2 = _options$color2 === void 0 ? 'green' : _options$color2,
    _options$borderRadius = options.borderRadius1,
    borderRadius1 = _options$borderRadius === void 0 ? 1 : _options$borderRadius,
    _options$borderRadius2 = options.borderRadius2,
    borderRadius2 = _options$borderRadius2 === void 0 ? 1 : _options$borderRadius2;
  var newID = (0, _svg._patternId)(id, name);
  var rect1 = (0, _svg._rect)([[0, 0], [r, r]], {
    fill: color1,
    rx: borderRadius1,
    ry: borderRadius1
  });
  var rect2 = (0, _svg._rect)([[r, r], [r * 2, r * 2]], {
    fill: color2,
    rx: borderRadius2,
    ry: borderRadius2
  });
  var chequer = (0, _svg.createSvg)('pattern', {
    id: newID,
    x: 0,
    y: 0,
    width: r * 2,
    height: r * 2,
    patternUnits: 'userSpaceOnUse'
  }, {}, [rect1, rect2]);
  var defs = (0, _svg._defs)(chequer);
  return defs;
};
// 点点
exports._chequer = _chequer;
var _dot = function _dot(options) {
  var name = options.name,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 5 : _options$r2,
    _options$id2 = options.id,
    id = _options$id2 === void 0 ? 'dot' : _options$id2;
  var newID = (0, _svg._patternId)(id, name);
  var dots = [(0, _svg._circle)({
    o: [r, r],
    r: r
  }, {
    fill: 'red'
  }), (0, _svg._circle)({
    o: [3 * r, 3 * r],
    r: r
  }, {
    fill: 'green'
  })];
  var chequer = (0, _svg.createSvg)('pattern', {
    id: newID,
    x: 0,
    y: 0,
    width: r * 4,
    height: r * 4,
    patternUnits: 'userSpaceOnUse'
  }, {}, dots);
  var defs = (0, _svg._defs)(chequer);
  return defs;
};
exports._dot = _dot;