"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sinPoints = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
var _math = require("@/math");
var _utils = require("@/utils");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var sinPoints = function sinPoints(points, options) {
  var _ref = options !== null && options !== void 0 ? options : {},
    _ref$k = _ref.k,
    k = _ref$k === void 0 ? 0 : _ref$k,
    _ref$a = _ref.a,
    a = _ref$a === void 0 ? 0 : _ref$a,
    _ref$w = _ref.w,
    w = _ref$w === void 0 ? 1 : _ref$w; //  num = 36, r = 100,
  var ps = [];
  var o = (0, _math._o)(points);
  var r = (0, _math._dis)(o, points[0]);
  var num = (0, _math._k)(r / 3, 0);
  (0, _utils.eachNext)(points, function (t, index, next) {
    var m = (0, _math._mid)(t, next);
    a = (0, _math._atan)(o, m);
    ps.push.apply(ps, _toConsumableArray(Array.from({
      length: num
    }, function (t, i) {
      return [(0, _math._k)(i * 360 / num + o[0] - 180), (0, _math._k)(r * (0, _math._sin)(w * (i * 360 / num - 180) - a) + o[1] - k)];
    })));
  });
  return ps;
};
exports.sinPoints = sinPoints;