"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.angleMapFn = void 0;
var _math = require("@/math");
var _a = function _a() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return {
    a: a + i * 360 / n / 2
  };
};
var _b = function _b() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return {
    a: a + i * 360 / n / 3
  };
};
var _c = function _c() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return {
    a: a + i * 360 / n / 4
  };
};
var _symmetry = function _symmetry() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  if (i % 2 === 0) {
    return {
      a: a + 360 / n / 4
    };
  }
  return {
    a: a - 360 / n / 4
  };
};
var _rand = function _rand() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return {
    a: a + (0, _math._random)(0, 360)
  };
};
var _none = function _none() {
  return {
    a: 0
  };
};
// 角度函数
var angleMapFn = {
  none: _none,
  symmetry: _symmetry,
  a: _a,
  b: _b,
  c: _c,
  random: _rand
};
exports.angleMapFn = angleMapFn;