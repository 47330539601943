"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hexaflakePoints = exports.hexaflake = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _mirror2 = require("@/math/mirror");
var _polar = require("@/algorithm/polar");
var _path = require("../core/path");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var hexaflakePoints = function hexaflakePoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [300, 200] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 200 : _options$r,
    _options$n = options.n,
    n = _options$n === void 0 ? 3 : _options$n,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 3 : _options$depth,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a;
  var result = [];
  var _iter = function _iter(o, r, n, a, depth) {
    if (depth === 0) {
      var points = (0, _polar.polarPoints)({
        o: o,
        r: r,
        n: n,
        a: a
      })[0];
      result.push.apply(result, _toConsumableArray(points));
      points.forEach(function (t) {
        result.push.apply(result, _toConsumableArray((0, _mirror2._mirror)(points, t)));
      });
    } else {
      var _points = (0, _polar.polarPoints)({
        o: o,
        r: r,
        n: n,
        a: a
      })[0];
      _points.forEach(function (t) {
        var o2 = (0, _mirror2._mirror)(t, o);
        _iter(o2, r / 3, n, a, depth - 1);
      });
    }
  };
  _iter(o, r, n, a, depth);
  return result;
};
// export const hexaflake = createPathFn(hexaflakePoints)
exports.hexaflakePoints = hexaflakePoints;
var hexaflake = function hexaflake(options, props) {
  var stps = hexaflakePoints(options);
  var n = options.n;
  return (0, _path.pointsPath)({
    points: stps,
    closed: false,
    broken: n
  }, props);
};
exports.hexaflake = hexaflake;