"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.axisConfig = void 0;
var _polar2 = require("./polar");
var _axis2 = require("./axis");
var _shape = require("@/types/shape");
var _axisConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var axisConfig = (_axisConfig = {}, _defineProperty(_axisConfig, _shape.Shape.Grid, _axis2._grid), _defineProperty(_axisConfig, _shape.Shape.Polar, _polar2._polar), _defineProperty(_axisConfig, _shape.Shape.Axis, _axis2._axis), _defineProperty(_axisConfig, _shape.Shape.None, function () {}), _axisConfig);
exports.axisConfig = axisConfig;