"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._gougu = void 0;
var _gougu2 = require("@/algorithm/gougu");
var _helper = require("../helper");
var _gougu = function _gougu(options, props) {
  var matrix = (0, _gougu2.gouguPoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._gougu = _gougu;