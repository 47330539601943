"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._spiral = void 0;
var _spiral2 = require("@/algorithm/spiral");
var _helper = require("../helper");
// 螺旋线
var _spiral = function _spiral(options, props) {
  var matrix = (0, _spiral2.spiralPoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._spiral = _spiral;