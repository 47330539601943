"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._plant = void 0;
var _tree = require("@/algorithm/tree");
var _helper = require("../helper");
var _plant = function _plant(ctx, options, props) {
  var matrix = (0, _tree.treePoints)(options);
  (0, _helper.renderMatrix)({
    matrix: matrix,
    ctx: ctx,
    options: options,
    props: props
  });
};
exports._plant = _plant;