"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._gougu = void 0;
var _gougu2 = require("@/algorithm/gougu");
var _helper = require("../helper");
var _gougu = function _gougu(ctx, options, props) {
  var matrix = (0, _gougu2.gouguPoints)(options);
  (0, _helper.renderMatrix)({
    ctx: ctx,
    matrix: matrix,
    options: options,
    props: props
  });
};
exports._gougu = _gougu;