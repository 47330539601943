"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformTypes = exports._transform = void 0;
require("core-js/modules/es.object.keys.js");
var _arraySort = require("./arraySort");
var _transform2 = require("@/types/transform");
var _transformConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var transformConfig = (_transformConfig = {}, _defineProperty(_transformConfig, _transform2.Transform.None, function (e) {
  return e;
}), _defineProperty(_transformConfig, _transform2.Transform.Diagonal, _arraySort.diagonal), _defineProperty(_transformConfig, _transform2.Transform.ParitySort, _arraySort.paritySort), _defineProperty(_transformConfig, _transform2.Transform.Misplaced, _arraySort.misplaced), _defineProperty(_transformConfig, _transform2.Transform.IntervalSort, _arraySort.intervalSort), _defineProperty(_transformConfig, _transform2.Transform.NeighborSwap, _arraySort.neighborSwap), _defineProperty(_transformConfig, _transform2.Transform.Shuffle, _arraySort.shuffle), _transformConfig);
var _transform = function _transform(p, transform) {
  return transformConfig[transform] ? transformConfig[transform](p) : p;
};
exports._transform = _transform;
var transformTypes = Object.keys(transformConfig);
exports.transformTypes = transformTypes;