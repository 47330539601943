"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.layoutAngles = exports._layout = void 0;
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.assign.js");
var _layout2 = require("@/algorithm/layout");
var _angle = require("@/algorithm/angle");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var layoutAngles = Object.keys(_angle.angleMapFn);
exports.layoutAngles = layoutAngles;
var _layout = function _layout(options, shapes, fn) {
  var len = shapes.length;
  var layout = options.layout,
    width = options.width,
    height = options.height,
    o = options.o;
  if (layout) {
    var type, angle;
    if (_typeof(layout) === 'object') {
      type = layout.type;
      angle = layout.angle;
    } else {
      type = layout;
    }
    var lps = (0, _layout2.layoutPoints)(type, {
      width: width,
      height: height,
      o: o
    });
    lps.forEach(function (t, i) {
      var shapeObj = shapes[len === 1 ? 0 : i % len];
      var n = shapeObj.n,
        a = shapeObj.a;
      var incFn = _angle.angleMapFn[angle];
      var inc = incFn ? incFn(a, i, n) : {};
      fn(Object.assign(Object.assign(Object.assign({
        shape: 'circle'
      }, shapeObj), {
        o: t
      }), inc));
    });
  } else {
    shapes.forEach(function (t) {
      return fn(t);
    });
  }
};
exports._layout = _layout;