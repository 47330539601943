"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initBuffers = exports.defineProgram = void 0;
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.typed-array.float32-array.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-string.js");
var loadShader = function loadShader(gl, type, source) {
  //根据着色类型，建立着色器对象
  var shader = gl.createShader(type);
  //将着色器源文件传入着色器对象中
  gl.shaderSource(shader, source);
  //编译着色器对象
  gl.compileShader(shader);
  //返回着色器对象
  return shader;
};
var defineProgram = function defineProgram(gl, vertex, fragment) {
  //创建程序对象
  var program = gl.createProgram();
  //建立着色对象
  var vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertex);
  var fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragment);
  //把顶点着色对象装进程序对象中
  gl.attachShader(program, vertexShader);
  //把片元着色对象装进程序对象中
  gl.attachShader(program, fragmentShader);
  //连接webgl上下文对象和程序对象
  gl.linkProgram(program);
  //启动程序对象
  gl.useProgram(program);
  //将程序对象挂到上下文对象上
  // gl.program = program;
  return program;
};
//创建缓冲器来存储顶点
exports.defineProgram = defineProgram;
var initBuffers = function initBuffers(gl, positions) {
  //创建缓冲器
  var positionBuffer = gl.createBuffer();
  //绑定上下文。
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
  return {
    position: positionBuffer
  };
};
//绘制场景
// export const drawScene = (gl, programInfo, buffers) => {
//     //用背景色擦除画布
//     gl.clearColor(0.0, 0.0, 0.0, 1.0);
//     gl.clearDepth(1.0);
//     gl.enable(gl.DEPTH_TEST);
//     gl.depthFunc(gl.LEQUAL);
//     gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
//     //建立摄像机透视矩阵
//     const fieldOfView = 45 * Math.PI / 180; //设置45度的视图角度
//     //设置一个适合实际图像的宽高比
//     const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
//     //指定在摄像机距离0.1到100单位长度的范围内的物体可见。
//     const zNear = 0.1;
//     const zFar = 100.0;
//     const projectionMatrix = mat4.create();
//     mat4.perspective(projectionMatrix,
//         fieldOfView,
//         aspect,
//         zNear,
//         zFar);
//     const modelViewMatrix = mat4.create();
//     //加载特定位置，并把正方形放在距离摄像机6个单位的的位置
//     mat4.translate(modelViewMatrix,
//         modelViewMatrix,
//         [-0.0, 0.0, -6.0]);
//     {
//         const numComponents = 2;
//         const type = gl.FLOAT;
//         const normalize = false;
//         const stride = 0;
//         const offset = 0;
//         gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
//         gl.vertexAttribPointer(
//             programInfo.attribLocations.vertexPosition,
//             numComponents,
//             type,
//             normalize,
//             stride,
//             offset);
//         gl.enableVertexAttribArray(
//             programInfo.attribLocations.vertexPosition);
//     }
//     gl.useProgram(programInfo.program);
//     gl.uniformMatrix4fv(
//         programInfo.uniformLocations.projectionMatrix,
//         false,
//         projectionMatrix);
//     gl.uniformMatrix4fv(
//         programInfo.uniformLocations.modelViewMatrix,
//         false,
//         modelViewMatrix);
//     {
//         const offset = 0;
//         const vertexCount = 4;
//         gl.drawArrays(gl.TRIANGLE_STRIP, offset, vertexCount);
//     }
// }
exports.initBuffers = initBuffers;