"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._oneline = void 0;
var _polar = require("@/algorithm/polar");
var _transform2 = require("@/math/transform");
var _helper = require("../helper");
// 镜像函数升级，支持一笔画  ，替代polarShape
var _oneline = function _oneline(options, props) {
  var matrix = (0, _polar.polarPoints)(options);
  var transform = options.transform;
  matrix = (0, _transform2._transform)(matrix, transform);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._oneline = _oneline;