"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._edgeExtension = void 0;
require("core-js/modules/es.object.assign.js");
var _link = require("@/algorithm/link");
var _dashLines2 = require("./dashLines");
var _pathMode = require("../core/pathMode");
var _svg = require("../core/svg");
var _common = require("@/common");
var _labels2 = require("./labels");
// 边延长线
var _edgeExtension = function _edgeExtension(_ref, props) {
  var points = _ref.points,
    edgeExtension = _ref.edgeExtension;
  var gs = [];
  var crossPoints = edgeExtension.crossPoints,
    labels = edgeExtension.labels;
  var ps = (0, _link.edgeExtensionPoints)(points, edgeExtension.iterNum);
  gs.push((0, _dashLines2._dashLines)(ps, Object.assign(Object.assign({}, props), edgeExtension)));
  if (crossPoints || labels) {
    var cps = (0, _link.edgeExtensionCrossPoints)(points);
    // 交点
    if (crossPoints) {
      gs.push((0, _svg._path)((0, _pathMode._points)({
        points: cps
      }, {
        r: edgeExtension.r
      }), (0, _common._merge)(props, edgeExtension)));
    }
    // 标注
    if (labels) {
      gs.push((0, _labels2._labels)({
        points: cps
      }, {}, {
        stroke: 'blue'
      }));
    }
  }
  return gs;
};
exports._edgeExtension = _edgeExtension;