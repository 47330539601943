"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._mirrorPolygon = exports._mirror = void 0;
var _polygon2 = require("../core/polygon");
var _common = require("@/common");
var _path2 = require("../core/path");
var _mirrorPolygon = function _mirrorPolygon(ctx, options, props) {
  return (0, _common._mirrorPath)(function (options, props) {
    (0, _polygon2._polygon)(ctx, options, props);
  }, options, props);
};
exports._mirrorPolygon = _mirrorPolygon;
var _mirror = function _mirror(ctx, options, props) {
  return (0, _common._mirrorPath)(function (options, props) {
    (0, _path2._path)(ctx, options, props);
  }, options, props);
};
exports._mirror = _mirror;