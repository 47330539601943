"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peanoPoints = exports._peanoPoints = exports._peano = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _polar = require("@/algorithm/polar");
var _pathShape = require("../pathShape");
var _math = require("@/math");
var _peanoPoints = function _peanoPoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 3 : _options$depth;
  var n = 4;
  var points = (0, _polar.polarPoints)({
    o: o,
    r: r,
    a: a,
    n: n
  })[0];
  var _iter = function _iter(points, _ref) {
    var depth = _ref.depth,
      a = _ref.a;
    // const stack = []
    var ps = points.map(function (t, i) {
      var m = i % 4;
      var a2 = a;
      var direction = 1;
      if (m === 0) {
        direction = -1;
        a2 = a + 90;
      } else if (m === 3) {
        a2 = a - 90;
        direction = -1;
      }
      return (0, _polar.polarPoints)({
        o: t,
        r: r / Math.pow(2, depth + 1),
        a: a2,
        n: n,
        direction: direction
      })[0];
      // if (i === 0) {
      //     stack.push(t)
      // }
      // const next = points[i + 1]
      // if (next) {
      //     stack.push(..._item(t, next))
      // }
    });

    return (0, _math.plainMatrix)(ps);
  };
  if (depth > 0) {
    var ps = points;
    for (var i = 0; i < depth; i++) {
      ps = _iter(ps, {
        depth: i,
        a: a
      });
    }
    return ps;
  }
  return points;
};
exports._peanoPoints = _peanoPoints;
var peanoPoints = function peanoPoints(options) {
  var _iter = function _iter(options, _ref2) {
    var depth = _ref2.depth;
    if (depth === 0) {
      var _options$o2 = options.o,
        o = _options$o2 === void 0 ? [400, 300] : _options$o2,
        _options$r2 = options.r,
        r = _options$r2 === void 0 ? 100 : _options$r2,
        _options$a2 = options.a,
        a = _options$a2 === void 0 ? 0 : _options$a2;
      var n = 4;
      var points = (0, _polar.polarPoints)({
        o: o,
        r: r,
        a: a,
        n: n
      })[0];
      return points;
    } else if (depth == 1) {
      var _points = _iter(options, {
        depth: 0
      });
      var d = Math.pow(4, depth - 1);
      var _a = options.a,
        _r = options.r;
      var _ps = _points.map(function (t, i) {
        var a2 = _a;
        var r2 = _r / Math.pow(2, depth);
        var m = Math.round(i / d);
        if (m === 0) {
          a2 = _a + 90;
          return _iter(Object.assign(Object.assign({}, options), {
            o: t,
            r: r2,
            a: a2
          }), {
            depth: 0
          }).reverse();
        } else if (m === 3) {
          a2 = _a - 90;
          return _iter(Object.assign(Object.assign({}, options), {
            o: t,
            r: r2,
            a: a2
          }), {
            depth: 0
          }).reverse();
        }
        return _iter(Object.assign(Object.assign({}, options), {
          o: t,
          r: r2,
          a: a2
        }), {
          depth: 0
        });
      });
      return (0, _math.plainMatrix)(_ps);
    } else {
      var _points2 = _iter(options, {
        depth: depth - 1
      });
      var _d = Math.pow(4, depth - 1);
      var _a2 = options.a,
        _r2 = options.r;
      var _ps2 = _points2.map(function (t, i) {
        var a2 = _a2;
        var r2 = _r2 / Math.pow(2, depth);
        var m = Math.round(i / _d);
        var o = t;
        if (m === 0) {
          a2 = _a2 + 90;
          return _iter(Object.assign(Object.assign({}, options), {
            o: o,
            r: r2,
            a: a2
          }), {
            depth: 0
          }).reverse();
        } else if (m === 3) {
          a2 = _a2 - 90;
          return _iter(Object.assign(Object.assign({}, options), {
            o: o,
            r: r2,
            a: a2
          }), {
            depth: 0
          }).reverse();
        }
        return _iter(Object.assign(Object.assign({}, options), {
          o: o,
          r: r2,
          a: a2
        }), {
          depth: 0
        });
      });
      return (0, _math.plainMatrix)(_ps2);
    }
  };
  var depth = options.depth;
  var ps = _iter(options, {
    depth: depth
  });
  return ps;
  // const d = Math.pow(4, depth)
  // return ps.slice(0,d )
};
exports.peanoPoints = peanoPoints;
var _peano = (0, _pathShape.createPathFn)(peanoPoints);
exports._peano = _peano;