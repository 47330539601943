"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportSVG = exports.exportJSON = exports.exportCanvasAsPNG = exports.enum2array = exports.eachNext = exports.deleteProperty = exports.deepClone = exports.changeProperty = exports.camel2snake = exports.camel2kebab = exports._type = exports._has = exports._capitalize = exports._camelCase = void 0;
exports.isCustomEvent = isCustomEvent;
exports.snake2camel = exports.plainTreeMenu = exports.isUndefined = exports.isObject = exports.isFunction = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.string.replace.js");
// 判断类型
// enum EType {
//   string = 'string',
//   null = 'null',
//   undefined = 'undefined',
//   object = 'object',
//   function = 'function',
//   number = 'number',
//   infinity = 'infinity'
// }
var _type = function _type(o) {
  if (o === null) return 'null';
  if (o === undefined) return 'undefined'; //兼容ie8
  var s = Object.prototype.toString.call(o);
  var t = s.match(/\[object\s(\w*?)\]/)[1].toLowerCase();
  return t === 'number' ? isNaN(o) ? 'nan' : !isFinite(o) ? 'infinity' : t : t;
};
exports._type = _type;
var isUndefined = function isUndefined(o) {
  return _type(o) === 'undefined';
};
exports.isUndefined = isUndefined;
var isObject = function isObject(o) {
  return _type(o) === 'object';
};
exports.isObject = isObject;
var isFunction = function isFunction(o) {
  return _type(o) === 'function';
};
exports.isFunction = isFunction;
function isCustomEvent(event) {
  return 'detail' in event;
}
//首字母大写 _capitalize
var _capitalize = function _capitalize(t) {
  return t.slice(0, 1).toUpperCase() + t.slice(1);
};
//小驼峰
exports._capitalize = _capitalize;
var _camelCase = function _camelCase(arr) {
  return arr.map(function (t, index) {
    return index === 0 ? t : _capitalize(t);
  }).join('');
};
// 判断属性
exports._camelCase = _camelCase;
var _has = function _has() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.prototype.hasOwnProperty.call(obj, property);
};
exports._has = _has;
var deepClone = function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
};
// 修改属性名
exports.deepClone = deepClone;
var changeProperty = function changeProperty(obj, from, to) {
  var target = deepClone(obj);
  if (!_has(target, to)) {
    target[to] = target[from];
    deleteProperty(target, from);
  }
  return target;
};
// 删除属性
exports.changeProperty = changeProperty;
var deleteProperty = function deleteProperty(obj, property) {
  delete obj[property];
  return obj;
};
// foreach增加一个next参数
exports.deleteProperty = deleteProperty;
var eachNext = function eachNext(points, callback) {
  var n = points.length;
  points.forEach(function (t, index) {
    var next = points[(index + 1) % n];
    callback && callback(t, index, next);
  });
};
exports.eachNext = eachNext;
var plainTreeMenu = function plainTreeMenu(tree) {
  var list = [];
  var fn = function fn(t) {
    t.forEach(function (t) {
      if (t.children) {
        fn(t.children);
      } else {
        list.push(t);
      }
    });
  };
  fn(tree);
  return list;
};
exports.plainTreeMenu = plainTreeMenu;
var exportJSON = function exportJSON(data, name) {
  var type = 'json';
  var link = document.createElement('a');
  var exportName = name ? name : 'data';
  var url = 'data:text/' + type + ";charset=utf-8,\uFEFF" + encodeURI(data);
  link.href = url;
  link.download = exportName + '.' + type;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
exports.exportJSON = exportJSON;
var exportSVG = function exportSVG(data, name) {
  var type = 'svg';
  var link = document.createElement('a');
  var exportName = name ? name : 'data';
  var url = 'data:text/' + type + ";charset=utf-8,\uFEFF" + encodeURI(data);
  link.href = url;
  link.download = exportName + '.' + type;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
exports.exportSVG = exportSVG;
var exportCanvasAsPNG = function exportCanvasAsPNG(data, name) {
  var MIME_TYPE = 'image/png';
  var link = document.createElement('a');
  link.download = name;
  link.href = data;
  link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(':');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// 小驼峰命名法：camelCase
// 下划线命名法：snake_case
// 中划线命名法：kebab-case
exports.exportCanvasAsPNG = exportCanvasAsPNG;
var camel2kebab = function camel2kebab(key) {
  return key.replace(/([A-Z])/g, function (p, m) {
    return "-".concat(m.toLowerCase());
  });
};
// 将 camel 命名转 sanke 命名
exports.camel2kebab = camel2kebab;
var camel2snake = function camel2snake(key) {
  return key.replace(/([A-Z])/g, function (p, m) {
    return "_".concat(m.toLowerCase());
  });
};
// 将 sanke 命名转 camel 命名
exports.camel2snake = camel2snake;
var snake2camel = function snake2camel(key) {
  return key.replace(/_([a-z])/g, function (p, m) {
    return m.toUpperCase();
  });
};
exports.snake2camel = snake2camel;
var enum2array = function enum2array(e) {
  var arr = [];
  for (var n in e) {
    if (typeof e[n] === 'number') {
      arr.push(n);
    } else {
      arr.push(e[n]);
    }
  }
  return arr;
};
exports.enum2array = enum2array;