"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._drag = void 0;
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var _dom = require("@/ui/core/dom");
var _svg = require("./svg");
var _common = require("@/common");
var _drag = function _drag(points, callback, options) {
  // 拖动事件
  var dragedPoint;
  var dragedPointIndex;
  var boundingRectBorder;
  var container;
  var _dragPoint = function _dragPoint(e) {
    var cx = dragedPoint.getAttribute('cx');
    var cy = dragedPoint.getAttribute('cy');
    var x = e.movementX;
    var y = e.movementY;
    var point = [Number(cx) + x, Number(cy) + y];
    (0, _dom._attr)(dragedPoint, {
      cx: point[0],
      cy: point[1]
    });
    points[dragedPointIndex] = point;
    var d = callback(Object.assign(Object.assign({}, options), {
      points: points,
      container: container
    }));
    if (d) {
      (0, _dom._attr)(boundingRectBorder, 'd', d);
    }
  };
  var color = options.color;
  var _props;
  if ((0, _common.isVisible)(color)) {
    // 彩色
    var n = points.length;
    _props = (0, _common._colorProps)({
      type: color,
      fill: color
    }, n);
  }
  var result = points.map(function (t, i) {
    return (0, _svg._circle)({
      o: t,
      r: 3,
      name: 'boundingRectPoint'
    }, Object.assign({
      fill: 'gray',
      stroke: 'gray'
    }, _props(i)), {
      mouseenter: function mouseenter(e) {
        var el = e.target;
        (0, _dom._attr)(el, {
          r: 5,
          fill: 'red'
        });
        (0, _dom._style)(el, {
          cursor: 'pointer'
        });
        dragedPoint = el;
        dragedPointIndex = i;
        var svg = (0, _dom._closest)(el, 'svg');
        boundingRectBorder = (0, _dom._query)('path[name="edge"]', svg);
        container = svg;
      },
      mousedown: function mousedown(e) {
        var el = e.target;
        var svg = (0, _dom._closest)(el, 'svg');
        container = svg;
        (0, _dom._events)(svg, {
          mousemove: _dragPoint,
          mouseup: function mouseup() {
            (0, _dom._removeEvents)(svg, {
              mousemove: _dragPoint
            });
          }
        });
      },
      mouseout: function mouseout(e) {
        var el = e.target;
        (0, _dom._attr)(el, {
          r: 3,
          fill: 'gray'
        });
      }
    });
  });
  return (0, _svg._g)(result, {
    name: options.name
  });
};
exports._drag = _drag;