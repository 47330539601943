"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spiralPoints = exports.spiral2Points = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
var _math = require("../math");
var _traversal = require("./traversal");
// // 阿基米德螺线
// 一个点在射线上匀速向外运动，同时射线以w的速度转动，点的轨迹就被称为阿基米德螺旋线或等速螺线。
// 阿基米德螺旋线的极坐标公式可以表示为：
// r = a + b ∗ θ r = a+b*\theta
// r=a+b∗θ
// 在直角坐标系下，利用极坐标系到直角坐标的公式，其公式可以被改写为：
// x = r ∗ c o s θ y = r ∗ s i n θ x = r*cos\theta\\ y = r*sin\theta
// x=r∗cosθ
// y=r∗sinθ

// Archimedean spiral
// 阿基米德螺线（亦称等速螺线），得名于公元前三世纪希腊数学家阿基米德。
// 阿基米德螺线是一个点匀速离开一个固定点的同时又以固定的角速度绕该固定点转动而产生的轨迹。
// 阿基米德螺旋公式可以用指定的半径r，圆周速度v，直线运动速度w来表示
// x(t)=vt*cos(wt), y(t)=vt*sin(wt)
var spiralPoints = function spiralPoints(options) {
  var _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    o = options.o,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$n = options.n,
    n = _options$n === void 0 ? 200 : _options$n,
    _options$v = options.v,
    v = _options$v === void 0 ? 1 : _options$v,
    _options$w = options.w,
    w = _options$w === void 0 ? 5 : _options$w,
    _options$m = options.m,
    m = _options$m === void 0 ? 2 : _options$m;
  // const rad = _rad(a) // Math.PI
  // const _x = (t: number) => {
  //     return (v * t) * Math.cos(w * t + rad);
  // }
  // const _y = (t: number) => {
  //     return (v * t) * Math.sin(w * t + rad);
  // }
  var matrix = [];
  var _loop = function _loop(i) {
    var rad = (0, _math._rad)(a + i * 360 / m);
    var _x = function _x(t) {
      return v * t * Math.cos(w * t + rad);
    };
    var _y = function _y(t) {
      return v * t * Math.sin(w * t + rad);
    };
    var points = (0, _traversal._traversalPolar)({
      o: o,
      r: r,
      n: n,
      _x: _x,
      _y: _y
    });
    matrix.push(points);
  };
  for (var i = 0; i < m; i++) {
    _loop(i);
  }
  return matrix;
};
// 螺旋线点
exports.spiralPoints = spiralPoints;
var spiral2Points = function spiral2Points(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [0, 0] : _options$o,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 10 : _options$r2,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 6 : _options$n2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? 0 : _options$a2,
    _options$m2 = options.m,
    m = _options$m2 === void 0 ? 10 : _options$m2;
  var a1 = a;
  var a2 = 360 + a1;
  return Array.from({
    length: m
  }, function (_, j) {
    return Array.from({
      length: n
    }, function (_, i) {
      var a = a1 + i * (a2 - a1) / n;
      return (0, _math._polar)(o, r * (j + 1) + r * i / n, a);
    });
  });
};
exports.spiral2Points = spiral2Points;