"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderMatrix = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.assign.js");
var _common = require("@/common");
var _pathMode = require("@/types/pathMode");
var _transform2 = require("@/math/transform");
var _path2 = require("../core/path");
var _mirror = require("@/algorithm/mirror");
var renderMatrix = function renderMatrix(renderMatrixoptions) {
  var ctx = renderMatrixoptions.ctx,
    matrix = renderMatrixoptions.matrix,
    options = renderMatrixoptions.options,
    props = renderMatrixoptions.props;
  var edge = options.edge,
    mirror = options.mirror,
    transform = options.transform,
    _options$pathMode = options.pathMode,
    pathMode = _options$pathMode === void 0 ? _pathMode.PathMode.LINE_LOOP : _options$pathMode;
  var n = matrix.length;
  var color = (0, _common.isVisible)(mirror) && mirror && mirror.color ? (0, _common._mirrorColor)(mirror) : options.color;
  var makeProps = (0, _common._colorProps)(color, n);
  ctx.save();
  matrix.forEach(function (t, i) {
    var ps = transform ? (0, _transform2._transform)(t, transform) : t;
    var newEdge = Object.assign(Object.assign(Object.assign({}, props), edge), makeProps(i));
    if ((0, _common.isVisible)(mirror)) {
      var mps = (0, _mirror._mirrorPoints)(ps, mirror);
      renderMatrix({
        ctx: ctx,
        matrix: mps,
        options: Object.assign(Object.assign({}, options), {
          pathMode: pathMode,
          // points: mps,
          mirror: null,
          edge: newEdge
        }),
        props: props
      });
      // _path(ctx, {
      //     ...options,
      //     pathMode,
      //     points: mps,
      //     mirror: null,
      //     edge: newEdge
      // }, props)
    } else {
      (0, _path2._path)(ctx, Object.assign(Object.assign({}, options), {
        pathMode: pathMode,
        points: ps,
        edge: newEdge
      }), props);
    }
  });
  ctx.restore();
};
exports.renderMatrix = renderMatrix;