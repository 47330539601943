"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._playBar = void 0;
var _dom = require("../core/dom");
var _next = _interopRequireDefault(require("@/assets/icon/next.svg"));
var _prev = _interopRequireDefault(require("@/assets/icon/prev.svg"));
var _rotute = require("../rotute");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _playBar = function _playBar(handleClick) {
  var goNext = function goNext() {
    var page = (0, _rotute.goNextPage)();
    handleClick && handleClick(page);
    // timer = setTimeout(goNext, 2000)
  };

  var goPrev = function goPrev() {
    var page = (0, _rotute.goPrevPage)();
    handleClick && handleClick(page);
    // timer = setTimeout(goPrev, 2000)
  };

  var handleToolbar = function handleToolbar(e) {
    var el = e.target;
    if ((0, _dom._closest)(el, '.next')) {
      goNext();
    } else if ((0, _dom._closest)(el, '.prev')) {
      goPrev();
    }
    // let cls = e.target.className
    // if (cls === 'next') {
    //     goNext()
    // } else if (cls === 'prev') {
    //     goPrev()
    // } else if (cls === 'stop') {
    //     // force = true
    // }
  };

  var prev = _dom.dom.svg(_prev.default, {
    class: 'prev',
    width: '25',
    height: '25'
  }, {
    click: function click(e) {
      handleToolbar(e);
    }
  });
  var next = _dom.dom.svg(_next.default, {
    class: 'next',
    width: '25',
    height: '25'
  }, {
    click: function click(e) {
      handleToolbar(e);
    }
  });
  var playBar = _dom.dom.div([prev, next], {
    class: 'toolbar'
  });
  return playBar;
};
exports._playBar = _playBar;