"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._dragonPoints = exports._dragon = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _polar = require("@/algorithm/polar");
var _pathShape = require("../pathShape");
var _math = require("@/math");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _dragonPoints = function _dragonPoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$n = options.n,
    n = _options$n === void 0 ? 2 : _options$n,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 3 : _options$depth;
  var points = (0, _polar.polarPoints)({
    o: o,
    r: r,
    a: a,
    n: n
  })[0];
  var _item = function _item(p1, p2, i) {
    var _rotatePoints = (0, _math.rotatePoints)([p1, p2], 90),
      _rotatePoints2 = _slicedToArray(_rotatePoints, 2),
      c1 = _rotatePoints2[0],
      c2 = _rotatePoints2[1];
    return [i % 2 ? c1 : c2, p2];
  };
  var _iter = function _iter(points) {
    var stack = [];
    points.forEach(function (t, i) {
      if (i == 0) {
        stack.push(t);
      }
      var next = points[i + 1];
      if (next) {
        stack.push.apply(stack, _toConsumableArray(_item(t, next, i)));
        // let [c1, c2] = rotatePoints([t, next], 90)
        // switch (type) {
        //     case 'dragon':
        //         stack.push(i % 2 ? c1 : c2, next)
        //         break
        //     case 'c':
        //         stack.push(c2, next)
        //         break
        //     default:
        //         // stack.push(c1, next)
        //         stack.push(i % 2 ? c1 : c2, next)
        // }
      }
    });

    return stack;
  };
  if (depth > 0) {
    var ps = points;
    for (var i = 0; i < depth; i++) {
      ps = _iter(ps);
    }
    return ps;
  }
  return points;
};
exports._dragonPoints = _dragonPoints;
var _dragon = (0, _pathShape.createPathFn)(_dragonPoints);
exports._dragon = _dragon;