"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._radial = exports._gradient = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var _svg = require("../core/svg");
//     <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
//     <stop offset="0%" stop-color="red"/>
//     <stop offset="50%" stop-color="black" stop-opacity="0"/>
//     <stop offset="100%" stop-color="blue"/>
//   </linearGradient>
var _stops = function _stops(list, colors) {
  var len = list.length;
  var stops = list.map(function (t, index) {
    return (0, _svg.createSvg)('stop', {
      offset: 100 * index / (len - 1) + '%',
      'stop-color': colors[index % len] //options['color' + (index + 1)],
    });
  });

  return stops;
};
// 渐变
var _gradient = function _gradient(options) {
  var name = options.name,
    _options$id = options.id,
    id = _options$id === void 0 ? 'shape-gradient' : _options$id,
    _options$colors = options.colors,
    colors = _options$colors === void 0 ? ['green', 'red'] : _options$colors;
  id = (0, _svg._patternId)(id, name);
  var list = [1, 2];
  var stops = _stops(list, colors);
  var grad = (0, _svg.createSvg)('linearGradient', {
    x1: '0',
    x2: '0',
    y1: '0',
    y2: '1',
    id: id
  }, {}, stops);
  var defs = (0, _svg._defs)(grad);
  return defs;
};
exports._gradient = _gradient;
var _radial = function _radial(options) {
  var name = options.name,
    _options$id2 = options.id,
    id = _options$id2 === void 0 ? 'shape-radialGradient' : _options$id2,
    _options$colors2 = options.colors,
    colors = _options$colors2 === void 0 ? ['green', 'red'] : _options$colors2;
  id = (0, _svg._patternId)(id, name);
  var list = [1, 2];
  var stops = _stops(list, colors);
  var grad = (0, _svg.createSvg)('radialGradient', {
    id: id
  }, {}, stops);
  var defs = (0, _svg._defs)(grad);
  return defs;
};
exports._radial = _radial;