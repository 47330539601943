"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.segmentsIntr = exports.segCrossPoints = exports.neighbourSegCrossPoints = exports.edgeCrossPoints = void 0;
var _math = require("@/math");
var _area = require("./area");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 四个点的交叉点
// 算法三: 判断每一条线段的两个端点是否都在另一条线段的两侧, 是则求出两条线段所在直线的交点, 否则不相交.
// 不通过法线投影来判断点和线段的位置关系, 而是通过点和线段构成的三角形面积来判断.
// 如果”线段ab和点c构成的三角形面积”与”线段ab和点d构成的三角形面积” 构成的三角形面积的正负符号相异,
// 那么点c和点d位于线段ab两侧.
var segmentsIntr = function segmentsIntr(_ref, _ref2) {
  var _ref3 = _slicedToArray(_ref, 2),
    a = _ref3[0],
    b = _ref3[1];
  var _ref4 = _slicedToArray(_ref2, 2),
    c = _ref4[0],
    d = _ref4[1];
  // 三角形abc 面积
  var area_abc = (0, _area.triArea)(a, b, c); //(a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0]);
  // 三角形abd 面积
  var area_abd = (0, _area.triArea)(a, b, d); //(a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0]);
  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd >= 0) {
    return null;
  }
  // 三角形cda 面积
  var area_cda = (0, _area.triArea)(c, d, a); //(c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0]);
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  var area_cdb = area_cda + area_abc - area_abd;
  if (area_cda * area_cdb >= 0) {
    return null;
  }
  //计算交点坐标
  var t = area_cda / (area_abd - area_abc);
  var dx = t * (b[0] - a[0]);
  var dy = t * (b[1] - a[1]);
  return [(0, _math._k)(a[0] + dx), (0, _math._k)(a[1] + dy)];
};
// 边与线段 交点
exports.segmentsIntr = segmentsIntr;
var edgeCrossPoints = function edgeCrossPoints(points, segs) {
  var lcps = [];
  var n = segs.length;
  var m = points.length;
  for (var i = 0; i < n; i++) {
    var seg = segs[i];
    for (var j = 0; j < m; j++) {
      var t = points[j];
      var next = points[(j + 1) % m];
      var p = segmentsIntr(seg, [t, next]);
      if (p) {
        lcps.push(p);
      }
    }
  }
  return lcps;
};
// 线段的所有交点
exports.edgeCrossPoints = edgeCrossPoints;
var segCrossPoints = function segCrossPoints(segs) {
  var ps = [];
  var len = segs.length;
  for (var i = 0; i < len; i++) {
    var seg = segs[i];
    for (var j = i + 1; j < len; j++) {
      var next = segs[j];
      var p = segmentsIntr(seg, next);
      if (p) {
        ps.push(p);
      }
    }
  }
  return ps;
};
// links seg Matrix
// export const segCrossPoints = (segs) => {
//     let lcps = []
//     for (let i = 0; i < segs.length - 1; i++) {
//         let seg = segs[i]
//         let others = segs.slice(i + 1)
//         let osegs = plainMatrix(others)
//         seg.forEach(u => {
//             osegs.forEach(v => {
//                 let p = segmentsIntr(u, v)
//                 if (p) {
//                     lcps.push(p)
//                 }
//             })
//         })
//     }
//     return lcps
// }
// 隔壁线段的 交点
exports.segCrossPoints = segCrossPoints;
var neighbourSegCrossPoints = function neighbourSegCrossPoints(segs) {
  var lcps = [];
  var len = segs.length;
  for (var i = 0; i < len; i++) {
    var seg = segs[i];
    var next = segs[(i + 1) % len];
    var p = segmentsIntr(seg, next);
    if (p) {
      lcps.push(p);
    }
  }
  return lcps;
};
exports.neighbourSegCrossPoints = neighbourSegCrossPoints;