"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentType = void 0;
var ContentType;
exports.ContentType = ContentType;
(function (ContentType) {
  ContentType["SVG"] = "svg";
  ContentType["Canvas"] = "Canvas";
  ContentType["Webgl"] = "webgl";
  ContentType["Lottie"] = "lottie";
  ContentType["Editor"] = "editor";
  ContentType["Html"] = "html";
})(ContentType || (exports.ContentType = ContentType = {}));