"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._axis = void 0;
var _axis = function _axis(ctx, options) {
  var _options$gridSpace = options.gridSpace,
    gridSpace = _options$gridSpace === void 0 ? 50 : _options$gridSpace,
    _options$gridLineColo = options.gridLineColor,
    gridLineColor = _options$gridLineColo === void 0 ? '#ccc' : _options$gridLineColo,
    _options$lineDashStyl = options.lineDashStyle,
    lineDashStyle = _options$lineDashStyl === void 0 ? 5 : _options$lineDashStyl;
  var canvas = ctx.canvas;
  var xLines = Math.floor(canvas.width / gridSpace);
  ctx.save();
  // 画横线
  for (var i = 0; i <= xLines; i++) {
    ctx.beginPath();
    ctx.moveTo(0, 0 + i * gridSpace);
    ctx.lineTo(canvas.width, 0 + i * gridSpace);
    ctx.lineWidth = 1;
    ctx.setLineDash([lineDashStyle]);
    ctx.strokeStyle = gridLineColor;
    ctx.stroke();
  }
  // 画竖线
  for (var _i = 0; _i <= xLines; _i++) {
    ctx.beginPath();
    ctx.moveTo(0 + _i * gridSpace, 0);
    ctx.lineTo(0 + _i * gridSpace, canvas.height);
    ctx.lineWidth = 1;
    ctx.setLineDash([lineDashStyle]);
    ctx.strokeStyle = gridLineColor;
    ctx.stroke();
  }
  ctx.restore();
};
exports._axis = _axis;