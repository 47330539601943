"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconsConfig = exports._iconStop = exports._iconPrev = exports._iconPlay = exports._iconPause = exports._iconNext = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _math = require("@/math");
var _square = require("@/algorithm/square");
var _pathShape = require("./pathShape");
var _pathMode = require("@/types/pathMode");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _icon = function _icon(points, props) {
  return (0, _pathShape.createPathFn)(points)({
    pathMode: _pathMode.PathMode.LINE_FAN // 'POINTS'
    // labels:{}
  }, Object.assign({
    fill: 'red',
    stroke: 'none'
  }, props));
};
var _iconStop = function _iconStop(options, props) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$edge = options.edge,
    edge = _options$edge === void 0 ? 36 : _options$edge;
  var r = edge / 2 || 18;
  var squarePoints = (0, _square._squarePoints)(o, r);
  return _icon(squarePoints, props);
};
exports._iconStop = _iconStop;
var _iconPlay = function _iconPlay(options, props) {
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [400, 300] : _options$o2,
    _options$edge2 = options.edge,
    edge = _options$edge2 === void 0 ? 36 : _options$edge2;
  var r = edge / 2 || 18;
  var squarePoints = (0, _square._squarePoints)(o, r);
  var _squarePoints2 = _slicedToArray(squarePoints, 4),
    p1 = _squarePoints2[0],
    p2 = _squarePoints2[1],
    p3 = _squarePoints2[2],
    p4 = _squarePoints2[3];
  var p = (0, _math._mid)(p1, p4);
  return _icon([p2, p, p3], props);
};
exports._iconPlay = _iconPlay;
var _iconNext = function _iconNext(options, props) {
  return _iconPlay(options, props);
};
exports._iconNext = _iconNext;
var _iconPrev = function _iconPrev(options, props) {
  var _options$o3 = options.o,
    o = _options$o3 === void 0 ? [400, 300] : _options$o3,
    _options$edge3 = options.edge,
    edge = _options$edge3 === void 0 ? 36 : _options$edge3;
  var r = edge / 2 || 18;
  var squarePoints = (0, _square._squarePoints)(o, r);
  var _squarePoints3 = _slicedToArray(squarePoints, 4),
    p1 = _squarePoints3[0],
    p2 = _squarePoints3[1],
    p3 = _squarePoints3[2],
    p4 = _squarePoints3[3];
  var p = (0, _math._mid)(p2, p3);
  return _icon([p1, p, p4], props);
};
exports._iconPrev = _iconPrev;
var _iconPause = function _iconPause(options, props) {
  var _options$o4 = options.o,
    o = _options$o4 === void 0 ? [400, 300] : _options$o4,
    _options$edge4 = options.edge,
    edge = _options$edge4 === void 0 ? 36 : _options$edge4;
  var r = edge / 2 || 18;
  var squarePoints = (0, _square._squarePoints)(o, r);
  var _squarePoints4 = _slicedToArray(squarePoints, 4),
    p1 = _squarePoints4[0],
    p2 = _squarePoints4[1],
    p3 = _squarePoints4[2],
    p4 = _squarePoints4[3];
  var _map = [[p1, p2], [p4, p3]].map(function (t) {
      return (0, _math._lerp)(t[0], t[1], 0.3);
    }),
    _map2 = _slicedToArray(_map, 2),
    p5 = _map2[0],
    p6 = _map2[1];
  var _map3 = [[p1, p2], [p4, p3]].map(function (t) {
      return (0, _math._lerp)(t[0], t[1], 0.7);
    }),
    _map4 = _slicedToArray(_map3, 2),
    p7 = _map4[0],
    p8 = _map4[1];
  // return _icon([p2, p7, p8, p3])
  // return _icon([p1, p5, p6, p4])
  return _icon([[p1, p5, p6, p4], [p2, p7, p8, p3]], props);
};
exports._iconPause = _iconPause;
var iconsConfig = {
  iconPlay: _iconPlay,
  iconStop: _iconStop,
  iconPause: _iconPause,
  iconNext: _iconNext,
  iconPrev: _iconPrev
};
exports.iconsConfig = iconsConfig;