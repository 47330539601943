"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.weak-map.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPdf = createPdf;
exports.createPdfByDataUri = createPdfByDataUri;
exports.createPdfBySvg = createPdfBySvg;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.map.js");
var pdfMake = _interopRequireWildcard(require("pdfmake/build/pdfmake.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var getMaxSize = function getMaxSize(images) {
  return images.reduce(function (a, b) {
    var width = Math.max(a.width, b.size.width);
    var height = Math.max(a.height, b.size.height);
    return {
      width: width,
      height: height
    };
  }, {
    width: 0,
    height: 0
  });
};
function createPdf(images) {
  var _getMaxSize = getMaxSize(images),
    width = _getMaxSize.width,
    height = _getMaxSize.height;
  var docDefinition = {
    pageMargins: 0,
    content: images.map(function (_ref) {
      var data = _ref.data,
        _ref$size = _ref.size,
        width = _ref$size.width,
        height = _ref$size.height,
        type = _ref.type;
      var fit = [width, height];
      if (type === 'uri') {
        return {
          image: data,
          width: width,
          height: height,
          fit: fit
        };
      }
      return {
        svg: data,
        width: width,
        height: height,
        fit: fit
      };
    }),
    pageBreak: 'before',
    pageSize: {
      width: width,
      height: height
    },
    pageOrientation: width >= height ? 'landscape' : 'portrait'
  };
  var pdf = pdfMake.createPdf(docDefinition);
  return pdf;
}
function createPdfBySvg(images) {
  var _getMaxSize2 = getMaxSize(images),
    width = _getMaxSize2.width,
    height = _getMaxSize2.height;
  var docDefinition = {
    pageMargins: 0,
    content: images.map(function (_ref2) {
      var svg = _ref2.svg,
        _ref2$size = _ref2.size,
        width = _ref2$size.width,
        height = _ref2$size.height;
      var fit = [width, height];
      return {
        svg: svg,
        width: width,
        height: height,
        fit: fit
      };
    }),
    pageBreak: 'before',
    pageSize: {
      width: width,
      height: height
    },
    pageOrientation: width >= height ? 'landscape' : 'portrait'
  };
  var pdf = pdfMake.createPdf(docDefinition);
  return pdf;
}
function createPdfByDataUri(uri) {
  var docDefinition = {
    pageMargins: 0,
    content: {
      image: uri
    },
    pageBreak: 'before'
  };
  var pdf = pdfMake.createPdf(docDefinition);
  return pdf;
}