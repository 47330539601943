"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._centre = void 0;
require("core-js/modules/es.object.assign.js");
var _svg = require("../core/svg");
var _math = require("@/math");
var _drag2 = require("../core/drag");
var _pathMode2 = require("../core/pathMode");
var _centre = function _centre(_ref, props) {
  var points = _ref.points,
    centre = _ref.centre,
    pathMode = _ref.pathMode,
    curve = _ref.curve;
  var o = (0, _math._o)(points);
  var r = centre.r,
    draggable = centre.draggable;
  if (draggable) {
    return (0, _drag2._drag)([o], function (options) {
      var o2 = options.points[0];
      var ps = (0, _math._move)(points, o, o2);
      return (0, _pathMode2._pathMode)({
        pathMode: pathMode,
        points: ps,
        curve: curve
      });
    }, {
      curve: curve,
      pathMode: pathMode
    });
  }
  return (0, _svg._path)((0, _pathMode2._points)({
    points: [o]
  }, {
    r: r
  }), Object.assign(Object.assign(Object.assign({}, props), centre), {
    name: 'centre'
  }));
};
exports._centre = _centre;