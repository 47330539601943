"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._gridRect = exports._gridPolygon = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _color = require("@/color");
var _math = require("@/math");
var _grid = require("@/algorithm/grid");
var _polygon2 = require("./polygon");
var _svg = require("./core/svg");
var _color2 = require("@/types/color");
var _gridPolygon = function _gridPolygon(options, props) {
  var _options$r = options.r,
    r = _options$r === void 0 ? 25 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    n = options.n,
    transform = options.transform,
    grid = options.grid,
    _options$color = options.color,
    color = _options$color === void 0 ? _color2.Color.ColorCircle : _options$color;
  var gcps = (0, _math.plainMatrix)((0, _grid.gridCellPoints)(Object.assign({
    r: 50
  }, grid)));
  var colors = (0, _color._colors)(color, gcps.length, 0.5);
  var children = gcps.map(function (t, i) {
    return (0, _polygon2._polygon)({
      o: t,
      n: n,
      r: r,
      a: a,
      transform: transform
    }, Object.assign({
      fill: colors[i],
      stroke: 'none'
    }, props));
  });
  return (0, _svg._g)(children);
};
exports._gridPolygon = _gridPolygon;
var _gridRect = function _gridRect(options, props) {
  return _gridPolygon(Object.assign(Object.assign({}, options), {
    n: 4
  }), props);
};
exports._gridRect = _gridRect;