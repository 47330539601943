"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.oppositeDirection = exports.normalize = exports.isConcavePolygon = exports.crossProduct = exports.checkSweepFlag = exports.checkPolygonSweepFlag = exports.checkConcave = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.map.js");
var _ = require(".");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 叉乘 v1 × v2 = |a| * |b| * sin θ
var crossProduct = function crossProduct(_ref, _ref2) {
  var _ref3 = _slicedToArray(_ref, 2),
    p1 = _ref3[0],
    p2 = _ref3[1];
  var _ref4 = _slicedToArray(_ref2, 2),
    p3 = _ref4[0],
    p4 = _ref4[1];
  var v1 = [p1[0] - p2[0], p1[1] - p2[1]];
  var v2 = [p3[0] - p4[0], p3[1] - p4[1]];
  return v1[0] * v2[1] - v1[1] * v2[0];
};
// 判断是凹凸点
// 1凹点  -1凸点
// 1顺时针 0逆时针
// 右手法则
exports.crossProduct = crossProduct;
var checkConcave = function checkConcave(_ref5) {
  var _ref6 = _slicedToArray(_ref5, 3),
    p1 = _ref6[0],
    p2 = _ref6[1],
    p3 = _ref6[2];
  var v1 = [p1[0] - p2[0], p1[1] - p2[1]];
  var v2 = [p2[0] - p3[0], p2[1] - p3[1]];
  return v1[0] * v2[1] - v1[1] * v2[0] >= 0 ? 1 : 0;
};
// 优角（Reflexive Angle
// 1顺时针 0逆时针
exports.checkConcave = checkConcave;
var checkSweepFlag = function checkSweepFlag(points, index) {
  var len = points.length;
  var prev = points[index - 1 < 0 ? len - 1 : index - 1];
  var curr = points[index];
  var next = points[(index + 1) % len];
  return checkConcave([prev, curr, next]);
};
// 多边形 顺逆时针
exports.checkSweepFlag = checkSweepFlag;
var checkPolygonSweepFlag = function checkPolygonSweepFlag(points) {
  var o = (0, _._o)(points);
  return checkConcave([points[0], o, points[1]]);
};
// 凸 (convex) 多边形
// 凹 (concave) 多边形
exports.checkPolygonSweepFlag = checkPolygonSweepFlag;
var isConcavePolygon = function isConcavePolygon(points) {
  var len = points.length;
  var first = checkSweepFlag(points, 0);
  for (var i = 0; i < len; i++) {
    var curr = points[i];
    var next = points[(i + 1) % len];
    var next2 = points[(i + 2) % len];
    var cc = checkConcave([curr, next, next2]);
    if (first !== cc) {
      return true;
    }
  }
  return false;
};
// 移动绝对距离
exports.isConcavePolygon = isConcavePolygon;
var toVec = function toVec(p1, p2) {
  return [p1[0] - p2[0], p1[1] - p2[1]];
};
// 对两向量做点乘,若结果大于0,就是同向,等于0垂直,小于0反向
// 如a(x1,y1),b(x2,y2)
// a*b=x1*x2+y1*y2;
// 判断a*b的符号就可可以了
var oppositeDirection = function oppositeDirection(_ref7, _ref8) {
  var _ref9 = _slicedToArray(_ref7, 2),
    p1 = _ref9[0],
    p2 = _ref9[1];
  var _ref10 = _slicedToArray(_ref8, 2),
    p3 = _ref10[0],
    p4 = _ref10[1];
  var v1 = toVec(p1, p2);
  var v2 = toVec(p3, p4);
  return v1[0] * v2[0] + v1[1] * v2[1] < 0;
};
// 长度的平方
exports.oppositeDirection = oppositeDirection;
var sqrLength = function sqrLength() {
  var v1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return v1.map(function (t) {
    return t * t;
  }).reduce(function (a, b) {
    return a + b;
  });
};
// 长度
var length = function length() {
  var v1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return Math.sqrt(sqrLength(v1));
};
// 向量单位
var normalize = function normalize(v1) {
  var len = length(v1);
  // if (len > Math.EPS) {
  return v1.map(function (t) {
    return t / len;
  });
  // }
};
exports.normalize = normalize;