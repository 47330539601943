"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._cube = void 0;
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.typed-array.float32-array.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-string.js");
require("core-js/modules/es.typed-array.uint8-array.js");
var _webgl = require("./webgl");
var _cube2 = _interopRequireDefault(require("./glsl/cube.frag"));
var _cube3 = _interopRequireDefault(require("./glsl/cube.vert"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _cube = function _cube(gl) {
  //初始化着色器
  var program = (0, _webgl.defineProgram)(gl, _cube3.default, _cube2.default);
  //获取顶点着色器的位置变量apos
  var aposLocation = gl.getAttribLocation(program, 'apos');
  //八个顶点的坐标数组
  var data = new Float32Array([0.5, 0.5, 0.5, -0.5, 0.5, 0.5, -0.5, -0.5, 0.5, 0.5, -0.5, 0.5, 0.5, 0.5, -0.5, -0.5, 0.5, -0.5, -0.5, -0.5, -0.5, 0.5, -0.5, -0.5 //顶点7
  ]);
  //        顶点索引数组
  var indexes = new Uint8Array([
  //        前四个点
  0, 1, 2, 3,
  //        后四个顶点
  4, 5, 6, 7,
  //        前后对应点
  0, 4, 1, 5, 2, 6, 3, 7]);
  //创建缓冲区对象
  var indexexBuffer = gl.createBuffer();
  //绑定缓冲区对象
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexexBuffer);
  //索引数组indexes数据传入缓冲区
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, indexes, gl.STATIC_DRAW);
  //创建缓冲区对象
  var Buffer = gl.createBuffer();
  //绑定缓冲区对象
  gl.bindBuffer(gl.ARRAY_BUFFER, Buffer);
  //将顶点数据data传入缓冲区
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  //缓冲区中的数据按照一定的规律传递给位置变量apos
  gl.vertexAttribPointer(aposLocation, 3, gl.FLOAT, false, 0, 0);
  //允许数据传递
  gl.enableVertexAttribArray(aposLocation);
  //LINE_LOOP模式绘制前四个点
  gl.drawElements(gl.LINE_LOOP, 4, gl.UNSIGNED_BYTE, 0);
  //LINE_LOOP模式从第五个点开始绘制四个点
  gl.drawElements(gl.LINE_LOOP, 4, gl.UNSIGNED_BYTE, 4);
  //LINES模式绘制后八个点
  gl.drawElements(gl.LINES, 8, gl.UNSIGNED_BYTE, 8);
  return gl;
};
exports._cube = _cube;