"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._motion = exports._animateTransform = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
var _svg = require("./svg");
var _math = require("@/math");
var _pathMode = require("./pathMode");
// const pointsFnMap = {
//     polygon: polarPoints,
//     grid: gridCrossPoints,
//     gridCell: gridCellPoints,
//     polarGrid: polarGridPoints,
//     spiral: spiralPoints
// }
// export const _motion = (options = {}) => {
//     let { id, dur = '6s', begin = "0s", path = {}, type = "dashoffset" } = options
//     if (type === 'dashoffset') {
//         // dashoffset动画
//         let to = _k(Math.PI * 100)
//         return createSvg("animate", {
//             attributeType: 'XML',
//             attributeName: 'stroke-dashoffset',
//             repeatCount: 'indefinite',
//             from: '0',
//             to,
//             dur,
//         })
//     }
//     let mpath
//     if (id) {
//         mpath = createSvg("mpath", {
//             'xlink:href': '#' + id
//         })
//     }
//     let { shape = 'polygon', r = 100, n = 3, broken = false, closed = true } = path
//     let points = pointsFnMap[shape]({
//         r, n,
//         ...path,
//         o: [0, 0]
//     })
//     let d = _d(points, {
//         broken,
//         closed
//     })
//     // 路径动画
//     return createSvg("animateMotion", {
//         dur,
//         begin,
//         repeatCount: 'indefinite',
//         rotate: 'auto',
//         fill: 'remove',
//         path: d
//     }, {}, mpath)
// }
// 动画
var _animateTransform = function _animateTransform(_ref) {
  var _ref$type = _ref.type,
    type = _ref$type === void 0 ? 'rotate' : _ref$type,
    from = _ref.from,
    to = _ref.to,
    dur = _ref.dur;
  return (0, _svg.createSvg)('animateTransform', {
    attributeType: 'XML',
    attributeName: 'transform',
    begin: '0s',
    dur: dur,
    type: type,
    from: from,
    to: to,
    repeatCount: 'indefinite'
    // fill:'freeze'
  });
};
exports._animateTransform = _animateTransform;
var _animateMotion = function _animateMotion(_ref2) {
  var path = _ref2.path,
    dur = _ref2.dur;
  return (0, _svg.createSvg)('animateMotion', {
    attributeType: 'XML',
    attributeName: 'transform',
    begin: '0s',
    dur: dur,
    path: path,
    // type,
    // from,
    // to,
    repeatCount: 'indefinite'
    // fill:'freeze'
  });
};

var _motion = function _motion(_ref3) {
  var type = _ref3.type,
    _ref3$speed = _ref3.speed,
    speed = _ref3$speed === void 0 ? 1 : _ref3$speed,
    origin = _ref3.origin,
    points = _ref3.points;
  var centre, from, to;
  var dur = (0, _math._k)(10 / speed) + 's';
  var o = (0, _math._o)(points);
  switch (origin) {
    case 'centre':
      centre = o;
      break;
    case 'vertex':
      centre = points[0];
      break;
  }
  switch (type) {
    case 'rotate':
      from = "0 ".concat(centre.map(function (t) {
        return (0, _math._k)(t);
      }).join(' '));
      to = "360 ".concat(centre.map(function (t) {
        return (0, _math._k)(t);
      }).join(' '));
      break;
    case 'scale':
      from = '1 1';
      to = '2 2';
      break;
    case 'skewX':
    case 'skewY':
      from = "0 ".concat(centre.map(function (t) {
        return (0, _math._k)(t);
      }).join(' '));
      to = "0 ".concat(centre.map(function (t) {
        return (0, _math._k)(t * 2);
      }).join(' '));
      break;
    case 'path':
      var ps = (0, _math._move)(points, o, [0, 0]);
      var path = (0, _pathMode._lineLoop)({
        points: ps
      });
      return _animateMotion({
        path: path,
        dur: dur
      });
  }
  return _animateTransform({
    type: type,
    from: from,
    to: to,
    dur: dur
  });
};
exports._motion = _motion;