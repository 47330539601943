"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._sierpinskiBezier = exports._sierpinski = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.join.js");
var _sierpinski2 = require("@/algorithm/sierpinski");
var _math = require("@/math");
var _polar = require("@/algorithm/polar");
var _traversal2 = require("@/algorithm/traversal");
var _curve = require("../core/curve");
var _svg = require("../core/svg");
var _helper = require("../helper");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _sierpinski = function _sierpinski(options, props) {
  var points = (0, _polar.polarPoints)(options)[0];
  var matrix = (0, _sierpinski2.sierpinskiPoints)(Object.assign(Object.assign({}, options), {
    points: points,
    endToEnd: true,
    loop: true
  }));
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
// 杨辉三角贝塞尔
exports._sierpinski = _sierpinski;
var _sierpinskiBezier = function _sierpinskiBezier(options, props) {
  var points = (0, _polar.polarPoints)(options)[0];
  var _options$depth = options.depth,
    depth = _options$depth === void 0 ? 0 : _options$depth,
    _options$skew = options.skew,
    skew = _options$skew === void 0 ? 0 : _options$skew,
    _options$amplitude = options.amplitude,
    amplitude = _options$amplitude === void 0 ? 0 : _options$amplitude,
    _options$loop = options.loop,
    loop = _options$loop === void 0 ? true : _options$loop,
    bottomControlGear = options.bottomControlGear;
  var matrix = (0, _sierpinski2.sierpinskiPoints)({
    points: points,
    depth: depth,
    loop: loop,
    bottomControlGear: bottomControlGear,
    amplitude: amplitude
  });
  var n = 4;
  var path = (0, _traversal2._traversal)({
    points: (0, _math.plainMatrix)(matrix),
    n: n,
    iter: function iter(_ref) {
      var _ref$points = _slicedToArray(_ref.points, 4),
        p1 = _ref$points[0],
        p2 = _ref$points[1],
        p3 = _ref$points[2],
        p4 = _ref$points[3];
      return (0, _curve._bezierCurveTo)([p2, p3, p4]);
    },
    init: function init(_ref2) {
      var point = _ref2.point;
      return (0, _curve._moveTo)(point);
    },
    loop: loop
  }).join(' ');
  return (0, _svg._path)(path, Object.assign({
    stroke: 'red',
    fill: 'none'
  }, props));
};
exports._sierpinskiBezier = _sierpinskiBezier;