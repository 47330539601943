"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._edge = void 0;
require("core-js/modules/es.object.assign.js");
var _stroke = require("@/types/stroke");
var _pathMode2 = require("../core/pathMode");
var _svg = require("../core/svg");
var _isometricalScale = require("@/algorithm/isometricalScale");
// 边
var _edge = function _edge(_ref, props) {
  var pathMode = _ref.pathMode,
    points = _ref.points,
    curve = _ref.curve;
  var strokeMode = props.strokeMode,
    strokeWidth = props.strokeWidth;
  switch (strokeMode) {
    case _stroke.StrokeMode.InnerStroke:
      points = (0, _isometricalScale.isometricalScale)(points, -strokeWidth / 2);
      break;
    case _stroke.StrokeMode.OuterStroke:
      points = (0, _isometricalScale.isometricalScale)(points, +strokeWidth / 2);
      break;
  }
  var path = (0, _pathMode2._pathMode)({
    pathMode: pathMode,
    points: points,
    curve: curve
  });
  return (0, _svg._path)(path, Object.assign({
    stroke: 'red',
    fill: 'none',
    name: 'edge'
  }, props));
};
exports._edge = _edge;