"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._square = void 0;
var _webgl = require("./webgl");
var _square2 = _interopRequireDefault(require("./glsl/square.frag"));
var _square3 = _interopRequireDefault(require("./glsl/square.vert"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _square = function _square(gl) {
  // 初始化着色器
  var program = (0, _webgl.defineProgram)(gl, _square3.default, _square2.default);
  // 指定将要用来清理绘图区的颜色
  // gl.clearColor(0., 0.0, 0.0, 1.0);
  // 清理绘图区
  gl.clear(gl.COLOR_BUFFER_BIT);
  // 绘制顶点
  gl.drawArrays(gl.POINTS, 0, 1);
  // const positions = [
  //     1.0, 1.0,
  //     -1.0, 1.0,
  //     1.0, -1.0,
  //     -1.0, -1.0,
  // ];
  // let buffers = initBuffers(gl, positions)
  // drawScene(gl,program,buffers)
  return gl;
};
exports._square = _square;