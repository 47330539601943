"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.segToPolygonPoints = exports.polygonPoints = exports.polarPoints = exports.polarGridPoints = exports.polar2Points = exports.isometricPoints = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.array.map.js");
var _math = require("@/math");
var _traversal = require("./traversal");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 圆弧切分
var polarPoints = function polarPoints(options) {
  var o = options.o,
    _options$r = options.r,
    r = _options$r === void 0 ? 200 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$n = options.n,
    n = _options$n === void 0 ? 4 : _options$n,
    _options$sweepFlag = options.sweepFlag,
    sweepFlag = _options$sweepFlag === void 0 ? true : _options$sweepFlag,
    _options$m = options.m,
    m = _options$m === void 0 ? 1 : _options$m;
  var _x = function _x(t) {
    return Math.cos(t);
  };
  var _y = function _y(t) {
    return Math.sin(t);
  };
  var _r = function _r(i) {
    return r * (m - i) / m;
  };
  return (0, _traversal._multiplePolar)({
    o: o,
    r: _r,
    a: a,
    n: n,
    m: m,
    sweepFlag: sweepFlag,
    _x: _x,
    _y: _y
  });
};
// bak
exports.polarPoints = polarPoints;
var polar2Points = function polar2Points(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [0, 0] : _options$o,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 100 : _options$r2,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 6 : _options$n2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? 0 : _options$a2,
    _options$direction = options.direction,
    direction = _options$direction === void 0 ? 1 : _options$direction;
  var a1 = a;
  var a2 = options.a2 || 360 + a1;
  var ps = Array.from({
    length: n
  }, function (_, i) {
    var a = a1 + i * (a2 - a1) / n;
    return (0, _math._polar)(o, r, a);
  });
  return direction === 1 ? ps : ps.reverse();
};
// 坐标网格线
exports.polar2Points = polar2Points;
var polarGridPoints = function polarGridPoints(options) {
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [0, 0] : _options$o2,
    _options$r3 = options.r,
    r = _options$r3 === void 0 ? 100 : _options$r3,
    _options$n3 = options.n,
    n = _options$n3 === void 0 ? 6 : _options$n3,
    _options$a3 = options.a,
    a = _options$a3 === void 0 ? 0 : _options$a3,
    _options$m2 = options.m,
    m = _options$m2 === void 0 ? 5 : _options$m2;
  var a1 = a,
    a2 = 360 + a1;
  return Array.from({
    length: m
  }, function (_, j) {
    return Array.from({
      length: n
    }, function (_, i) {
      var a = a1 + i * (a2 - a1) / n;
      return (0, _math._polar)(o, r * (j + 1), a);
    });
  });
};
// 等角
exports.polarGridPoints = polarGridPoints;
var isometricPoints = function isometricPoints(options) {
  var _options$o3 = options.o,
    o = _options$o3 === void 0 ? [0, 0] : _options$o3,
    _options$r4 = options.r,
    r = _options$r4 === void 0 ? 100 : _options$r4,
    _options$a4 = options.a,
    a = _options$a4 === void 0 ? 45 : _options$a4,
    _options$n4 = options.n,
    n = _options$n4 === void 0 ? 10 : _options$n4;
  return Array.from({
    length: n
  }, function (_, i) {
    return (0, _math._polar)(o, r, a * i);
  });
};
// 根据线段 作图 多边形
// 逆时针
exports.isometricPoints = isometricPoints;
var segToPolygonPoints = function segToPolygonPoints(_ref, n, sweepFlag) {
  var _ref2 = _slicedToArray(_ref, 2),
    from = _ref2[0],
    to = _ref2[1];
  // 内角
  var ia = 180 - 360 / n;
  var points = [from, to];
  var fn = function fn(p, o) {
    var next = (0, _math._rotate)(p, o, ia * ((sweepFlag !== null && sweepFlag !== void 0 ? sweepFlag : true) ? 1 : -1)).map(function (t) {
      return (0, _math._k)(t);
    });
    points.push(next);
    var len = points.length;
    if (len < n) {
      fn(o, next);
    }
  };
  fn(from, to);
  return points;
};
// 等边 已知边，作多边形
exports.segToPolygonPoints = segToPolygonPoints;
var polygonPoints = function polygonPoints(options) {
  var _options$o4 = options.o,
    o = _options$o4 === void 0 ? [0, 0] : _options$o4,
    _options$r5 = options.r,
    r = _options$r5 === void 0 ? 100 : _options$r5,
    _options$a5 = options.a,
    a = _options$a5 === void 0 ? 45 : _options$a5,
    _options$n5 = options.n,
    n = _options$n5 === void 0 ? 10 : _options$n5,
    _options$sweepFlag2 = options.sweepFlag,
    sweepFlag = _options$sweepFlag2 === void 0 ? true : _options$sweepFlag2;
  var from = o;
  var to = options.to || (0, _math._polar)(o, r, a);
  return segToPolygonPoints([from, to], n, sweepFlag);
};
exports.polygonPoints = polygonPoints;