"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spiralShape = exports.ringShape = exports.rayShape = exports.polarShape = void 0;
require("core-js/modules/es.array.map.js");
var _polarMatrix = require("@/algorithm/polarMatrix");
var _math = require("@/math");
var _transform2 = require("@/math/transform");
var _helper = require("../helper");
var _polar = require("@/algorithm/polar");
var _array = require("@/math/array");
/**
 * 多边形
 * @param ctx
 * @param options
 * @param props
 */
var polarShape = function polarShape(ctx, options, props) {
  // _polygon(ctx, options, props)
  var matrix = (0, _polar.polarPoints)(options);
  (0, _helper.renderMatrix)({
    matrix: matrix,
    ctx: ctx,
    options: options,
    props: props
  });
};
/**
 * 螺旋
 * @param ctx
 * @param options
 * @param props
 */
exports.polarShape = polarShape;
var spiralShape = function spiralShape(ctx, options, props) {
  var transform = options.transform,
    n = options.n;
  var matrix = (0, _polarMatrix.spiralPoints)(options).map(function (t) {
    return (0, _transform2._transform)(t, transform);
  });
  var points = (0, _math.plainMatrix)(matrix);
  matrix = (0, _array.toMatrix)(points, n, true);
  (0, _helper.renderMatrix)({
    ctx: ctx,
    matrix: matrix,
    options: options,
    props: props
  });
};
/**
 * 环形
 * @param ctx
 * @param options
 * @param props
 */
exports.spiralShape = spiralShape;
var ringShape = function ringShape(ctx, options, props) {
  var matrix = (0, _polarMatrix.ringPoints)(options);
  (0, _helper.renderMatrix)({
    matrix: matrix,
    ctx: ctx,
    options: options,
    props: props
  });
};
/**
 * 射线
 * @param ctx
 * @param options
 * @param props
 */
exports.ringShape = ringShape;
var rayShape = function rayShape(ctx, options, props) {
  var matrix = (0, _polarMatrix.rayPoints)(options);
  (0, _helper.renderMatrix)({
    matrix: matrix,
    ctx: ctx,
    options: options,
    props: props
  });
};
exports.rayShape = rayShape;