"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._dashLines = void 0;
var _pathMode = require("../core/pathMode");
var _svg = require("../core/svg");
var _dashLines = function _dashLines(points, props) {
  return (0, _svg._path)((0, _pathMode._lines)({
    points: points
  }), props);
};
exports._dashLines = _dashLines;