"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._iconClose = void 0;
var _dom = require("./dom");
var _close = _interopRequireDefault(require("@/assets/icon/close.svg"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _iconClose = function _iconClose(events) {
  return _dom.dom.svg(_close.default, {
    class: 'icon-close'
  }, events);
};
exports._iconClose = _iconClose;