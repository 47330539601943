"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mirrorTypes = exports.mirrorRefractions = exports._onelinePoints = exports._mirrorPoints = exports._mirrorIterPoints = exports._borderPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.object.keys.js");
var _math = require("../math");
var _utils = require("../utils");
var _mirror2 = require("../math/mirror");
var _arraySort = require("../math/arraySort");
var _shapeRect = require("./shapeRect");
var _angle = require("./angle");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 外圈顶点
var _borderPoints = function _borderPoints(matrix, mirror) {
  var index = (0, _utils.isObject)(mirror) ? mirror.borderIndex : 0;
  var len = matrix.length;
  return matrix[index >= len || index <= 0 ? len - 1 : index];
};
// 镜像点
exports._borderPoints = _borderPoints;
var _mirrorPoint = function _mirrorPoint(point, index, mirrorPoints) {
  return mirrorPoints ? mirrorPoints[index % mirrorPoints.length] : point;
};
var _none = function _none(points) {
  return points || [];
};
var _seq = function _seq(ps, i) {
  var len = ps.length;
  return (0, _arraySort.newSeq)(ps, i % len);
};
var _refraction = function _refraction(refraction, i, len) {
  if (typeof refraction === 'function') {
    var ra = refraction(0, i, len).a;
    return ra;
  }
  return refraction;
};
var _vertexOneline = function _vertexOneline(points, _ref, mirrorPoints) {
  var scale = _ref.scale,
    refraction = _ref.refraction,
    _ref$departure = _ref.departure,
    departure = _ref$departure === void 0 ? 0 : _ref$departure,
    onelineOffset = _ref.onelineOffset;
  var result = [];
  var len = points.length;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  points.forEach(function (t, i) {
    var mp = _mirrorPoint(t, i, mirrorPoints);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, mp, scale, ra);
    result.push(t);
    result.push.apply(result, _toConsumableArray(_seq(ps, i + onelineOffset)));
  });
  return result;
};
var _vertexMirror = function _vertexMirror(points, _ref2, mirrorPoints) {
  var scale = _ref2.scale,
    refraction = _ref2.refraction,
    _ref2$departure = _ref2.departure,
    departure = _ref2$departure === void 0 ? 0 : _ref2$departure;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var len = points.length;
  var ps = points.map(function (t, i) {
    var mp = _mirrorPoint(t, i, mirrorPoints);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, mp, scale, ra);
    return ps;
  });
  return [points].concat(_toConsumableArray(ps));
};
var _edgeOneline = function _edgeOneline(points, _ref3, mirrorPoints) {
  var scale = _ref3.scale,
    refraction = _ref3.refraction,
    onelineOffset = _ref3.onelineOffset,
    _ref3$departure = _ref3.departure,
    departure = _ref3$departure === void 0 ? 0 : _ref3$departure;
  var result = [];
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var ms = (0, _math._mid)(points);
  var mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
  var len = points.length;
  ms.forEach(function (t, i) {
    var mp = _mirrorPoint(t, i, mms);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, mp, scale, ra);
    result.push(points[i]);
    result.push.apply(result, _toConsumableArray(_seq(ps, i + onelineOffset)));
  });
  return result;
};
var _edgeMirror = function _edgeMirror(points, _ref4, mirrorPoints) {
  var scale = _ref4.scale,
    refraction = _ref4.refraction,
    _ref4$departure = _ref4.departure,
    departure = _ref4$departure === void 0 ? 0 : _ref4$departure;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var ms = (0, _math._mid)(points);
  var mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
  var len = points.length;
  var ps = ms.map(function (t, i) {
    var mp = _mirrorPoint(t, i, mms);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, mp, scale, ra);
    return ps;
  });
  return [points].concat(_toConsumableArray(ps));
};
var _edgeMidOneline = function _edgeMidOneline(points, _ref5, mirrorPoints) {
  var scale = _ref5.scale,
    refraction = _ref5.refraction,
    onelineOffset = _ref5.onelineOffset,
    _ref5$departure = _ref5.departure,
    departure = _ref5$departure === void 0 ? 0 : _ref5$departure;
  var result = [];
  var len = points.length;
  var ms = (0, _math._mid)(points);
  var mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  ms.forEach(function (t, i) {
    var mp = _mirrorPoint(t, i, mms);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(ms, mp, scale, ra);
    result.push(points[i]);
    result.push.apply(result, _toConsumableArray(_seq(ps, i + onelineOffset)));
  });
  return result;
};
var _edgeMidMirror = function _edgeMidMirror(points, _ref6, mirrorPoints) {
  var scale = _ref6.scale,
    refraction = _ref6.refraction,
    _ref6$departure = _ref6.departure,
    departure = _ref6$departure === void 0 ? 0 : _ref6$departure;
  var len = points.length;
  var ms = (0, _math._mid)(points);
  var mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var ps = ms.map(function (t, i) {
    var mp = _mirrorPoint(t, i, mms);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(ms, mp, scale, ra);
    return ps;
  });
  return [points].concat(_toConsumableArray(ps));
};
var _radiusMidOneline = function _radiusMidOneline(points, _ref7, mirrorPoints) {
  var scale = _ref7.scale,
    refraction = _ref7.refraction,
    onelineOffset = _ref7.onelineOffset,
    _ref7$departure = _ref7.departure,
    departure = _ref7$departure === void 0 ? 0 : _ref7$departure;
  var result = [];
  var len = points.length;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  points.forEach(function (t, i) {
    var mp = _mirrorPoint(t, i, mirrorPoints);
    mp = (0, _math._moveDis)(mp, o, departure);
    var rm = (0, _math._mid)(mp, o);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, rm, scale, ra);
    result.push(t);
    result.push.apply(result, _toConsumableArray(_seq(ps, i + onelineOffset)));
  });
  return result;
};
var _radiusMidMirror = function _radiusMidMirror(points, _ref8, mirrorPoints) {
  var scale = _ref8.scale,
    refraction = _ref8.refraction,
    _ref8$departure = _ref8.departure,
    departure = _ref8$departure === void 0 ? 0 : _ref8$departure;
  var len = points.length;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var ps = points.map(function (t, i) {
    var mp = _mirrorPoint(t, i, mirrorPoints);
    mp = (0, _math._moveDis)(mp, o, departure);
    var rm = (0, _math._mid)(mp, o);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, rm, scale, ra);
    return ps;
  });
  return [points].concat(_toConsumableArray(ps));
};
var _leftVertexMirror = function _leftVertexMirror(points, _ref9, mirrorPoints) {
  var scale = _ref9.scale,
    refraction = _ref9.refraction,
    _ref9$departure = _ref9.departure,
    departure = _ref9$departure === void 0 ? 0 : _ref9$departure;
  var p = (0, _shapeRect.getLeftPoint)(points);
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var len = points.length;
  p = (0, _math._moveDis)(p, o, departure);
  // let mp = _mirrorPoint(t, i, mirrorPoints)
  var ra = _refraction(refraction, 1, len);
  var ps = (0, _mirror2._mirror)(points, p, scale, ra);
  return [points, ps];
};
var _topVertexMirror = function _topVertexMirror(points, _ref10, mirrorPoints) {
  var scale = _ref10.scale,
    refraction = _ref10.refraction,
    _ref10$departure = _ref10.departure,
    departure = _ref10$departure === void 0 ? 0 : _ref10$departure;
  var p = (0, _shapeRect.getTopPoint)(points);
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  p = (0, _math._moveDis)(p, o, departure);
  var len = points.length;
  var ra = _refraction(refraction, 1, len);
  var ps = (0, _mirror2._mirror)(points, p, scale, ra);
  return [points, ps];
};
var _rightVertexMirror = function _rightVertexMirror(points, _ref11, mirrorPoints) {
  var scale = _ref11.scale,
    refraction = _ref11.refraction,
    _ref11$departure = _ref11.departure,
    departure = _ref11$departure === void 0 ? 0 : _ref11$departure;
  var p = (0, _shapeRect.getRightPoint)(points);
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  p = (0, _math._moveDis)(p, o, departure);
  var len = points.length;
  var ra = _refraction(refraction, 1, len);
  var ps = (0, _mirror2._mirror)(points, p, scale, ra);
  return [points, ps];
};
var _bottomVertexMirror = function _bottomVertexMirror(points, _ref12, mirrorPoints) {
  var scale = _ref12.scale,
    refraction = _ref12.refraction,
    _ref12$departure = _ref12.departure,
    departure = _ref12$departure === void 0 ? 0 : _ref12$departure;
  var p = (0, _shapeRect.getBottomPoint)(points);
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  p = (0, _math._moveDis)(p, o, departure);
  var len = points.length;
  var ra = _refraction(refraction, 1, len);
  var ps = (0, _mirror2._mirror)(points, p, scale, ra);
  return [points, ps];
};
var _rectVertexMirror = function _rectVertexMirror(points, _ref13, mirrorPoints) {
  var scale = _ref13.scale,
    refraction = _ref13.refraction,
    _ref13$departure = _ref13.departure,
    departure = _ref13$departure === void 0 ? 0 : _ref13$departure;
  var len = points.length;
  var o = mirrorPoints ? (0, _math._o)(mirrorPoints) : (0, _math._o)(points);
  var _getBoundingClientRec = (0, _shapeRect.getBoundingClientRectPoints)(points),
    top = _getBoundingClientRec.top,
    right = _getBoundingClientRec.right,
    bottom = _getBoundingClientRec.bottom,
    left = _getBoundingClientRec.left;
  var ps = [top, right, bottom, left].map(function (t, i) {
    var mp = _mirrorPoint(t, i, mirrorPoints);
    mp = (0, _math._moveDis)(mp, o, departure);
    var ra = _refraction(refraction, i, len);
    var ps = (0, _mirror2._mirror)(points, mp, scale, ra);
    return ps;
  });
  return [points].concat(_toConsumableArray(ps));
};
var onelineMapFn = {
  none: _none,
  vertex: _vertexOneline,
  edge: _edgeOneline,
  edgeMid: _edgeMidOneline,
  radiusMid: _radiusMidOneline,
  leftVertex: _leftVertexMirror,
  topVertex: _topVertexMirror,
  rightVertex: _rightVertexMirror,
  bottomVertex: _bottomVertexMirror,
  rectVertex: _rectVertexMirror
};
var mirrorMapFn = {
  none: _none,
  vertex: _vertexMirror,
  edge: _edgeMirror,
  edgeMid: _edgeMidMirror,
  radiusMid: _radiusMidMirror,
  leftVertex: _leftVertexMirror,
  topVertex: _topVertexMirror,
  rightVertex: _rightVertexMirror,
  bottomVertex: _bottomVertexMirror,
  rectVertex: _rectVertexMirror
};
var mirrorTypes = Object.keys(mirrorMapFn);
// 镜像迭代 一笔画
exports.mirrorTypes = mirrorTypes;
var _onelinePoints = function _onelinePoints(type, points, options, mirrorPoints) {
  var fn = onelineMapFn[type] || _none;
  return fn(points, options, mirrorPoints);
};
// 镜像迭代
exports._onelinePoints = _onelinePoints;
var _mirrorIterPoints = function _mirrorIterPoints(type, points, options, mirrorPoints) {
  var fn = mirrorMapFn[type] || _none;
  return fn(points, options, mirrorPoints);
};
// 镜像点
exports._mirrorIterPoints = _mirrorIterPoints;
var _mirrorPoints = function _mirrorPoints(points, mirror, mirrorPoints) {
  // if (isMatrix(points)) {
  //     let mirrorPoints = _borderPoints(points, mirror)
  //     return points.map(t => {
  //         return _mirrorPoints(t, mirror, mirrorPoints)
  //     }) as T
  // }
  var type,
    scale,
    oneline = false,
    onelineOffset = 1,
    refraction = 0,
    departure = 0;
  if ((0, _utils.isObject)(mirror)) {
    type = mirror.type;
    scale = mirror.scale || 1;
    oneline = mirror.oneline;
    onelineOffset = mirror.onelineOffset;
    refraction = _angle.angleMapFn[mirror.refraction] || mirror.refraction;
    departure = mirror.departure;
  } else {
    type = mirror;
  }
  var fn = oneline ? _onelinePoints : _mirrorIterPoints;
  return fn(type, points, {
    scale: scale,
    onelineOffset: onelineOffset,
    refraction: refraction,
    departure: departure
  }, mirrorPoints);
};
exports._mirrorPoints = _mirrorPoints;
var mirrorRefractions = Object.keys(_angle.angleMapFn);
exports.mirrorRefractions = mirrorRefractions;