"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._traversalPolar = exports._traversal = exports._multiplePolar = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.map.js");
var _array = require("@/math/array");
var _math = require("@/math");
// 遍历函数

// 连续
// Continuous signal
// serial
var _traversal = function _traversal(_ref) {
  var ctx = _ref.ctx,
    points = _ref.points,
    _ref$n = _ref.n,
    n = _ref$n === void 0 ? 2 : _ref$n,
    loop = _ref.loop,
    iter = _ref.iter,
    init = _ref.init,
    _ref$discrete = _ref.discrete,
    discrete = _ref$discrete === void 0 ? false : _ref$discrete;
  var len = points.length;
  var result = [];
  var _iter = function _iter(ps, i) {
    result[result.length] = iter({
      ctx: ctx,
      points: ps,
      index: i
    });
  };
  var _init = function _init(ps) {
    if (init) {
      result[result.length] = init({
        ctx: ctx,
        point: ps ? ps[0] : points[0]
      });
    }
  };
  if (discrete) {
    // 离散
    var matrix = (0, _array.toMatrix)(points, n);
    matrix.forEach(function (ps, i) {
      if (ps.length === n) {
        _init(ps);
        _iter(ps, i);
      }
    });
  } else {
    if (init) {
      _init();
    }
    // 连续
    if (loop) {
      for (var i = 0; i < len; i += n - 1) {
        var ps = [];
        for (var j = 0; j < n; j++) {
          ps.push(points[(i + j) % len]);
        }
        _iter(ps, i);
      }
    } else {
      for (var _i = 0; _i < len - n + 1; _i += n - 1) {
        var _ps = [];
        for (var _j = 0; _j < n; _j++) {
          _ps.push(points[_i + _j]);
        }
        _iter(_ps, _i);
      }
    }
  }
  return result;
};
// 极坐标遍历
exports._traversal = _traversal;
var _traversalPolar = function _traversalPolar(_ref2) {
  var n = _ref2.n,
    o = _ref2.o,
    r = _ref2.r,
    _ref2$a = _ref2.a,
    a = _ref2$a === void 0 ? 0 : _ref2$a,
    _x = _ref2._x,
    _y = _ref2._y,
    _ref2$sweepFlag = _ref2.sweepFlag,
    sweepFlag = _ref2$sweepFlag === void 0 ? true : _ref2$sweepFlag;
  // 最大角度
  var maxt = 360; //2 * Math.PI;
  // 根据增长弧度得循环次数
  // const maxi = Math.ceil(maxt / increasedAgnle);
  var points = [];
  // 每次增长多少弧度
  var vt = (sweepFlag ? 1 : -1) * (0, _math._rad)(maxt / n);
  var _r = function _r(i) {
    return typeof r === 'function' ? r(i) : r;
  };
  var x = 0;
  var y = 0;
  var t = a ? (0, _math._rad)(a) : 0;
  for (var i = 0; i < n; i++) {
    x = _x(t);
    y = _y(t);
    t += vt;
    points.push([x * _r(i) + o[0], y * _r(i) + o[1]].map(function (t) {
      return (0, _math._k)(t);
    }));
  }
  return points;
};
// 多层遍历极坐标
exports._traversalPolar = _traversalPolar;
var _multiplePolar = function _multiplePolar(options) {
  var n = options.n,
    o = options.o,
    r = options.r,
    a = options.a,
    _x = options._x,
    _y = options._y,
    sweepFlag = options.sweepFlag,
    _options$m = options.m,
    m = _options$m === void 0 ? 1 : _options$m;
  var _r = function _r(i) {
    return typeof r === 'function' ? r(i) : r;
  };
  var matrix = [];
  for (var i = 0; i < m; i++) {
    matrix[matrix.length] = _traversalPolar({
      n: n,
      o: o,
      r: _r(i),
      a: a,
      _x: _x,
      _y: _y,
      sweepFlag: sweepFlag
    });
  }
  return matrix;
};
exports._multiplePolar = _multiplePolar;