"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lsystem = void 0;
var _lsystem = require("@/algorithm/lsystem");
var _helper = require("../helper");
var lsystem = function lsystem(ctx, options, props) {
  var matrix = (0, _lsystem.lSystemPoints)(options);
  (0, _helper.renderMatrix)({
    ctx: ctx,
    matrix: matrix,
    options: options,
    props: props
  });
};
exports.lsystem = lsystem;