"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._vertex = exports._texts = exports._text = exports._radius = exports._props = exports._links = exports._incircle = exports._excircle = exports._edgeExtension = exports._edge = exports._circles = exports._circle = exports._centre = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.fill.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.slice.js");
var _arrayMix = require("@/math/arrayMix");
var _math = require("@/math");
var _path2 = require("./path");
var _link = require("@/algorithm/link");
var _curve = require("./curve");
var _common = require("@/common");
var _pathMode2 = require("./pathMode");
var _labels = require("@/common/labels");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var propsMap = {
  stroke: 'strokeStyle',
  fill: 'fillStyle',
  strokeWidth: 'lineWidth',
  fillAlpha: 'globalAlpha',
  fillOpacity: 'globalAlpha',
  opacity: 'globalAlpha',
  alpha: 'globalAlpha'
};
var valMap = {
  none: 'transparent'
};
// 虚线
var _dashLine = function _dashLine(ctx, key, value) {
  if ('strokeDasharray' === key && value > 0) {
    ctx.setLineDash([value, value]);
    return true;
  }
  return false;
};
// 属性
var _props = function _props(ctx) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  Object.keys(props).forEach(function (t) {
    var value = props[t];
    if (!_dashLine(ctx, t, value)) {
      var prop = propsMap[t] || t;
      ctx[prop] = valMap[value] || value;
    }
  });
};
// 文本
exports._props = _props;
var _text = function _text(ctx, text, pos, props) {
  ctx.save();
  var _props$fontSize = props.fontSize,
    fontSize = _props$fontSize === void 0 ? 12 : _props$fontSize,
    _props$stroke = props.stroke,
    stroke = _props$stroke === void 0 ? 'red' : _props$stroke;
  ctx.font = "".concat(fontSize, "px \u5FAE\u8F6F\u96C5\u9ED1");
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = stroke;
  ctx.fillText(String(text), pos[0], pos[1]);
  ctx.restore();
};
// 多文本
exports._text = _text;
var _texts = function _texts(ctx, points, labels) {
  var type = labels.type;
  var o = (0, _math._o)(points);
  points.forEach(function (t, i) {
    var pos = (0, _math._moveDis)(t, o, 10);
    var text = (0, _labels.genLabel)(type, i);
    _text(ctx, text, pos, labels);
  });
};
// 圆
exports._texts = _texts;
var _circle = function _circle(ctx, o, r, props) {
  ctx.save();
  var _o2 = _slicedToArray(o, 2),
    x = _o2[0],
    y = _o2[1];
  var stroke = props.stroke,
    fill = props.fill;
  ctx.beginPath();
  _props(ctx, props);
  ctx.arc(x, y, r, 0, 2 * Math.PI);
  if (stroke && stroke !== 'none') {
    ctx.stroke();
  }
  if (fill && fill !== 'none') {
    ctx.fill();
  }
  ctx.closePath();
  ctx.restore();
};
// 多个圆
exports._circle = _circle;
var _circles = function _circles(ctx, points, props) {
  var _props$r = props.r,
    r = _props$r === void 0 ? 5 : _props$r;
  points.forEach(function (t, i) {
    _circle(ctx, t, r, Object.assign({
      stroke: 'red'
    }, props));
  });
};
// 顶点
exports._circles = _circles;
var _vertex = function _vertex(ctx, points, props) {
  var _props$r2 = props.r,
    r = _props$r2 === void 0 ? 5 : _props$r2;
  var one = props.one;
  if (one) {
    _circle(ctx, points[0], r, Object.assign({
      stroke: 'red'
    }, props));
  } else {
    _circles(ctx, points, Object.assign({
      r: r
    }, props));
  }
};
// 中心点
exports._vertex = _vertex;
var _centre = function _centre(ctx, o, props) {
  var _props$r3 = props.r,
    r = _props$r3 === void 0 ? 5 : _props$r3;
  _circle(ctx, o, r, Object.assign({
    fill: 'none',
    stroke: 'red'
  }, props));
};
// 半径
exports._centre = _centre;
var _radius = function _radius(ctx, _ref, props) {
  var points = _ref.points;
  var one = props.one;
  var o = (0, _math._o)(points);
  if (one) {
    (0, _path2._path)(ctx, {
      points: [o, points[0]],
      closed: false
    }, props);
  } else {
    var rps = (0, _arrayMix.rayMix)(o, points);
    (0, _path2._path)(ctx, {
      points: rps,
      closed: false
    }, props);
  }
};
// 内切圆
exports._radius = _radius;
var _incircle = function _incircle(ctx, _ref2, props) {
  var o = _ref2.o,
    points = _ref2.points;
  var r = (0, _math._dis)(o, _math._mid.apply(null, points.slice(0, 2)));
  _circle(ctx, o, r, Object.assign({
    fill: 'none',
    stroke: 'red'
  }, props));
};
// 外切圆
exports._incircle = _incircle;
var _excircle = function _excircle(ctx, _ref3, props) {
  var o = _ref3.o,
    r = _ref3.r;
  _circle(ctx, o, r, Object.assign({
    fill: 'none',
    stroke: 'red'
  }, props));
};
// 边
exports._excircle = _excircle;
var _edge = function _edge(ctx, options, props) {
  var pathMode = options.pathMode,
    points = options.points,
    curve = options.curve,
    closed = options.closed;
  ctx.save();
  ctx.beginPath();
  _props(ctx, props);
  (0, _pathMode2._pathMode)({
    ctx: ctx,
    pathMode: pathMode,
    points: points,
    curve: curve,
    closed: closed
  }, props);
  ctx.restore();
};
// 链接线
exports._edge = _edge;
var _links = function _links(ctx, points, props) {
  var lps = (0, _link.linkPoints)(points);
  (0, _path2._path)(ctx, {
    points: lps,
    closed: false
  }, props);
  if (props.crossPoints) {
    var lcps = (0, _link.linkCrossPoints)(points);
    _circles(ctx, lcps, Object.assign({
      fill: 'red'
    }, props));
  }
};
// const _dashLines = (ctx,points, props) => {
//     return _path(_lines(points), props)
// }
// 边延长线
exports._links = _links;
var _edgeExtension = function _edgeExtension(ctx, _ref4, props) {
  var points = _ref4.points,
    edgeExtension = _ref4.edgeExtension;
  var crossPoints = edgeExtension.crossPoints,
    labels = edgeExtension.labels;
  var ps = (0, _link.edgeExtensionPoints)(points, edgeExtension.iterNum);
  ctx.save();
  ctx.beginPath();
  _props(ctx, props);
  (0, _curve._polyline)(ctx, {
    points: ps,
    step: 2
  });
  ctx.stroke();
  if (crossPoints || labels) {
    var cps = (0, _link.edgeExtensionCrossPoints)(points);
    // 交点
    if (crossPoints) {
      _circles(ctx, cps, Object.assign({
        stroke: 'gray',
        fill: 'white'
      }, (0, _common._merge)(props, edgeExtension)));
    }
    // 标注
    if (labels) {
      _texts(ctx, cps, {
        stroke: 'blue'
      });
    }
  }
  ctx.restore();
};
exports._edgeExtension = _edgeExtension;