"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isometricalScale = isometricalScale;
var _math = require("../math");
/**
 *
 * 多边形 等距缩放
折线平行线的计算方法

即： Qi ＝ Pi ＋ (v1 + v2)
    Qi = Pi + L / sinθ * ( Normalize(v2) + Normalize(v1))
    Sin θ = |v1 × v2 | /|v1|/|v2|
、获取多边形顶点数组PList;
⑵、计算DPList[Vi+1-Vi];
⑶、单位化NormalizeDPList,得到NDP[DPi];（用同一个数组存储）
⑷、Sinα = Dp(i+1) X DP(i);
⑸、Qi = Pi + d/sinα (NDPi+1-NDPi)
⑹、这样一次性可以把所有顶点计算完。

 */

// 可用来计算描边方式,内描边 外描边，剧中描边
function isometricalScale(points, offset) {
  var path = [];
  var N = points.length - 1;
  var max = N;
  var mi, mi1, li, li1, ri, ri1, si, si1, Xi1, Yi1;
  var p0, p1, p2;
  var isClosed = (0, _math.equals)(points[0], points[N]);
  if (!isClosed) {
    p0 = points[0];
    p1 = points[1];
    p2 = [p0[0] + (p1[1] - p0[1]) / (0, _math._dis)(p0, p1) * offset, p0[1] - (p1[0] - p0[0]) / (0, _math._dis)(p0, p1) * offset];
    path.push(p2);
    points.push(points[N]);
    N++;
    max--;
  }
  for (var i = 0; i < max; i++) {
    p0 = points[i];
    p1 = points[(i + 1) % N];
    p2 = points[(i + 2) % N];
    mi = (p1[1] - p0[1]) / (p1[0] - p0[0]);
    mi1 = (p2[1] - p1[1]) / (p2[0] - p1[0]);
    // Prevent alignements
    if (Math.abs(mi - mi1) > 1e-10) {
      li = (0, _math._dis)(p0, p1);
      li1 = (0, _math._dis)(p1, p2);
      ri = p0[0] + offset * (p1[1] - p0[1]) / li;
      ri1 = p1[0] + offset * (p2[1] - p1[1]) / li1;
      si = p0[1] - offset * (p1[0] - p0[0]) / li;
      si1 = p1[1] - offset * (p2[0] - p1[0]) / li1;
      Xi1 = (mi1 * ri1 - mi * ri + si - si1) / (mi1 - mi);
      Yi1 = (mi * mi1 * (ri1 - ri) + mi1 * si - mi * si1) / (mi1 - mi);
      // Correction for vertical lines
      if (p1[0] - p0[0] == 0) {
        Xi1 = p1[0] + offset * (p1[1] - p0[1]) / Math.abs(p1[1] - p0[1]);
        Yi1 = mi1 * Xi1 - mi1 * ri1 + si1;
      }
      if (p2[0] - p1[0] == 0) {
        Xi1 = p2[0] + offset * (p2[1] - p1[1]) / Math.abs(p2[1] - p1[1]);
        Yi1 = mi * Xi1 - mi * ri + si;
      }
      path.push([Xi1, Yi1]);
    }
  }
  if (isClosed) {
    path.push(path[0]);
  } else {
    points.pop();
    p0 = points[points.length - 1];
    p1 = points[points.length - 2];
    p2 = [p0[0] - (p1[1] - p0[1]) / (0, _math._dis)(p0, p1) * offset, p0[1] + (p1[0] - p0[0]) / (0, _math._dis)(p0, p1) * offset];
    path.push(p2);
  }
  return path;
}