"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._fibonacci = void 0;
var _fibonacci2 = require("@/algorithm/fibonacci");
var _helper = require("../helper");
var _fibonacci = function _fibonacci(options, props) {
  var matrix = (0, _fibonacci2.fibonacciPoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._fibonacci = _fibonacci;