"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toProp = exports.dom = exports.createElement = exports._toggleStyle = exports._toggleClassName = exports._textNode = exports._style = exports._removeEvents = exports._removeClassName = exports._remove = exports._queryAll = exports._query = exports._img = exports._events = exports._empty = exports._div = exports._content = exports._closest = exports._children = exports._c = exports._attr = exports._append = exports._addClassName = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _utils = require("../../utils");
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
// dom操作
// 事件
var _events = function _events(el, events) {
  if (events) {
    switch (_typeof(events)) {
      case 'function':
        el.addEventListener('click', events);
        break;
      case 'object':
        Object.keys(events).forEach(function (key) {
          // let fn = (e) => {
          //   events[key](e, {
          //     model: e.detail //自定义
          //   })
          // }
          // let fn = events[key]
          el.addEventListener(key, events[key]);
        });
        break;
    }
  }
  return el;
};
// 清除事件
exports._events = _events;
var _removeEvents = function _removeEvents(el, events) {
  Object.keys(events).forEach(function (key) {
    el.removeEventListener(key, events[key]);
  });
};
// 样式
exports._removeEvents = _removeEvents;
var _style = function _style(el, options) {
  if (options) {
    var keys = Object.keys(options);
    el.setAttribute('style', keys.map(function (t) {
      var val = options[t];
      if (typeof val === 'number') {
        return "".concat(t, ":").concat(val, "px");
      }
      return "".concat(t, ":").concat(val);
    }).join(';'));
  }
};
// 属性
exports._style = _style;
var _attr = function _attr(el, key, val) {
  if (!el) {
    return;
  } else if (typeof key === 'string') {
    if (val === undefined) {
      return el.getAttribute(key);
    } else if (val != null) {
      el.setAttribute(key, val);
    }
  } else if ((0, _utils.isObject)(key)) {
    Object.keys(key).forEach(function (t) {
      var v = key[t];
      _attr(el, t, v);
    });
  }
};
exports._attr = _attr;
var _children = function _children(el, children) {
  if (Array.isArray(children)) {
    children.forEach(function (t) {
      return _children(el, t);
    });
  } else {
    children && el.appendChild(children);
  }
};
exports._children = _children;
var createElement = function createElement(tag, options, events, children) {
  var el = document.createElement(tag);
  var addContent = function addContent(val) {
    if (options && options.isHtml) {
      el.innerHTML += val;
    } else {
      el.textContent += val;
    }
  };
  // 子节点
  var _children = function _children(val) {
    if (!val) return;
    if (Array.isArray(val)) {
      val.forEach(function (t) {
        return _children(t);
      });
      return;
    }
    if (val instanceof SVGElement) {
      el.appendChild(val);
    } else if (val instanceof HTMLElement) {
      el.appendChild(val);
    } else if (val instanceof Text) {
      el.appendChild(val);
    } else {
      switch (_typeof(val)) {
        case 'string':
          addContent(val);
          break;
        case 'number':
          addContent(String(val));
          break;
        case 'object':
          addContent(JSON.stringify(val));
          break;
        default:
          el.appendChild(val);
      }
    }
  };
  // 属性 样式
  var _props = function _props(key, val) {
    switch (key) {
      case 'children':
        _children(val);
        break;
      case 'text':
      case 'label':
        addContent(val);
        break;
      case 'width':
        _attr(el, key, val);
        break;
      case 'style':
        _style(el, val);
        break;
      case 'disabled':
        if (val) {
          _attr(el, key, val);
        }
        break;
      default:
        {
          var type = (0, _utils._type)(val);
          if (!['array', 'object'].includes(type)) {
            _attr(el, key, val);
          }
        }
    }
  };
  // 属性
  if (options) {
    Object.keys(options).forEach(function (key) {
      var val = options[key];
      _props(key, val);
    });
  }
  // 事件
  if (events) {
    _events(el, events);
  }
  //子节点
  if (children) {
    _children(children);
  }
  return el;
};
exports.createElement = createElement;
var _c = function _c(tag, options, events, children) {
  return createElement(tag, options, events, children);
};
// css转属性
// .a.b   .a[b=c] #a
exports._c = _c;
var toProp = function toProp(selector) {
  var first = selector.slice(0, 1);
  var prop = selector.slice(1);
  if ('#' === first) {
    return {
      id: prop
    };
  } else if ('.' === first) {
    var reg = /(.*?)\[(.*?)=(.*?)\]/;
    if (reg.test(prop)) {
      var arr = prop.match(reg);
      return _defineProperty({
        class: arr[1]
      }, arr[2], arr[3]);
    }
    return {
      class: prop.split('.').join(' ')
    };
  }
};
// 插入节点
exports.toProp = toProp;
var _append = function _append(el, children) {
  var $el;
  if (typeof el === 'string') {
    var target = el;
    $el = _query(el);
    if (!$el) {
      $el = dom['div']('', Object.assign({}, toProp(target)));
      document.body.appendChild($el);
    }
  } else {
    $el = el;
  }
  if (typeof children === 'string') {
    $el.appendChild(_textNode(children));
  } else if (typeof children === 'number') {
    $el.appendChild(_textNode(String(children)));
  } else if (Array.isArray(children)) {
    children.forEach(function (t) {
      return _append($el, t);
    });
  } else {
    children && $el.appendChild(children);
  }
  return $el;
};
exports._append = _append;
var _content = function _content(el, children) {
  _empty(el);
  _append(el, children);
};
exports._content = _content;
var _div = function _div(children, options, events) {
  return _c('div', options, events, children);
};
exports._div = _div;
var _textNode = function _textNode(text) {
  return document.createTextNode(text);
};
// 移除
exports._textNode = _textNode;
var _remove = function _remove(el, index) {
  var children = el.childNodes;
  if (children && children.length) {
    switch (index) {
      case 'last':
        el.removeChild(children[children.length - 1]);
        break;
      case 'frist':
        el.removeChild(children[0]);
        break;
      default:
        if ((0, _utils._type)(index) === 'number') {
          el.removeChild(children[index]);
        }
    }
  }
  return el;
};
// 查找
exports._remove = _remove;
var _query = function _query(condition, el) {
  if (typeof condition === 'string') {
    if (el) {
      return el.querySelector(condition);
    } else {
      return document.querySelector(condition);
    }
  } else if (condition) {
    return condition;
  }
  return undefined;
};
exports._query = _query;
var _queryAll = function _queryAll(condition, el) {
  if (condition) {
    if (el) {
      return Array.from(el.querySelectorAll(condition));
    } else {
      return Array.from(document.querySelectorAll(condition));
    }
  }
  return [];
};
// 清空子节点
exports._queryAll = _queryAll;
var _empty = function _empty(el) {
  while (el.hasChildNodes()) {
    //当el下还存在子节点时 循环继续
    el.removeChild(el.firstChild);
  }
  return el;
};
exports._empty = _empty;
var _closest = function _closest(el, cls) {
  if (!el) return undefined;
  if (/\.\S*?/.test(cls)) {
    if (el.classList && el.classList.contains(cls.slice(1))) {
      return el;
    } else if (el.parentNode) {
      return _closest(el.parentNode, cls);
    }
  } else if ((0, _utils._type)(cls) === 'string') {
    if (el.nodeName && el.nodeName.toLowerCase() === cls.toLowerCase()) {
      return el;
    } else if (el.parentNode) {
      return _closest(el.parentNode, cls);
    }
  }
  return undefined;
};
exports._closest = _closest;
var _toggleClassName = function _toggleClassName(el, cls, cls2, fn, fn2) {
  if (!el) return;
  if (el.classList.contains(cls)) {
    fn2 && fn2();
    el.classList.remove(cls);
    if (cls2) {
      el.classList.add(cls2);
    }
  } else {
    fn && fn();
    el.classList.add(cls);
    if (cls2) {
      el.classList.remove(cls2);
    }
  }
};
exports._toggleClassName = _toggleClassName;
var _removeClassName = function _removeClassName(el, cls) {
  if (!el) return;
  if (Array.isArray(el)) {
    el.forEach(function (t) {
      return _removeClassName(t, cls);
    });
  } else {
    el.classList.remove(cls);
  }
};
exports._removeClassName = _removeClassName;
var _addClassName = function _addClassName(el, cls) {
  if (!el) return;
  if (Array.isArray(el)) {
    el.forEach(function (t) {
      return _addClassName(t, cls);
    });
  } else {
    el.classList.add(cls);
  }
};
exports._addClassName = _addClassName;
var checkStyle = function checkStyle(el, css) {
  var key = Object.keys(css)[0];
  var val = '' + css[key];
  if (['width', 'height', 'left', 'right', 'top', 'bottom'].includes(key)) {
    if (parseInt(window.getComputedStyle(el)[key]) === parseInt(val)) {
      return true;
    }
  } else {
    if (window.getComputedStyle(el)[key] === val) {
      return true;
    }
  }
  return false;
};
// let toggleStyleFlag = true
var _toggleStyle = function _toggleStyle(el, cssA, cssB) {
  if (!checkStyle(el, cssA)) {
    _style(el, cssA);
  } else {
    _style(el, cssB);
  }
};
exports._toggleStyle = _toggleStyle;
var _img = function _img(data, props, events) {
  var img = new Image();
  img.src = data;
  Object.keys(props).forEach(function (t) {
    return _attr(img, t, props[t]);
  });
  // 事件
  if (events) {
    _events(img, events);
  }
  return img;
};
exports._img = _img;
var dom = {};
exports.dom = dom;
['form', 'select', 'option', 'button', 'div', 'span', 'font', 'i', 'label', 'tr', 'thead', 'tbody', 'tfoot', 'table', 'td', 'th', 'ul', 'li'].forEach(function (t) {
  dom["".concat(t)] = function (children, options, events) {
    return _c(t, options, events, children);
  };
});
dom['input'] = function (children, options, events) {
  return _c('input', options, events, children);
};
dom['html'] = function (str, options, events) {
  return dom['div'](str, Object.assign(Object.assign({}, options), {
    isHtml: true
  }), events);
};
dom['svg'] = function (svg, options, events) {
  var width = options.width,
    height = options.height;
  svg = svg.replace(/width="(\d+)"/, function () {
    return "width=".concat(width);
  }).replace(/height="(\d+)"/, function () {
    return "height=".concat(height);
  });
  return dom['html'](svg, options, events);
};