"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matrixPoints = exports.layoutPoints = exports.gridCrossPoints = exports.gridCellPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.array.map.js");
var _math = require("@/math");
// 交叉点
// grid cross point
var gridCrossPoints = function gridCrossPoints(options) {
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$padding = options.padding,
    padding = _options$padding === void 0 ? 30 : _options$padding,
    _options$r = options.r,
    r = _options$r === void 0 ? 50 : _options$r;
  var psX = [];
  var result = [];
  // x轴
  var n = Math.ceil((width / 2 - padding) / r);
  Array.from({
    length: n
  }).forEach(function (t, i) {
    if (i === 0) {
      psX.push(o);
    } else {
      psX.unshift((0, _math.translateX)(o, -i * r));
      psX.push((0, _math.translateX)(o, i * r));
    }
  });
  // y轴
  var m = Math.ceil((height / 2 - padding) / r);
  Array.from({
    length: m
  }).forEach(function (t, i) {
    if (i === 0) {
      result.push(psX);
    } else {
      result.unshift(psX.map(function (t) {
        return (0, _math.translateY)(t, -i * r);
      }));
      result.push(psX.map(function (t) {
        return (0, _math.translateY)(t, i * r);
      }));
    }
  });
  return result;
};
exports.gridCrossPoints = gridCrossPoints;
var matrixPoints = function matrixPoints(_ref) {
  var _ref$o = _ref.o,
    o = _ref$o === void 0 ? [400, 300] : _ref$o,
    _ref$r = _ref.r,
    r = _ref$r === void 0 ? 50 : _ref$r,
    _ref$n = _ref.n,
    n = _ref$n === void 0 ? 2 : _ref$n,
    _ref$m = _ref.m,
    m = _ref$m === void 0 ? 2 : _ref$m;
  var psX = [];
  var psY = [];
  Array.from({
    length: n
  }).forEach(function (t, i) {
    if (i === 0) return;
    psX.unshift((0, _math.translate)(o, [-i * r + r / 2, r / 2]));
    psX.push((0, _math.translate)(o, [i * r - r / 2, r / 2]));
  });
  Array.from({
    length: m
  }).forEach(function (t, i) {
    if (i === 0) {
      psY.push(psX);
    } else {
      psY.unshift(psX.map(function (t) {
        return (0, _math.translateY)(t, -i * r);
      }));
      if (i < m - 1) {
        psY.push(psX.map(function (t) {
          return (0, _math.translateY)(t, i * r);
        }));
      }
    }
  });
  return psY;
};
// grid单元格点
exports.matrixPoints = matrixPoints;
var gridCellPoints = function gridCellPoints(options) {
  var _ref2 = options !== null && options !== void 0 ? options : {},
    _ref2$width = _ref2.width,
    width = _ref2$width === void 0 ? 800 : _ref2$width,
    _ref2$height = _ref2.height,
    height = _ref2$height === void 0 ? 600 : _ref2$height,
    _ref2$o = _ref2.o,
    o = _ref2$o === void 0 ? [400, 300] : _ref2$o,
    _ref2$padding = _ref2.padding,
    padding = _ref2$padding === void 0 ? 30 : _ref2$padding,
    _ref2$r = _ref2.r,
    r = _ref2$r === void 0 ? 50 : _ref2$r;
  // x轴
  var n = Math.ceil((width / 2 - padding) / r);
  // y轴
  var m = Math.ceil((height / 2 - padding) / r);
  return matrixPoints({
    o: o,
    r: r,
    n: n,
    m: m
  });
};
// 排版布局
exports.gridCellPoints = gridCellPoints;
var layoutPoints = function layoutPoints(total, options) {
  var _options$width2 = options.width,
    width = _options$width2 === void 0 ? 800 : _options$width2,
    _options$height2 = options.height,
    height = _options$height2 === void 0 ? 600 : _options$height2,
    _options$o2 = options.o,
    o = _options$o2 === void 0 ? [400, 300] : _options$o2,
    _options$padding2 = options.padding,
    padding = _options$padding2 === void 0 ? 30 : _options$padding2,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 50 : _options$r2;
  // '0', '2x2', '4x4', '10x8', '25'
  var nMap = {
    '2x2': 4,
    '4x4': 8,
    '10x8': 16,
    '18x12': 25
  };
  var lr = r * 5 * 4 / (nMap[total] || total);
  // // x轴
  var n = Math.ceil((width / 2 - padding) / lr);
  // y轴
  var m = Math.ceil((height / 2 - padding) / lr);
  // let n = 2, m = 2
  // switch (total) {
  //     case 4:
  //         n = 2;
  //         m = 2
  //         break;
  //     case 5:
  //         n = 5
  //         m = 1
  //     case 8:
  //         n = 3;
  //         m = 2
  //         break;
  //     case 16:
  //         n = 4;
  //         m = 4
  //         break;
  //     case 25:
  //         n = 5;
  //         m = 5
  //         break;
  // }
  var mps = matrixPoints({
    r: lr,
    n: n,
    m: m,
    o: o
  });
  return (0, _math.plainMatrix)(mps);
};
exports.layoutPoints = layoutPoints;