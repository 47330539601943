"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._btnNative = exports._btn = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.concat.js");
var _dom = require("./dom");
var _btnNative = function _btnNative(text, options, events) {
  return _dom.dom.button(text, Object.assign(Object.assign({
    class: 'button'
  }, options), {
    type: 'button'
  }), events);
};
exports._btnNative = _btnNative;
var _btn = function _btn(text, options, events) {
  var className = options.class;
  var popover = options.popover;
  return _dom.dom.div(text, {
    class: "button".concat(className ? ' ' + className : '', " ").concat(popover ? ' popover' : '')
  }, events
  // {
  //   click: (e) => {
  //     // let model = {}
  //     // fields.filter(t => t.field).forEach(t => { //['text','number','select'].includes(t.type)
  //     //   let key = t.field
  //     //   let item = _query( `[name=${key}]`,form)
  //     //   model[key] = item.value
  //     // })
  //     // let scope = {
  //     //   model,
  //     //   form,
  //     //   emit //事件
  //     // }
  //     // 弹窗
  //     // if (t.popover) {
  //     //   document.body.appendChild(_popover(t.popover, e.target))
  //     // }
  //     // let scope = this
  //     // t.click.call(scope, e, scope)
  //     // t.click.call(scope, e, scope)
  //   }
  // }
  );
};
exports._btn = _btn;