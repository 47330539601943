"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._formItem = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.assign.js");
var _utils = require("../../utils");
var _dom = require("./dom");
var _select2 = require("./select");
var _input2 = require("./input");
var _progress2 = require("./progress");
var _formItemLabel = function _formItemLabel(field, options) {
  var style = options.labelWidth ? {
    width: options.labelWidth + 'px'
  } : null;
  var itemLabel = _dom.dom.div(field.label, {
    class: 'form-item-label',
    title: field.label,
    style: style
  });
  return itemLabel;
};
var _formItemBody = function _formItemBody(field, options) {
  var type = field.type,
    value = field.value,
    disabled = field.disabled;
  var itemBodyContent;
  var onChange = function onChange(e) {
    window.postMessage({
      eventType: 'change',
      options: {
        field: field,
        // fields: options.fields,
        name: options.name
      }
    }, '*');
  };
  switch (type) {
    case 'select':
      {
        itemBodyContent = (0, _select2._select)(value, Object.assign(Object.assign({}, field), {
          class: 'form-item-' + type,
          width: options.inputWidth,
          options: field.options
        }), {
          change: onChange
        });
        break;
      }
    case 'number':
    case 'random':
      {
        itemBodyContent = (0, _input2._number)(value, Object.assign(Object.assign({}, field), {
          width: options.inputWidth,
          class: 'form-item-' + type
        }), {
          change: onChange
        });
        break;
      }
    // case 'progress':
    //     {
    //         itemBodyContent = _progress({
    //             name,
    //             value,
    //             max: 1
    //         })
    //         break;
    //     }
    case 'boolean':
    case 'checkbox':
      {
        itemBodyContent = (0, _input2._checkbox)(value, Object.assign(Object.assign({}, field), {
          class: 'form-item-' + type
        }), {
          change: onChange
        });
        break;
      }
    default:
      {
        if (disabled) {
          itemBodyContent = _dom.dom.div([value, (0, _input2._hidden)(value, Object.assign({}, field))]);
        } else {
          itemBodyContent = (0, _input2._input)(value, Object.assign(Object.assign({}, field), {
            width: options.inputWidth,
            class: 'form-item-input'
          }), {
            change: onChange
          });
        }
      }
  }
  var itemBody = _dom.dom.div(itemBodyContent, {
    class: 'form-item-body'
  });
  return itemBody;
};
var _formItem = function _formItem(field, options) {
  // 改下属性名
  field = (0, _utils.changeProperty)(field, 'field', 'name');
  var _field = field,
    type = _field.type,
    value = _field.value,
    name = _field.name;
  var itemContent;
  var itemLabel = _formItemLabel(field, options);
  var itemBody = _formItemBody(field, options);
  itemContent = [itemLabel, itemBody];
  if (type === 'progress') {
    itemContent = (0, _progress2._progress)(Object.assign(Object.assign({}, field), {
      name: name,
      value: value,
      max: 1
    }), {
      change: function change(e) {
        window.postMessage({
          eventType: 'change',
          options: {
            field: field,
            // fields: options.fields,
            name: options.name
          }
        }, '*');
      }
    });
  }
  var item = _dom.dom.div(itemContent, {
    class: 'form-item',
    width: options.width
  });
  return item;
};
exports._formItem = _formItem;