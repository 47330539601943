"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Color = void 0;
var Color;
exports.Color = Color;
(function (Color) {
  Color["None"] = "none";
  Color["ColorCircle"] = "colorCircle";
})(Color || (exports.Color = Color = {}));