"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFillByPattern = exports._taichi = exports.FillPattern = void 0;
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
var _curve = require("../core/curve");
var _svg = require("../core/svg");
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var FillPattern;
exports.FillPattern = FillPattern;
(function (FillPattern) {
  FillPattern["taichi"] = "taichi";
})(FillPattern || (exports.FillPattern = FillPattern = {}));
var _taichi = function _taichi(points) {
  var o = (0, _math._o)(points);
  return (0, _curve._taichiToSeg)(o, [points[0], points[1]]);
};
exports._taichi = _taichi;
var fillPatterConfig = _defineProperty({}, FillPattern.taichi, _taichi);
var createFillByPattern = function createFillByPattern(pattern, points, props) {
  var fn = fillPatterConfig[pattern];
  if (fn) {
    return (0, _svg._path)(fn(points), Object.assign({
      stroke: 'red',
      fill: 'none'
    }, props));
  }
};
exports.createFillByPattern = createFillByPattern;