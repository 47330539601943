"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOpenListBox = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _store = require("@/store");
var _accordion = require("../core/accordion");
var _checkbox = require("../core/checkbox");
var _dom = require("../core/dom");
var _icon = require("../core/icon");
var _list2 = require("../core/list");
var _helper = require("../helper");
var _rotute = require("../rotute");
var _g = require("./g2");
var getOpenListBox = function getOpenListBox() {
  var openList = _store.store.getOpenList();
  // const data = getGraphicDataList()
  var list = (0, _list2._list)(openList, function (item) {
    var name = item.name;
    var checkbox = (0, _checkbox._checkboxItem)(name, {
      checked: true,
      name: name
    });
    var icon = (0, _icon._iconClose)({
      click: function click(e) {
        var el = e.target;
        var item = (0, _dom._closest)(el, '.item');
        if (item) {
          var input = (0, _dom._query)('input', item);
          var _name = input.getAttribute('name');
          var panel = (0, _helper.getPanelByName)(_name);
          panel && panel.remove();
          item.remove();
          _store.store.removePage(_name);
        }
        e.stopPropagation();
        e.preventDefault();
        // console.log(e.target)
      }
    });

    return [checkbox, icon];
  }, {
    activeName: _store.store.currentPage.name
  }, {
    click: function click(e) {
      //
      var el = e.target;
      var item = (0, _dom._closest)(el, 'li');
      var input = (0, _dom._query)('input', item);
      var name = input.getAttribute('name');
      (0, _accordion._accordionPanel)(name);
      item.parentNode.childNodes.forEach(function (t) {
        return (0, _dom._removeClassName)(t, 'active');
      });
      (0, _dom._addClassName)(item, 'active');
      var page = _store.store.getPageByName(name);
      (0, _g.rerenderInspector)(page, ['inspector', 'grammar']); //, 'graphic'
      // const page=getPageByName(name)
      // store.setPage(page)
      (0, _rotute.goPage)(name);
    }
  });
  var listTitle = (0, _dom._div)('open list', {
    class: 'title'
  });
  var openListBox = (0, _dom._div)([listTitle, list], {
    class: 'graphic-open-list'
  });
  return openListBox;
};
exports.getOpenListBox = getOpenListBox;