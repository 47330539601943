"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._menu = exports._fallmenu = void 0;
var _dom = require("../core/dom");
var _tree2 = require("../core/tree");
var _utils = require("../../utils");
var _playBar2 = require("./playBar");
var _panel = require("./panel");
var _store = require("@/store");
// let currentMenu = ""
var handleClick = function handleClick(t, target, root) {
  var panel = (0, _panel.renderPanel)(t);
  // _accordion(panel, `.panel[role=${t.name}]`, target, root)
};

var _menu = function _menu(treeData, config) {
  var target = config.target,
    _config$root = config.root,
    root = _config$root === void 0 ? document.body : _config$root;
  var $target = (0, _dom._query)(target);
  var render = function render(t) {
    return _dom.dom.div(t.title, {
      class: 'item'
    }, {
      click: function click() {
        handleClick(t, $target, root);
      }
    });
  };
  var tree = (0, _tree2._tree)(treeData, render);
  return _dom.dom.div(tree, {
    class: 'menu'
  });
};
exports._menu = _menu;
var _fallmenu = function _fallmenu(treeData, config) {
  var target = config.target,
    _config$root2 = config.root,
    root = _config$root2 === void 0 ? document.body : _config$root2;
  var $target = (0, _dom._query)(target);
  var list = (0, _utils.plainTreeMenu)(treeData);
  _store.store.setAllPages(list);
  var render = function render(t) {
    return _dom.dom.div(t.title, {
      class: 'item'
    }, {
      click: function click() {
        handleClick(t, $target, root);
      }
    });
  };
  var tree = (0, _tree2._tree)(treeData, render);
  var playBar = (0, _playBar2._playBar)(function (t) {
    handleClick(t, $target, root);
  });
  return _dom.dom.div([tree, playBar], {
    class: 'fallmenu'
  });
};
exports._fallmenu = _fallmenu;