"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lsystem = void 0;
var _lsystem = require("@/algorithm/lsystem");
var _helper = require("../helper");
var lsystem = function lsystem(options, props) {
  var matrix = (0, _lsystem.lSystemPoints)(options);
  // return colorPath(matrix, options, props)
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.lsystem = lsystem;