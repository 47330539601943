"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._gridRows = exports._gridFoot = exports._grid = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _dom = require("./dom");
var _eventBus = require("../eventBus");
var _checkbox2 = require("./checkbox");
var _utils = require("../../utils");
// 表格
var _grid = function _grid(options) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var events = arguments.length > 2 ? arguments[2] : undefined;
  var fields = options.fields,
    statis = options.statis,
    props = options.props;
  // 删除属性
  fields = fields.map(function (t) {
    t = (0, _utils.changeProperty)(t, 'field', 'name');
    (0, _utils.deleteProperty)(t, 'placeholder');
    return t;
  });
  var thead = _gridHead(fields);
  var tbody = _gridBody(fields, data);
  var children = [thead, tbody];
  if (statis) {
    var tfoot = _gridFoot(fields, data, statis);
    children.push(tfoot);
  }
  return _dom.dom.table(children, Object.assign({
    cellspacing: 0,
    cellpadding: 0,
    border: 0
  }, props), events);
};
// 表头
exports._grid = _grid;
var _gridHead = function _gridHead(fields) {
  var cols = fields.map(function (t) {
    var label = t.label;
    var type = t.type;
    if (type === 'selection') {
      label = (0, _checkbox2._checkbox)('', {}, {
        click: function click() {}
      });
    } else {
      label = t.label;
    }
    delete t.label;
    return _dom.dom.th(label, t);
  });
  var tr = _dom.dom.tr(cols);
  var thead = _dom.dom.thead(tr);
  return thead;
};
// 表体
var _gridBody = function _gridBody(fields) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var rows = data.map(function (row, rowIndex) {
    return _gridRows(fields, row, rowIndex);
  });
  return _dom.dom.tbody(rows);
};
// 行
var _gridRows = function _gridRows(fields, row) {
  var rowIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var cols = fields.map(function (t) {
    var type = t.type;
    var key = t.field || t.name || '';
    var value = row[key] || '';
    var text;
    switch (type) {
      case 'index':
        text = row.index || rowIndex + 1;
        break;
      case 'selection':
        text = (0, _checkbox2._checkbox)('');
        t.class = 'center';
        break;
      case 'operation':
        text = t.options.map(function (op) {
          var btn = _dom.dom.div(op.label, {
            class: op.name
          }, {
            click: function click(e) {
              var tr = (0, _dom._closest)(btn, 'tr'),
                table = (0, _dom._closest)(tr, 'table');
              op.click(e, {
                row: row,
                rowIndex: rowIndex,
                btn: btn,
                tr: tr,
                table: table,
                emit: _eventBus.emit //事件
              });
            }
          });

          return btn;
        });
        break;
      default:
        text = value;
    }
    return _dom.dom.td(text, t);
  });
  return _dom.dom.tr(cols);
};
// 表尾
exports._gridRows = _gridRows;
var _gridFoot = function _gridFoot(fields) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var statis = arguments.length > 2 ? arguments[2] : undefined;
  var statisFields = statis.fields;
  // 合计
  var getsum = function getsum(field) {
    return data.reduce(function (a, b) {
      return a + b[field];
    }, 0);
  };
  var cols = fields.map(function (t, index) {
    var label = '';
    if (index === 0) {
      label = statis.label;
    } else {
      var field = t.field || t.name || '';
      if (statisFields.includes(field)) {
        label = '' + getsum(field);
      }
    }
    return _dom.dom.td(label, t);
  });
  var tr = _dom.dom.tr(cols);
  var tfoot = _dom.dom.tfoot(tr);
  return tfoot;
};
exports._gridFoot = _gridFoot;