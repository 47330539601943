"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._canvas = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _axis2 = require("./core/axis");
var _store = require("@/store");
var _math = require("@/math");
var _factory = require("./factory");
var _common = require("@/common");
var _layout2 = require("@/common/layout");
var _motion = require("@/types/motion");
var timer;
var _canvas = function _canvas(options) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    shapes = options.shapes,
    axis = options.axis,
    o = options.o,
    layout = options.layout;
  var screen = (0, _store._screen)(width, height);
  var h = screen.height;
  var w = screen.width;
  if (!o) {
    o = screen.o;
  }
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  canvas.width = w;
  canvas.height = h;
  timer && cancelAnimationFrame(timer);
  var _main = function _main() {
    var fn = function fn(t) {
      var shape = t.shape;
      if (shape === 'lsystemPlant' && (layout == null || layout === '0')) {
        // 植物lsystem的原点下移
        o = (0, _math.translateY)(o, h / 2 - 100);
      }
      (0, _factory.factory)(shape, ctx, Object.assign({
        o: o
      }, t), Object.assign(Object.assign({
        stroke: 'blue',
        fill: 'none'
      }, t.props), props));
    };
    if ((0, _common.isVisible)(axis)) {
      (0, _axis2._axis)(ctx, axis);
    }
    (0, _layout2._layout)({
      layout: layout,
      width: w,
      height: h,
      o: o
    }, shapes, fn);
    // 旋转动画
    var rotateShapes = shapes.filter(function (t) {
      return t.motion && t.motion.type === _motion.MotionType.Rotate;
    });
    if (rotateShapes.length) {
      timer = requestAnimationFrame(function () {
        ctx.fillStyle = 'rgba(0,0,0,0.02)';
        ctx.fillRect(0, 0, w, h);
        rotateShapes.forEach(function (t) {
          var speed = t.motion.speed || 1;
          t.a += speed;
        });
        _main();
      });
    }
  };
  _main();
  return canvas;
};
exports._canvas = _canvas;