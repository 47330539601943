"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.treePoints = exports.treeModePoints = exports.treeCurveTypes = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
var _math = require("@/math");
var _pathMode = require("@/types/pathMode");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var treeCurveTypes = ['QQ', 'QL', 'V', 'LL'];
exports.treeCurveTypes = treeCurveTypes;
var treePoints = function treePoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [300, 200] : _options$o,
    _options$n = options.n,
    n = _options$n === void 0 ? 3 : _options$n,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? -90 : _options$a,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 1 : _options$depth,
    _options$attenuation = options.attenuation,
    attenuation = _options$attenuation === void 0 ? 2 / 3 : _options$attenuation,
    _options$wriggle = options.wriggle,
    wriggle = _options$wriggle === void 0 ? 10 : _options$wriggle,
    pathMode = options.pathMode,
    _options$treeCurve = options.treeCurve,
    treeCurve = _options$treeCurve === void 0 ? 'QQ' : _options$treeCurve;
  var result = new Array(depth);
  var _ra = function _ra() {
    return wriggle ? (0, _math._random)(-wriggle, wriggle) : 0;
  };
  var _iter = function _iter(o, r, a, depth) {
    var p = (0, _math._polar)(o, r, a + _ra());
    var a1 = a + 30 + _ra(),
      a2 = a - 30 + _ra();
    var r1 = r * attenuation,
      r2 = r * attenuation;
    if (!result[depth]) {
      result[depth] = [];
    }
    result[depth].push(o, p);
    if (depth > 0) {
      _iter(p, r1, a1, depth - 1);
      _iter(p, r2, a2, depth - 1);
    }
  };
  if (n == 1) {
    _iter((0, _math.translateY)(o, r), r, a, depth);
  } else {
    Array.from({
      length: n
    }).forEach(function (_, i) {
      _iter(o, r, a + i * 360 / n, depth);
    });
  }
  return result.reverse();
};
exports.treePoints = treePoints;
var treeModePoints = function treeModePoints(options) {
  var _modeMap;
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [300, 200] : _options$o2,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 3 : _options$n2,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 100 : _options$r2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? -90 : _options$a2,
    _options$depth2 = options.depth,
    depth = _options$depth2 === void 0 ? 1 : _options$depth2,
    _options$attenuation2 = options.attenuation,
    attenuation = _options$attenuation2 === void 0 ? 2 / 3 : _options$attenuation2,
    _options$wriggle2 = options.wriggle,
    wriggle = _options$wriggle2 === void 0 ? 10 : _options$wriggle2,
    pathMode = options.pathMode;
  var result = new Array(depth);
  var _ra = function _ra() {
    return wriggle ? (0, _math._random)(-wriggle, wriggle) : 0;
  };
  var modeMap = (_modeMap = {}, _defineProperty(_modeMap, _pathMode.PathMode.LINES, function (_ref) {
    var _ref2 = _slicedToArray(_ref, 4),
      o = _ref2[0],
      p = _ref2[1],
      p1 = _ref2[2],
      p2 = _ref2[3];
    return [o, p];
  }), _defineProperty(_modeMap, _pathMode.PathMode.SQUARES, function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 4),
      o = _ref4[0],
      p = _ref4[1],
      p1 = _ref4[2],
      p2 = _ref4[3];
    return [o, p, p1, p2];
  }), _defineProperty(_modeMap, _pathMode.PathMode.TRIANGLES, function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 4),
      o = _ref6[0],
      p1 = _ref6[2],
      p2 = _ref6[3];
    return [o, p1, p2];
  }), _modeMap);
  var _mode = modeMap[pathMode] || modeMap[_pathMode.PathMode.TRIANGLES];
  var _iter = function _iter(o, r, a, depth) {
    var _result$depth;
    var p = (0, _math._polar)(o, r, a + _ra());
    var a1 = a + 30 + _ra(),
      a2 = a - 30 + _ra();
    var r1 = r * attenuation,
      r2 = r * attenuation;
    var p1 = (0, _math._polar)(p, r1, a1);
    var p2 = (0, _math._polar)(p, r2, a2);
    if (!result[depth]) {
      result[depth] = [];
    }
    (_result$depth = result[depth]).push.apply(_result$depth, _toConsumableArray(_mode([o, p, p1, p2])));
    // squares
    // result[depth].push(o, p, p1, p2)
    // triangle
    // result[depth].push(o, p1, p2)
    // switch (treeCurve) {
    //     case 'QL':
    //         // 曲线1 QL
    //         result[depth].push([o, [...p, ...p1]], [p, p2])
    //         break;
    //     case 'QQ':
    //         // 曲线2 QQ
    //         result[depth].push([o, [...p, ...p1]], [o, [...p, ...p2]])
    //         break;
    //     case 'V':
    //         // 二叉树 V
    //         result[depth].push([o, p1], [o, p2])
    //         break
    //     default:
    //         // 二叉树 LL
    //         result[depth].push([o, p, p1], [p, p2])
    // }
    if (depth > 0) {
      _iter(p1, r1, a1, depth - 1);
      _iter(p2, r2, a2, depth - 1);
    }
  };
  if (n == 1) {
    _iter((0, _math.translateY)(o, r), r, a, depth);
  } else {
    Array.from({
      length: n
    }).forEach(function (_, i) {
      _iter(o, r, a + i * 360 / n, depth);
    });
  }
  return result.reverse();
};
exports.treeModePoints = treeModePoints;