"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pathModeTypes = exports.PathMode = void 0;
require("core-js/modules/es.object.values.js");
//  参数名参考webgl.type
var PathMode;
exports.PathMode = PathMode;
(function (PathMode) {
  PathMode["POINTS"] = "POINTS";
  PathMode["LINES"] = "LINES";
  PathMode["LINE_STRIP"] = "LINE_STRIP";
  PathMode["LINE_LOOP"] = "LINE_LOOP";
  PathMode["LINE_FAN"] = "LINE_FAN";
  // POLYGON= 'POLYGON',
  PathMode["TRIANGLES"] = "TRIANGLES";
  PathMode["TRIANGLE_STRIP"] = "TRIANGLE_STRIP";
  PathMode["TRIANGLE_STRIP_LOOP"] = "TRIANGLE_STRIP_LOOP";
  PathMode["TRIANGLE_FAN"] = "TRIANGLE_FAN";
  PathMode["TRIANGLE_SERIAL"] = "TRIANGLE_SERIAL";
  PathMode["TRIANGLE_SERIAL_LOOP"] = "TRIANGLE_SERIAL_LOOP";
  PathMode["SQUARES"] = "SQUARES";
  PathMode["SQUARE_STRIP"] = "SQUARE_STRIP";
  PathMode["SQUARE_STRIP_LOOP"] = "SQUARE_STRIP_LOOP";
  PathMode["SQUARE_SERIAL"] = "SQUARE_SERIAL";
  PathMode["SQUARE_SERIAL_LOOP"] = "SQUARE_SERIAL_LOOP";
  PathMode["RAY"] = "RAY";
  PathMode["RAY_FAN"] = "RAY_FAN";
  PathMode["LINK"] = "LINK";
  PathMode["CURVE_STRIP"] = "CURVE_STRIP";
  PathMode["CURVE_LOOP"] = "CURVE_LOOP";
  PathMode["BEZIER_STRIP"] = "BEZIER_STRIP";
  PathMode["BEZIER_LOOP"] = "BEZIER_LOOP";
  PathMode["DISCRETE_BEZIER"] = "DISCRETE_BEZIER";
})(PathMode || (exports.PathMode = PathMode = {}));
var pathModeTypes = Object.values(PathMode);
exports.pathModeTypes = pathModeTypes;