"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rerenderPanel = exports.renderPanel = exports.activePanel = exports._panel = void 0;
require("core-js/modules/es.function.name.js");
var _dom = require("../core/dom");
var _renderContent3 = require("../renderContent");
var _store = require("../../store");
var _accordion2 = require("../core/accordion");
var _layout2 = require("../core/layout");
var _panel = function _panel(name, content, isHtml) {
  var panel = _dom.dom.div(content, {
    name: name,
    role: name,
    class: 'panel',
    isHtml: isHtml
  });
  return panel;
};
exports._panel = _panel;
var activePanel = function activePanel(name) {
  var panel = (0, _layout2._layout)(".screen-viewer .panel"); //[name=${name}]
  // const panel = _query(`.panel[name=${name}]`, store.screenViewer)
  (0, _accordion2._accordion)(panel, ".panel[name=".concat(name, "]"), _store.store.screenViewer, _store.store.root);
  return panel;
};
// 渲染
exports.activePanel = activePanel;
var renderPanel = function renderPanel(t) {
  var active = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var _renderContent = (0, _renderContent3.renderContent)(t),
    content = _renderContent.content,
    isHtml = _renderContent.isHtml;
  var callback;
  var panel;
  if (typeof content === 'function') {
    callback = content;
    panel = _panel(t.name, '', isHtml);
  } else {
    panel = _panel(t.name, content, isHtml);
  }
  // const callback = typeof content === 'function'   ?content :()=>void
  // 存储，展示
  if (active) {
    _store.store.setPage(t);
    (0, _accordion2._accordion)(panel, ".panel[name=".concat(t.name, "]"), _store.store.screenViewer, _store.store.root);
    callback && callback(panel);
  }
  return panel;
};
// 重新渲染
exports.renderPanel = renderPanel;
var rerenderPanel = function rerenderPanel(t) {
  var _renderContent2 = (0, _renderContent3.renderContent)(t),
    content = _renderContent2.content;
  // const viewer = _query(".screen-viewer")
  var panel = (0, _dom._query)(".panel[name=\"".concat(t.name, "\"]"), _store.store.screenViewer);
  if (typeof content === 'function') {
    content(panel);
  } else {
    (0, _dom._content)(panel, content);
  }
};
exports.rerenderPanel = rerenderPanel;