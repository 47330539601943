"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._koch = void 0;
require("core-js/modules/es.array.map.js");
var _koch2 = require("@/algorithm/koch");
var _polar = require("@/algorithm/polar");
var _helper = require("../helper");
var _koch = function _koch(options, props) {
  var matrix = (0, _polar.polarPoints)(options);
  var _options$depth = options.depth,
    depth = _options$depth === void 0 ? 0 : _options$depth,
    _options$skew = options.skew,
    skew = _options$skew === void 0 ? 0 : _options$skew,
    _options$amplitude = options.amplitude,
    amplitude = _options$amplitude === void 0 ? 1 : _options$amplitude,
    _options$loop = options.loop,
    loop = _options$loop === void 0 ? true : _options$loop;
  var matrix2 = matrix.map(function (points) {
    return (0, _koch2.kochPoints)({
      points: points,
      depth: depth,
      skew: skew,
      amplitude: amplitude,
      loop: loop
    });
  });
  return (0, _helper.makeSvgElementByMatrix)(matrix2, options, props);
};
exports._koch = _koch;