"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapShapeFn = void 0;
var _triangle2 = require("./triangle");
var _square2 = require("./square");
var _cube2 = require("./cube");
var _line2 = require("./line");
// import { _ball } from './ball'
// import { _demo } from './demo';

var shapeMap = {
  default: _triangle2._triangle,
  glTriangle: _triangle2._triangle,
  glSquare: _square2._square,
  glCube: _cube2._cube,
  // glBall: _ball,
  glLine: _line2._line
  //   glDemo:_demo
};

var mapShapeFn = function mapShapeFn(t) {
  return shapeMap[t]; //|| shapeMap['default']
};
exports.mapShapeFn = mapShapeFn;