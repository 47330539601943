"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initEditor = initEditor;
var _Editor = require("./Editor");
var _command = _interopRequireDefault(require("./command"));
var _config = _interopRequireDefault(require("./config"));
var _view = require("./contextMenu/view");
var _EditorSetting = require("./setting/EditorSetting");
var _register = require("./shortcut/register");
var _tools = require("./tools");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function initEditor() {
  var editor = new _Editor.Editor();
  // ; (window as any).editor = editor // debug in devtool
  var commandManager = new _command.default(editor);
  editor.setCommandManager(commandManager);
  editor.setSetting(new _EditorSetting.EditorSetting());
  var tools = new _tools.ToolManager(editor);
  editor.setToolManager(tools);
  tools.setCurrentTool(_config.default.tool);
  tools.initToolEvent();
  (0, _register.registerShortcut)(editor);
  editor.contextMenu.on('show', _view.createContextMenu);
  editor.contextMenu.on('hide', _view.removeContentMenu);
  return editor;
}