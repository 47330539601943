"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Shape = void 0;
var Shape;
exports.Shape = Shape;
(function (Shape) {
  Shape["None"] = "none";
  Shape["Polygon"] = "polygon";
  Shape["Axis"] = "axis";
  Shape["Path"] = "path";
  Shape["Circle"] = "circle";
  Shape["Text"] = "text";
  Shape["Rect"] = "rect";
  Shape["Star"] = "star";
  Shape["Grid"] = "grid";
  Shape["Polar"] = "polar";
  Shape["Radial"] = "radial";
  Shape["Dot"] = "dot";
  Shape["Sin"] = "sin";
  Shape["Wave"] = "wave";
  Shape["Fibonacci"] = "fibonacci";
  Shape["FibonacciCircle"] = "fibonacciCircle";
  Shape["Heart"] = "heart";
  Shape["Koch"] = "koch";
  Shape["LSystem"] = "lsystem";
  Shape["LSystemPlant"] = "lsystemPlant";
  Shape["Maze"] = "maze";
  Shape["Oneline"] = "oneline";
  Shape["Peano"] = "peano";
  Shape["Triangle"] = "triangle";
  Shape["Line"] = "line";
  Shape["Ray"] = "ray";
  Shape["Curve"] = "curve";
  Shape["Rose"] = "rose";
  Shape["Sawtooth"] = "sawtooth";
  Shape["Sierpinski"] = "sierpinski";
  Shape["SierpinskiBezier"] = "sierpinskiBezier";
  Shape["SierpinskiTriangle"] = "sierpinskiTriangle";
  Shape["CircleMaze"] = "circleMaze";
  Shape["WaveMaze"] = "waveMaze";
  Shape["Spiral"] = "spiral";
  Shape["Stripe"] = "stripe";
  Shape["Chequer"] = "chequer";
  Shape["Isometric"] = "isometric";
  Shape["PolarShape"] = "polarShape";
  Shape["SpiralShape"] = "spiralShape";
  Shape["RingShape"] = "ringShape";
  Shape["RayShape"] = "rayShape";
  Shape["PolygonShape"] = "polygonShape";
  Shape["GridShape"] = "gridShape";
  Shape["IsometricShape"] = "isometricShape";
  Shape["Gougu"] = "gougu";
  Shape["Plant"] = "plant";
  Shape["Tree"] = "tree";
  Shape["TreeMode"] = "treeMode";
  Shape["TreeCircle"] = "treeCircle";
  Shape["Mitsubishi"] = "mitsubishi";
  Shape["Hexaflake"] = "hexaflake";
  Shape["GridRect"] = "gridRect";
  Shape["GridPolygon"] = "gridPolygon";
  Shape["Wanhuachi"] = "wanhuachi";
  Shape["Leaves"] = "leaves";
  Shape["TriangleLeaf"] = "triangleLeaf";
  Shape["Dragon"] = "dragon";
  Shape["SquareFractal"] = "squareFractal";
  Shape["Leaf"] = "leaf";
  Shape["Semicircle"] = "semicircle";
  Shape["Elliptical"] = "elliptical";
  Shape["Gradient"] = "gradient";
  Shape["DiagonalStripe"] = "diagonalStripe";
  Shape["Square"] = "square";
  Shape["Pentagon"] = "pentagon";
  Shape["Hexagon"] = "hexagon";
  Shape["Heptagon"] = "heptagon";
})(Shape || (exports.Shape = Shape = {}));