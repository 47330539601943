"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._heart = void 0;
var _curve = require("@/algorithm/curve");
var _helper = require("../helper");
var _heart = function _heart(options, props) {
  var curveShape = options.curveShape;
  var matrix = (0, _curve._curvePoints)(curveShape, options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._heart = _heart;