"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBoxBy2points = getBoxBy2points;
exports.getEllipseProps = exports.getCircleProps = exports.getCircleIn2Points = exports.getCentre = void 0;
exports.getSymmetryPoint = getSymmetryPoint;
exports.keepTwoDecimal = void 0;
function getBoxBy2points(x1, y1, x2, y2) {
  var w = Math.abs(x2 - x1);
  var h = Math.abs(y2 - y1);
  var x = Math.min(x2, x1);
  var y = Math.min(y2, y1);
  return {
    x: x,
    y: y,
    w: w,
    h: h
  };
}
var getCircleIn2Points = function getCircleIn2Points(p1, p2) {
  var x1 = p1.x,
    y1 = p1.y;
  var x2 = p2.x,
    y2 = p2.y;
  var w = Math.abs(x2 - x1);
  var h = Math.abs(y2 - y1);
  var x = Math.min(x2, x1);
  var y = Math.min(y2, y1);
  return {
    cx: x + w / 2,
    cy: y + h / 2,
    r: Math.min(w / 2, h / 2)
  };
};
exports.getCircleIn2Points = getCircleIn2Points;
var getCentre = function getCentre(bounds) {
  var x = bounds.x,
    y = bounds.y,
    width = bounds.width,
    height = bounds.height;
  return {
    x: x + width / 2,
    y: y + height / 2
  };
};
exports.getCentre = getCentre;
var getCircleProps = function getCircleProps(x1, y1, x2, y2) {
  var w = Math.abs(x2 - x1);
  var h = Math.abs(y2 - y1);
  var x = Math.min(x2, x1);
  var y = Math.min(y2, y1);
  return {
    cx: x + w / 2,
    cy: y + h / 2,
    r: Math.min(w / 2, h / 2)
  };
};
exports.getCircleProps = getCircleProps;
var getEllipseProps = function getEllipseProps(x1, y1, x2, y2) {
  var w = Math.abs(x2 - x1);
  var h = Math.abs(y2 - y1);
  var x = Math.min(x2, x1);
  var y = Math.min(y2, y1);
  return {
    x: x + w / 2,
    y: y + h / 2,
    rx: w / 2,
    ry: h / 2
  };
};
exports.getEllipseProps = getEllipseProps;
function getSymmetryPoint(pt, cx, cy) {
  return {
    x: cx * 2 - pt.x,
    y: cy * 2 - pt.y
  };
}
var keepTwoDecimal = function keepTwoDecimal(num) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var g = Math.pow(10, decimal);
  return Math.round(num * g) / g;
};
exports.keepTwoDecimal = keepTwoDecimal;