"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._accordionPanel = exports._accordion = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _dom = require("./dom");
var _layout2 = require("./layout");
// 手风琴
var _accordion = function _accordion(panel, current, target, root) {
  var el = (0, _dom._query)(current);
  if (el) {
    // 更新
    el.parentNode.childNodes.forEach(function (t) {
      return (0, _dom._addClassName)(t, 'hide');
    });
    (0, _dom._removeClassName)(el, 'hide');
  } else {
    // 新增
    var container = target ? (0, _layout2._layout)(target, root) : root;
    if (container) {
      container.childNodes.forEach(function (t) {
        return (0, _dom._addClassName)(t, 'hide');
      });
      (0, _dom._append)(container, panel);
    }
  }
};
exports._accordion = _accordion;
var _accordionPanel = function _accordionPanel(name) {
  var panel = (0, _dom._query)(".screen-viewer .panel[name=\"".concat(name, "\"]"));
  // 更新
  panel.parentNode.childNodes.forEach(function (t) {
    return (0, _dom._addClassName)(t, 'hide');
  });
  (0, _dom._removeClassName)(panel, 'hide');
};
// export const _accordion=(el,cls)=>{
// }
exports._accordionPanel = _accordionPanel;