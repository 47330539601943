"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._rough = void 0;
var _curve = require("./curve");
var _math = require("@/math");
var _controlPoints3 = require("@/algorithm/controlPoints");
var _fill = require("@/algorithm/fill");
var _rough2 = require("@/algorithm/rough");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _roughTo = function _roughTo(_ref, angle, radio) {
  var _ref2 = _slicedToArray(_ref, 2),
    from = _ref2[0],
    to = _ref2[1];
  from = (0, _rough2._offset)(from);
  to = (0, _rough2._offset)(to);
  var _controlPoints = (0, _controlPoints3.controlPoints)([from, to], (0, _math._random)(10, 20), (0, _math._random)(0.1, 0.2)),
    _controlPoints2 = _slicedToArray(_controlPoints, 2),
    c1 = _controlPoints2[0],
    c2 = _controlPoints2[1];
  return (0, _curve._moveTo)(from) + ' ' + (0, _curve._bezierCurveTo)([c1, c2, to]);
};
var _dooubleRoughTo = function _dooubleRoughTo(_ref3, angle, radio) {
  var _ref4 = _slicedToArray(_ref3, 2),
    from = _ref4[0],
    to = _ref4[1];
  return _roughTo([from, to], angle, radio) + ' ' + _roughTo([from, to], angle, radio);
};
var _rough = function _rough(options) {
  var points = options.points;
  var gridDensity = 10;
  var ps = (0, _fill.fillGridPoints)({
    points: points,
    gridDensity: gridDensity
  });
  return (0, _curve._loop)(options, _dooubleRoughTo) + ' ' + (0, _curve._loop)({
    points: ps,
    step: 2
  }, _roughTo);
};
exports._rough = _rough;