"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._fill = void 0;
require("core-js/modules/es.array.fill.js");
require("core-js/modules/es.object.assign.js");
var _fill2 = require("@/algorithm/fill");
var _ = require(".");
var _fillPattern = require("./fillPattern");
// 填充

var _fill = function _fill(options, props) {
  var _options$points = options.points,
    points = _options$points === void 0 ? [] : _options$points,
    _options$fill = options.fill,
    fill = _options$fill === void 0 ? {} : _options$fill,
    curve = options.curve;
  var pattern = fill.pattern,
    _fill$inheritCurve = fill.inheritCurve,
    inheritCurve = _fill$inheritCurve === void 0 ? true : _fill$inheritCurve;
  if (pattern in _fillPattern.FillPattern) {
    return (0, _fillPattern.createFillByPattern)(pattern, points, props);
  }
  var fp = (0, _fill2.fillPatternMap)(Object.assign(Object.assign({}, fill), {
    points: points
  }));
  return (0, _.createSvgPathShape)(Object.assign({
    name: 'fill_' + pattern,
    curve: inheritCurve ? curve : 'none'
  }, fp), props);
};
exports._fill = _fill;