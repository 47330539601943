"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._star = void 0;
require("core-js/modules/es.object.assign.js");
var _polygon2 = require("./polygon");
var _star2 = require("@/algorithm/star");
var _polar = require("@/algorithm/polar");
var _pathShape = require("./pathShape");
var _svg = require("./core/svg");
var _pathMode = require("@/types/pathMode");
// 星星

var _star = function _star(options, props) {
  var o = options.o,
    r = options.r,
    n = options.n,
    a = options.a,
    _options$interiorPoly = options.interiorPolygon,
    interiorPolygon = _options$interiorPoly === void 0 ? true : _options$interiorPoly;
  if (n > 4) {
    var points = (0, _polar.polarPoints)({
      o: o,
      r: r,
      n: n,
      a: a
    })[0];
    var ps = (0, _star2.starPoints)(points);
    var g = [];
    if (interiorPolygon) {
      // ploygon
      g[g.length] = (0, _pathShape.createSvgPathShape)({
        // ...options,
        points: points,
        pathMode: _pathMode.PathMode.LINE_LOOP
      }, Object.assign(Object.assign({}, props), {
        strokeDasharray: 4
      }));
    }
    // star
    g[g.length] = (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
      points: ps
    }), props);
    return (0, _svg._g)(g);
  } else {
    var star = (0, _polygon2._polygon)(Object.assign(Object.assign({}, options), {
      transform: 'paritySort'
    }), props);
    return star;
  }
};
exports._star = _star;