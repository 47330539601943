"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spiralShape = exports.ringShape = exports.rayShape = exports.polygonShape = exports.polarShape = exports.isometricShape = exports.gridShape = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
var _pathShape = require("../pathShape");
var _transform2 = require("@/math/transform");
var _polar = require("@/algorithm/polar");
var _polarMatrix = require("@/algorithm/polarMatrix");
var _grid = require("@/algorithm/grid");
var _helper = require("../helper");
// 被oneline取代
var polarShape = function polarShape(options, props) {
  var matrix = (0, _polar.polarPoints)(options);
  var transform = options.transform;
  // 变形函数
  matrix = (0, _transform2._transform)(matrix, transform);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.polarShape = polarShape;
var spiralShape = function spiralShape(options, props) {
  var transform = options.transform;
  var matrix = (0, _polarMatrix.spiralPoints)(options).map(function (t) {
    return (0, _transform2._transform)(t, transform);
  });
  var mirrorPoints = matrix[matrix.length - 1];
  var points = (0, _math.plainMatrix)(matrix);
  return (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
    points: points,
    mirrorPoints: mirrorPoints
  }), props);
};
exports.spiralShape = spiralShape;
var ringShape = function ringShape(options, props) {
  // const { transform, mirror, edge } = options
  // const color = _mirrorColor(mirror)
  var matrix = (0, _polarMatrix.ringPoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.ringShape = ringShape;
var rayShape = function rayShape(options, props) {
  var matrix = (0, _polarMatrix.rayPoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.rayShape = rayShape;
var isometricShape = function isometricShape(options, props) {
  var points = (0, _polar.isometricPoints)(options);
  return (0, _helper.makeSvgElementByPoints)(points, options, props);
};
// 多边形 迭代
exports.isometricShape = isometricShape;
var polygonShape = function polygonShape(options, props) {
  var matrix = [];
  // 变形函数
  // points = _transform(points, transform)
  var transform = options.transform,
    n = options.n;
  for (var i = 0; i < n; i++) {
    var last = matrix[matrix.length - 1];
    var points = (0, _polar.polygonPoints)(Object.assign(Object.assign({}, options), {
      o: last ? last[last.length - 1] : options.o,
      end: last ? last[0] : options.end,
      n: n - i,
      sweepFlag: i % 2
    }));
    matrix.push(points);
  }
  matrix = matrix.map(function (t) {
    return (0, _transform2._transform)(t, transform);
  });
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.polygonShape = polygonShape;
var gridShape = function gridShape(options, props) {
  var transform = options.transform;
  var matrix = (0, _grid.gridCellPoints)(options);
  matrix = matrix.map(function (t) {
    return (0, _transform2._transform)(t, transform);
  });
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports.gridShape = gridShape;