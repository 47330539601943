"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peanoPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _square = require("./square");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var _peano = function _peano(o, r, depth, sweep, startIndex, a) {
  var points = (0, _square._squarePoints)(o, r, sweep, startIndex, a);
  if (depth === 0) {
    return points;
  } else {
    var ps = [];
    points.forEach(function (t, i) {
      if (i === 0) {
        ps.push.apply(ps, _toConsumableArray(_peano(t, r / 2, depth - 1, !sweep, startIndex, a)));
      } else if (i === 1) {
        ps.push.apply(ps, _toConsumableArray(_peano(t, r / 2, depth - 1, sweep, startIndex, a)));
      } else if (i === 2) {
        ps.push.apply(ps, _toConsumableArray(_peano(t, r / 2, depth - 1, sweep, startIndex, a)));
      } else if (i === 3) {
        ps.push.apply(ps, _toConsumableArray(_peano(t, r / 2, depth - 1, !sweep, startIndex ? 0 : 2, a)));
      }
    });
    return ps;
  }
};
var peanoPoints = function peanoPoints(options) {
  var o = options.o,
    r = options.r,
    depth = options.depth,
    a = options.a;
  var points = _peano(o, r, depth, false, 0, a);
  return points;
};
exports.peanoPoints = peanoPoints;