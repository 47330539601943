"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._radius = void 0;
require("core-js/modules/es.object.assign.js");
var _svg = require("../core/svg");
var _pathMode = require("../core/pathMode");
var _common = require("@/common");
var _radius = function _radius(_ref, props) {
  var points = _ref.points,
    radius = _ref.radius;
  var one = radius.one;
  if (one) {
    return (0, _svg._path)((0, _pathMode._rayOne)({
      points: points
    }), (0, _common._merge)(props, radius));
  }
  return (0, _svg._path)((0, _pathMode._ray)({
    points: points
  }), Object.assign(Object.assign(Object.assign({}, props), radius), {
    name: 'radius'
  }));
};
exports._radius = _radius;