"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTreeItem = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.object.assign.js");
// 解析目录item
var parseTreeItem = function parseTreeItem(t) {
  return new Promise(function (resolve) {
    if (t.content) {
      return resolve(t);
    } else if (t.url) {
      if (/^canvas.*?/.test(t.url)) {
        import("../data/canvas/index").then(function (res) {
          return resolve(Object.assign(Object.assign({}, t), {
            content: res[t.url]
          }));
        });
      } else if (/^svg.*?/.test(t.url)) {
        import("../data/svg/index").then(function (res) {
          return resolve(Object.assign(Object.assign({}, t), {
            content: res[t.url]
          }));
        });
      } else if (/^(webgl|gl).*?/.test(t.url)) {
        import("../data/webgl/index").then(function (res) {
          return resolve(Object.assign(Object.assign({}, t), {
            content: res[t.url]
          }));
        });
      } else if (/^(lottie).*?/.test(t.url)) {
        import("../data/lottie/index").then(function (res) {
          return resolve(Object.assign(Object.assign({}, t), {
            content: res[t.url]
          }));
        });
      } else {
        import("../data/html").then(function (res) {
          return resolve(Object.assign(Object.assign({}, t), {
            content: res[t.url]
          }));
        });
      }
    }
  });
};
exports.parseTreeItem = parseTreeItem;