"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitSquare = exports._unitScale = exports._unitMove = exports._squarePoints4_4 = exports._squarePoints = void 0;
require("core-js/modules/es.array.map.js");
var _math = require("@/math");
var _arraySort = require("@/math/arraySort");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 单位正方形
// 顺时针
var unitSquare = [[1, -1], [-1, -1], [-1, 1], [1, 1]];
// 逆时针
exports.unitSquare = unitSquare;
var unitSquareSweep = [[1, -1], [1, 1], [-1, 1], [-1, -1]];
// [[-1, 1], [1, 1], [1, -1], [-1, -1]]
// 放大缩小
// 支持多点围绕中点旋转
var _unitScale = function _unitScale(p, radio, a) {
  if ((0, _math.isPoints)(p)) {
    if (a) {
      p = (0, _math.rotatePoints)(p, a);
    }
    return p.map(function (t) {
      return _unitScale(t, radio);
    });
  }
  return [p[0] * radio, p[1] * radio];
};
// 线段平移
exports._unitScale = _unitScale;
var _unitMove = function _unitMove(p, to, radio, a) {
  if ((0, _math.isPoints)(p)) {
    if (radio) {
      var points = _unitScale(p, radio, a);
      return _unitMove(points, to);
    }
    return p.map(function (t) {
      return _unitMove(t, to);
    });
  } else {
    return [p[0] + to[0], p[1] + to[1]];
  }
};
exports._unitMove = _unitMove;
var _squarePoints = function _squarePoints(o, r, sweep, startIndex, a) {
  var arr = _toConsumableArray(sweep ? unitSquareSweep : unitSquare);
  if (startIndex) {
    arr = (0, _arraySort.newSeq)(arr, startIndex);
  }
  return _unitMove(arr, o, r, a);
};
// 2x2
// _squarePoints
// 4x4
exports._squarePoints = _squarePoints;
var _squarePoints4_4 = function _squarePoints4_4(o, r) {
  var arr = _squarePoints(o, r);
  return arr.map(function (t) {
    return _squarePoints(t, r / 2);
  });
};
exports._squarePoints4_4 = _squarePoints4_4;