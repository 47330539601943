"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.polylineToBezier = exports.curveTypes = exports._taichiToSeg = exports._taichiTo = exports._quadraticCurveTo = exports._polyline = exports._moveTo = exports._loop = exports._lineTo = exports._leftCentripetal = exports._doubleCentripetal = exports._curve = exports._centripetal = exports._bezierCurveTo = exports._arcTo = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.keys.js");
var _math = require("@/math");
var _mirror2 = require("@/math/mirror");
var _controlPoints5 = require("@/algorithm/controlPoints");
var _sierpinski2 = require("@/algorithm/sierpinski");
var _koch2 = require("@/algorithm/koch");
var _traversal2 = require("@/algorithm/traversal");
var _sin2 = require("@/algorithm/sin");
var _rough2 = require("./rough");
var _utils = require("@/utils");
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// M = moveto
// L = lineto
// H = horizontal lineto
// V = vertical lineto
// C = curveto
// S = smooth curveto
// Q = quadratic Bézier curve
// T = smooth quadratic Bézier curveto
// A = elliptical Arc
// Z = closepath
var pathCommandMap = {
  7: 'A',
  6: 'C',
  4: 'Q',
  2: 'L'
};
var _lineTo = function _lineTo(p) {
  if (p.length === 0) {
    return '';
  } else if ((0, _math.isPoints)(p)) {
    return _lineTo(p[0]) + (p.length > 0 ? _lineTo(p.slice(1)) : '');
  }
  return " ".concat(pathCommandMap[p.length] || 'M').concat(p.join(' '));
};
exports._lineTo = _lineTo;
var _moveTo = function _moveTo(p) {
  return "M".concat(p.join(' '), " ");
};
exports._moveTo = _moveTo;
var _polylineTo = function _polylineTo(points) {
  return points.map(function (t) {
    return 'L' + t.join(' ');
  }).join(' ');
};
// 弧形
var _arcTo = function _arcTo(options) {
  var _options$sweepFlag = options.sweepFlag,
    sweepFlag = _options$sweepFlag === void 0 ? true : _options$sweepFlag,
    _options$largeArcFlag = options.largeArcFlag,
    largeArcFlag = _options$largeArcFlag === void 0 ? false : _options$largeArcFlag,
    _options$xAxisRotatio = options.xAxisRotation,
    xAxisRotation = _options$xAxisRotatio === void 0 ? 0 : _options$xAxisRotatio,
    r = options.r,
    to = options.to;
  return 'A' + [r, r, xAxisRotation, largeArcFlag ? 1 : 0, sweepFlag ? 1 : 0].concat(_toConsumableArray(to)).join(' ');
};
// 折线转曲线
exports._arcTo = _arcTo;
var polylineToBezier = function polylineToBezier(points) {
  var len = points.length;
  if (len === 2) {
    var _points = _slicedToArray(points, 2),
      p1 = _points[0],
      p2 = _points[1];
    var p = [].concat(_toConsumableArray(p1), _toConsumableArray(p2));
    return _lineTo(p);
  } else if (len >= 3) {
    var _points2 = _toArray(points),
      _p = _points2[0],
      _p2 = _points2[1],
      p3 = _points2[2],
      rest = _points2.slice(3);
    var _p3 = [].concat(_toConsumableArray(_p), _toConsumableArray(_p2), _toConsumableArray(p3));
    return _lineTo(_p3) + polylineToBezier(rest);
  }
  return '';
};
exports.polylineToBezier = polylineToBezier;
var _bezierCurveTo = function _bezierCurveTo(_ref) {
  var _ref2 = _slicedToArray(_ref, 3),
    c1 = _ref2[0],
    c2 = _ref2[1],
    t = _ref2[2];
  return 'C' + [c1[0], c1[1], c2[0], c2[1], t[0], t[1]].join(' ');
};
exports._bezierCurveTo = _bezierCurveTo;
var _quadraticCurveTo = function _quadraticCurveTo(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    c = _ref4[0],
    to = _ref4[1];
  return 'Q' + [c[0], c[1], to[0], to[1]].join(' ');
};
exports._quadraticCurveTo = _quadraticCurveTo;
var _bezierTo = function _bezierTo(_ref5, _ref6) {
  var _ref7 = _slicedToArray(_ref5, 2),
    from = _ref7[0],
    to = _ref7[1];
  var _ref8 = _slicedToArray(_ref6, 2),
    c1 = _ref8[0],
    c2 = _ref8[1];
  return _bezierCurveTo([c2, c1, to]);
};
var _leftBezierTo = function _leftBezierTo(_ref9, _ref10) {
  var _ref11 = _slicedToArray(_ref9, 2),
    from = _ref11[0],
    to = _ref11[1];
  var _ref12 = _slicedToArray(_ref10, 2),
    c1 = _ref12[0],
    c2 = _ref12[1];
  return _bezierCurveTo([c1, c2, to]);
};
var _doubleBezierTo = function _doubleBezierTo(_ref13, _ref14) {
  var _ref15 = _slicedToArray(_ref13, 2),
    from = _ref15[0],
    to = _ref15[1];
  var _ref16 = _slicedToArray(_ref14, 2),
    c1 = _ref16[0],
    c2 = _ref16[1];
  return _moveTo(from) + _bezierCurveTo([c1, c2, to]) + _moveTo(from) + _bezierCurveTo([c2, c1, to]);
};
var _sawtoothTo = function _sawtoothTo(_ref17, _ref18) {
  var _ref19 = _slicedToArray(_ref17, 2),
    from = _ref19[0],
    to = _ref19[1];
  var _ref20 = _slicedToArray(_ref18, 2),
    c1 = _ref20[0],
    c2 = _ref20[1];
  return _polylineTo([c2, c1, to]);
};
var _leftSawtoothTo = function _leftSawtoothTo(_ref21, _ref22) {
  var _ref23 = _slicedToArray(_ref21, 2),
    from = _ref23[0],
    to = _ref23[1];
  var _ref24 = _slicedToArray(_ref22, 2),
    c1 = _ref24[0],
    c2 = _ref24[1];
  return _polylineTo([c1, c2, to]);
};
var _doubleSawtoothTo = function _doubleSawtoothTo(_ref25, _ref26) {
  var _ref27 = _slicedToArray(_ref25, 2),
    from = _ref27[0],
    to = _ref27[1];
  var _ref28 = _slicedToArray(_ref26, 2),
    c1 = _ref28[0],
    c2 = _ref28[1];
  return _moveTo(from) + _polylineTo([c1, c2, to]) + _moveTo(from) + _polylineTo([c2, c1, to]);
};
var _rightAngleTo = function _rightAngleTo(_ref29, _ref30) {
  var _ref31 = _slicedToArray(_ref29, 2),
    from = _ref31[0],
    to = _ref31[1];
  var _ref32 = _slicedToArray(_ref30, 2),
    c1 = _ref32[0],
    c2 = _ref32[1];
  return _polylineTo([c2, to]);
};
var _leftAngleTo = function _leftAngleTo(_ref33, _ref34) {
  var _ref35 = _slicedToArray(_ref33, 2),
    from = _ref35[0],
    to = _ref35[1];
  var _ref36 = _slicedToArray(_ref34, 2),
    c1 = _ref36[0],
    c2 = _ref36[1];
  return _polylineTo([c1, to]);
};
var _diamondTo = function _diamondTo(_ref37, _ref38) {
  var _ref39 = _slicedToArray(_ref37, 2),
    from = _ref39[0],
    to = _ref39[1];
  var _ref40 = _slicedToArray(_ref38, 2),
    c1 = _ref40[0],
    c2 = _ref40[1];
  return _moveTo(from) + ' ' + _polylineTo([c1, to, c2, from]);
};
var _crossTo = function _crossTo(_ref41, _ref42) {
  var _ref43 = _slicedToArray(_ref41, 2),
    from = _ref43[0],
    to = _ref43[1];
  var _ref44 = _slicedToArray(_ref42, 2),
    c1 = _ref44[0],
    c2 = _ref44[1];
  return [_moveTo(from), _lineTo(to), _moveTo(c1), _lineTo(c2)].join(' ');
};
var _triangleTo = function _triangleTo(_ref45, _ref46) {
  var _ref47 = _slicedToArray(_ref45, 2),
    from = _ref47[0],
    to = _ref47[1];
  var _ref48 = _slicedToArray(_ref46, 2),
    c1 = _ref48[0],
    c2 = _ref48[1];
  return _moveTo(from) + _polylineTo([c2, to, from]);
};
var _leftTriangleTo = function _leftTriangleTo(_ref49, _ref50) {
  var _ref51 = _slicedToArray(_ref49, 2),
    from = _ref51[0],
    to = _ref51[1];
  var _ref52 = _slicedToArray(_ref50, 2),
    c1 = _ref52[0],
    c2 = _ref52[1];
  return _moveTo(from) + _polylineTo([c1, to, from]);
};
var _circleByRadius = function _circleByRadius(o, r) {
  var _o2 = _slicedToArray(o, 2),
    x = _o2[0],
    y = _o2[1];
  // let r = _disToSeg(o, [p1, p2])
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(x - y)) return '';
  return 'M' + (x - r) + ' ' + y + 'a' + r + ' ' + r + ' 0 1 0 ' + 2 * r + ' 0' + 'a' + r + ' ' + r + ' 0 1 0 ' + -2 * r + ' 0' + 'z';
};
var _circleTo = function _circleTo(_ref53) {
  var _ref54 = _slicedToArray(_ref53, 2),
    from = _ref54[0],
    to = _ref54[1];
  var o = (0, _math._o)([from, to]);
  var r = (0, _math._dis)(o, to);
  return _circleByRadius(o, r);
};
// 相切圆
var _circleToSeg = function _circleToSeg(o, _ref55) {
  var _ref56 = _slicedToArray(_ref55, 2),
    p1 = _ref56[0],
    p2 = _ref56[1];
  var r = (0, _math._disToSeg)(o, [p1, p2]);
  return _circleByRadius(o, r);
};
// 半圆
var _semicircleTo = function _semicircleTo(_ref57) {
  var _ref58 = _slicedToArray(_ref57, 2),
    from = _ref58[0],
    to = _ref58[1];
  var xAxisRotation = 0,
    largeArcFlag = false,
    sweepFlag = false;
  var r = (0, _math._dis)(from, to) / 2;
  return 'A' + [r, r, xAxisRotation, largeArcFlag ? 1 : 0, sweepFlag ? 1 : 0].concat(_toConsumableArray(to)).join(' ');
};
var _leftSemicircleTo = function _leftSemicircleTo(_ref59) {
  var _ref60 = _slicedToArray(_ref59, 2),
    from = _ref60[0],
    to = _ref60[1];
  var xAxisRotation = 0,
    largeArcFlag = false,
    sweepFlag = true;
  var r = (0, _math._dis)(from, to) / 2;
  return 'A' + [r, r, xAxisRotation, largeArcFlag ? 1 : 0, sweepFlag ? 1 : 0].concat(_toConsumableArray(to)).join(' ');
};
var _circle = function _circle(options) {
  return _loop(options, _circleTo);
};
// 太极
var _taichiTo = function _taichiTo(_ref61) {
  var _ref62 = _slicedToArray(_ref61, 2),
    from = _ref62[0],
    to = _ref62[1];
  var o = (0, _math._mid)(from, to);
  var o1 = (0, _math._mid)(from, o);
  var o2 = (0, _math._mid)(o, to);
  var r1 = (0, _math._dis)(from, o1) / 2;
  return _moveTo(from) + _semicircleTo([from, o]) + _leftSemicircleTo([o, to]) + _circleTo([from, to]) + _circleByRadius(o1, r1) + _circleByRadius(o2, r1);
};
exports._taichiTo = _taichiTo;
var _taichiToSeg = function _taichiToSeg(o, _ref63) {
  var _ref64 = _slicedToArray(_ref63, 2),
    p1 = _ref64[0],
    p2 = _ref64[1];
  var fp = (0, _math._footPoint)(o, [p1, p2]);
  var o1 = (0, _math._mid)(fp, o);
  var o2 = (0, _mirror2._mirror)(o1, o);
  var r = (0, _math._dis)(o, fp);
  var r1 = r / 4;
  var fp2 = (0, _mirror2._mirror)(fp, o);
  return _moveTo(fp) + _semicircleTo([fp, o]) + _leftSemicircleTo([o, fp2]) + _circleByRadius(o, r) + _circleByRadius(o1, r1) + _circleByRadius(o2, r1);
};
exports._taichiToSeg = _taichiToSeg;
var _monkeyToSeg = function _monkeyToSeg(o, _ref65) {
  var _ref66 = _slicedToArray(_ref65, 2),
    p1 = _ref66[0],
    p2 = _ref66[1];
  var fp = (0, _math._footPoint)(o, [p1, p2]);
  var o1 = (0, _math._mid)(fp, o);
  var o2 = (0, _mirror2._mirror)(o1, o);
  var r = (0, _math._dis)(o, fp);
  var r1 = r / 4;
  var fp2 = (0, _mirror2._mirror)(fp, o);
  return _moveTo(fp) + _semicircleTo([fp, o]) + _semicircleTo([o, fp2]) + _circleByRadius(o, r) + _circleByRadius(o1, r1) + _circleByRadius(o2, r1);
};
// 循环
var _loop = function _loop(_ref67, fn) {
  var points = _ref67.points,
    loop = _ref67.loop,
    _ref67$step = _ref67.step,
    step = _ref67$step === void 0 ? 1 : _ref67$step,
    _ref67$skew = _ref67.skew,
    skew = _ref67$skew === void 0 ? 0 : _ref67$skew,
    _ref67$amplitude = _ref67.amplitude,
    amplitude = _ref67$amplitude === void 0 ? 1 : _ref67$amplitude,
    _ref67$depth = _ref67.depth,
    depth = _ref67$depth === void 0 ? 1 : _ref67$depth;
  var len = points.length;
  var result = [];
  var prev;
  var index = 0;
  // let controls = []
  var callFn = function callFn(i, t, next) {
    if (step > 1) {
      if (i % step === 0) {
        result[result.length] = _moveTo(t);
        prev = t;
      } else {
        var _controlPoints = (0, _controlPoints5.controlPoints)([prev, t], skew, amplitude),
          _controlPoints2 = _slicedToArray(_controlPoints, 2),
          c1 = _controlPoints2[0],
          c2 = _controlPoints2[1];
        result[result.length] = fn([prev, t], [c1, c2], index++);
      }
    } else {
      var _controlPoints3 = (0, _controlPoints5.controlPoints)([t, next], skew, amplitude),
        _controlPoints4 = _slicedToArray(_controlPoints3, 2),
        _c = _controlPoints4[0],
        _c2 = _controlPoints4[1];
      result[result.length] = fn([t, next], [_c, _c2], index++);
    }
  };
  var total = loop ? len : len - 1;
  for (var i = 0; i < total; i++) {
    var t = points[i];
    var next = points[(i + 1) % len];
    if (depth === 1) {
      callFn(i, t, next);
    } else {
      var curr = t;
      for (var j = 1; j <= depth; j++) {
        var p = (0, _math._lerp)(t, next, j / depth);
        callFn(i, curr, p);
        curr = p;
      }
    }
  }
  return result.join(' ');
};
// 曲线
exports._loop = _loop;
var _bezier = function _bezier(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _bezierTo);
};
var _leftBezier = function _leftBezier(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _leftBezierTo);
};
var _doubleBezier = function _doubleBezier(options) {
  return _loop(options, _doubleBezierTo);
};
var _symmetry = function _symmetry(options, fn1, fn2) {
  return _loop(options, function (_ref68, _ref69, index) {
    var _ref70 = _slicedToArray(_ref68, 2),
      t = _ref70[0],
      next = _ref70[1];
    var _ref71 = _slicedToArray(_ref69, 2),
      c1 = _ref71[0],
      c2 = _ref71[1];
    if (index % 2 === 0) {
      return _moveTo(t) + fn1([t, next], [c1, c2]);
    } else {
      return _moveTo(t) + fn2([t, next], [c1, c2]);
    }
  });
};
var _symmetryBezier = function _symmetryBezier(options) {
  return _symmetry(options, _bezierTo, _leftBezierTo);
};
// 锯齿
var _sawtooth = function _sawtooth(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _sawtoothTo);
};
var _leftSawtooth = function _leftSawtooth(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _leftSawtoothTo);
};
var _doubleSawtooth = function _doubleSawtooth(options) {
  return _loop(options, _doubleSawtoothTo);
};
var _symmetrySawtooth = function _symmetrySawtooth(options) {
  return _symmetry(options, _sawtoothTo, _leftSawtoothTo);
};
// 半圆
// {points, o, options = {}}
var _semicircle = function _semicircle(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _semicircleTo);
};
var _leftSemicircle = function _leftSemicircle(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _leftSemicircleTo);
};
// 太极
var _taichi = function _taichi(options) {
  return _loop(options, _taichiTo);
};
// 向心弧线
var _centripetal = function _centripetal(options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  return _moveTo(points[0]) + _loop(options, function (_ref72) {
    var _ref73 = _slicedToArray(_ref72, 2),
      t = _ref73[0],
      next = _ref73[1];
    return _quadraticCurveTo([o, next]);
  });
};
exports._centripetal = _centripetal;
var _leftCentripetal = function _leftCentripetal(options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  return _moveTo(points[0]) + _loop(options, function (_ref74) {
    var _ref75 = _slicedToArray(_ref74, 2),
      t = _ref75[0],
      next = _ref75[1];
    var c = (0, _math._mid)(t, next);
    var oo = (0, _mirror2._mirror)(o, c);
    return _quadraticCurveTo([oo, next]);
  });
};
exports._leftCentripetal = _leftCentripetal;
var _doubleCentripetal = function _doubleCentripetal(options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  return _loop(options, function (_ref76) {
    var _ref77 = _slicedToArray(_ref76, 2),
      t = _ref77[0],
      next = _ref77[1];
    var stack = [];
    stack[stack.length] = _moveTo(t);
    stack[stack.length] = _quadraticCurveTo([o, next]);
    stack[stack.length] = _moveTo(t);
    var c = (0, _math._mid)(t, next);
    var oo = (0, _mirror2._mirror)(o, c);
    stack[stack.length] = _quadraticCurveTo([oo, next]);
    return stack.join(' ');
  });
};
// 菱形
exports._doubleCentripetal = _doubleCentripetal;
var _diamond = function _diamond(options) {
  return _loop(options, _diamondTo);
};
// 十字
var _cross = function _cross(options) {
  return _loop(options, _crossTo);
};
// 三角形
var _triangle = function _triangle(options) {
  return _loop(options, _triangleTo);
};
var _leftTriangle = function _leftTriangle(options) {
  return _loop(options, _leftTriangleTo);
};
// 直角拐线
var _rightAngle = function _rightAngle(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _rightAngleTo);
};
var _leftAngle = function _leftAngle(options) {
  var points = options.points;
  return _moveTo(points[0]) + _loop(options, _leftAngleTo);
};
var _symmetryAngle = function _symmetryAngle(options) {
  return _symmetry(options, _rightAngleTo, _leftAngleTo);
};
var _polyline = function _polyline(options) {
  var points = options.points,
    loop = options.loop,
    step = options.step,
    discrete = options.discrete;
  var n = step;
  var iter = function iter(_ref78) {
    var _ref78$points = _slicedToArray(_ref78.points, 2),
      p1 = _ref78$points[0],
      p2 = _ref78$points[1];
    return _polylineTo([p2]);
  };
  var init = function init(_ref79) {
    var point = _ref79.point;
    return _moveTo(point);
  };
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: iter,
    init: init,
    loop: false,
    discrete: discrete
  }).join(' ') + (loop ? 'z' : '');
};
exports._polyline = _polyline;
var _sin = function _sin(options) {
  var points = options.points;
  var ps = (0, _sin2.sinPoints)(points);
  return _polyline({
    points: ps
  });
};
// 互切圆
var _tangentCircle = function _tangentCircle(options) {
  var points = options.points,
    loop = options.loop,
    _options$step = options.step,
    step = _options$step === void 0 ? 1 : _options$step;
  var o = (0, _math._o)(points);
  return _loop({
    points: points,
    step: step,
    loop: loop
  }, function (_ref80) {
    var _ref81 = _slicedToArray(_ref80, 2),
      t = _ref81[0],
      next = _ref81[1];
    var c = (0, _math._mid)(t, next);
    return _circleToSeg(c, [o, next]);
  });
};
var _tangentTaichi = function _tangentTaichi(options) {
  var points = options.points,
    loop = options.loop,
    _options$step2 = options.step,
    step = _options$step2 === void 0 ? 1 : _options$step2;
  var o = (0, _math._o)(points);
  return _loop({
    points: points,
    step: step,
    loop: loop
  }, function (_ref82) {
    var _ref83 = _slicedToArray(_ref82, 2),
      t = _ref83[0],
      next = _ref83[1];
    var c = (0, _math._mid)(t, next);
    return _taichiToSeg(c, [o, next]);
  });
};
var _tangentMonkey = function _tangentMonkey(options) {
  var points = options.points,
    loop = options.loop,
    _options$step3 = options.step,
    step = _options$step3 === void 0 ? 1 : _options$step3;
  var o = (0, _math._o)(points);
  return _loop({
    points: points,
    step: step,
    loop: loop
  }, function (_ref84) {
    var _ref85 = _slicedToArray(_ref84, 2),
      t = _ref85[0],
      next = _ref85[1];
    var c = (0, _math._mid)(t, next);
    return _monkeyToSeg(c, [o, next]);
  });
};
// 杨辉三角
var _sierpinski = function _sierpinski(options) {
  var loop = options.loop;
  var matrix = (0, _sierpinski2.sierpinskiPoints)(options);
  return (0, _traversal2._traversal)({
    points: (0, _math.plainMatrix)(matrix),
    iter: function iter(_ref86) {
      var points = _ref86.points;
      return _polylineTo(points);
    },
    init: function init(_ref87) {
      var point = _ref87.point;
      return _moveTo(point);
    },
    loop: loop
  }).join(' ');
};
// 杨辉三角贝塞尔
var _sierpinskiBezier = function _sierpinskiBezier(options) {
  var loop = options.loop;
  var matrix = (0, _sierpinski2.sierpinskiPoints)(options);
  return (0, _traversal2._traversal)({
    points: (0, _math.plainMatrix)(matrix),
    iter: function iter(_ref88) {
      var _ref88$points = _slicedToArray(_ref88.points, 4),
        p1 = _ref88$points[0],
        p2 = _ref88$points[1],
        p3 = _ref88$points[2],
        p4 = _ref88$points[3];
      return _bezierCurveTo([p2, p3, p4]);
    },
    init: function init(_ref89) {
      var point = _ref89.point;
      return _moveTo(point);
    },
    loop: loop
  }).join(' ');
};
var _sierpinskiDiscreteBezier = function _sierpinskiDiscreteBezier(options) {
  var loop = options.loop;
  var matrix = (0, _sierpinski2.sierpinskiPoints)(options);
  var n = 4;
  var discrete = true;
  var ps = (0, _math.plainMatrix)(matrix);
  return (0, _traversal2._traversal)({
    points: ps,
    n: n,
    iter: function iter(_ref90) {
      var _ref90$points = _slicedToArray(_ref90.points, 4),
        p1 = _ref90$points[0],
        p2 = _ref90$points[1],
        p3 = _ref90$points[2],
        p4 = _ref90$points[3];
      return _moveTo(p1) + ' ' + _bezierCurveTo([p2, p3, p4]);
    },
    loop: loop,
    discrete: discrete
  }).join(' ');
};
var _koch = function _koch(options) {
  var loop = options.loop;
  var ps = (0, _koch2.kochPoints)(options);
  return (0, _traversal2._traversal)({
    points: ps,
    // n,
    iter: function iter(_ref91) {
      var points = _ref91.points;
      return _polylineTo(points);
    },
    init: function init(_ref92) {
      var point = _ref92.point;
      return _moveTo(point);
    },
    loop: loop
  }).join(' ');
};
var _kochCurve = function _kochCurve(options) {
  var loop = options.loop;
  var ps = (0, _koch2.kochPoints)(options);
  var n = 3;
  var iter = function iter(_ref93) {
    var _ref93$points = _slicedToArray(_ref93.points, 3),
      p1 = _ref93$points[0],
      p2 = _ref93$points[1],
      p3 = _ref93$points[2];
    return _quadraticCurveTo([p2, p3]);
  };
  return (0, _traversal2._traversal)({
    points: ps,
    n: n,
    iter: iter,
    init: function init(_ref94) {
      var point = _ref94.point;
      return _moveTo(point);
    },
    loop: loop
  }).join(' ');
};
var _kochDiscreteCurve = function _kochDiscreteCurve(options) {
  var loop = options.loop;
  var ps = (0, _koch2.kochPoints)(options);
  var n = 5;
  var iter = function iter(_ref95) {
    var _ref95$points = _slicedToArray(_ref95.points, 5),
      p1 = _ref95$points[0],
      p2 = _ref95$points[1],
      p3 = _ref95$points[2],
      p4 = _ref95$points[3],
      p5 = _ref95$points[4];
    return _moveTo(p1) + ' ' + _quadraticCurveTo([p2, p3]) + _quadraticCurveTo([p4, p5]);
  };
  var discrete = true;
  return (0, _traversal2._traversal)({
    points: ps,
    n: n,
    iter: iter,
    loop: loop,
    discrete: discrete
  }).join(' ');
};
var _fibonacciCurve = function _fibonacciCurve(options) {
  var points = options.points,
    loop = options.loop,
    _options$increasedAgn = options.increasedAgnle,
    increasedAgnle = _options$increasedAgn === void 0 ? 90 : _options$increasedAgn;
  var a = Math.PI * increasedAgnle / 180;
  var iter = function iter(_ref96) {
    var _ref96$points = _slicedToArray(_ref96.points, 2),
      p1 = _ref96$points[0],
      p2 = _ref96$points[1];
    var r = (0, _math._k)(0.5 * (0, _math._dis)(p1, p2) / Math.sin(a / 2));
    return _arcTo({
      sweepFlag: false,
      largeArcFlag: false,
      xAxisRotation: 0,
      r: r,
      to: p2
    });
  };
  var n = 2;
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: iter,
    init: function init(_ref97) {
      var point = _ref97.point;
      return _moveTo(point);
    },
    loop: loop
  }).join(' ');
};
var curveMapFn = {
  // none: () => { },
  none: _polyline,
  bezier: _bezier,
  leftBezier: _leftBezier,
  doubleBezier: _doubleBezier,
  symmetryBezier: _symmetryBezier,
  sawtooth: _sawtooth,
  leftSawtooth: _leftSawtooth,
  doubleSawtooth: _doubleSawtooth,
  symmetrySawtooth: _symmetrySawtooth,
  rightAngle: _rightAngle,
  leftAngle: _leftAngle,
  symmetryAngle: _symmetryAngle,
  circle: _circle,
  diamond: _diamond,
  cross: _cross,
  triangle: _triangle,
  leftTriangle: _leftTriangle,
  semicircle: _semicircle,
  leftSemicircle: _leftSemicircle,
  centripetal: _centripetal,
  leftCentripetal: _leftCentripetal,
  doubleCentripetal: _doubleCentripetal,
  sin: _sin,
  tangentCircle: _tangentCircle,
  rough: _rough2._rough,
  taichi: _taichi,
  tangentTaichi: _tangentTaichi,
  tangentMonkey: _tangentMonkey,
  sierpinski: _sierpinski,
  sierpinskiBezier: _sierpinskiBezier,
  // sierpinskiDiscreteBezier: _sierpinskiDiscreteBezier,
  koch: _koch,
  kochCurve: _kochCurve,
  fibonacciCurve: _fibonacciCurve
  // kochDiscreteCurve: _kochDiscreteCurve
};

var curveTypes = Object.keys(curveMapFn);
//曲线
exports.curveTypes = curveTypes;
var _curve = function _curve(_ref98) {
  var points = _ref98.points,
    o = _ref98.o,
    curve = _ref98.curve,
    loop = _ref98.loop,
    step = _ref98.step,
    discrete = _ref98.discrete;
  var type,
    amplitude = 1,
    skew = 0,
    depth = 1,
    increasedAgnle = 90;
  if (typeof curve === 'string') {
    type = curve;
  } else if ((0, _utils.isObject)(curve)) {
    type = curve.type;
    amplitude = curve.amplitude;
    skew = curve.skew;
    depth = curve.depth;
    increasedAgnle = curve.increasedAgnle;
  }
  var fn = curveMapFn[type] || _polyline;
  if (step > 1) {
    loop = true;
  }
  return fn({
    points: points,
    o: o,
    loop: loop,
    step: step,
    skew: skew,
    amplitude: amplitude,
    depth: depth,
    discrete: discrete,
    increasedAgnle: increasedAgnle
  });
};
exports._curve = _curve;