"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cycloidPoints = void 0;
var _traversal = require("./traversal");
/**
 * 摆线，又称旋轮线、圆滚线，在数学中，摆线（Cycloid）被定义为，一个圆沿一条直线运动时，圆边界上一定点所形成的轨迹。
它是一般旋轮线的一种。摆线也是最速降线问题和等时降落问题的解。
摆线也是最速降线问题和等时降落问题的解。
方    程x=r*(t-sint)； y=r*(1-cost)
它的长度等于旋转圆直径的 4 倍。尤为令人感兴趣的是，它的长度是 一个不依赖于π的有理数。
 * @param param0
 * @returns
 */
var cycloidPoints = function cycloidPoints(_ref) {
  var _ref$r = _ref.r,
    r = _ref$r === void 0 ? 15 : _ref$r,
    o = _ref.o,
    _ref$a = _ref.a,
    a = _ref$a === void 0 ? 0 : _ref$a,
    _ref$n = _ref.n,
    n = _ref$n === void 0 ? 200 : _ref$n;
  var _x = function _x(t) {
    return t - Math.sin(t);
  };
  var _y = function _y(t) {
    return 1 - Math.cos(t); //  5 * (2 * Math.cos(t) + Math.cos(2 * t));
  };

  return (0, _traversal._traversalPolar)({
    o: o,
    r: r,
    a: a,
    n: n,
    _x: _x,
    _y: _y
  });
};
exports.cycloidPoints = cycloidPoints;