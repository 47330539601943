"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.treeLine = exports.squarePath = exports.rect2path = exports.rect = exports.polyline2path = exports.polyline = exports.polygonColorpath = exports.pointsPath = exports.line2path = exports.ellipse2path = exports.closedLinePath = exports.circle = exports.brokenLinePath = exports._d2 = exports._d = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.slice.js");
var _svg = require("./svg");
var _math = require("@/math");
// 点的路径图
var pointsPath = function pointsPath(_ref, props, events, children) {
  var points = _ref.points,
    closed = _ref.closed,
    broken = _ref.broken;
  return (0, _svg._path)(_d(points, {
    closed: closed,
    broken: broken
  }), props, events, children);
};
// 树形路径
exports.pointsPath = pointsPath;
var treeLine = function treeLine(treePoints, props) {
  var d = treePoints.map(function (ps) {
    return _d(ps, {
      broken: false,
      closed: false
    });
  }).join(' ');
  return (0, _svg._path)(d, props);
};
exports.treeLine = treeLine;
var polyline = function polyline(points, props) {
  return pointsPath({
    points: points,
    closed: false,
    broken: false
  }, props);
};
// M = moveto
// L = lineto
// H = horizontal lineto
// V = vertical lineto
// C = curveto
// S = smooth curveto
// Q = quadratic Bézier curve
// T = smooth quadratic Bézier curveto
// A = elliptical Arc
// Z = closepath
exports.polyline = polyline;
var pathCommandMap = {
  7: 'A',
  6: 'C',
  4: 'Q',
  2: 'L'
};
// 链接点 [p1,p2]  =>[[x,y],[x,y]]  通用链接方式 polyline
var _d = function _d(points, options) {
  var _ref2 = options !== null && options !== void 0 ? options : {},
    closed = _ref2.closed,
    broken = _ref2.broken;
  var n = typeof broken === 'number' ? broken : broken ? 2 : 0;
  return points.map(function (t, index) {
    if ((0, _math.isPoints)(t)) {
      return _d(t, {
        closed: false,
        broken: broken
      });
    }
    var len = t.length;
    var stack = [t.join(' ')];
    if (broken ? index % n === 0 : index === 0) {
      stack.unshift('M');
    } else {
      stack.unshift(pathCommandMap[len] || pathCommandMap[2]);
    }
    return stack.join('');
    // return `${(broken ? index % 2 === 0 : index === 0) ? "M" : len === 7 ? "A" : len === 4 ? "Q" : "L"}${t.join(" ")}`
    // return Array.isArray(t[0]) ? _d(t, {
    //     closed: false,
    //     broken
    // }) : `${(broken ? index % 2 === 0 : index === 0) ? "M" : t.length === 7 ? "A" : t.length === 4 ? "Q" : "L"}${t.join(" ")}`
  }).join(' ') + (closed ? ' z' : '');
};
// 闭合线段[p1,p2,p3] p1->p2->p3->p1
exports._d = _d;
var closedLinePath = function closedLinePath(points) {
  return points.map(function (t, index) {
    return (index === 0 ? 'M' : 'L') + t.join(' ');
  }).concat(['z']).join(' ');
};
// 折线[p1,p2,p3,p4]  p1->p2  p3->p4
exports.closedLinePath = closedLinePath;
var brokenLinePath = function brokenLinePath(points) {
  return points.map(function (t, index) {
    return (index % 2 === 0 ? 'M' : 'L') + t.join(' ');
  }).join(' ');
};
// 线段，二维点[[p1,p2],[p3,p4]] => [[[x,y],[x,y]],[[x,y],[x,y]]]
exports.brokenLinePath = brokenLinePath;
var _d2 = function _d2(segments, closed, broken) {
  return segments.map(function (t) {
    // return `M${t[0].join(" ")} L${t[1].join(" ")}`
    return _d(t, {
      closed: closed,
      broken: broken
    });
  }).join(' ');
};
// 长方形
exports._d2 = _d2;
var rect = function rect(p, width, height) {
  return _d([p, [p[0] + width, p[1]], [p[0] + width, p[1] + height], [p[0], p[1] + height]]);
};
// 正方形
exports.rect = rect;
var squarePath = function squarePath(p, d) {
  var path = _d([p, [p[0] + d, p[1]], [p[0] + d, p[1] + d], [p[0], p[1] + d]], {
    closed: true,
    broken: false
  });
  return (0, _svg._path)(path);
};
// 圆形
exports.squarePath = squarePath;
var circle = function circle(p, r) {
  return ellipse2path(p[0], p[1], r, r);
};
exports.circle = circle;
var rect2path = function rect2path(x, y, width, height, rx, ry) {
  /* * rx 和 ry 的规则是： * 1. 如果其中一个设置为 0 则圆角不生效 * 2. 如果有一个没有设置则取值为另一个 */
  rx = rx || ry || 0;
  ry = ry || rx || 0; //非数值单位计算，如当宽度像100%则移除
  if (isNaN(x - y + width - height + rx - ry)) return;
  rx = rx > width / 2 ? width / 2 : rx;
  ry = ry > height / 2 ? height / 2 : ry; //如果其中一个设置为 0 则圆角不生效
  if (0 == rx || 0 == ry) {
    // var path =
    // 'M' + x + ' ' + y +
    // 'H' + (x + width) + 不推荐用绝对路径，相对路径节省代码量
    // 'V' + (y + height) +
    // 'H' + x +
    // 'z';
    return 'M' + x + ' ' + y + 'h' + width + 'v' + height + 'h' + -width + 'z';
  }
  return 'M' + x + ' ' + (y + ry) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + rx + ' ' + -ry + 'h' + (width - rx - rx) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + rx + ' ' + ry + 'v' + (height - ry - ry) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + -rx + ' ' + ry + 'h' + (rx + rx - width) + 'a' + rx + ' ' + ry + ' 0 0 1 ' + -rx + ' ' + -ry + 'z';
};
exports.rect2path = rect2path;
var ellipse2path = function ellipse2path(cx, cy, rx, ry) {
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(cx - cy + rx - ry)) return null;
  var path = 'M' + (cx - rx) + ' ' + cy + 'a' + rx + ' ' + ry + ' 0 1 0 ' + 2 * rx + ' 0' + 'a' + rx + ' ' + ry + ' 0 1 0 ' + -2 * rx + ' 0' + 'z';
  return path;
};
exports.ellipse2path = ellipse2path;
var line2path = function line2path(x1, y1, x2, y2) {
  //非数值单位计算，如当宽度像100%则移除
  if (isNaN(x1 - y1 + x2 - y2)) return;
  x1 = x1 || 0;
  y1 = y1 || 0;
  x2 = x2 || 0;
  y2 = y2 || 0;
  var path = 'M' + x1 + ' ' + y1 + 'L' + x2 + ' ' + y2;
  return path;
};
// polygon折线转换 points = [x1, y1, x2, y2, x3, y3 ...];
exports.line2path = line2path;
var polyline2path = function polyline2path(points) {
  var path = 'M' + points.slice(0, 2).join(' ') + 'L' + points.slice(2).join(' ');
  return path;
};
// polygon多边形转换 points = [x1, y1, x2, y2, x3, y3 ...];
exports.polyline2path = polyline2path;
var polygonColorpath = function polygonColorpath(points) {
  var path = 'M' + points.slice(0, 2).join(' ') + 'L' + points.slice(2).join(' ') + 'z';
  return path;
};
exports.polygonColorpath = polygonColorpath;