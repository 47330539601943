"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lSystemPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _math = require("../math");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var _iter = function _iter() {
  var axiom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var rules = arguments.length > 1 ? arguments[1] : undefined;
  var tmp,
    result = '';
  axiom.split('').forEach(function (t) {
    tmp = rules[t];
    if (tmp) {
      result += tmp;
    } else {
      result += t;
    }
  });
  return result;
};
var _grammar = function _grammar(options) {
  var _options$axiom = options.axiom,
    axiom = _options$axiom === void 0 ? 'F' : _options$axiom,
    _options$rules = options.rules,
    rules = _options$rules === void 0 ? {
      F: 'F[+F]'
    } : _options$rules,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 5 : _options$depth,
    _options$angle = options.angle,
    angle = _options$angle === void 0 ? 60 : _options$angle;
  var result = axiom;
  for (var i = 0; i < depth; i++) {
    result = _iter(result, rules);
  }
  return result;
};
var lSystemPoints = function lSystemPoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [0, 0] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 20 : _options$r,
    _options$angle2 = options.angle,
    angle = _options$angle2 === void 0 ? 60 : _options$angle2,
    _options$wriggle = options.wriggle,
    wriggle = _options$wriggle === void 0 ? 10 : _options$wriggle,
    _options$reduction = options.reduction,
    reduction = _options$reduction === void 0 ? 1.0 : _options$reduction;
  var _ra = function _ra() {
    return wriggle ? (0, _math._random)(-wriggle, wriggle) : 0;
  };
  var a = 0;
  var stack = [];
  var point = o;
  var radius = r;
  var result = [[point]];
  var _branch = function _branch() {
    result[result.length] = [point];
  };
  var _grow = function _grow() {
    result[result.length - 1].push(_toConsumableArray(point));
  };
  var _forword = function _forword() {
    point = (0, _math._polar)(point, radius, a - 90);
  };
  var _turnLeft = function _turnLeft() {
    a -= angle + _ra();
  };
  var _turnRight = function _turnRight() {
    a += angle + _ra();
  };
  var _move = function _move() {
    _forword();
    _grow();
  };
  var _move2 = function _move2() {
    _forword();
    _branch();
  };
  var _push = function _push() {
    stack.push({
      point: _toConsumableArray(point),
      a: a
    });
    radius *= reduction;
  };
  var _pop = function _pop() {
    ;
    var _stack$pop = stack.pop();
    point = _stack$pop.point;
    a = _stack$pop.a;
    _branch();
    radius /= reduction;
  };
  var actions = {
    F: _move,
    f: _move2,
    '+': _turnLeft,
    '-': _turnRight,
    '[': _push,
    ']': _pop
  };
  var grammer = _grammar(options);
  var grammers = grammer.replace(/[^F+-\[\]]/g, '').split('');
  grammers.forEach(function (t) {
    var act = actions[t];
    if (act) {
      act();
    }
  });
  return result;
};
exports.lSystemPoints = lSystemPoints;