"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._select = exports._option = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _dom = require("./dom");
var _option = function _option(value, label, selected) {
  return _dom.dom.option(value, {
    value: value,
    selected: selected ? true : undefined
  });
};
exports._option = _option;
var _select = function _select(val, options, events) {
  var children = options.options.map(function (t) {
    return _option(t.value, t.label, val === t.value);
  });
  delete options.label;
  var newOptions = Object.assign({
    class: 'select'
  }, options);
  var select = _dom.dom.select(children, newOptions, events);
  return select;
};
exports._select = _select;