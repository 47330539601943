"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextAnchor = void 0;
var TextAnchor;
exports.TextAnchor = TextAnchor;
(function (TextAnchor) {
  TextAnchor["Start"] = "start";
  TextAnchor["Middle"] = "middle";
  TextAnchor["End"] = "end";
})(TextAnchor || (exports.TextAnchor = TextAnchor = {}));