"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._mark = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.fill.js");
var _canvas = require("./canvas");
var _curve = require("./curve");
var _mark2 = require("@/algorithm/mark");
var _mark = function _mark(ctx, _ref, props) {
  var points = _ref.points,
    mark = _ref.mark;
  var mi = (0, _mark2.markInfo)(points, mark);
  var _mark$angle = mark.angle,
    angle = _mark$angle === void 0 ? true : _mark$angle,
    _mark$edge = mark.edge,
    edge = _mark$edge === void 0 ? true : _mark$edge,
    _mark$radius = mark.radius,
    radius = _mark$radius === void 0 ? false : _mark$radius,
    _mark$labels = mark.labels,
    labels = _mark$labels === void 0 ? false : _mark$labels,
    _mark$boundingRect = mark.boundingRect,
    boundingRect = _mark$boundingRect === void 0 ? false : _mark$boundingRect,
    boundingSize = mark.boundingSize;
  // 夹角
  if (angle) {
    var angleInfo = mi.angleInfo;
    ctx.save();
    ctx.beginPath();
    (0, _canvas._props)(ctx, Object.assign(Object.assign({}, props), {
      strokeDasharray: 0
    }));
    angleInfo.forEach(function (_ref2) {
      var points = _ref2.points,
        a = _ref2.a,
        ia = _ref2.ia,
        sweepFlag = _ref2.sweepFlag,
        o = _ref2.o,
        r = _ref2.r,
        label = _ref2.label,
        labelPos = _ref2.labelPos;
      (0, _curve._polyline)(ctx, {
        points: points
      });
      if (sweepFlag === 0) {
        // 逆时针  凹点
        ctx.arc(o[0], o[1], r, a - ia, a);
      } else {
        ctx.arc(o[0], o[1], r, a, a + ia);
      }
      // if (Math.abs(ia - Math.PI / 2) < 0.0001) { //Number.EPSILON
      //     // 直角
      // } else {
      //     ctx.arc(o[0], o[1], r, a, a + ia);
      // }
      (0, _canvas._text)(ctx, label, labelPos, Object.assign({
        fontSize: 10
      }, props));
    });
    ctx.stroke();
    ctx.fill();
    ctx.restore();
  }
  // 线段长度
  if (edge) {
    var edgeInfo = mi.edgeInfo;
    ctx.save();
    ctx.beginPath();
    (0, _canvas._props)(ctx, Object.assign({
      strokeDasharray: 4
    }, props));
    edgeInfo.forEach(function (_ref3) {
      var points = _ref3.points,
        label = _ref3.label,
        labelPos = _ref3.labelPos;
      (0, _curve._polyline)(ctx, {
        points: points
      });
      (0, _canvas._text)(ctx, label, labelPos, Object.assign({
        fontSize: 10
      }, props));
    });
    ctx.stroke();
    ctx.restore();
  }
  if (radius) {
    var radiusInfo = mi.radiusInfo;
    ctx.save();
    ctx.beginPath();
    (0, _canvas._props)(ctx, Object.assign({
      strokeDasharray: 4
    }, props));
    radiusInfo.forEach(function (_ref4) {
      var points = _ref4.points,
        label = _ref4.label,
        labelPos = _ref4.labelPos;
      (0, _curve._polyline)(ctx, {
        points: points
      });
      (0, _canvas._text)(ctx, label, labelPos, Object.assign({
        fontSize: 10
      }, props));
    });
    ctx.stroke();
    ctx.restore();
  }
  if (labels) {
    var labelsInfo = mi.labelsInfo;
    ctx.save();
    ctx.beginPath();
    labelsInfo.forEach(function (_ref5) {
      var label = _ref5.label,
        labelPos = _ref5.labelPos;
      (0, _canvas._text)(ctx, label, labelPos, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0
      }));
    });
    ctx.stroke();
    ctx.restore();
  }
  if (boundingSize) {
    var boundingSizeInfo = mi.boundingSizeInfo;
    ctx.save();
    ctx.beginPath();
    (0, _canvas._props)(ctx, Object.assign({
      strokeDasharray: 4
    }, props));
    boundingSizeInfo.forEach(function (_ref6) {
      var points = _ref6.points,
        label = _ref6.label,
        labelPos = _ref6.labelPos;
      (0, _curve._polyline)(ctx, {
        points: points
      });
      (0, _canvas._text)(ctx, label, labelPos, Object.assign({
        fontSize: 10
      }, props));
      // g[g.length] = _path(_polyline({ points }), { strokeDasharray: 4, ...props, fill: 'none' })
      // g[g.length] = _text({
      //     x: labelPos[0],
      //     y: labelPos[1],
      //     text: label
      // }, { fontSize: 10, ...props, strokeDasharray: 0, textAnchor: 'middle' })
    });

    ctx.stroke();
    ctx.restore();
  }
  if (boundingRect) {
    var boundingRectPoints = mi.boundingRectPoints;
    ctx.save();
    ctx.beginPath();
    (0, _canvas._props)(ctx, Object.assign(Object.assign({}, props), {
      strokeDasharray: 0,
      stroke: 'gray'
    }));
    (0, _curve._polyline)(ctx, {
      points: boundingRectPoints,
      loop: true
    });
    ctx.stroke();
    ctx.beginPath();
    boundingRectPoints.forEach(function (t) {
      (0, _canvas._circle)(ctx, t, 3, {
        fill: 'gray',
        stroke: 'gray'
      });
    });
    ctx.stroke();
    ctx.restore();
  }
};
exports._mark = _mark;