"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyToClipBoard = void 0;
var copyToClipBoard = function copyToClipBoard(value, callback) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value).then(function () {
      callback && callback();
    });
  } else if (document.execCommand) {
    var element = document.createElement('SPAN');
    element.textContent = value;
    document.body.appendChild(element);
    // if (document.selection) {
    //     var range = document.body.createTextRange();
    //     range.moveToElementText(element);
    //     range.select();
    // } else
    if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(element);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    document.execCommand('copy');
    element.remove();
    // element.remove ? element.remove() : element.removeNode(true);
    callback && callback();
  }
};
exports.copyToClipBoard = copyToClipBoard;