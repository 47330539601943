"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resizeRect = exports.resizePath = exports.resizeCircle = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.concat.js");
var _math = require("./math");
var _pathHelper = require("./pathHelper");
var _scale = function _scale(x1, x, scaleX) {
  return (0, _math.keepTwoDecimal)((x1 - x) * scaleX + x);
};
var resizePoint = function resizePoint(p, scale, basePoint) {
  var bx = basePoint.x,
    by = basePoint.y;
  var scaleX = scale.scaleX,
    scaleY = scale.scaleY;
  var x = p.x,
    y = p.y;
  var newX = _scale(x, bx, scaleX);
  var newY = _scale(y, by, scaleY);
  return {
    x: newX,
    y: newY
  };
};
var resizeRect = function resizeRect(t, scale, basePoint) {
  var scaleX = scale.scaleX,
    scaleY = scale.scaleY;
  if (t.isRect()) {
    var bbox = t.getBBox();
    var p = resizePoint(bbox, scale, basePoint);
    var newWidth = bbox.width * scaleX;
    var newHeight = bbox.height * scaleY;
    return Object.assign(Object.assign({}, p), {
      width: newWidth,
      height: newHeight
    });
  }
};
exports.resizeRect = resizeRect;
var resizeCircle = function resizeCircle(t, scale, basePoint) {
  // const { x, y, width, height } = bbox
  // const { cx, cy, r } = getCircleProps(x, y, x + width * scaleX, y + height * scaleY)
  if (t.isCircle()) {
    var bbox = t.getBBox();
    var _resizePoint = resizePoint(bbox, scale, basePoint),
      x = _resizePoint.x,
      y = _resizePoint.y;
    var scaleX = scale.scaleX,
      scaleY = scale.scaleY;
    var _getCentre = (0, _math.getCentre)({
        x: x,
        y: y,
        width: bbox.width * scaleX,
        height: bbox.height * scaleY
      }),
      cx = _getCentre.x,
      cy = _getCentre.y;
    return {
      cx: cx,
      cy: cy,
      r: t.getRadius() * Math.min(scaleX, scaleY)
    };
  }
};
/**
 * 路径缩放
 * @param d
 * @param scaleX
 * @param scaleY
 * @param basePoint
 * @returns
 */
exports.resizeCircle = resizeCircle;
var resizePath = function resizePath(d, scale, basePoint) {
  var scaleX = scale.scaleX,
    scaleY = scale.scaleY;
  var segments = (0, _pathHelper.parsePath)(d);
  var _ref = basePoint !== null && basePoint !== void 0 ? basePoint : segments[0],
    x = _ref.x,
    y = _ref.y;
  return segments.map(function (t) {
    var type = t.type;
    if (type === 'C') {
      return {
        type: type,
        x: (0, _math.keepTwoDecimal)((t.x - x) * scaleX + x),
        x1: (0, _math.keepTwoDecimal)((t.x1 - x) * scaleX + x),
        x2: (0, _math.keepTwoDecimal)((t.x2 - x) * scaleX + x),
        y: (0, _math.keepTwoDecimal)((t.y - y) * scaleY + y),
        y1: (0, _math.keepTwoDecimal)((t.y1 - y) * scaleY + y),
        y2: (0, _math.keepTwoDecimal)((t.y2 - y) * scaleY + y)
      };
    }
    return {
      type: type,
      x: _scale(t.x, x, scaleX),
      y: _scale(t.y, y, scaleY)
    };
  }).map(function (t) {
    if (t.type === 'C') {
      return "".concat(t.type, " ").concat(t.x1, " ").concat(t.y1, " ").concat(t.x2, " ").concat(t.y2, " ").concat(t.x, " ").concat(t.y);
    }
    return "".concat(t.type, " ").concat(t.x, " ").concat(t.y);
  }).join(' ');
};
exports.resizePath = resizePath;