"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._triangle = void 0;
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.typed-array.float32-array.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-string.js");
var _webgl = require("./webgl");
var _color = require("../color");
var _triangle2 = _interopRequireDefault(require("./glsl/triangle.frag"));
var _triangle3 = _interopRequireDefault(require("./glsl/triangle.vert"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _triangle = function _triangle(gl) {
  // 初始化着色器
  var program = (0, _webgl.defineProgram)(gl, _triangle3.default, _triangle2.default);
  // 获取着色器程序中变量的指针位置
  var a_Position = gl.getAttribLocation(program, 'a_Position');
  var u_Color = gl.getUniformLocation(program, 'u_Color');
  // 定义三角形的三个顶点
  var positions = [0, 0.5, -0.5, -0.5, 0.5, -0.5 // 右顶点
  ];
  // 创建缓冲区
  var buffer = gl.createBuffer();
  // 绑定缓冲区并指定缓冲区的类型
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  // 往缓冲区中写入数据
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
  // 将属性绑定到缓冲区
  gl.enableVertexAttribArray(a_Position);
  // 如何读取缓冲区数据
  // 指定要修改的顶点属性的索引（允许哪个属性读取当前缓冲区的数据） - a_Position
  // 指定每个顶点属性的组成数量（一次读取几个数据） - 2
  // 指定数组中每个元素的数据类型 - gl.FLOAT（32 位 IEEE 标准的浮点数，占用 4 个字节）
  // 当转换为浮点数时是否应该将整数数值归一化到特定的范围 - false（对于类型 gl.FLOAT 和 gl.HALF_FLOAT，此参数无效）
  // 步长（即：每个顶点所包含数据的字节数）0 表示一个属性的数据是连续存放的
  // 偏移量（指定顶点属性数组中第一部分的字节偏移量）（在每个步长的数据里，目标属性需要偏移多少字节开始读取；必须是类型的字节长度的倍数）0 * 4 = 0
  gl.vertexAttribPointer(a_Position, 2, gl.FLOAT, false, 0, 0);
  // 随机颜色
  var _randomColor = (0, _color.randomColor)(),
    r = _randomColor.r,
    g = _randomColor.g,
    b = _randomColor.b,
    a = _randomColor.a;
  // 向片元着色器传递颜色信息
  gl.uniform4f(u_Color, r, g, b, a);
  // 绘制三角形
  // 指定绘制图元的方式 - gl.TRIANGLES（三角形）
  // 指定从哪个点开始绘制 - 0
  // 指定绘制需要使用到多少个点 - 3
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 3);
  return gl;
};
exports._triangle = _triangle;