"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isVisible = exports.isCurve = exports._mirrorPath = exports._mirrorColor = exports._mergeProps = exports._merge = exports._colorProps = exports._borderPoints = void 0;
require("core-js/modules/es.array.fill.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _utils = require("../utils");
var _mirror2 = require("@/math/mirror");
var _math = require("@/math");
var _color = require("@/color");
var isVisible = function isVisible(obj) {
  if ((0, _utils.isObject)(obj)) {
    if ((0, _utils._has)(obj, 'visible')) {
      return obj.visible;
    } else if ((0, _utils._has)(obj, 'type')) {
      return obj.type !== 'none';
    } else if ((0, _utils._has)(obj, 'pattern')) {
      return obj.pattern !== 'none';
    } else {
      return true;
    }
    // return obj && (!_has(obj, 'visible') || obj.visible)
  } else if ((0, _utils._type)(obj) === 'string') {
    return obj !== 'none';
  }
  return obj;
};
// 判断曲线
exports.isVisible = isVisible;
var isCurve = function isCurve(curve) {
  return curve && curve !== 'none';
};
// 外圈顶点
exports.isCurve = isCurve;
var _borderPoints = function _borderPoints(matrix, mirror) {
  var index = (0, _utils.isObject)(mirror) ? mirror.borderIndex : 0;
  var len = matrix.length;
  return matrix[index >= len || index <= 0 ? len - 1 : index];
};
// 镜像点
exports._borderPoints = _borderPoints;
var _mirrorPoint = function _mirrorPoint(point, index, mirrorPoints) {
  return mirrorPoints ? mirrorPoints[index % mirrorPoints.length] : point;
};
var _mirrorColor = function _mirrorColor(mirror) {
  var colorObj = null;
  if (mirror && mirror.color) {
    var color = mirror.color,
      alpha = mirror.alpha,
      fill = mirror.fill,
      fillRule = mirror.fillRule;
    colorObj = {
      type: color,
      color: color,
      alpha: alpha,
      fill: fill,
      fillRule: fillRule
    };
  }
  return colorObj;
};
exports._mirrorColor = _mirrorColor;
var _colorProps = function _colorProps(color, n) {
  var alpha = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  if (!isVisible(color)) {
    return function () {
      return {};
    };
  }
  // 兼容对象
  var type,
    fill = false,
    fillRule;
  if (typeof color === 'string') {
    type = color;
  } else if ((0, _utils.isObject)(color)) {
    type = color.type;
    alpha = color.alpha;
    fill = !!((0, _utils._type)(color.fill) === 'string' ? color.fill !== 'none' : color.fill);
    fillRule = color.fillRule;
  }
  var colors = (0, _color._colors)(type, n, alpha);
  return function (index) {
    var color = colors[index % n];
    var stroke = color;
    var props = {
      stroke: stroke
    };
    if (fill) {
      props.fill = color;
      if (fillRule) {
        props.fillRule = fillRule;
      }
    }
    return props;
  };
};
// 合并属性 ，
// 第一个为 none，后续不能覆盖
exports._colorProps = _colorProps;
var _mergeProps = function _mergeProps(a, b) {
  var props = {};
  Object.keys(a).forEach(function (t) {
    if ((0, _utils._has)(a, t)) {
      if (a[t] !== 'none') {
        props[t] = b[t] || a[t];
      }
    } else if ((0, _utils._has)(b, t)) {
      if (b[t] !== 'none') {
        props[t] = b[t];
      }
    }
  });
  return props;
};
exports._mergeProps = _mergeProps;
var _merge = function _merge(a, b) {
  return Object.assign(Object.assign({}, a), b);
};
// 镜像  被 algorithm/mirror代替
exports._merge = _merge;
var _mirrorPath = function _mirrorPath(pathFn, options, props) {
  var points = options.points,
    mirror = options.mirror,
    mirrorPoints = options.mirrorPoints,
    _options$alpha = options.alpha,
    alpha = _options$alpha === void 0 ? 1 : _options$alpha;
  var color = mirror.color || options.color;
  var n = points.length;
  var _props = _colorProps(color, n, alpha);
  var fn = function fn(ps, index) {
    var o = (0, _math._o)(ps);
    var r = (0, _math._dis)(o, ps[0]);
    return pathFn(Object.assign(Object.assign({}, options), {
      points: ps,
      o: o,
      r: r,
      mirror: null
    }), _mergeProps(props, _props(index)));
  };
  if ((0, _math.isPointMatrix)(points)) {
    var _mirrorPoints = _borderPoints(points, mirror);
    return points.map(function (t, i) {
      return _mirrorPath(pathFn, Object.assign(Object.assign({}, options), {
        points: t,
        mirrorPoints: _mirrorPoints
      }), _merge(props, _props(i)));
    });
  }
  var type, radio;
  if ((0, _utils.isObject)(mirror)) {
    type = mirror.type;
    radio = mirror.radio || 1;
  } else {
    type = mirror;
    radio = 1;
  }
  switch (type) {
    case 'none':
      return [];
    case 'vertex':
      {
        return points.map(function (t, i) {
          var mp = _mirrorPoint(t, i, mirrorPoints);
          var ps = (0, _mirror2._mirror)(points, mp, radio);
          return fn(ps, i);
        });
      }
    case 'edge':
      {
        var ms = (0, _math._mid)(points);
        var mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
        return ms.map(function (t, i) {
          var mp = _mirrorPoint(t, i, mms);
          var ps = (0, _mirror2._mirror)(points, mp, radio);
          return fn(ps, i);
        });
      }
    case 'edgeMid':
      {
        var _ms = (0, _math._mid)(points);
        var _mms = mirrorPoints ? (0, _math._mid)(mirrorPoints) : null;
        return _ms.map(function (t, i) {
          var mp = _mirrorPoint(t, i, _mms);
          var ps = (0, _mirror2._mirror)(_ms, mp, radio);
          return fn(ps, i);
        });
      }
    case 'radiusMid':
      {
        var o = (0, _math._o)(points);
        return points.map(function (t, i) {
          var mp = _mirrorPoint(t, i, mirrorPoints);
          var rm = (0, _math._mid)(mp, o);
          var ps = (0, _mirror2._mirror)(points, rm, radio);
          return fn(ps, i);
        });
      }
  }
};
exports._mirrorPath = _mirrorPath;