"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._vertex = void 0;
require("core-js/modules/es.array.map.js");
var _svg = require("../core/svg");
var _circle2 = require("../core/circle");
var _common = require("@/common");
var _drag2 = require("../core/drag");
var _pathMode2 = require("../core/pathMode");
var _dom = require("@/ui/core/dom");
var _math = require("@/math");
var _vertex = function _vertex(_ref, props) {
  var points = _ref.points,
    vertex = _ref.vertex,
    curve = _ref.curve,
    pathMode = _ref.pathMode;
  var one = vertex.one,
    r = vertex.r,
    draggable = vertex.draggable,
    color = vertex.color;
  if (one) {
    return (0, _svg._path)((0, _circle2._circle)({
      o: points[0],
      r: r
    }), (0, _common._merge)(props, vertex));
  }
  if (draggable) {
    // 可拖动
    var updateEdgePath = function updateEdgePath(points, container) {
      var edge = (0, _dom._query)('path[name="edge"]', container);
      var path = (0, _pathMode2._pathMode)({
        points: points,
        curve: curve,
        pathMode: pathMode
      });
      (0, _dom._attr)(edge, {
        d: path
      });
    };
    var updateCentrePath = function updateCentrePath(points, container) {
      var centre = (0, _dom._query)('path[name="centre"]', container);
      var o = (0, _math._o)(points);
      var path = (0, _pathMode2._points)({
        points: [o]
      }, {
        r: r
      });
      (0, _dom._attr)(centre, {
        d: path
      });
    };
    var updateRadiusPath = function updateRadiusPath(points, container) {
      var radius = (0, _dom._query)('path[name="radius"]', container);
      var path = (0, _pathMode2._ray)({
        points: points
      });
      (0, _dom._attr)(radius, {
        d: path
      });
    };
    return (0, _drag2._drag)(points, function (_ref2) {
      var points = _ref2.points,
        container = _ref2.container;
      updateEdgePath(points, container);
      updateCentrePath(points, container);
      updateRadiusPath(points, container);
    }, {
      color: color
    });
    // return _drag(points, _pathMode, {
    //     curve, pathMode,
    //     name: 'vertex'
    // })
  }

  if ((0, _common.isVisible)(color)) {
    // 彩色
    var n = points.length;
    var _props = (0, _common._colorProps)({
      type: color,
      fill: color
    }, n);
    return points.map(function (t, i) {
      return (0, _svg._circle)({
        o: t,
        r: r
      }, (0, _common._merge)(vertex, _props(i)));
    });
  }
  // 单色
  return (0, _svg._path)((0, _pathMode2._points)({
    points: points
  }, {
    r: r
  }), (0, _common._merge)(props, vertex));
};
exports._vertex = _vertex;