"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripPoints = exports.stripLoopPoints = exports.stripFanPoints = exports.linkSegments = exports.linkPoints = exports.linkCrossPoints = exports.edgeExtensionSegs = exports.edgeExtensionPoints = exports.edgeExtensionCrossPoints = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _arrayMix = require("../math/arrayMix");
var _math = require("../math");
var _segCross = require("./segCross");
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 多边形连接线
var linkPoints = function linkPoints(points) {
  var lps = [];
  for (var i = 0; i < points.length - 1; i++) {
    var p = points[i];
    lps = [].concat(_toConsumableArray(lps), _toConsumableArray((0, _arrayMix.rayMix)(p, points.slice(i + 1))));
  }
  return lps;
};
// 不包含边连线段
exports.linkPoints = linkPoints;
var linkSegments = function linkSegments(points) {
  var segs = [];
  for (var i = 0, len = points.length; i < len - 2; i++) {
    var p = points[i];
    segs.push.apply(segs, _toConsumableArray((0, _arrayMix.raySegs)(p, points.slice(i + 2, i === 0 ? len - 1 : len))));
  }
  return segs;
};
// 多边形连接线交叉点
exports.linkSegments = linkSegments;
var linkCrossPoints = function linkCrossPoints(points) {
  var segs = linkSegments(points);
  return (0, _segCross.segCrossPoints)(segs);
};
// 线段延长线
exports.linkCrossPoints = linkCrossPoints;
var lineExtension = function lineExtension(p1, p2) {
  var iterNum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var p = (0, _math._move)(p2, p1, p2);
  while (iterNum-- > 0) {
    p = (0, _math._move)(p, p1, p);
  }
  return [p2, p];
};
var edgeExtensionSegs = function edgeExtensionSegs(points, iterNum) {
  var len = points.length;
  var segs1 = [];
  var segs2 = [];
  points.forEach(function (t, i) {
    var next = points[(i + 1) % len];
    var seg1 = lineExtension(t, next, iterNum);
    var seg2 = lineExtension(next, t, iterNum);
    segs1.push(seg1);
    segs2.push(seg2);
  });
  var n = segs1.length;
  var segs = [];
  segs1.forEach(function (t, i) {
    segs.push(t);
    segs.push(segs2[(i + 2) % n]);
  });
  return segs;
};
// 延长线
exports.edgeExtensionSegs = edgeExtensionSegs;
var edgeExtensionPoints = function edgeExtensionPoints(points, iterNum) {
  return (0, _math.plainMatrix)(edgeExtensionSegs(points, iterNum));
};
// 延长线交叉点
exports.edgeExtensionPoints = edgeExtensionPoints;
var edgeExtensionCrossPoints = function edgeExtensionCrossPoints(points, iterNum) {
  var segs = edgeExtensionSegs(points, iterNum);
  return (0, _segCross.neighbourSegCrossPoints)(segs);
};
// 带状
exports.edgeExtensionCrossPoints = edgeExtensionCrossPoints;
var stripPoints = function stripPoints(points, n) {
  var len = points.length;
  var matrix = [];
  for (var i = 0; i <= len - n; i++) {
    var stack = [];
    for (var j = 0; j < n; j++) {
      var p = points[i + j];
      stack.push(p);
    }
    matrix.push(stack);
  }
  return matrix;
};
exports.stripPoints = stripPoints;
var stripLoopPoints = function stripLoopPoints(points, n) {
  var len = points.length;
  var matrix = [];
  for (var i = 0; i < len; i++) {
    var stack = [];
    for (var j = 0; j < n; j++) {
      var p = points[(i + j) % len];
      stack.push(p);
    }
    matrix.push(stack);
  }
  return matrix;
};
exports.stripLoopPoints = stripLoopPoints;
var stripFanPoints = function stripFanPoints(points, n) {
  var _points = _toArray(points),
    o = _points[0],
    rest = _points.slice(1);
  var len = rest.length;
  var matrix = [];
  for (var i = 0; i <= len - n; i++) {
    var stack = [o];
    for (var j = 0; j < n; j++) {
      var p = rest[(i + j) % len];
      stack.push(p);
    }
    matrix.push(stack);
  }
  return matrix;
};
exports.stripFanPoints = stripFanPoints;