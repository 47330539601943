"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderShape = exports.renderPath = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var _factory = require("./factory");
var _polygon = require("./polygon");
var _pathShape = require("./pathShape");
var _common = require("@/common");
var renderPath = function renderPath(points, options, props) {
  return (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
    points: points
  }), (0, _common._merge)({
    stroke: 'blue',
    fill: 'none'
  }, props));
};
exports.renderPath = renderPath;
var renderUnregistry = function renderUnregistry(options) {
  var shape = options.shape,
    o = options.o;
  // 提示未注册shape
  var label = "\u672A\u6CE8\u518C".concat(shape);
  var labels = (0, _polygon._labels)({
    points: [o],
    label: label
  }, {}, {
    'font-size': '20px'
  });
  return labels;
};
var renderShape = function renderShape(options, props) {
  var name = options.name,
    shape = options.shape,
    pattern = options.pattern,
    points = options.points;
  // 填充图案
  if (pattern) {
    props = Object.assign(Object.assign({}, props), {
      fill: "url(#".concat(name, "_").concat(pattern, ")")
    });
  }
  var registry = (0, _factory.factory)(shape, options, props);
  if (registry) {
    return registry;
  } else {
    if (points) {
      //         if (isCurve(shape)) {
      //             // 曲线函数
      //             points = _curve(points, options)
      //         }
      return renderPath(points, options, props);
    } else {
      return renderUnregistry(options);
    }
  }
};
exports.renderShape = renderShape;