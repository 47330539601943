"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.triArea = void 0;
/**
三角形面积公式
方法1：行列式
设三角形的面积为S， 则S = (1/2)*(下面行列式)
|x1 y1 1|
|x2 y2 1|
|x3 y3 1|
即 S=(1/2)*(x1*y2+x2*y3+x3*y1-x1*y3-x2*y1-x3*y2) = (1 / 2) * ((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1));
两向量叉乘==两向量构成的平行四边形(以两向量为邻边)的面积
 * @param a
 * @param b
 * @param c
 * @returns
 */
var triArea = function triArea(a, b, c) {
  return ((a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])) / 2;
};
/**
 * 方法2：海伦公式
海伦公式 利用三角形的三条边的边长直接求三角形面积的公式 S=√p(p-a)(p-b)(p-c) ,p=(a+b+c)/2
S = sqrt (p * (p - a)(p - b)(p - c))  其中p = (a + b + c) / 2, abc为三角形三边长

 */
exports.triArea = triArea;