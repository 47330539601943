"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._mark = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.assign.js");
var _curve = require("./curve");
var _svg = require("./svg");
var _mark2 = require("@/algorithm/mark");
var _drag2 = require("./drag");
var _text2 = require("@/types/text");
var _mark = function _mark(_ref, props) {
  var points = _ref.points,
    mark = _ref.mark;
  var mi = (0, _mark2.markInfo)(points, mark);
  var _mark$angle = mark.angle,
    angle = _mark$angle === void 0 ? true : _mark$angle,
    _mark$edge = mark.edge,
    edge = _mark$edge === void 0 ? true : _mark$edge,
    _mark$radius = mark.radius,
    radius = _mark$radius === void 0 ? false : _mark$radius,
    _mark$labels = mark.labels,
    labels = _mark$labels === void 0 ? false : _mark$labels,
    _mark$boundingSize = mark.boundingSize,
    boundingSize = _mark$boundingSize === void 0 ? false : _mark$boundingSize,
    _mark$boundingRect = mark.boundingRect,
    boundingRect = _mark$boundingRect === void 0 ? false : _mark$boundingRect;
  var g = [];
  if (angle) {
    var angleInfo = mi.angleInfo;
    angleInfo.forEach(function (_ref2) {
      var points = _ref2.points,
        a = _ref2.a,
        ia = _ref2.ia,
        sweepFlag = _ref2.sweepFlag,
        o = _ref2.o,
        r = _ref2.r,
        label = _ref2.label,
        labelPos = _ref2.labelPos;
      var path = (0, _curve._polyline)({
        points: points
      });
      // 凹多边形（Concave Polygon
      // 优角（Reflexive Angle）   sweepFlag==1  顺时针
      path += (0, _curve._arcTo)({
        r: r,
        to: points[0],
        sweepFlag: sweepFlag === 1
      });
      g[g.length] = (0, _svg._path)(path, Object.assign(Object.assign({}, props), {
        strokeDasharray: 0
      }));
      g[g.length] = (0, _svg._text)({
        x: labelPos[0],
        y: labelPos[1],
        text: label
      }, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0,
        textAnchor: _text2.TextAnchor.Middle
      }));
    });
  }
  if (edge) {
    var edgeInfo = mi.edgeInfo;
    edgeInfo.forEach(function (_ref3) {
      var points = _ref3.points,
        label = _ref3.label,
        labelPos = _ref3.labelPos;
      g[g.length] = (0, _svg._path)((0, _curve._polyline)({
        points: points
      }), Object.assign(Object.assign({
        strokeDasharray: 4
      }, props), {
        fill: 'none'
      }));
      g[g.length] = (0, _svg._text)({
        x: labelPos[0],
        y: labelPos[1],
        text: label
      }, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0,
        textAnchor: _text2.TextAnchor.Middle
      }));
    });
  }
  if (radius) {
    var radiusInfo = mi.radiusInfo;
    radiusInfo.forEach(function (_ref4) {
      var points = _ref4.points,
        label = _ref4.label,
        labelPos = _ref4.labelPos;
      g[g.length] = (0, _svg._path)((0, _curve._polyline)({
        points: points
      }), Object.assign(Object.assign({
        strokeDasharray: 4
      }, props), {
        fill: 'none'
      }));
      g[g.length] = (0, _svg._text)({
        x: labelPos[0],
        y: labelPos[1],
        text: label
      }, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0,
        textAnchor: _text2.TextAnchor.Middle
      }));
    });
  }
  if (labels) {
    var labelsInfo = mi.labelsInfo;
    labelsInfo.forEach(function (_ref5) {
      var label = _ref5.label,
        labelPos = _ref5.labelPos;
      g[g.length] = (0, _svg._text)({
        x: labelPos[0],
        y: labelPos[1],
        text: label
      }, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0,
        textAnchor: _text2.TextAnchor.Middle
      }));
    });
  }
  if (boundingSize) {
    var boundingSizeInfo = mi.boundingSizeInfo;
    boundingSizeInfo.forEach(function (_ref6) {
      var points = _ref6.points,
        label = _ref6.label,
        labelPos = _ref6.labelPos;
      g[g.length] = (0, _svg._path)((0, _curve._polyline)({
        points: points
      }), Object.assign(Object.assign({
        strokeDasharray: 4
      }, props), {
        fill: 'none'
      }));
      g[g.length] = (0, _svg._text)({
        x: labelPos[0],
        y: labelPos[1],
        text: label
      }, Object.assign(Object.assign({
        fontSize: 10
      }, props), {
        strokeDasharray: 0,
        textAnchor: _text2.TextAnchor.Middle
      }));
    });
  }
  if (boundingRect) {
    var boundingRectPoints = mi.boundingRectPoints;
    g[g.length] = (0, _svg._path)((0, _curve._polyline)({
      points: boundingRectPoints,
      loop: true
    }), Object.assign(Object.assign({}, props), {
      stroke: 'gray',
      strokeDasharray: 0,
      fill: 'none',
      name: 'boundingRect'
    }));
    // 拖动
    g.push((0, _drag2._drag)(boundingRectPoints, _curve._polyline));
  }
  return (0, _svg._g)(g, {
    name: 'mark'
  });
};
exports._mark = _mark;