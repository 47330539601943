"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSvgPathShape = exports.createPathFn = exports.colorPath = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.fill.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _svg = require("../core/svg");
var _index = require("@/math/index");
var _link2 = require("@/algorithm/link");
var _index2 = require("@/color/index");
var _common = require("@/common");
var _motion2 = require("../core/motion");
var _pathMode = require("../core/pathMode");
var _mark2 = require("../core/mark");
var _fill2 = require("./fill");
var _vertex2 = require("./vertex");
var _centre2 = require("./centre");
var _radius2 = require("./radius");
var _edge2 = require("./edge");
var _labels2 = require("./labels");
var _edgeExtension2 = require("./edgeExtension");
var _excircle2 = require("./excircle");
var _incircle2 = require("./incircle");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
/**
 * 生成svg路径图形
 * @param options
 * @param props
 * @returns
 */
var createSvgPathShape = function createSvgPathShape(options, props) {
  var name = options.name,
    points = options.points,
    pathMode = options.pathMode,
    curve = options.curve,
    mirror = options.mirror,
    vertex = options.vertex,
    centre = options.centre,
    excircle = options.excircle,
    incircle = options.incircle,
    radius = options.radius,
    labels = options.labels,
    linkCross = options.linkCross,
    links = options.links,
    motion = options.motion,
    edgeExtension = options.edgeExtension,
    edge = options.edge,
    mark = options.mark,
    fill = options.fill;
  var gs = [];
  // 填充
  if ((0, _common.isVisible)(fill)) {
    gs[gs.length] = (0, _fill2._fill)({
      points: points,
      fill: fill,
      curve: curve
    }, (0, _common._merge)(props, fill));
  }
  // 边
  if (edge) {
    if ((0, _common.isVisible)(edge)) {
      gs[gs.length] = (0, _edge2._edge)({
        pathMode: pathMode,
        points: points,
        curve: curve
      }, (0, _common._merge)(props, edge));
    }
  } else {
    gs[gs.length] = (0, _edge2._edge)({
      pathMode: pathMode,
      points: points,
      curve: curve
    }, props);
  }
  // 边延长线
  if ((0, _common.isVisible)(edgeExtension)) {
    gs[gs.length] = (0, _edgeExtension2._edgeExtension)({
      points: points,
      edgeExtension: edgeExtension
    }, props);
  }
  // 半径
  if ((0, _common.isVisible)(radius)) {
    gs[gs.length] = (0, _radius2._radius)({
      points: points,
      radius: radius
    }, props);
  }
  // 顶点
  if ((0, _common.isVisible)(vertex)) {
    gs[gs.length] = (0, _vertex2._vertex)({
      points: points,
      vertex: vertex,
      pathMode: pathMode,
      curve: curve
    }, props);
  }
  // 中点
  if ((0, _common.isVisible)(centre)) {
    gs[gs.length] = (0, _centre2._centre)({
      points: points,
      centre: centre,
      pathMode: pathMode,
      curve: curve
    }, props);
  }
  // 外切圆
  if ((0, _common.isVisible)(excircle)) {
    gs[gs.length] = (0, _excircle2._excircle)({
      points: points,
      excircle: excircle
    }, props);
  }
  // 内切圆
  if ((0, _common.isVisible)(incircle)) {
    gs[gs.length] = (0, _incircle2._incircle)({
      points: points,
      incircle: incircle
    }, props);
  }
  // 标注
  if ((0, _common.isVisible)(labels)) {
    // 与边的颜色一致
    if (edge && labels.sameColorAsEdge) {
      labels.stroke = edge.stroke;
    }
    gs[gs.length] = (0, _labels2._labels)({
      points: points
    }, labels, (0, _common._merge)(props, labels));
  }
  // 连线交叉点
  if ((0, _common.isVisible)(linkCross)) {
    if (points.length > 50) {
      // '截取了50个节点，linkCross'
      points.splice(50);
    }
    var lcps = (0, _link2.linkCrossPoints)(points);
    gs[gs.length] = (0, _svg._path)((0, _pathMode._points)({
      points: lcps
    }), (0, _common._merge)(props, linkCross));
  }
  // 连线 与 交叉点
  if ((0, _common.isVisible)(links)) {
    gs[gs.length] = (0, _svg._path)((0, _pathMode._link)({
      points: points
    }), links);
    if (links.crossPoints) {
      var _lcps = (0, _link2.linkCrossPoints)(points);
      gs[gs.length] = (0, _svg._path)((0, _pathMode._points)({
        points: _lcps
      }), (0, _common._merge)({
        fill: 'red'
      }, links));
    }
  }
  // 标注
  if ((0, _common.isVisible)(mark)) {
    gs[gs.length] = (0, _mark2._mark)({
      points: points,
      mark: mark
    }, Object.assign(Object.assign({}, props), mark));
  }
  // 镜像
  if ((0, _common.isVisible)(mirror)) {
    gs.push.apply(gs, _toConsumableArray((0, _common._mirrorPath)(createSvgPathShape, options, props)));
  }
  // motion
  if ((0, _common.isVisible)(motion)) {
    gs[gs.length] = (0, _motion2._motion)(Object.assign(Object.assign({}, motion), {
      points: points
    }));
  }
  return (0, _svg._g)(gs, {
    name: name
  });
};
exports.createSvgPathShape = createSvgPathShape;
var createPathFn = function createPathFn(genPoints) {
  return function (options, props) {
    var points = typeof genPoints === 'function' ? genPoints(options) : genPoints;
    return createSvgPathShape(Object.assign(Object.assign({}, options), {
      points: points
    }), Object.assign({
      stroke: 'blue',
      fill: 'none'
    }, props));
  };
};
// 彩色
exports.createPathFn = createPathFn;
var colorPath = function colorPath(matrix, options, props) {
  var color = options.color,
    alpha = options.alpha,
    _options$oneLine = options.oneLine,
    oneLine = _options$oneLine === void 0 ? false : _options$oneLine;
  var _fn = function _fn(colors) {
    var children = matrix.map(function (t, i) {
      var newProps = Object.assign(Object.assign({}, props), {
        fill: 'none',
        stroke: Array.isArray(colors) ? colors[i] : colors
      });
      return createSvgPathShape(Object.assign(Object.assign({}, options), {
        points: t,
        motion: null
      }), newProps);
    });
    return (0, _svg._g)(children);
  };
  if (oneLine) {
    // 单色 一笔画
    var points = (0, _index.plainMatrix)(matrix);
    return createSvgPathShape(Object.assign(Object.assign({}, options), {
      points: points
    }), Object.assign({
      stroke: 'red',
      fill: 'none'
    }, props));
  } else if (color) {
    if (color === 'none') {
      return _fn('red');
    } else {
      // 彩色
      var colors = (0, _index2._colors)(color, matrix.length, alpha);
      return _fn(colors);
    }
  }
};
exports.colorPath = colorPath;