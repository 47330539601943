"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._rose = void 0;
var _rose2 = require("@/algorithm/rose");
var _helper = require("../helper");
var _rose = function _rose(options, props) {
  var matrix = (0, _rose2.rosePoints)(options);
  return (0, _helper.makeSvgElementByMatrix)(matrix, options, props);
};
exports._rose = _rose;