"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSvgText = exports.createSvgRect = exports.createSvgPath = exports.createSvgLine = exports.createSvgGroup = exports.createSvgEllipse = exports.createSvgElement = exports.createSvgCircle = void 0;
var _namespace = require("../namespace");
var createSvgElement = function createSvgElement(tagName) {
  return document.createElementNS(_namespace.Namespace.SVG, tagName);
};
exports.createSvgElement = createSvgElement;
var createSvgGroup = function createSvgGroup() {
  return createSvgElement('g');
};
exports.createSvgGroup = createSvgGroup;
var createSvgPath = function createSvgPath() {
  return createSvgElement('path');
};
exports.createSvgPath = createSvgPath;
var createSvgLine = function createSvgLine() {
  return createSvgElement('line');
};
exports.createSvgLine = createSvgLine;
var createSvgRect = function createSvgRect() {
  return createSvgElement('rect');
};
exports.createSvgRect = createSvgRect;
var createSvgCircle = function createSvgCircle() {
  return createSvgElement('circle');
};
exports.createSvgCircle = createSvgCircle;
var createSvgEllipse = function createSvgEllipse() {
  return createSvgElement('ellipse');
};
exports.createSvgEllipse = createSvgEllipse;
var createSvgText = function createSvgText() {
  return createSvgElement('text');
};
exports.createSvgText = createSvgText;