"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Fractal = void 0;
var Fractal;
exports.Fractal = Fractal;
(function (Fractal) {
  Fractal["None"] = "none";
  Fractal["EdgeFractal"] = "edgeFractal";
  Fractal["VertexFractal"] = "vertexFractal";
  Fractal["VertexMirror"] = "vertexMirror";
  Fractal["EdgeMirror"] = "edgeMirror";
  Fractal["EdgeMid"] = "edgeMid";
  Fractal["Yanghui"] = "yanghui";
  Fractal["Gougu"] = "gougu";
})(Fractal || (exports.Fractal = Fractal = {}));