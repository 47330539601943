"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRouteQuery = exports.updateRouteFragment = exports.updateRoute = exports.resetRoute = exports.queryToObject = exports.goPrevPage = exports.goPage = exports.goNextPage = exports.getRoute = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _store = require("@/store");
var _utils = require("../../utils");
var objectToQuery = function objectToQuery(shapeObj) {
  var params = Object.keys(shapeObj).filter(function (t) {
    return !['shape'].includes(t) && ['string', 'number'].includes((0, _utils._type)(shapeObj[t]));
  }).map(function (t) {
    return "".concat(t, "=").concat(shapeObj[t]);
  }).join('&');
  return params;
};
var pushState = function pushState(state, title, url) {
  try {
    history.pushState(state, title, url);
  } catch (e) {
    console.log(e);
  }
};
// scheme://host:port/path?query#fragment
var updateRoute = function updateRoute(t) {
  if (t) {
    var content = t.content,
      title = t.title,
      name = t.name;
    var url = location.origin + '/#' + name;
    if (content) {
      // return
      var shapes = content.shapes;
      // 参数
      url = '';
      if (Array.isArray(shapes) && shapes.length >= 1) {
        var shapeObj = shapes[0];
        var query = objectToQuery(shapeObj);
        url += "?".concat(query);
      }
      url += '#' + name;
    }
    // 路由
    var state = {};
    pushState(state, title, url);
  }
};
exports.updateRoute = updateRoute;
var updateRouteFragment = function updateRouteFragment(t) {
  if (t) {
    var state = {};
    var title = t.title,
      name = t.name;
    var url = '';
    url += '#' + name;
    pushState(state, title, url);
  }
};
exports.updateRouteFragment = updateRouteFragment;
var updateRouteQuery = function updateRouteQuery(t) {
  if (t) {
    var state = {};
    var content = t.content,
      title = t.title;
    var shapes = content.shapes;
    var url = '';
    if (shapes.length >= 1) {
      var shapeObj = shapes[0];
      var query = objectToQuery(shapeObj);
      url += "?".concat(query);
    }
    // url += '#' + name
    pushState(state, title, url);
  }
};
exports.updateRouteQuery = updateRouteQuery;
var getRoute = function getRoute() {
  var hash = location.hash;
  if (hash.indexOf('#') === 0) {
    return hash.substring(1);
  }
  return '';
};
exports.getRoute = getRoute;
var resetRoute = function resetRoute() {
  var _location = location,
    hash = _location.hash,
    origin = _location.origin,
    pathname = _location.pathname;
  var url = "".concat(origin).concat(pathname).concat(hash);
  var state = {};
  var title = '';
  history.pushState(state, title, url);
  location.reload();
};
exports.resetRoute = resetRoute;
var queryToObject = function queryToObject() {
  var res = {};
  var search = location.search;
  var paramArr = new URLSearchParams(search);
  paramArr.forEach(function (val, key) {
    res[key] = isNaN(+val) ? val : +val;
  });
  return res;
};
exports.queryToObject = queryToObject;
var goNextPage = function goNextPage() {
  var page = _store.store.getNextPage();
  // store.setPage(page)
  return page;
};
exports.goNextPage = goNextPage;
var goPrevPage = function goPrevPage() {
  var page = _store.store.getPrevPage();
  // store.setPage(page)
  return page;
};
exports.goPrevPage = goPrevPage;
var goPage = function goPage(name) {
  // console.log(name,history)
  var page = _store.store.getPageByName(name);
  _store.store.setPage(page);
  updateRoute(page);
  // history.go(-1)
};
exports.goPage = goPage;