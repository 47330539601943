"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._grid = exports._axisFn = exports._axisArrow = exports._axis = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _svg = require("../core/svg");
var _path = require("../core/path");
var _math = require("@/math");
var _grid2 = require("@/algorithm/grid");
var _polygon = require("../polygon");
var _polar2 = require("./polar");
var _text2 = require("@/types/text");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 箭头
var _axisArrow = function _axisArrow() {
  var path = (0, _path.pointsPath)({
    points: [[0, 0], [10, 5], [0, 10]],
    closed: true
  }, {
    fill: '#f00',
    stroke: 'none'
  });
  var id = 'markerArrow';
  var market = (0, _svg._marker)(path, {
    id: id,
    viewBox: '0 0 10 10',
    markerUnits: 'strokeWidth',
    markerWidth: 10,
    markerHeight: 10,
    refX: 1,
    refY: 5,
    orient: 'auto'
  });
  return (0, _svg._defs)(market, {
    id: id
  });
};
exports._axisArrow = _axisArrow;
var _grid = function _grid(options) {
  var children = [];
  var _options$id = options.id,
    id = _options$id === void 0 ? 'grid' : _options$id,
    _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    _options$padding = options.padding,
    padding = _options$padding === void 0 ? 30 : _options$padding,
    _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 50 : _options$r,
    yPoints = options.yPoints,
    xPoints = options.xPoints,
    crossPoints = options.crossPoints,
    cellPoints = options.cellPoints,
    _options$props = options.props,
    props = _options$props === void 0 ? {} : _options$props;
  var ps = [];
  // x轴
  xPoints = xPoints || [[0 + padding, o[1]], [width - padding, o[1]]];
  yPoints = yPoints || [[o[0], height - padding], [o[0], 0 + padding]];
  // x轴
  var n = Math.ceil((width / 2 - padding) / r);
  Array.from({
    length: n
  }).forEach(function (t, i) {
    if (i === 0) {
      ps.push(yPoints);
    } else {
      ps.push((0, _math.translateX)(yPoints, -i * r));
      ps.push((0, _math.translateX)(yPoints, i * r));
    }
  });
  var m = Math.ceil((height / 2 - padding) / r);
  Array.from({
    length: m
  }).forEach(function (t, i) {
    if (i === 0) {
      ps.push(xPoints);
    } else {
      ps.push((0, _math.translateY)(xPoints, -i * r));
      ps.push((0, _math.translateY)(xPoints, i * r));
    }
  });
  var path = (0, _path.pointsPath)({
    points: ps,
    broken: true,
    closed: false
  }, Object.assign({
    'stroke-dasharray': 4,
    stroke: 'gray'
  }, props));
  children.push(path);
  // 交叉点
  if (crossPoints) {
    var gps = (0, _grid2.gridCrossPoints)({
      height: height,
      width: width,
      o: o,
      r: r,
      padding: padding
    });
    var plaingps = (0, _math.plainMatrix)(gps);
    children.push.apply(children, _toConsumableArray(plaingps.map(function (t) {
      return (0, _svg._circle)({
        o: t,
        r: 2
      }, {
        fill: 'red',
        stroke: 'none'
      });
    })));
    if (crossPoints.label) {
      var coors = (0, _math.plainMatrix)((0, _math.unitMatrix)(gps));
      var labels = (0, _polygon._labels)({
        points: plaingps,
        render: function render(i) {
          return "[".concat(coors[i], "]");
        }
      }, crossPoints.label);
      children.push.apply(children, _toConsumableArray(labels));
    }
  }
  // 单元格中心点
  if (cellPoints) {
    var gcps = (0, _grid2.gridCellPoints)({
      height: height,
      width: width,
      o: o,
      r: r,
      padding: padding
    });
    var plaincps = (0, _math.plainMatrix)(gcps);
    children.push.apply(children, _toConsumableArray(plaincps.map(function (t) {
      return (0, _svg._circle)({
        o: t,
        r: 2
      }, {
        fill: 'blue',
        stroke: 'none'
      });
    })));
    if (cellPoints.label) {
      var _coors = (0, _math.plainMatrix)((0, _math.unitMatrix)(gcps));
      var _labels2 = (0, _polygon._labels)({
        points: plaincps,
        render: function render(i) {
          return "[".concat(_coors[i], "]");
        }
      }, cellPoints.label);
      children.push.apply(children, _toConsumableArray(_labels2));
    }
  }
  // return children
  return (0, _svg._g)(children, Object.assign({
    name: id,
    stroke: 'black'
  }, props));
};
// // x轴
// const _xAxis = (options) => {
//     _axis(options, 'xAxis')
// }
// // y轴
// const _yAxis = (options) => {
//     _axis(options, 'yAxis')
// }
// 坐标轴 id = xAxis yAxis
exports._grid = _grid;
var _axis = function _axis(options) {
  var _options$id2 = options.id,
    id = _options$id2 === void 0 ? 'axis' : _options$id2,
    _options$width2 = options.width,
    width = _options$width2 === void 0 ? 800 : _options$width2,
    _options$height2 = options.height,
    height = _options$height2 === void 0 ? 600 : _options$height2,
    _options$padding2 = options.padding,
    padding = _options$padding2 === void 0 ? 30 : _options$padding2,
    _options$o2 = options.o,
    o = _options$o2 === void 0 ? [400, 300] : _options$o2,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 50 : _options$r2,
    _options$sticks = options.sticks,
    sticks = _options$sticks === void 0 ? true : _options$sticks,
    grid = options.grid,
    _options$props2 = options.props,
    props = _options$props2 === void 0 ? {} : _options$props2;
  var children = [];
  // let arrow = _axisArrow()
  // arrow && children.push(arrow)
  // x轴
  var xPoints = [[0 + padding, o[1]], [width - padding, o[1]]];
  var xAxis = (0, _svg._line)(xPoints, Object.assign(Object.assign({}, props), {
    stroke: 'black'
  }));
  var xArrow = [[0, -5], [10, 0], [0, 5]];
  var xAxisArrow = (0, _path.pointsPath)({
    points: xArrow.map(function (t) {
      return (0, _math.translate)(xPoints[1], t);
    }),
    closed: true
  }, {
    fill: 'black'
  });
  // y轴
  var yPoints = [[o[0], height - padding], [o[0], 0 + padding]];
  var yAxis = (0, _svg._line)(yPoints, Object.assign(Object.assign({}, props), {
    stroke: 'black'
  }));
  var yArrow = (0, _math.vertical)(xArrow);
  var yAxisArrow = (0, _path.pointsPath)({
    points: yArrow.map(function (t) {
      return (0, _math.translate)(yPoints[1], t);
    }),
    closed: true
  }, {
    fill: 'black'
  });
  children.push(xAxis, yAxis);
  children.push(xAxisArrow, yAxisArrow);
  // 刻度
  if (sticks) {
    var _sticks$stickWidth = sticks.stickWidth,
      stickWidth = _sticks$stickWidth === void 0 ? 5 : _sticks$stickWidth; //r = 50,
    var ps = [];
    var texts = [];
    var text = (0, _svg._text)((0, _svg.toTextOptions)([o[0] - stickWidth - 10, o[1] + stickWidth + 15, 0], ''));
    texts.push(text);
    // x轴
    var n = Math.ceil((width / 2 - padding) / r);
    Array.from({
      length: n
    }).forEach(function (t, i) {
      if (i === 0) return;
      var stick = [o, (0, _math.translateY)(o, stickWidth)];
      ps.push((0, _math.translateX)(stick, -i * r));
      ps.push((0, _math.translateX)(stick, i * r));
      texts.push((0, _svg._text)((0, _svg.toTextOptions)((0, _math.translate)(o, [-i * r, stickWidth + 15]), -i)));
      texts.push((0, _svg._text)((0, _svg.toTextOptions)((0, _math.translate)(o, [i * r, stickWidth + 15]), i)));
    });
    // y轴
    var m = Math.ceil((height / 2 - padding) / r);
    Array.from({
      length: m
    }).forEach(function (t, i) {
      if (i === 0) return;
      var stick = [o, (0, _math.translateX)(o, -stickWidth)];
      ps.push((0, _math.translateY)(stick, -i * r));
      ps.push((0, _math.translateY)(stick, i * r));
      texts.push((0, _svg._text)((0, _svg.toTextOptions)((0, _math.translate)(o, [-stickWidth - 10, -i * r]), i)));
      texts.push((0, _svg._text)((0, _svg.toTextOptions)((0, _math.translate)(o, [-stickWidth - 10, i * r]), -i)));
    });
    var path = (0, _path.pointsPath)({
      points: ps,
      broken: true,
      closed: false
    }, {
      stroke: 'black',
      strokeWidth: 2
    });
    var labels = (0, _svg._g)(texts, {
      name: 'sticks',
      'font-size': 12,
      'text-anchor': _text2.TextAnchor.Middle,
      'dominant-baseline': 'middle'
    });
    children.push(path, labels);
  }
  // 网格
  if (grid) {
    children.push(_grid(Object.assign({
      width: width,
      height: height,
      padding: padding,
      o: o,
      r: r,
      yPoints: yPoints,
      xPoints: xPoints
    }, grid)));
  }
  return (0, _svg._g)(children, Object.assign({
    id: id,
    name: id,
    stroke: 'black'
  }, props));
  // let axis = _defs(_g(children, {
  //     id,
  //     name: id,
  //     stroke: 'black',
  //     ...props
  // }), {
  //     id
  // })
  // let use = _use({ id })
  // return [axis, use]
};
exports._axis = _axis;
var axisFnMap = {
  axis: _axis,
  grid: _grid,
  polar: _polar2._polar
};
var _axisFn = function _axisFn(options) {
  var axis = options.axis,
    grid = options.grid,
    polar = options.polar;
  var children = [];
  if (axis) {
    var _axis$shape = axis.shape,
      shape = _axis$shape === void 0 ? 'axis' : _axis$shape;
    children.push(axisFnMap[shape] ? axisFnMap[shape](axis) : _axis({
      grid: {}
    }));
  }
  if (grid) {
    children.push(_grid(grid));
  }
  if (polar) {
    children.push((0, _polar2._polar)(polar));
  }
  return children;
};
exports._axisFn = _axisFn;