"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.raySegs = exports.rayMix = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
// 数组混合
// 射线混排
var rayMix = function rayMix(p, arr) {
  var arr2 = [];
  arr.forEach(function (t) {
    arr2.push(p, t);
  });
  return arr2;
};
exports.rayMix = rayMix;
var raySegs = function raySegs(p, arr) {
  var segs = [];
  arr.forEach(function (t) {
    segs.push([p, t]);
  });
  return segs;
};
exports.raySegs = raySegs;