"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spiralPoints = exports.ringPoints = exports.rayPoints = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
var _math = require("@/math");
// 螺旋线点
var spiralPoints = function spiralPoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [0, 0] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 10 : _options$r,
    _options$n = options.n,
    n = _options$n === void 0 ? 6 : _options$n,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$m = options.m,
    m = _options$m === void 0 ? 10 : _options$m;
  var a1 = a;
  var a2 = 360 + a1;
  return Array.from({
    length: m
  }, function (_, j) {
    return Array.from({
      length: n
    }, function (_, i) {
      var a = a1 + i * (a2 - a1) / n;
      return (0, _math._polar)(o, r * (j + 1) + r * i / n, a);
    });
  });
};
// 环形
exports.spiralPoints = spiralPoints;
var ringPoints = function ringPoints(options) {
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [0, 0] : _options$o2,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 10 : _options$r2,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 6 : _options$n2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? 0 : _options$a2,
    _options$m2 = options.m,
    m = _options$m2 === void 0 ? 10 : _options$m2;
  var a1 = a;
  var a2 = 360 + a1;
  return Array.from({
    length: m
  }, function (_, j) {
    var r2 = r * (j + 1);
    return Array.from({
      length: n
    }, function (_, i) {
      var a = a1 + i * (a2 - a1) / n;
      return (0, _math._polar)(o, r2, a);
    });
  }).reverse();
};
// 射线
exports.ringPoints = ringPoints;
var rayPoints = function rayPoints(options) {
  var _options$o3 = options.o,
    o = _options$o3 === void 0 ? [0, 0] : _options$o3,
    _options$r3 = options.r,
    r = _options$r3 === void 0 ? 10 : _options$r3,
    _options$n3 = options.n,
    n = _options$n3 === void 0 ? 6 : _options$n3,
    _options$a3 = options.a,
    a = _options$a3 === void 0 ? 0 : _options$a3,
    _options$m3 = options.m,
    m = _options$m3 === void 0 ? 10 : _options$m3;
  var a1 = a;
  var a2 = 360 + a1;
  return Array.from({
    length: n
  }, function (_, j) {
    var a = a1 + j * (a2 - a1) / n;
    return Array.from({
      length: m
    }, function (_, i) {
      var r2 = r * (i + 1);
      return (0, _math._polar)(o, r2, a);
    });
  });
};
exports.rayPoints = rayPoints;