"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gouguPoints = void 0;
var _polar2 = require("./polar");
var _controlPoints3 = require("./controlPoints");
var _math = require("@/math");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/**
 * 勾股树
 * @param options
 * @returns
 */
var gouguPoints = function gouguPoints(options) {
  var o = options.o,
    _options$n = options.n,
    n = _options$n === void 0 ? 4 : _options$n,
    _options$a = options.a,
    a = _options$a === void 0 ? 180 : _options$a,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 3 : _options$depth,
    _options$wriggle = options.wriggle,
    wriggle = _options$wriggle === void 0 ? 10 : _options$wriggle;
  var from = o;
  var to = (0, _math._polar)(o, r, a);
  var _ra = function _ra() {
    return wriggle ? (0, _math._random)(-wriggle, wriggle) : 0;
  };
  var points = (0, _polar2.segToPolygonPoints)([from, to], n, true);
  var result = [points];
  var _gougu = function _gougu(points, i, depth) {
    var t = points[i];
    var next = points[(i + 1) % n];
    var _controlPoints = (0, _controlPoints3.controlPoints)([t, next], -180 + _ra()),
      _controlPoints2 = _slicedToArray(_controlPoints, 2),
      c1 = _controlPoints2[0],
      c2 = _controlPoints2[1];
    var ps1 = (0, _polar2.segToPolygonPoints)([c1, t], n);
    var ps2 = (0, _polar2.segToPolygonPoints)([next, c1], n);
    result.push(ps1);
    result.push(ps2);
    if (depth > 0) {
      _gougu(ps1, Math.floor(n / 2), depth - 1);
      _gougu(ps2, Math.ceil(n / 2), depth - 1);
    }
  };
  _gougu(points, 0, depth);
  return result;
};
exports.gouguPoints = gouguPoints;