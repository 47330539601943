"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fractalTypes = exports._fractal = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.slice.js");
var _color = require("@/color");
var _polygon2 = require("../polygon");
var _math = require("@/math");
var _mirror2 = require("@/math/mirror");
var _utils = require("@/utils");
var _svg = require("../core/svg");
var _path = require("../core/path");
var _fractal2 = require("@/types/fractal");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var _fractal = function _fractal(options, props) {
  var _a;
  if (Array.isArray(options)) {
    return options.map(function (t) {
      return _fractal(t, props);
    });
  }
  var fractal = options.fractal,
    _options$points = options.points,
    points = _options$points === void 0 ? [] : _options$points,
    n = options.n,
    o = options.o,
    r = options.r,
    color = options.color;
  var result = [];
  var colors;
  if (typeof color === 'string') {
    colors = (0, _color._colors)(color, n, 0.5);
  } else {
    colors = (0, _color._colors)(color.type, n, (_a = color.alpha) !== null && _a !== void 0 ? _a : 0.5);
  }
  // let colors = _colors(color, n, 0.5)
  var _props = function _props(index) {
    var color = colors[index % n];
    return Object.assign({
      stroke: color
    }, props);
  };
  var fn = function fn(_ref) {
    var o = _ref.o,
      r = _ref.r,
      a = _ref.a,
      index = _ref.index,
      points = _ref.points,
      closed = _ref.closed,
      broken = _ref.broken;
    var pg = (0, _polygon2._polygon)(Object.assign(Object.assign({}, options), {
      o: o,
      r: r,
      a: a,
      points: points,
      closed: closed,
      broken: broken,
      fractal: null,
      axis: null
    }), _props(index));
    result.push(pg);
  };
  var fractalType = '';
  var type = (0, _utils._type)(fractal);
  if (type === 'string') {
    fractalType = fractal;
  } else if (type == 'object') {
    fractalType = fractal.type;
    var radio = fractal.radio;
    //缩放
    r *= radio;
  }
  switch (fractalType) {
    case _fractal2.Fractal.EdgeFractal:
      {
        // 边中点分形
        var midPoints = (0, _math._mid)(points);
        midPoints.forEach(function (t, index) {
          return fn({
            o: t,
            index: index,
            r: r
          });
        });
      }
      break;
    case _fractal2.Fractal.VertexFractal:
      // 顶点分形
      points.forEach(function (t, index) {
        return fn({
          o: t,
          index: index,
          r: r
        });
      });
      break;
    case _fractal2.Fractal.EdgeMirror:
      {
        // 边镜像
        var _midPoints = (0, _math._mid)(points);
        _midPoints.forEach(function (t, index) {
          var o2 = (0, _mirror2._mirror)(o, t);
          var a = index * 360 / _midPoints.length;
          fn({
            o: o2,
            index: index,
            r: r,
            a: a
          });
        });
      }
      break;
    case _fractal2.Fractal.VertexMirror:
      // 顶点镜像
      points.forEach(function (t, index) {
        var o2 = (0, _mirror2._mirror)(o, t);
        var a = index * 360 / points.length;
        fn({
          o: o2,
          index: index,
          r: r,
          a: a
        });
      });
      break;
    case _fractal2.Fractal.EdgeMid:
      {
        // 边中点连线
        var midFn = function midFn(points, level) {
          if (level <= 0) return [];
          var midPoints = (0, _math._mid)(points);
          fn({
            o: o,
            points: midPoints,
            closed: true,
            index: level
          });
          midFn(midPoints, level - 1);
        };
        midFn(points, fractal.level || 3);
      }
      break;
    case _fractal2.Fractal.Yanghui:
      {
        // 杨辉三角
        var _midFn = function _midFn(points, level) {
          if (level <= 0) return [];
          var midPoints = (0, _math._mid)(points);
          var len = midPoints.length;
          fn({
            points: midPoints,
            closed: true,
            index: level
          });
          points.forEach(function (t, index) {
            _midFn([t, midPoints[index], midPoints[index - 1 < 0 ? len - 1 : index - 1]], level - 1);
          });
        };
        _midFn(points, fractal.level || 3);
      }
      break;
    case _fractal2.Fractal.Gougu:
      {
        // 勾股树
        var _midPoints2 = (0, _math._mid)(points);
        var _r = _math._dis.apply(void 0, _toConsumableArray(points.slice(0, 2))) / 2;
        (0, _utils.eachNext)(points, function (t, index, next) {
          var a = 0 + index * 360 / n;
          // a=0
          var mp = _midPoints2[index];
          var p = (0, _math._polar)(mp, _r, a);
          result.push((0, _svg._circle)({
            o: p,
            r: 3
          }, {
            fill: 'red',
            stroke: 'none'
          }));
          result.push((0, _svg._circle)({
            o: mp,
            r: _r
          }, {
            'stroke-dasharray': 4
          }));
          // 直角三角形
          result.push((0, _path.pointsPath)({
            points: [t, p, next],
            closed: true,
            broken: false
          }));
          // 正方形1
          result.push((0, _path.pointsPath)({
            points: (0, _math._squarePoints)(t, p),
            closed: true,
            broken: false
          }));
          // 正方形2
          result.push((0, _path.pointsPath)({
            points: (0, _math._squarePoints)(p, next),
            closed: true,
            broken: false
          }));
        });
      }
      break;
  }
  return result;
};
exports._fractal = _fractal;
var fractalTypes = (0, _utils.enum2array)(_fractal2.Fractal);
exports.fractalTypes = fractalTypes;