"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._triangles = exports._squares = exports._ray = exports._points = exports._ploygon = exports._pathMode = exports._lines = exports._lineStrip = exports._lineLoop = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.fill.js");
var _pathMode2 = require("@/types/pathMode");
var _curve2 = require("./curve");
var _canvas = require("./canvas");
var _arrayMix = require("@/math/arrayMix");
var _math = require("@/math");
var _array = require("@/math/array");
var _link2 = require("@/algorithm/link");
var _traversal2 = require("@/algorithm/traversal");
var _pathModeMapFn;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 点
var _points = function _points(_ref, props) {
  var ctx = _ref.ctx,
    points = _ref.points;
  var r = 3;
  points.forEach(function (t, i) {
    (0, _canvas._circle)(ctx, t, r, Object.assign({
      stroke: 'none',
      fill: 'red'
    }, props));
  });
};
exports._points = _points;
var _lineStrip = function _lineStrip(_ref2) {
  var ctx = _ref2.ctx,
    points = _ref2.points,
    curve = _ref2.curve;
  (0, _curve2._curve)({
    ctx: ctx,
    points: points,
    curve: curve
  });
};
exports._lineStrip = _lineStrip;
var _lineLoop = function _lineLoop(_ref3) {
  var ctx = _ref3.ctx,
    points = _ref3.points,
    curve = _ref3.curve;
  (0, _curve2._curve)({
    ctx: ctx,
    points: points,
    curve: curve,
    loop: true
  });
};
// 线段
exports._lineLoop = _lineLoop;
var _lines = function _lines(_ref4) {
  var ctx = _ref4.ctx,
    points = _ref4.points,
    curve = _ref4.curve;
  (0, _curve2._curve)({
    ctx: ctx,
    points: points,
    curve: curve,
    step: 2,
    discrete: true
  });
};
// 多边形
exports._lines = _lines;
var _ploygon = function _ploygon(_ref5) {
  var ctx = _ref5.ctx,
    points = _ref5.points,
    n = _ref5.n,
    curve = _ref5.curve;
  var matrix = (0, _array.toMatrix)(points, n);
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve,
      loop: true
    });
  });
};
// 三角形
exports._ploygon = _ploygon;
var _triangles = function _triangles(_ref6) {
  var ctx = _ref6.ctx,
    points = _ref6.points,
    curve = _ref6.curve;
  _ploygon({
    ctx: ctx,
    points: points,
    n: 3,
    curve: curve
  });
};
exports._triangles = _triangles;
var _serial = function _serial(_ref7) {
  var ctx = _ref7.ctx,
    points = _ref7.points,
    n = _ref7.n,
    loop = _ref7.loop,
    curve = _ref7.curve;
  var iter = function iter(_ref8) {
    var points = _ref8.points;
    return (0, _curve2._curve)({
      ctx: ctx,
      points: points,
      curve: curve,
      loop: true
    });
  };
  return (0, _traversal2._traversal)({
    points: points,
    n: n,
    iter: iter,
    loop: loop
  });
};
var _triangleSerial = function _triangleSerial(_ref9) {
  var ctx = _ref9.ctx,
    points = _ref9.points,
    curve = _ref9.curve;
  var n = 3;
  var loop = false;
  return _serial({
    ctx: ctx,
    points: points,
    n: n,
    loop: loop,
    curve: curve
  });
};
var _squareSerial = function _squareSerial(_ref10) {
  var ctx = _ref10.ctx,
    points = _ref10.points,
    curve = _ref10.curve;
  var n = 4;
  var loop = false;
  return _serial({
    ctx: ctx,
    points: points,
    n: n,
    loop: loop,
    curve: curve
  });
};
var _triangleSerialLoop = function _triangleSerialLoop(_ref11) {
  var ctx = _ref11.ctx,
    points = _ref11.points,
    curve = _ref11.curve;
  var n = 3;
  var loop = true;
  return _serial({
    ctx: ctx,
    points: points,
    n: n,
    loop: loop,
    curve: curve
  });
};
var _squareSerialLoop = function _squareSerialLoop(_ref12) {
  var ctx = _ref12.ctx,
    points = _ref12.points,
    curve = _ref12.curve;
  var n = 4;
  var loop = true;
  return _serial({
    ctx: ctx,
    points: points,
    n: n,
    loop: loop,
    curve: curve
  });
};
var _triangleStrip = function _triangleStrip(_ref13) {
  var ctx = _ref13.ctx,
    points = _ref13.points,
    curve = _ref13.curve;
  var matrix = (0, _link2.stripPoints)(points, 3);
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve,
      loop: true
    });
  });
};
var _triangleStripLoop = function _triangleStripLoop(_ref14) {
  var ctx = _ref14.ctx,
    points = _ref14.points,
    curve = _ref14.curve;
  var matrix = (0, _link2.stripLoopPoints)(points, 3);
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve,
      loop: true
    });
  });
};
var _triangleFan = function _triangleFan(_ref15) {
  var ctx = _ref15.ctx,
    points = _ref15.points,
    curve = _ref15.curve;
  var matrix = (0, _link2.stripFanPoints)(points, 2);
  var last = matrix.pop();
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve
    });
  });
  (0, _curve2._curve)({
    ctx: ctx,
    points: last,
    curve: curve,
    loop: true
  });
};
// 四边形
var _squares = function _squares(_ref16) {
  var ctx = _ref16.ctx,
    points = _ref16.points,
    curve = _ref16.curve;
  _ploygon({
    ctx: ctx,
    points: points,
    n: 4,
    curve: curve
  });
};
exports._squares = _squares;
var _squareStrip = function _squareStrip(_ref17) {
  var ctx = _ref17.ctx,
    points = _ref17.points,
    curve = _ref17.curve;
  var matrix = (0, _link2.stripPoints)(points, 4);
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve,
      loop: true
    });
  });
};
var _squareStripLoop = function _squareStripLoop(_ref18) {
  var ctx = _ref18.ctx,
    points = _ref18.points,
    curve = _ref18.curve;
  var matrix = (0, _link2.stripLoopPoints)(points, 4);
  matrix.forEach(function (ps) {
    (0, _curve2._curve)({
      ctx: ctx,
      points: ps,
      curve: curve,
      loop: true
    });
  });
};
// 扇线
var _lineFan = function _lineFan(_ref19) {
  var ctx = _ref19.ctx,
    points = _ref19.points,
    curve = _ref19.curve;
  var _points2 = _toArray(points),
    p = _points2[0],
    rest = _points2.slice(1);
  var ps = (0, _arrayMix.rayMix)(p, rest);
  (0, _curve2._curve)({
    ctx: ctx,
    points: ps,
    curve: curve
  });
};
var _ray = function _ray(_ref20) {
  var ctx = _ref20.ctx,
    points = _ref20.points,
    curve = _ref20.curve;
  var o = (0, _math._o)(points);
  var ps = (0, _arrayMix.rayMix)(o, points);
  (0, _curve2._curve)({
    ctx: ctx,
    points: ps,
    curve: curve,
    step: 2
  });
};
exports._ray = _ray;
var _rayOne = function _rayOne(_ref21) {
  var ctx = _ref21.ctx,
    points = _ref21.points,
    curve = _ref21.curve;
  var o = (0, _math._o)(points);
  (0, _curve2._curve)({
    ctx: ctx,
    points: [o, points[0]],
    curve: curve
  });
};
var _rayFan = function _rayFan(_ref22) {
  var ctx = _ref22.ctx,
    points = _ref22.points,
    curve = _ref22.curve;
  _ray({
    ctx: ctx,
    points: points,
    curve: curve
  });
  _lineLoop({
    ctx: ctx,
    points: points,
    curve: curve
  });
};
var _link = function _link(_ref23) {
  var ctx = _ref23.ctx,
    points = _ref23.points,
    curve = _ref23.curve;
  var lps = (0, _link2.linkPoints)(points);
  (0, _curve2._curve)({
    ctx: ctx,
    points: lps,
    curve: curve,
    step: 2
  });
};
var pathModeMapFn = (_pathModeMapFn = {}, _defineProperty(_pathModeMapFn, _pathMode2.PathMode.LINE_STRIP, _lineStrip), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.LINE_LOOP, _lineLoop), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.POINTS, _points), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.LINES, _lines), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLES, _triangles), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLE_SERIAL, _triangleSerial), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLE_SERIAL_LOOP, _triangleSerialLoop), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.SQUARES, _squares), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.LINE_FAN, _lineFan), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.RAY, _ray), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.RAY_FAN, _rayFan), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.LINK, _link), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLE_STRIP, _triangleStrip), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.SQUARE_STRIP, _squareStrip), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLE_STRIP_LOOP, _triangleStripLoop), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.SQUARE_STRIP_LOOP, _squareStripLoop), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.SQUARE_SERIAL, _squareSerial), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.SQUARE_SERIAL_LOOP, _squareSerialLoop), _defineProperty(_pathModeMapFn, _pathMode2.PathMode.TRIANGLE_FAN, _triangleFan), _pathModeMapFn);
var _pathMode = function _pathMode(_ref24, props) {
  var ctx = _ref24.ctx,
    pathMode = _ref24.pathMode,
    points = _ref24.points,
    curve = _ref24.curve,
    closed = _ref24.closed;
  if (closed && !pathMode) {
    pathMode = _pathMode2.PathMode.LINE_LOOP;
  }
  var fn = pathModeMapFn[pathMode] || _lineStrip;
  fn({
    ctx: ctx,
    points: points,
    curve: curve
  });
  var stroke = props.stroke,
    fill = props.fill,
    fillRule = props.fillRule;
  if (fill !== 'none') {
    if (fillRule === 'evenodd') {
      ctx.fill('evenodd');
    } else {
      ctx.fill();
    }
  }
  if (stroke == null || stroke !== 'none') {
    ctx.stroke();
  }
};
exports._pathMode = _pathMode;