"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._nav = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.object.to-string.js");
var _dom = require("../core/dom");
var _playBar2 = require("./playBar");
var _tree2 = require("../core/tree");
var _rotute = require("../rotute");
var _g = require("./g2");
var _navTree2 = require("./navTree");
var _aside = require("./aside");
var _store = require("../../store");
var _svgEditor = require("@/svgEditor");
var _accordion2 = require("../core/accordion");
var _panel2 = require("./panel");
var _ui = require("@/types/ui");
var _nav = function _nav(treeData, config) {
  var target = config.target,
    _config$root = config.root,
    root = _config$root === void 0 ? document.body : _config$root; //activeName
  (0, _navTree2.setNavTreeConfig)({
    target: target,
    root: root
  });
  /**
   *  左侧导航菜单
   * @param data
   */
  var buildNavTree = function buildNavTree(data, initAside) {
    if (data) {
      console.log(data);
      if (data.contentType === _ui.ContentType.Editor) {
        buildSvgEditor(data);
      } else {
        initAside && (0, _aside.showAside)();
        (0, _navTree2._navTree)(data);
      }
    }
  };
  /**
   * 编辑器
   * @param t
   */
  var buildSvgEditor = function buildSvgEditor(t) {
    var editor = _store.store.getEditor();
    if (!editor) {
      editor = (0, _svgEditor.initEditor)();
      _store.store.setEditor(editor);
    }
    var panel = (0, _panel2._panel)('svgEditor', editor.svgContainer, true);
    _store.store.setPage(t);
    (0, _accordion2._accordion)(panel, ".panel[name=".concat(t.name, "]"), _store.store.screenViewer, _store.store.root);
    (0, _aside.showAside)();
    // const tools = editor.getToolNavTree()
    // _navTree(tools)
    editor.buildNavTree();
  };
  /**
   * 头部导航菜单
   * @param name
   * @param enterFromUrl
   */
  var initNavRoot = function initNavRoot(name, enterFromUrl) {
    var list = _store.store.getAllPages();
    var item = list.find(function (t) {
      return t.name === name;
    });
    if (item && item.contentType) {
      var data = treeData.find(function (t) {
        return t.name === item.contentType;
      });
      setTimeout(function () {
        var items = (0, _dom._queryAll)('.nav .nav-item');
        (0, _dom._removeClassName)(items, 'active');
        var el = (0, _dom._query)(".nav-item[role=\"".concat(item.contentType, "\"]"));
        (0, _dom._addClassName)(el, 'active');
      });
      // debugger
      buildNavTree(data);
      _store.store.getNavTree().clickTreeItem(item, enterFromUrl);
    }
  };
  var render = function render(t) {
    return _dom.dom.div(t.title, {
      class: 'nav-item',
      role: t.name
    }, {
      click: function click(e) {
        // 更新路由
        (0, _rotute.updateRoute)(t);
        var items = (0, _dom._queryAll)('.nav-item');
        (0, _dom._removeClassName)(items, 'active');
        var el = e.target;
        (0, _dom._addClassName)(el, 'active');
        buildNavTree(t, true);
      }
    });
  };
  var nav = (0, _tree2._tree)(treeData, render, 0);
  var playBar = (0, _playBar2._playBar)(function (t) {
    (0, _aside.hideAside)();
    var navtree = (0, _dom._query)('.aside .navtree');
    if (navtree) {
      // 显示菜单 模式
      initNavRoot(t.name, false);
    } else {
      // 隐藏菜单 模式
      // clickTreeItem(t, false)
      _store.store.getNavTree().clickTreeItem(t, false);
    }
  });
  // 初始化路由
  var route = (0, _rotute.getRoute)();
  if (route) {
    // activeName = route
    initNavRoot(route, true);
  }
  var tools = _dom.dom.div(_store.store.isH5 ? playBar : [playBar, (0, _g._g2)()], {
    class: 'nav-right'
  });
  return _dom.dom.div([nav, tools], {
    class: 'nav'
  });
};
exports._nav = _nav;