"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._polar = void 0;
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.assign.js");
var _svg = require("../core/svg");
var _path = require("../core/path");
var _arrayMix = require("@/math/arrayMix");
var _index = require("@/math/index");
var _polar2 = require("@/algorithm/polar");
var _text2 = require("@/types/text");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
// 极坐标
var _polar = function _polar(options) {
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$n = options.n,
    n = _options$n === void 0 ? 12 : _options$n,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$props = options.props,
    props = _options$props === void 0 ? {} : _options$props,
    _options$crossPoints = options.crossPoints,
    crossPoints = _options$crossPoints === void 0 ? {} : _options$crossPoints,
    labels = options.labels;
  var o = [width / 2, height / 2];
  var m = Math.ceil((width > height ? width : height) / r / 2);
  var circles = Array.from({
    length: m + 1
  }, function (_, i) {
    return (0, _svg._circle)({
      o: o,
      r: r * i
    }, {
      'stroke-dasharray': 4
    });
  });
  var children = [].concat(circles);
  // 最外层点
  var points = (0, _polar2.polarPoints)({
    o: o,
    r: r * m,
    n: n,
    a: a
  })[0];
  var pgp = [];
  if (crossPoints || labels) {
    pgp = (0, _polar2.polarGridPoints)({
      o: o,
      r: r,
      n: n,
      a: a,
      m: m
    });
    pgp = (0, _index.plainMatrix)(pgp);
  }
  // 交叉点
  if (crossPoints) {
    children.push.apply(children, _toConsumableArray(pgp.map(function (t) {
      return (0, _svg._circle)({
        o: t,
        r: 2
      }, {
        fill: 'red',
        stroke: 'none'
      });
    })));
  }
  if (labels) {
    children.push.apply(children, _toConsumableArray(pgp.map(function (_ref, i) {
      var _ref2 = _slicedToArray(_ref, 2),
        x = _ref2[0],
        y = _ref2[1];
      return (0, _svg._text)({
        x: x,
        y: y,
        text: i
      }, {
        'font-size': 12,
        'text-anchor': _text2.TextAnchor.Middle,
        'dominant-baseline': 'middle',
        stroke: 'gray'
      });
    })));
  }
  // 对角点  排序
  var rps = (0, _arrayMix.rayMix)(o, points);
  // 轴
  var axis = (0, _path.pointsPath)({
    points: rps,
    closed: false,
    broken: true
  }, {
    'stroke-dasharray': 4
  });
  children.push(axis);
  var g = (0, _svg._g)(children, Object.assign({
    name: 'polar',
    fill: 'none',
    stroke: 'black'
  }, props));
  return g;
};
exports._polar = _polar;