"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._vertex = exports._triangle = exports._square = exports._ray = exports._radius = exports._polygon = exports._pentagon = exports._links = exports._labels = exports._incircle = exports._hexagon = exports._heptagon = exports._excircle = exports._dege = exports._centre = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _svg = require("./core/svg");
var _renderShape = require("./renderShape");
var _link = require("@/algorithm/link");
var _math = require("@/math");
var _fractal2 = require("./fractal/fractal");
var _transform2 = require("@/math/transform");
var _polar = require("@/algorithm/polar");
var _line = require("@/algorithm/line");
var _index = require("@/color/index");
var _motion2 = require("./core/motion");
var _arrayMix = require("@/math/arrayMix");
var _common = require("@/common");
var _shape = require("@/types/shape");
var _pathMode = require("@/types/pathMode");
var _motion3 = require("@/types/motion");
var _text = require("@/types/text");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// 边
var _dege = function _dege(points, options, props) {
  return (0, _renderShape.renderShape)(Object.assign({
    shape: _shape.Shape.Path,
    //默认path
    points: points,
    pathMode: _pathMode.PathMode.LINE_LOOP,
    closed: true
  }, options), Object.assign(Object.assign({
    name: 'edge'
  }, props), options.props));
};
// 中心点
exports._dege = _dege;
var _centre = function _centre(_ref, options, props) {
  var o = _ref.o,
    r = _ref.r;
  return (0, _renderShape.renderShape)(Object.assign(Object.assign({
    shape: _shape.Shape.Circle,
    r: r || 5
  }, options), {
    o: o
  }), Object.assign(Object.assign({
    name: 'centre'
  }, props), options.props));
};
// 外切圆
exports._centre = _centre;
var _excircle = function _excircle(_ref2, options, props) {
  var o = _ref2.o,
    r = _ref2.r;
  return (0, _renderShape.renderShape)({
    shape: _shape.Shape.Circle,
    o: o,
    r: r
  }, Object.assign(Object.assign(Object.assign({
    name: 'excircle'
  }, props), {
    fill: 'none'
  }), options.props));
};
// 内切圆，
exports._excircle = _excircle;
var _incircle = function _incircle(o, points, opitons, props) {
  var r = (0, _math._dis)(o, _math._mid.apply(null, points.slice(0, 2)));
  return (0, _renderShape.renderShape)({
    shape: _shape.Shape.Circle,
    o: o,
    r: r
  }, Object.assign(Object.assign(Object.assign({
    name: 'incircle'
  }, props), {
    fill: 'none'
  }), opitons.props));
};
// 顶点
exports._incircle = _incircle;
var _vertex = function _vertex(points, options, props) {
  // 默认向心
  var _options$orient = options.orient,
    orient = _options$orient === void 0 ? true : _options$orient,
    _options$n = options.n,
    n = _options$n === void 0 ? points.length : _options$n;
  return points.map(function (t, i) {
    return (0, _renderShape.renderShape)(Object.assign({
      shape: _shape.Shape.Circle,
      r: 5,
      o: t,
      a: orient ? 360 * i / n : 0
    }, options), Object.assign(Object.assign({
      name: 'vertex'
    }, props), options.props));
  });
};
// 半径
exports._vertex = _vertex;
var _radius = function _radius(o, points, options, props) {
  var rps = (0, _arrayMix.rayMix)(o, points);
  return (0, _renderShape.renderShape)(Object.assign({
    shape: _shape.Shape.Path,
    points: rps,
    closed: true
  }, options), Object.assign(Object.assign({
    name: 'radius'
  }, props), options.props));
};
exports._radius = _radius;
var _labels = function _labels(_ref3, opitons, props) {
  var points = _ref3.points,
    render = _ref3.render,
    label = _ref3.label;
  return points.map(function (t, index) {
    var _t = _slicedToArray(t, 2),
      x = _t[0],
      y = _t[1];
    return (0, _renderShape.renderShape)({
      shape: _shape.Shape.Text,
      x: x,
      y: y,
      text: render ? render(index) : label || index
    }, Object.assign(Object.assign({
      name: _shape.Shape.Text,
      fontSize: 12,
      textAnchor: _text.TextAnchor.Middle,
      dominantBaseline: 'middle'
    }, props), opitons));
  });
};
// 连线
exports._labels = _labels;
var _links = function _links(points, options, props) {
  var lps = (0, _link.linkPoints)(points);
  var children = [];
  if (options.crossPoints) {
    var lcps = (0, _link.linkCrossPoints)(points);
    children = lcps.map(function (t) {
      return (0, _svg._circle)({
        o: t,
        r: 3
      }, {
        fill: 'red',
        stroke: 'none'
      });
    });
  }
  children[children.length] = (0, _renderShape.renderShape)(Object.assign({
    shape: _shape.Shape.Path,
    points: lps,
    closed: false
  }, options), Object.assign({
    name: 'link'
  }, props));
  return (0, _svg._g)(children);
};
// export const _polygon=(options,props)=>{
//     let points = polarPoints(options)
//     let { transform, mirror, pathMode = 'LINE_LOOP' } = options
//     // 变形函数
//     points = _transform(points, transform)
//     points = _mirrorPoints(points, mirror)
//     return createSvgPathShape({
//         ...options,
//         points,
//         pathMode,
//         mirror: null,
//         color: mirror ? mirror.color : null
//     },props)
// }
// 多边形
exports._links = _links;
var _polygon = function _polygon(options, props) {
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 6 : _options$n2,
    _options$o = options.o,
    o = _options$o === void 0 ? [800 / 2, 600 / 2] : _options$o,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    centre = options.centre,
    vertex = options.vertex,
    radius = options.radius,
    excircle = options.excircle,
    incircle = options.incircle,
    links = options.links,
    labels = options.labels,
    fractal = options.fractal,
    _options$edge = options.edge,
    edge = _options$edge === void 0 ? {} : _options$edge,
    transform = options.transform,
    axis = options.axis,
    pathPoints = options.pathPoints,
    motion = options.motion,
    color = options.color;
  //  两种方式：直接传points优先，或者 根据orn计算点，
  var points = (0, _polar.polarPoints)({
    o: o,
    r: r,
    n: n,
    a: a
  })[0];
  // 变形函数
  points = (0, _transform2._transform)(points, transform);
  var container = [];
  // 坐标
  if (axis) {
    container.push((0, _renderShape.renderShape)(Object.assign({
      shape: _shape.Shape.Axis,
      width: width,
      height: height,
      o: o
    }, axis)));
  }
  // 路径点
  if (pathPoints) {
    // 默认向心
    var _pathPoints$orient = pathPoints.orient,
      orient = _pathPoints$orient === void 0 ? true : _pathPoints$orient,
      m = pathPoints.m;
    var pps = (0, _line.polylinePoints)(points, {
      m: m
    });
    var colors = (0, _index._colors)(color, pps.length, 0.5);
    pps.map(function (t, i) {
      var v = (0, _renderShape.renderShape)(Object.assign({
        shape: _shape.Shape.Circle,
        r: 5,
        o: t,
        a: orient ? 360 * i / n : 0
      }, pathPoints), Object.assign(Object.assign({
        name: 'pathPoints',
        // stroke: 'none',
        // fill: colors[i],
        fill: 'none',
        stroke: colors[i]
      }, props), pathPoints.props));
      container.push(v);
    });
  }
  // 边
  if (edge) {
    var children = [];
    // 动画
    if (motion) {
      if (motion.type === _motion3.MotionType.Dashoffset) {
        // edge.['stroke-dasharray'] = motion['stroke-dasharray'] || '10'
        Object.assign(edge, {
          props: {
            'stroke-dasharray': motion['stroke-dasharray'] || '10'
          }
        });
      }
      children.push((0, _motion2._motion)(motion));
    }
    container.push(_dege(points, edge, props));
  }
  // 中心点
  if ((0, _common.isVisible)(centre)) {
    container.push(_centre({
      o: o,
      r: 5
    }, centre, props));
  }
  // 顶点
  if ((0, _common.isVisible)(vertex)) {
    container.push.apply(container, _toConsumableArray(_vertex(points, vertex, props)));
  }
  // 半径
  if ((0, _common.isVisible)(radius)) {
    container.push(_radius(o, points, radius, props));
  }
  // 外切圆
  if ((0, _common.isVisible)(excircle)) {
    container.push(_excircle({
      o: o,
      r: r
    }, excircle, props));
  }
  // 内切圆
  if ((0, _common.isVisible)(incircle)) {
    container.push(_incircle(o, points, incircle, props));
  }
  // 连接线
  if ((0, _common.isVisible)(links)) {
    container.push(_links(points, links, props));
  }
  if ((0, _common.isVisible)(labels)) {
    container.push.apply(container, _toConsumableArray(_labels({
      points: points
    }, labels, props)));
  }
  // 分形
  if (fractal) {
    var fn = function fn(t) {
      var fs = (0, _fractal2._fractal)(Object.assign(Object.assign({}, options), {
        fractal: t,
        points: points,
        n: n,
        o: o
      }), props);
      container.push.apply(container, _toConsumableArray(fs));
    };
    if (Array.isArray(fractal)) {
      fractal.forEach(function (t) {
        return fn(t);
      });
    } else {
      fn(fractal);
    }
  }
  var len = container.length;
  return len ? len === 1 ? container[0] : (0, _svg._g)(container, Object.assign({
    name: _shape.Shape.Polygon,
    fill: 'none',
    stroke: 'black'
  }, props)) : null;
};
// 三角形
exports._polygon = _polygon;
var _triangle = function _triangle(options, props) {
  return _polygon(Object.assign({
    shape: _shape.Shape.Polygon,
    n: 3
  }, options), props);
};
// 正方形
exports._triangle = _triangle;
var _square = function _square(options, props) {
  return _polygon(Object.assign({
    shape: _shape.Shape.Polygon,
    n: 4
  }, options), props);
};
// 五边形
exports._square = _square;
var _pentagon = function _pentagon(options, props) {
  return _polygon(Object.assign({
    shape: _shape.Shape.Polygon,
    n: 5
  }, options), props);
};
// 六边形
exports._pentagon = _pentagon;
var _hexagon = function _hexagon(options, props) {
  return _polygon(Object.assign({
    shape: _shape.Shape.Polygon,
    n: 6
  }, options), props);
};
// 七边形
exports._hexagon = _hexagon;
var _heptagon = function _heptagon(options, props) {
  return _polygon(Object.assign({
    shape: _shape.Shape.Polygon,
    n: 7
  }, options), props);
};
// 射线
exports._heptagon = _heptagon;
var _ray = function _ray(options, props) {
  var radius = options.radius || {};
  var ray = _polygon(Object.assign(Object.assign({}, options), {
    edge: null,
    radius: radius
  }), props);
  return ray;
};
exports._ray = _ray;