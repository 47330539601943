"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Transform = void 0;
var Transform;
exports.Transform = Transform;
(function (Transform) {
  Transform["None"] = "none";
  Transform["Misplaced"] = "misplaced";
  Transform["Normal"] = "normal";
  Transform["Diagonal"] = "diagonal";
  Transform["ParitySort"] = "paritySort";
  Transform["IntervalSort"] = "intervalSort";
  Transform["NeighborSwap"] = "neighborSwap";
  Transform["Shuffle"] = "shuffle";
})(Transform || (exports.Transform = Transform = {}));