"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.edgeMirror = exports._mirror = void 0;
require("core-js/modules/es.array.map.js");
var _index = require("./index");
// 镜像
// Angle of Refraction折射角
// index of Refraction折射角
var _mirror = function _mirror(p, o, scale, refraction) {
  if ((0, _index.isPoints)(p)) {
    var n = p.length;
    return p.map(function (t, i) {
      return _mirror(t, o, scale, refraction);
    });
  }
  if (refraction) {
    var r = (0, _index._dis)(p, o) * scale;
    var a = (0, _index._atan)(p, o);
    var ra = a + refraction;
    return (0, _index._polar)(o, r, ra);
  }
  if (scale === undefined || scale === 1) {
    return [2 * o[0] - p[0], 2 * o[1] - p[1]].map(function (t) {
      return (0, _index._k)(t);
    });
  }
  return [(scale + 1) * o[0] - scale * p[0], (scale + 1) * o[1] - scale * p[1]].map(function (t) {
    return (0, _index._k)(t);
  });
};
// 边镜像
exports._mirror = _mirror;
var edgeMirror = function edgeMirror(points, options) {
  var _options$ratio = options.ratio,
    ratio = _options$ratio === void 0 ? 1 : _options$ratio,
    refraction = options.refraction;
  var midPoints = (0, _index._mid)(points);
  return midPoints.map(function (t) {
    return _mirror(points, t, ratio, refraction);
  });
};
exports.edgeMirror = edgeMirror;