"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._inspector = void 0;
require("core-js/modules/es.function.name.js");
var _form2 = require("../core/form");
var _dom = require("../core/dom");
var _g = require("./g2");
var _rotute = require("../rotute");
var _store = require("../../store");
var _propsData = require("../../common/propsData");
var _panel = require("./panel");
var rerender = function rerender(model) {
  // 获取数据
  var t = _store.store.getPage();
  (0, _propsData.contentAssignModel)(t.content, model);
  (0, _panel.rerenderPanel)(t);
  // const { content } = renderContent(t)
  // const viewer = _query(".screen-viewer")
  // const panel = _query(`.panel[name="${t.name}"]`, viewer)
  // _content(panel, content)
  // 输入输出参数
  (0, _g.rerenderInspector)(t, ['grammar', 'graphic']);
};
// 事件
window.addEventListener('message', function (e) {
  var data = e.data;
  var eventType = data.eventType,
    options = data.options;
  if ('change' === eventType) {
    var name = options.name;
    var form = (0, _dom._query)(".inspector form[name=\"".concat(name, "\"]"));
    // 获取数据
    var fields = _store.store.getFields();
    var _makeModel = (0, _propsData.makeModel)(fields, form),
      model = _makeModel.model;
    rerender(model);
  }
});
var _inspector = function _inspector(t) {
  var content = t.content,
    name = t.name;
  if (!content) return;
  var fields = (0, _propsData.mapFormFields)(content);
  // 保持数据
  _store.store.setFields(fields);
  var formData = {
    name: name,
    fields: fields,
    op: [{
      type: 'btn',
      text: 'rerender',
      className: 'primary',
      click: function click(e, _ref) {
        var model = _ref.model;
        rerender(model);
      }
    }]
  };
  if (!_store.store.isH5) {
    // 暂不支持h5中reset
    formData.op.push({
      type: 'btn',
      text: 'reset',
      className: 'default',
      click: function click() {
        (0, _rotute.resetRoute)();
      }
    });
  }
  return (0, _form2._form)(formData);
};
exports._inspector = _inspector;