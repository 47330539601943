"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._incircle = void 0;
require("core-js/modules/es.array.slice.js");
var _math = require("@/math");
var _circle2 = require("../core/circle");
var _svg = require("../core/svg");
var _common = require("@/common");
var _incircle = function _incircle(_ref, props) {
  var points = _ref.points,
    incircle = _ref.incircle;
  var o = (0, _math._o)(points);
  var r = (0, _math._dis)(o, _math._mid.apply(null, points.slice(0, 2)));
  var circle = (0, _circle2._circle)({
    o: o,
    r: r
  });
  return (0, _svg._path)(circle, (0, _common._merge)(props, incircle));
};
exports._incircle = _incircle;