"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._number = exports._input = exports._hidden = exports._checkbox = void 0;
require("core-js/modules/es.object.assign.js");
var _dom = require("./dom");
// 对于不同的输入类型，value 属性的用法也不同：
// type="button", "reset", "submit" - 定义按钮上的显示的文本
// type="text", "password", "hidden" - 定义输入字段的初始值
// type="checkbox", "radio", "image" - 定义与输入相关联的值
var _input = function _input(val, options, events) {
  return _dom.dom.input(val, Object.assign(Object.assign({}, options), {
    type: 'text'
  }), events);
};
exports._input = _input;
var _hidden = function _hidden(val, options) {
  return _dom.dom.input('', Object.assign(Object.assign({}, options), {
    value: val,
    type: 'hidden'
  }));
};
// 数字
exports._hidden = _hidden;
var _number = function _number(val, options, events) {
  // 范围
  var range = function (range) {
    return range ? {
      max: range[1],
      min: range[0]
    } : {};
  }(options.range ? options.range : [options.min, options.max]);
  var newOptions = Object.assign(Object.assign(Object.assign({}, options), {
    type: 'number'
  }), range);
  return _dom.dom.input(val, newOptions, events);
};
exports._number = _number;
var _checkbox = function _checkbox(val, options, events) {
  if (val) {
    var _newOptions = Object.assign(Object.assign({
      checked: true
    }, options), {
      type: 'checkbox'
    });
    return _dom.dom.input('', _newOptions, events);
  }
  var newOptions = Object.assign(Object.assign({}, options), {
    type: 'checkbox'
  });
  return _dom.dom.input('', newOptions, events);
};
exports._checkbox = _checkbox;