"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._excircle = void 0;
var _math = require("@/math");
var _svg = require("../core/svg");
var _common = require("@/common");
var _circle2 = require("../core/circle");
var _excircle = function _excircle(_ref, props) {
  var points = _ref.points,
    excircle = _ref.excircle;
  var o = (0, _math._o)(points);
  var r = (0, _math._dis)(o, points[0]);
  var circle = (0, _circle2._circle)({
    o: o,
    r: r
  });
  return (0, _svg._path)(circle, (0, _common._merge)(props, excircle));
};
exports._excircle = _excircle;