"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._plant = void 0;
var _pathShape = require("../pathShape");
var _tree = require("@/algorithm/tree");
// 植物 tree的升级
var _plant = function _plant(options, props) {
  // const { depth = 5, color = "none", alpha = 1, pathMode } = options
  var ps = (0, _tree.treePoints)(options);
  return (0, _pathShape.colorPath)(ps, options, props);
};
exports._plant = _plant;