"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.curveShapeTypes = exports.curvePointsConfig = exports._curvePoints = void 0;
require("core-js/modules/es.object.keys.js");
var _heart = require("./heart");
var _cycloid = require("./cycloid");
var curvePointsConfig = {
  peachHeart: _heart.peachHeartPoints,
  cartesianCardioid: _heart.cartesianCardioidPoints,
  cycloid: _cycloid.cycloidPoints,
  heart: _heart.heartPoints
};
exports.curvePointsConfig = curvePointsConfig;
var curveShapeTypes = Object.keys(curvePointsConfig);
exports.curveShapeTypes = curveShapeTypes;
var _curvePoints = function _curvePoints(type, options) {
  var fn = curvePointsConfig[type] || curvePointsConfig.peachHeart;
  return fn(options);
};
exports._curvePoints = _curvePoints;