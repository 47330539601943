"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.object.assign.js");
var _ui = require("@/ui");
var _g = require("@/ui/petal/g2");
var _aside = require("@/ui/petal/aside");
var _nav2 = _interopRequireDefault(require("@/data/nav3"));
var _store = require("@/store");
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var app = document.getElementById("app");
var layout = (0, _ui._layout)([".header", ".main .aside", ".main .screen-viewer", ".main .main-right .inspector", ".main .main-right .grammar", ".main .main-right .graphic"], app);
var _layout2 = _slicedToArray(layout, 4),
  header = _layout2[0],
  aside = _layout2[1],
  screen = _layout2[2],
  inspector = _layout2[3];
(0, _ui._events)(screen, {
  click: function click(e) {
    var el = e.target;
    (0, _aside.hideAside)();
    var panel = (0, _ui._closest)(el, '.panel');
    if (!panel || /(webgl|^gl.*?)/.test(panel.getAttribute('name'))) {
      return;
    }
    var viewer = (0, _ui._closest)(el, '.screen-viewer');
    if (!viewer) return;
    if (_store.store.isH5) {
      (0, _g.toggleInspector)('inspector');
    }
  }
});
var list = (0, _utils.plainTreeMenu)(_nav2.default.menu);
_store.store.setAllPages(list);
var nav = (0, _ui._nav)(_nav2.default.menu, Object.assign(Object.assign({}, _nav2.default), {
  root: app
}));
(0, _ui._append)(header, nav);