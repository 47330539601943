"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._sawtooth = exports._polyline = exports._moveTo = exports._loop = exports._lineTo = exports._leftSawtooth = exports._leftBezier = exports._doubleSawtooth = exports._doubleBezier = exports._curve = exports._bezierCurveTo = exports._bezier = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.join.js");
var _math = require("@/math");
var _mirror2 = require("@/math/mirror");
var _sin2 = require("@/algorithm/sin");
var _utils = require("@/utils");
var _controlPoints5 = require("@/algorithm/controlPoints");
var _rough2 = require("../pental/rough");
var _canvas = require("./canvas");
var _koch2 = require("@/algorithm/koch");
var _sierpinski2 = require("@/algorithm/sierpinski");
var _traversal2 = require("@/algorithm/traversal");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _moveTo = function _moveTo(ctx, p) {
  ctx.moveTo(p[0], p[1]);
};
exports._moveTo = _moveTo;
var _lineTo = function _lineTo(ctx, p) {
  ctx.lineTo(p[0], p[1]);
};
exports._lineTo = _lineTo;
var _polylineTo = function _polylineTo(ctx, points) {
  points.forEach(function (t) {
    return _lineTo(ctx, t);
  });
};
var _bezierCurveTo = function _bezierCurveTo(ctx, _ref) {
  var _ref2 = _slicedToArray(_ref, 3),
    c1 = _ref2[0],
    c2 = _ref2[1],
    t = _ref2[2];
  ctx.bezierCurveTo(c1[0], c1[1], c2[0], c2[1], t[0], t[1]);
};
exports._bezierCurveTo = _bezierCurveTo;
var _quadraticCurveTo = function _quadraticCurveTo(ctx, _ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    c = _ref4[0],
    to = _ref4[1];
  ctx.quadraticCurveTo(c[0], c[1], to[0], to[1]);
};
var _curveTo = function _curveTo(ctx, _ref5, _ref6) {
  var _ref7 = _slicedToArray(_ref5, 2),
    from = _ref7[0],
    to = _ref7[1];
  var _ref8 = _slicedToArray(_ref6, 2),
    c1 = _ref8[0],
    c2 = _ref8[1];
  _bezierCurveTo(ctx, [c2, c1, to]);
};
var _leftCurveTo = function _leftCurveTo(ctx, _ref9, _ref10) {
  var _ref11 = _slicedToArray(_ref9, 2),
    from = _ref11[0],
    to = _ref11[1];
  var _ref12 = _slicedToArray(_ref10, 2),
    c1 = _ref12[0],
    c2 = _ref12[1];
  _bezierCurveTo(ctx, [c1, c2, to]);
};
var _doubleCurveTo = function _doubleCurveTo(ctx, _ref13, _ref14) {
  var _ref15 = _slicedToArray(_ref13, 2),
    from = _ref15[0],
    to = _ref15[1];
  var _ref16 = _slicedToArray(_ref14, 2),
    c1 = _ref16[0],
    c2 = _ref16[1];
  _moveTo(ctx, from);
  _bezierCurveTo(ctx, [c1, c2, to]);
  _moveTo(ctx, from);
  _bezierCurveTo(ctx, [c2, c1, to]);
};
var _sawtoothTo = function _sawtoothTo(ctx, _ref17, _ref18) {
  var _ref19 = _slicedToArray(_ref17, 2),
    from = _ref19[0],
    to = _ref19[1];
  var _ref20 = _slicedToArray(_ref18, 2),
    c1 = _ref20[0],
    c2 = _ref20[1];
  _polylineTo(ctx, [c2, c1, to]);
};
var _leftSawtoothTo = function _leftSawtoothTo(ctx, _ref21, _ref22) {
  var _ref23 = _slicedToArray(_ref21, 2),
    from = _ref23[0],
    to = _ref23[1];
  var _ref24 = _slicedToArray(_ref22, 2),
    c1 = _ref24[0],
    c2 = _ref24[1];
  _polylineTo(ctx, [c1, c2, to]);
};
var _doubleSawtoothTo = function _doubleSawtoothTo(ctx, _ref25, _ref26) {
  var _ref27 = _slicedToArray(_ref25, 2),
    from = _ref27[0],
    to = _ref27[1];
  var _ref28 = _slicedToArray(_ref26, 2),
    c1 = _ref28[0],
    c2 = _ref28[1];
  _moveTo(ctx, from);
  _polylineTo(ctx, [c1, c2, to]);
  _moveTo(ctx, from);
  _polylineTo(ctx, [c2, c1, to]);
};
var _rightAngleTo = function _rightAngleTo(ctx, _ref29, _ref30) {
  var _ref31 = _slicedToArray(_ref29, 2),
    from = _ref31[0],
    to = _ref31[1];
  var _ref32 = _slicedToArray(_ref30, 2),
    c1 = _ref32[0],
    c2 = _ref32[1];
  _polylineTo(ctx, [c2, to]);
};
var _leftAngleTo = function _leftAngleTo(ctx, _ref33, _ref34) {
  var _ref35 = _slicedToArray(_ref33, 2),
    from = _ref35[0],
    to = _ref35[1];
  var _ref36 = _slicedToArray(_ref34, 2),
    c1 = _ref36[0],
    c2 = _ref36[1];
  _polylineTo(ctx, [c1, to]);
};
var _diamondTo = function _diamondTo(ctx, _ref37, _ref38) {
  var _ref39 = _slicedToArray(_ref37, 2),
    from = _ref39[0],
    to = _ref39[1];
  var _ref40 = _slicedToArray(_ref38, 2),
    c1 = _ref40[0],
    c2 = _ref40[1];
  _moveTo(ctx, from);
  _polylineTo(ctx, [c1, to, c2, from]);
};
var _crossTo = function _crossTo(ctx, _ref41, _ref42) {
  var _ref43 = _slicedToArray(_ref41, 2),
    from = _ref43[0],
    to = _ref43[1];
  var _ref44 = _slicedToArray(_ref42, 2),
    c1 = _ref44[0],
    c2 = _ref44[1];
  _moveTo(ctx, from);
  _lineTo(ctx, to);
  _moveTo(ctx, c1);
  _lineTo(ctx, c2);
};
var _triangleTo = function _triangleTo(ctx, _ref45, _ref46) {
  var _ref47 = _slicedToArray(_ref45, 2),
    from = _ref47[0],
    to = _ref47[1];
  var _ref48 = _slicedToArray(_ref46, 2),
    c1 = _ref48[0],
    c2 = _ref48[1];
  _moveTo(ctx, from);
  _polylineTo(ctx, [c2, to, from]);
};
var _leftTriangleTo = function _leftTriangleTo(ctx, _ref49, _ref50) {
  var _ref51 = _slicedToArray(_ref49, 2),
    from = _ref51[0],
    to = _ref51[1];
  var _ref52 = _slicedToArray(_ref50, 2),
    c1 = _ref52[0],
    c2 = _ref52[1];
  _moveTo(ctx, from);
  _polylineTo(ctx, [c1, to, from]);
};
var _circleTo = function _circleTo(ctx, _ref53) {
  var _ref54 = _slicedToArray(_ref53, 2),
    from = _ref54[0],
    to = _ref54[1];
  var o = (0, _math._o)([from, to]);
  var r = (0, _math._dis)(o, to);
  ctx.beginPath();
  ctx.arc(o[0], o[1], r, 0, 2 * Math.PI);
  ctx.stroke();
};
var _semicircleTo = function _semicircleTo(ctx, _ref55) {
  var _ref56 = _slicedToArray(_ref55, 2),
    from = _ref56[0],
    to = _ref56[1];
  var o = (0, _math._o)([from, to]);
  var r = (0, _math._dis)(o, to);
  var a = (0, _math._angleRad)(from, to);
  ctx.beginPath();
  ctx.arc(o[0], o[1], r, a, Math.PI + a);
  ctx.stroke();
};
var _leftSemicircleTo = function _leftSemicircleTo(ctx, _ref57) {
  var _ref58 = _slicedToArray(_ref57, 2),
    from = _ref58[0],
    to = _ref58[1];
  var o = (0, _math._o)([from, to]);
  var r = (0, _math._dis)(o, to);
  var a = (0, _math._angleRad)(from, to);
  ctx.beginPath();
  ctx.arc(o[0], o[1], r, Math.PI + a, 2 * Math.PI + a);
  ctx.stroke();
};
var _controls = function _controls(ctx, points) {
  var r = 3;
  ctx.save();
  (0, _canvas._props)(ctx, {
    stroke: 'red',
    fill: 'gray'
  });
  points.forEach(function (t) {
    ctx.arc(t[0], t[1], r, 0, 2 * Math.PI);
  });
  // ctx.stroke()
  // ctx.fill()
  ctx.restore();
};
// 循环
var _loop = function _loop(ctx, options, fn) {
  var points = options.points,
    loop = options.loop,
    _options$step = options.step,
    step = _options$step === void 0 ? 1 : _options$step,
    skew = options.skew,
    amplitude = options.amplitude,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 1 : _options$depth,
    _options$controlPoint = options.controlPointsVisible,
    controlPointsVisible = _options$controlPoint === void 0 ? false : _options$controlPoint;
  var len = points.length;
  var prev;
  var index = 0;
  var callFn = function callFn(i, t, next) {
    if (step > 1) {
      if (i % step === 0) {
        _moveTo(ctx, t);
        prev = t;
      } else {
        var _controlPoints = (0, _controlPoints5.controlPoints)([prev, t], skew, amplitude),
          _controlPoints2 = _slicedToArray(_controlPoints, 2),
          c1 = _controlPoints2[0],
          c2 = _controlPoints2[1];
        // if (controlPointsVisible) {
        //     _controls(ctx, [c1, c2])
        // }
        fn(ctx, [prev, t], [c1, c2], index++);
      }
    } else {
      var _controlPoints3 = (0, _controlPoints5.controlPoints)([t, next], skew, amplitude),
        _controlPoints4 = _slicedToArray(_controlPoints3, 2),
        _c = _controlPoints4[0],
        _c2 = _controlPoints4[1];
      // if (controlPointsVisible) {
      //     _controls(ctx, [c1, c2])
      // }
      fn(ctx, [t, next], [_c, _c2], index++);
    }
  };
  var total = loop ? len : len - 1;
  for (var i = 0; i < total; i++) {
    var t = points[i];
    var next = points[(i + 1) % len];
    if (depth === 1) {
      callFn(i, t, next);
    } else {
      var curr = t;
      for (var j = 1; j <= depth; j++) {
        var p = (0, _math._lerp)(t, next, j / depth);
        callFn(i, curr, p);
        curr = p;
      }
    }
  }
};
// 折线
exports._loop = _loop;
var _polyline = function _polyline(ctx, _ref59) {
  var _ref59$points = _ref59.points,
    points = _ref59$points === void 0 ? [] : _ref59$points,
    loop = _ref59.loop,
    _ref59$step = _ref59.step,
    step = _ref59$step === void 0 ? 1 : _ref59$step;
  _moveTo(ctx, points[0]);
  if (step === 1) {
    // 性能优化
    var len = points.length;
    for (var i = 1; i < len; i++) {
      var t = points[i];
      _lineTo(ctx, t);
    }
    if (loop) {
      ctx.closePath();
    }
  } else {
    if (step > 1) {
      loop = true;
    }
    // for lines
    _loop(ctx, {
      points: points,
      step: step,
      loop: loop
    }, function (ctx, ps) {
      _polylineTo(ctx, ps);
    });
  }
};
// 曲线
exports._polyline = _polyline;
var _bezier = function _bezier(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _curveTo);
};
exports._bezier = _bezier;
var _leftBezier = function _leftBezier(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _leftCurveTo);
};
// 双曲线
exports._leftBezier = _leftBezier;
var _doubleBezier = function _doubleBezier(ctx, options) {
  _loop(ctx, options, _doubleCurveTo);
};
exports._doubleBezier = _doubleBezier;
var _symmetry = function _symmetry(ctx, options, fn1, fn2) {
  _loop(ctx, options, function (ctx, _ref60, _ref61, index) {
    var _ref62 = _slicedToArray(_ref60, 2),
      t = _ref62[0],
      next = _ref62[1];
    var _ref63 = _slicedToArray(_ref61, 2),
      c1 = _ref63[0],
      c2 = _ref63[1];
    _moveTo(ctx, t);
    if (index % 2 === 0) {
      fn1(ctx, [t, next], [c1, c2]);
    } else {
      fn2(ctx, [t, next], [c1, c2]);
    }
  });
};
var _symmetryBezier = function _symmetryBezier(ctx, options) {
  _symmetry(ctx, options, _curveTo, _leftCurveTo);
};
// 锯齿
var _sawtooth = function _sawtooth(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _sawtoothTo);
};
exports._sawtooth = _sawtooth;
var _leftSawtooth = function _leftSawtooth(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _leftSawtoothTo);
};
// 双锯齿
exports._leftSawtooth = _leftSawtooth;
var _doubleSawtooth = function _doubleSawtooth(ctx, options) {
  _loop(ctx, options, _doubleSawtoothTo);
};
exports._doubleSawtooth = _doubleSawtooth;
var _symmetrySawtooth = function _symmetrySawtooth(ctx, options) {
  _symmetry(ctx, options, _sawtoothTo, _leftSawtoothTo);
};
// 直角拐线
var _rightAngle = function _rightAngle(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _rightAngleTo);
};
var _leftAngle = function _leftAngle(ctx, options) {
  var points = options.points;
  _moveTo(ctx, points[0]);
  _loop(ctx, options, _leftAngleTo);
};
var _symmetryAngle = function _symmetryAngle(ctx, options) {
  _symmetry(ctx, options, _rightAngleTo, _leftAngleTo);
};
var _circle = function _circle(ctx, options) {
  _loop(ctx, options, _circleTo);
};
// 菱形
var _diamond = function _diamond(ctx, options) {
  _loop(ctx, options, _diamondTo);
};
// 十字
var _cross = function _cross(ctx, options) {
  _loop(ctx, options, _crossTo);
};
// 三角形
var _triangle = function _triangle(ctx, options) {
  _loop(ctx, options, _triangleTo);
};
var _leftTriangle = function _leftTriangle(ctx, options) {
  _loop(ctx, options, _leftTriangleTo);
};
// 半圆
var _semicircle = function _semicircle(ctx, options) {
  _loop(ctx, options, _semicircleTo);
};
var _leftSemicircle = function _leftSemicircle(ctx, options) {
  _loop(ctx, options, _leftSemicircleTo);
};
// 向心弧线
var _centripetal = function _centripetal(ctx, options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  _moveTo(ctx, points[0]);
  _loop(ctx, options, function (ctx, ps) {
    var _ps = _slicedToArray(ps, 2),
      t = _ps[0],
      next = _ps[1];
    _quadraticCurveTo(ctx, [o, next]);
  });
};
// 向心弧线
var _leftCentripetal = function _leftCentripetal(ctx, options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  _moveTo(ctx, points[0]);
  _loop(ctx, options, function (ctx, ps) {
    var _ps2 = _slicedToArray(ps, 2),
      t = _ps2[0],
      next = _ps2[1];
    var c = (0, _math._mid)(t, next);
    var oo = (0, _mirror2._mirror)(o, c);
    return _quadraticCurveTo(ctx, [oo, next]);
  });
};
var _doubleCentripetal = function _doubleCentripetal(ctx, options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  _loop(ctx, options, function (ctx, ps) {
    var _ps3 = _slicedToArray(ps, 2),
      t = _ps3[0],
      next = _ps3[1];
    _moveTo(ctx, t);
    _quadraticCurveTo(ctx, [o, next]);
    _moveTo(ctx, t);
    var c = (0, _math._mid)(t, next);
    var oo = (0, _mirror2._mirror)(o, c);
    return _quadraticCurveTo(ctx, [oo, next]);
  });
};
// 正玄
var _sin = function _sin(ctx, _ref64) {
  var points = _ref64.points,
    loop = _ref64.loop;
  var ps = (0, _sin2.sinPoints)(points, {});
  _polyline(ctx, {
    points: ps,
    loop: loop
  });
};
// 互切圆
var _tangentCircle = function _tangentCircle(ctx, options) {
  var points = options.points;
  var o = (0, _math._o)(points);
  _moveTo(ctx, points[0]);
  _loop(ctx, options, function (ctx, ps) {
    var _ps4 = _slicedToArray(ps, 2),
      t = _ps4[0],
      next = _ps4[1];
    var c = (0, _math._mid)(t, next);
    var r = (0, _math._disToSeg)(c, [o, next]);
    ctx.beginPath();
    ctx.arc(c[0], c[1], r, 0, 2 * Math.PI);
    ctx.stroke();
  });
};
// 杨辉三角
var _sierpinski = function _sierpinski(ctx, _ref65) {
  var points = _ref65.points,
    depth = _ref65.depth,
    amplitude = _ref65.amplitude,
    loop = _ref65.loop,
    discrete = _ref65.discrete;
  var matrix = (0, _sierpinski2.sierpinskiPoints)({
    points: points,
    depth: depth,
    amplitude: amplitude,
    loop: loop,
    discrete: discrete
  });
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: (0, _math.plainMatrix)(matrix),
    iter: function iter(_ref66) {
      var points = _ref66.points;
      _polylineTo(ctx, points);
    },
    init: function init(_ref67) {
      var point = _ref67.point;
      _moveTo(ctx, point);
    },
    loop: loop
  });
};
// 杨辉三角贝塞尔
var _sierpinskiBezier = function _sierpinskiBezier(ctx, _ref68) {
  var points = _ref68.points,
    depth = _ref68.depth,
    amplitude = _ref68.amplitude,
    loop = _ref68.loop,
    discrete = _ref68.discrete;
  var matrix = (0, _sierpinski2.sierpinskiPoints)({
    points: points,
    depth: depth,
    amplitude: amplitude,
    loop: loop,
    discrete: discrete
  });
  var n = 4;
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: (0, _math.plainMatrix)(matrix),
    n: n,
    iter: function iter(_ref69) {
      var _ref69$points = _slicedToArray(_ref69.points, 4),
        p1 = _ref69$points[0],
        p2 = _ref69$points[1],
        p3 = _ref69$points[2],
        p4 = _ref69$points[3];
      _bezierCurveTo(ctx, [p2, p3, p4]);
    },
    init: function init(_ref70) {
      var point = _ref70.point;
      _moveTo(ctx, point);
    },
    loop: loop
  }).join(' ');
};
var _sierpinskiDiscreteBezier = function _sierpinskiDiscreteBezier(ctx, _ref71) {
  var points = _ref71.points,
    depth = _ref71.depth,
    amplitude = _ref71.amplitude,
    loop = _ref71.loop;
  var matrix = (0, _sierpinski2.sierpinskiPoints)({
    points: points,
    depth: depth,
    amplitude: amplitude,
    loop: loop
  });
  var n = 4;
  var discrete = true;
  var ps = (0, _math.plainMatrix)(matrix);
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: ps,
    n: n,
    iter: function iter(_ref72) {
      var _ref72$points = _slicedToArray(_ref72.points, 4),
        p1 = _ref72$points[0],
        p2 = _ref72$points[1],
        p3 = _ref72$points[2],
        p4 = _ref72$points[3];
      _moveTo(ctx, p1);
      _bezierCurveTo(ctx, [p2, p3, p4]);
    },
    loop: loop,
    discrete: discrete
  });
};
var _koch = function _koch(ctx, _ref73) {
  var points = _ref73.points,
    depth = _ref73.depth,
    skew = _ref73.skew,
    amplitude = _ref73.amplitude,
    loop = _ref73.loop,
    discrete = _ref73.discrete;
  var ps = (0, _koch2.kochPoints)({
    points: points,
    depth: depth,
    skew: skew,
    amplitude: amplitude,
    loop: loop,
    discrete: discrete
  });
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: ps,
    iter: function iter(_ref74) {
      var points = _ref74.points;
      return _polylineTo(ctx, points);
    },
    init: function init(_ref75) {
      var point = _ref75.point;
      return _moveTo(ctx, point);
    },
    loop: loop
  });
};
var _kochCurve = function _kochCurve(ctx, _ref76) {
  var points = _ref76.points,
    depth = _ref76.depth,
    skew = _ref76.skew,
    amplitude = _ref76.amplitude,
    loop = _ref76.loop,
    discrete = _ref76.discrete;
  var ps = (0, _koch2.kochPoints)({
    points: points,
    depth: depth,
    skew: skew,
    amplitude: amplitude,
    loop: loop,
    discrete: discrete
  });
  var n = 3;
  var iter = function iter(_ref77) {
    var _ref77$points = _slicedToArray(_ref77.points, 3),
      p1 = _ref77$points[0],
      p2 = _ref77$points[1],
      p3 = _ref77$points[2];
    _quadraticCurveTo(ctx, [p2, p3]);
  };
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: ps,
    n: n,
    iter: iter,
    init: function init(_ref78) {
      var point = _ref78.point;
      return _moveTo(ctx, point);
    },
    loop: loop
  });
};
var _kochDiscreteCurve = function _kochDiscreteCurve(ctx, _ref79) {
  var points = _ref79.points,
    depth = _ref79.depth,
    skew = _ref79.skew,
    amplitude = _ref79.amplitude,
    loop = _ref79.loop;
  var ps = (0, _koch2.kochPoints)({
    points: points,
    depth: depth,
    skew: skew,
    amplitude: amplitude,
    loop: loop
  });
  var n = 5;
  var iter = function iter(_ref80) {
    var _ref80$points = _slicedToArray(_ref80.points, 5),
      p1 = _ref80$points[0],
      p2 = _ref80$points[1],
      p3 = _ref80$points[2],
      p4 = _ref80$points[3],
      p5 = _ref80$points[4];
    _moveTo(ctx, p1);
    _quadraticCurveTo(ctx, [p2, p3]);
    _quadraticCurveTo(ctx, [p4, p5]);
  };
  var discrete = true;
  (0, _traversal2._traversal)({
    ctx: ctx,
    points: ps,
    n: n,
    iter: iter,
    loop: loop,
    discrete: discrete
  });
};
var curveMapFn = {
  bezier: _bezier,
  leftBezier: _leftBezier,
  doubleBezier: _doubleBezier,
  symmetryBezier: _symmetryBezier,
  sawtooth: _sawtooth,
  leftSawtooth: _leftSawtooth,
  doubleSawtooth: _doubleSawtooth,
  symmetrySawtooth: _symmetrySawtooth,
  rightAngle: _rightAngle,
  leftAngle: _leftAngle,
  symmetryAngle: _symmetryAngle,
  circle: _circle,
  diamond: _diamond,
  cross: _cross,
  triangle: _triangle,
  leftTriangle: _leftTriangle,
  semicircle: _semicircle,
  leftSemicircle: _leftSemicircle,
  centripetal: _centripetal,
  leftCentripetal: _leftCentripetal,
  doubleCentripetal: _doubleCentripetal,
  none: _polyline,
  sin: _sin,
  tangentCircle: _tangentCircle,
  rough: _rough2._rough,
  sierpinski: _sierpinski,
  sierpinskiBezier: _sierpinskiBezier,
  sierpinskiDiscreteBezier: _sierpinskiDiscreteBezier,
  koch: _koch,
  kochCurve: _kochCurve,
  kochDiscreteCurve: _kochDiscreteCurve
};
// 曲线
var _curve = function _curve(_ref81) {
  var ctx = _ref81.ctx,
    points = _ref81.points,
    curve = _ref81.curve,
    _ref81$loop = _ref81.loop,
    loop = _ref81$loop === void 0 ? false : _ref81$loop,
    _ref81$step = _ref81.step,
    step = _ref81$step === void 0 ? 1 : _ref81$step,
    discrete = _ref81.discrete;
  var type,
    amplitude = 1,
    skew = 0,
    depth = 1,
    controlPointsVisible = false;
  if (typeof curve === 'string') {
    type = curve;
  } else if ((0, _utils.isObject)(curve)) {
    type = curve.type;
    amplitude = curve.amplitude;
    skew = curve.skew;
    depth = curve.depth;
    controlPointsVisible = curve.controlPointsVisible;
  }
  var fn = curveMapFn[type] || _polyline;
  if (step > 1) {
    loop = true;
  }
  fn(ctx, {
    points: points,
    loop: loop,
    step: step,
    skew: skew,
    amplitude: amplitude,
    depth: depth,
    controlPointsVisible: controlPointsVisible,
    discrete: discrete
  });
};
exports._curve = _curve;