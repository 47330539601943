"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._webgl = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _registShape = require("./registShape");
var initGl = function initGl(canvas) {
  var gl;
  try {
    gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    // gl.viewportWidth = canvas.width;
    // gl.viewportHeight = canvas.height;
    // gl.viewport
  } catch (e) {}
  if (!gl) {
    alert('Could not initialise WebGL, sorry :-(');
  }
  return gl;
};
// interface IWebglOptions {
//   width: string| number;
//   height:string | number;
//   shapes: any[]
// }
var _webgl = function _webgl(options) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$width = options.width,
    width = _options$width === void 0 ? 800 : _options$width,
    _options$height = options.height,
    height = _options$height === void 0 ? 600 : _options$height,
    shapes = options.shapes;
  var canvas = document.createElement('canvas');
  canvas.width = typeof width === 'number' ? width : parseFloat(width);
  canvas.height = typeof height === 'number' ? height : parseFloat(height);
  var gl = initGl(canvas);
  if (gl) {
    shapes.forEach(function (t) {
      var fn = (0, _registShape.mapShapeFn)(t.shape);
      fn && fn(gl, t, props);
    });
  }
  return canvas;
};
exports._webgl = _webgl;