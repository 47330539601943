"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StrokeMode = void 0;
// export type StrokeMode = 'normal' | 'innerStroke' | 'outerStroke'
var StrokeMode;
exports.StrokeMode = StrokeMode;
(function (StrokeMode) {
  StrokeMode["InnerStroke"] = "innerStroke";
  StrokeMode["OuterStroke"] = "outerStroke";
  StrokeMode["Normal"] = "normal";
})(StrokeMode || (exports.StrokeMode = StrokeMode = {}));