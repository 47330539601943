"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._path = void 0;
require("core-js/modules/es.array.fill.js");
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
var _canvas = require("./canvas");
var _common = require("@/common");
var _mirror2 = require("../pental/mirror");
var _mark2 = require("./mark");
var _fill2 = require("./fill");
var _path = function _path(ctx, options, props) {
  ctx.save();
  ctx.beginPath();
  props && (0, _canvas._props)(ctx, props);
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    points = options.points,
    mirror = options.mirror,
    centre = options.centre,
    vertex = options.vertex,
    labels = options.labels,
    radius = options.radius,
    excircle = options.excircle,
    incircle = options.incircle,
    links = options.links,
    edge = options.edge,
    edgeExtension = options.edgeExtension,
    mark = options.mark,
    fill = options.fill;
  if (edge) {
    if ((0, _common.isVisible)(edge)) {
      (0, _canvas._edge)(ctx, options, Object.assign(Object.assign({}, props), edge));
    }
  } else {
    (0, _canvas._edge)(ctx, options, props);
  }
  // 边延长线
  if ((0, _common.isVisible)(edgeExtension)) {
    (0, _canvas._edgeExtension)(ctx, {
      points: points,
      edgeExtension: edgeExtension
    }, Object.assign(Object.assign({}, props), edgeExtension));
  }
  // 顶点
  if ((0, _common.isVisible)(vertex)) {
    (0, _canvas._vertex)(ctx, points, Object.assign(Object.assign({}, props), vertex));
  }
  // 中心点
  if ((0, _common.isVisible)(centre)) {
    var _o2 = (0, _math._o)(points);
    (0, _canvas._centre)(ctx, _o2, Object.assign(Object.assign({}, props), centre));
  }
  // 半径
  if ((0, _common.isVisible)(radius)) {
    (0, _canvas._radius)(ctx, {
      points: points
    }, Object.assign(Object.assign({}, props), radius));
  }
  // 外切圆
  if ((0, _common.isVisible)(excircle)) {
    (0, _canvas._excircle)(ctx, {
      o: o,
      r: r
    }, Object.assign(Object.assign({}, props), excircle));
  }
  // 内切圆
  if ((0, _common.isVisible)(incircle)) {
    (0, _canvas._incircle)(ctx, {
      o: o,
      points: points
    }, Object.assign(Object.assign({}, props), incircle));
  }
  // 链接线
  if ((0, _common.isVisible)(links)) {
    (0, _canvas._links)(ctx, points, Object.assign(Object.assign({}, props), links));
  }
  // 文字
  if ((0, _common.isVisible)(labels)) {
    (0, _canvas._texts)(ctx, points, Object.assign(Object.assign({}, props), labels));
  }
  // 标注
  if ((0, _common.isVisible)(mark)) {
    (0, _mark2._mark)(ctx, {
      points: points,
      mark: mark
    }, Object.assign(Object.assign({}, props), mark));
  }
  // 镜像，被mirrorPoints代替
  if ((0, _common.isVisible)(mirror)) {
    (0, _mirror2._mirror)(ctx, options, props);
  }
  if ((0, _common.isVisible)(fill)) {
    (0, _fill2._fill)(ctx, {
      points: points,
      fill: fill
    }, Object.assign(Object.assign({}, props), fill));
  }
  // }
  ctx.restore();
};
exports._path = _path;