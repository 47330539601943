"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleAside = exports.showAside = exports.hideAside = void 0;
var _dom = require("../core/dom");
// import { store } from '../store'

var toggleAside = function toggleAside() {
  // for H5
  // if (store.isH5) {
  var aside = (0, _dom._query)('.aside');
  (0, _dom._toggleStyle)(aside, {
    left: 0
  }, {
    left: '-220px'
  });
  // }
};
exports.toggleAside = toggleAside;
var hideAside = function hideAside() {
  var aside = (0, _dom._query)('.aside');
  (0, _dom._style)(aside, {
    width: '0px'
  });
};
exports.hideAside = hideAside;
var showAside = function showAside() {
  var aside = (0, _dom._query)('.aside');
  (0, _dom._style)(aside, {
    width: '180px'
  });
};
exports.showAside = showAside;