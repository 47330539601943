"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visibleInspector = exports.toggleInspector = exports.rerenderInspector = exports.inspectorList = exports.inspectorFnMap = exports.hideInspector = exports._graphic = exports._grammar = exports._g2 = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var _btn2 = require("../core/btn");
var _dom = require("../core/dom");
var _inspector2 = require("./inspector");
var _utils = require("../../utils");
var _clipboard = require("../../utils/clipboard");
var _math = require("../../math");
var _download = _interopRequireDefault(require("@/assets/icon/download.svg"));
var _grammar2 = _interopRequireDefault(require("@/assets/icon/grammar.svg"));
var _config = _interopRequireDefault(require("@/assets/icon/config.svg"));
var _saver = require("@/saver");
var _helper = require("../helper");
var _openList = require("./openList");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var visibleMap = {};
var inspectorList = ['inspector', 'grammar', 'graphic'];
exports.inspectorList = inspectorList;
inspectorList.forEach(function (t) {
  return [visibleMap[t] = true];
});
var _hide = function _hide(item) {
  (0, _dom._removeClassName)(item, 'active');
};
var isVisible = function isVisible(item) {
  (0, _dom._addClassName)(item, 'active');
};
var _hideRightAside = function _hideRightAside() {
  var rightside = (0, _dom._query)('.main .main-right');
  (0, _dom._style)(rightside, {
    width: 0
  });
};
var isVisibleRightAside = function isVisibleRightAside() {
  var rightside = (0, _dom._query)('.main .main-right');
  (0, _dom._style)(rightside, {
    width: '200px'
  });
};
var isRightAsideShow = function isRightAsideShow() {
  var rightside = (0, _dom._query)('.main .main-right');
  return rightside.style.width === '200px';
};
var hideInspector = function hideInspector() {
  inspectorList.forEach(function (t) {
    var item = (0, _dom._query)(".main .".concat(t));
    if (item) {
      _hide(item);
      visibleMap[t] = true;
    }
  });
  _hideRightAside();
};
exports.hideInspector = hideInspector;
var visibleInspector = function visibleInspector(key) {
  inspectorList.forEach(function (t) {
    var item = (0, _dom._query)(".main .".concat(t));
    if (item) {
      _hide(item);
      visibleMap[t] = true;
      if (t === key) {
        if (visibleMap[key]) {
          isVisible(item);
          visibleMap[key] = false;
          isVisibleRightAside();
        }
      }
    }
  });
};
exports.visibleInspector = visibleInspector;
var toggleInspector = function toggleInspector(key) {
  if (isRightAsideShow()) {
    var item = (0, _dom._query)(".main .".concat(key, ".active"));
    if (item) {
      _hide(item);
      _hideRightAside();
      var icon = (0, _dom._query)(".".concat(key === 'inspector' ? 'config' : key, "_enter svg"));
      // icon && icon.removeAttribute('style')
      if (icon) {
        (0, _dom._removeClassName)(icon, 'active');
      }
    } else {
      inspectorList.forEach(function (t) {
        var item = (0, _dom._query)(".main .".concat(t));
        if (item) {
          if (t === key) {
            isVisible(item);
          } else {
            _hide(item);
          }
        }
      });
    }
  } else {
    var _item = (0, _dom._query)(".main .".concat(key));
    isVisible(_item);
    isVisibleRightAside();
  }
};
exports.toggleInspector = toggleInspector;
var fill = function fill(e) {
  var el = e.target;
  var g2 = (0, _dom._closest)(el, '.g2');
  var icons = (0, _dom._queryAll)('svg', g2);
  icons.forEach(function (t) {
    // t.removeAttribute('style')
    (0, _dom._removeClassName)(t, 'active');
  });
  var svg = (0, _dom._closest)(el, 'svg');
  // _style(svg, {
  //     fill: 'var(--primary-color-active)'
  // })
  (0, _dom._addClassName)(svg, 'active');
};
var _g2 = function _g2() {
  var grammar = _dom.dom.svg(_grammar2.default, {
    class: 'grammar_enter',
    width: '25',
    height: '25'
  }, {
    click: function click(e) {
      fill(e);
      toggleInspector('grammar');
    }
  });
  var graphic = _dom.dom.svg(_download.default, {
    class: 'graphic_enter',
    width: '25',
    height: '25'
  }, {
    click: function click(e) {
      fill(e);
      toggleInspector('graphic');
    }
  });
  var config = _dom.dom.svg(_config.default, {
    class: 'config_enter',
    width: '25',
    height: '25'
  }, {
    click: function click(e) {
      fill(e);
      toggleInspector('inspector');
    }
  });
  var g2 = _dom.dom.div([grammar, graphic, config], {
    class: 'g2'
  });
  return g2;
};
// 语法
exports._g2 = _g2;
var _grammar = function _grammar(t) {
  var content = t.content;
  if (!content) return;
  var data = JSON.stringify(content);
  var str = data.replace(/.*?,/g, function (a) {
    return "".concat(a, "<br>");
  });
  var grammar = _dom.dom.div(str, {
    class: 'grammar-content',
    isHtml: true
  });
  var btn = (0, _btn2._btn)('saveAs', {
    class: 'primary'
  }, {
    click: function click() {
      (0, _utils.exportJSON)(data, t.name);
    }
  });
  return _dom.dom.div([grammar, btn], {
    class: ''
  });
};
// 图形
exports._grammar = _grammar;
var _graphic = function _graphic(t) {
  var name = t.name;
  // const panel = _query(`.screen-viewer .panel[name=${name}]`)
  var btn = (0, _btn2._btn)('copy svg', {
    class: 'primary'
  }, {
    click: function click() {
      var _getCurrentGraphicDat = (0, _helper.getCurrentGraphicData)(),
        data = _getCurrentGraphicDat.data,
        type = _getCurrentGraphicDat.type;
      if (type === _saver.GraphicDataType.svg) {
        (0, _clipboard.copyToClipBoard)(data);
      }
    }
  });
  var btn2 = (0, _btn2._btn)('save svg', {
    class: 'primary'
  }, {
    click: function click() {
      // 重新获取数据
      // const { data, type } = getCurrentGraphicData()
      // if (type === GraphicDataType.svg) {
      //     saveSvg({ svg: data, name })
      // } else if (type === GraphicDataType.canvas) {
      //     saveCanvasAsPng({ canvas: data, name })
      // }
      var data = (0, _helper.getCheckedOpenList)();
      (0, _saver.saveSvgFiles)(data);
    }
  });
  var btn3 = (0, _btn2._btn)('save png', {
    class: 'primary'
  }, {
    click: function click() {
      // 重新获取数据
      // const { data, type, size } = getCurrentGraphicData()
      // saveCanvasOrSvgAsPng({
      //     data,
      //     type, size, name
      // })
      var data = (0, _helper.getCheckedOpenList)();
      (0, _saver.savePngFiles)(data);
    }
  });
  var btn4 = (0, _btn2._btn)('save pdf', {
    class: 'primary'
  }, {
    click: function click() {
      // 全部打开的图形
      // const data = getGraphicDataList()
      // 打开列表
      var data = (0, _helper.getCheckedOpenList)();
      (0, _saver.saveAsPdf)(data);
      // 当前图形
      // const { data, type, size } = getCurrentGraphicData()
      // saveCanvasOrSvgAsPdf({ data, type, size, name })
    }
  });

  var output = (0, _helper.getCurrentGraphicData)();
  var info = _dom.dom.html("filetype:".concat(output.type, " <br> size:").concat((0, _math._k)((0, _helper.getDataLength)(output) / 1024), "kb"));
  var graphic = _dom.dom.div(info, {
    class: 'graphic-content',
    rolename: name
  });
  var openListBox = (0, _openList.getOpenListBox)();
  // output.type === GraphicDataType.svg ? : [btn3, btn4]
  var btns = _dom.dom.div([btn, btn2, btn3, btn4], {
    class: 'btns-group'
  });
  return _dom.dom.div([graphic, btns, openListBox], {
    class: ''
  });
};
exports._graphic = _graphic;
(0, _dom._events)(document.body, {
  mousedown: function mousedown(e) {
    var el = e.target;
    var inScreen = ['screen-viewer', 'nav', 'navtree', 'toolbar', 'grammar', 'graphic'].concat(inspectorList).some(function (t) {
      return (0, _dom._closest)(el, ".".concat(t));
    });
    if (inScreen) return;
    hideInspector();
  }
});
var inspectorFnMap = {
  inspector: _inspector2._inspector,
  grammar: _grammar,
  graphic: _graphic
};
exports.inspectorFnMap = inspectorFnMap;
var rerenderInspector = function rerenderInspector(t) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : inspectorList;
  list.forEach(function (key) {
    var conent = inspectorFnMap[key](t);
    var container = (0, _dom._query)(".main .".concat(key));
    container && (0, _dom._content)(container, conent);
  });
};
exports.rerenderInspector = rerenderInspector;