"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._tabs = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _dom = require("./dom");
var _rotute = require("../rotute");
var _renderContent2 = require("../renderContent");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var _tabs = function _tabs(config) {
  var tabs = config.tabs,
    activeName = config.activeName;
  // 初始化路由
  var route = (0, _rotute.getRoute)();
  if (route) {
    activeName = route;
  }
  var activeBar = _dom.dom.div('', {
    class: 'tabs__active-bar'
  });
  var updateActiveBar = function updateActiveBar(el, t) {
    // 等dom加载完毕后计算
    setTimeout(function () {
      var offsetLeft = el.offsetLeft;
      var width = el.offsetWidth;
      activeBar.setAttribute('style', "width:".concat(width, "px;transform: translateX(").concat(offsetLeft, "px);"));
    });
    // 更新路由
    (0, _rotute.updateRoute)(t);
  };
  var isActive = function isActive(t) {
    return t.name === activeName;
  };
  var items = tabs.map(function (t) {
    var item = _dom.dom.div(t.title, {
      class: 'tabs__item' + (isActive(t) ? ' active' : ''),
      name: t.name
    }, {
      click: function click(e) {
        var el = e.target;
        updateActiveBar(el, t);
        panels.forEach(function (panel) {
          var role = (0, _dom._attr)(panel, 'role');
          if (role === t.name) {
            panel.classList.add('active');
          } else {
            panel.classList.remove('active');
          }
        });
      }
    });
    if (isActive(t)) {
      updateActiveBar(item, t);
    }
    return item;
  });
  var tabsHeader = _dom.dom.div([activeBar].concat(_toConsumableArray(items)), {
    class: 'tabs__header'
  });
  var panels = tabs.map(function (t) {
    var _renderContent = (0, _renderContent2.renderContent)(t),
      content = _renderContent.content,
      isHtml = _renderContent.isHtml;
    var panel;
    if (typeof content === 'function') {
      panel = _dom.dom.div('', {
        role: t.name,
        class: 'tabpanel' + (isActive(t) ? ' active' : ''),
        isHtml: isHtml
      });
      content(panel);
    } else {
      panel = _dom.dom.div(content, {
        role: t.name,
        class: 'tabpanel' + (isActive(t) ? ' active' : ''),
        isHtml: isHtml
      });
    }
    return panel;
  });
  var tabsContent = _dom.dom.div(panels, {
    class: 'tabs__content'
  });
  var container = _dom.dom.div([tabsHeader, tabsContent], {
    class: 'tabs'
  });
  return container;
};
exports._tabs = _tabs;