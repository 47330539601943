"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MotionType = void 0;
// export enum Motion {}
var MotionType;
exports.MotionType = MotionType;
(function (MotionType) {
  MotionType["Rotate"] = "rotate";
  MotionType["Dashoffset"] = "dashoffset";
})(MotionType || (exports.MotionType = MotionType = {}));