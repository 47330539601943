"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._squareFractal = void 0;
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.map.js");
var _color = require("@/color");
var _math = require("@/math");
var _polar2 = require("@/algorithm/polar");
var _svg = require("../core/svg");
var _pathShape = require("../pathShape");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
var _squareFractal = function _squareFractal(options, props) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [400, 300] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a,
    _options$fractalLevel = options.fractalLevel,
    fractalLevel = _options$fractalLevel === void 0 ? 3 : _options$fractalLevel,
    assemble = options.assemble,
    _options$color = options.color,
    color = _options$color === void 0 ? 'rd' : _options$color,
    pathMode = options.pathMode;
  var n = 4;
  var points = (0, _polar2.polarPoints)({
    o: o,
    r: r,
    a: a,
    n: n
  })[0];
  var children = [];
  // const sf = pointsPath({
  //     points,
  //     closed: true
  // }, {
  //     stroke: 'blue',
  //     fill: 'none',
  //     ...props
  // })
  var sf = (0, _pathShape.createSvgPathShape)(Object.assign(Object.assign({}, options), {
    points: points,
    mode: pathMode
  }), Object.assign({
    stroke: 'blue',
    fill: 'none'
  }, props));
  children.push(sf);
  if (fractalLevel > 0) {
    var colors = (0, _color._colors)(color, n, 0.5);
    var sfs = points.map(function (t, i) {
      var r2 = r / 2;
      var o2 = assemble ? t : (0, _math._polar)(t, r2, a + i * 360 / n);
      // (i+2) %n
      var a2 = a;
      return _squareFractal(Object.assign(Object.assign({}, options), {
        o: o2,
        r: r / 2,
        a: a2,
        fractalLevel: fractalLevel - 1,
        assemble: assemble,
        color: color
      }), {
        fill: colors[i] //i === 0 ? colors[i] : 'none' //'red'
      });
    });

    children.push.apply(children, _toConsumableArray(sfs));
  }
  return (0, _svg._g)(children);
};
exports._squareFractal = _squareFractal;