"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typesMap = exports.strokeModeTypes = exports.selectFields = exports.rotatableShape = exports.propsSplitChar = exports.multiLevelProps = exports.motionTypes = exports.motionOrigins = exports.layoutableShape = exports.keysOrder = exports.defaultProps = exports.axisTypes = exports.animationTypes = exports._selectOptions = exports._groupField = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.array.map.js");
var _color = require("@/color");
var _tree = require("@/algorithm/tree");
var _fractal = require("@/svg/fractal/fractal");
var _transform = require("@/math/transform");
var _layout = require("@/algorithm/layout");
var _factory = require("@/svg/factory");
var _labels = require("./labels");
var _layout2 = require("./layout");
var _fill = require("@/algorithm/fill");
var _mirror = require("@/algorithm/mirror");
var _curve = require("@/svg/core/curve");
var _pathMode = require("../types/pathMode");
var _sierpinski = require("@/algorithm/sierpinski");
var _utils = require("@/utils");
var _curve2 = require("@/algorithm/curve");
var axisTypes = ['none', 'axis', 'grid', 'polar'];
// 默认right 向内 left向外
// export const curveTypes = ['none', 'bezier', 'leftBezier', 'doubleBezier',
//     'sawtooth', 'leftSawtooth', 'doubleSawtooth',
//     'semicircle', 'leftSemicircle', 'circle', 'diamond', 'cross',
//     'triangle', 'leftTriangle', 'rightAngle', 'leftAngle', 'centripetal',
//     'leftCentripetal', 'doubleCentripetal', 'tangentCircle',
//     'sin', 'rough']
// export const mirrorTypes = ['none', 'vertex', 'edge', 'edgeMid', 'radiusMid', 'leftVertex','topVertex']
exports.axisTypes = axisTypes;
var animationTypes = ['none', 'rotate', 'scale', 'skewX', 'skewY', 'translate', 'path'];
exports.animationTypes = animationTypes;
var motionTypes = animationTypes;
exports.motionTypes = motionTypes;
var motionOrigins = ['centre', 'vertex'];
exports.motionOrigins = motionOrigins;
var monocolors = ['none', 'black', 'gray', 'red', 'blue', 'purple', 'green']; //,'yellow'
var strokeTypes = monocolors;
var fillTypes = monocolors;
var fillRuleTypes = ['none', 'nonzero', 'evenodd'];
var strokeLinecapTypes = ['none', 'butt', 'round', 'square'];
var strokeLinejoinTypes = ['none', 'miter', 'round', 'bevel'];
var strokeModeTypes = ['normal', 'outerStroke', 'innerStroke'];
exports.strokeModeTypes = strokeModeTypes;
var typesMap = {
  fractalTypes: _fractal.fractalTypes,
  transformTypes: _transform.transformTypes,
  patternTypes: _factory.patternTypes,
  colorTypes: _color.colorTypes,
  strokeTypes: strokeTypes,
  fillTypes: fillTypes,
  axisTypes: axisTypes,
  layoutTypes: _layout.layoutTypes,
  treeCurveTypes: _tree.treeCurveTypes,
  pathModeTypes: _pathMode.pathModeTypes,
  curveTypes: _curve.curveTypes,
  mirrorTypes: _mirror.mirrorTypes,
  animationTypes: animationTypes,
  motionTypes: motionTypes,
  motionOrigins: motionOrigins,
  labelsTypes: _labels.labelsTypes,
  layoutAngles: _layout2.layoutAngles,
  fillPatterns: _fill.fillPatterns,
  mirrorRefractions: _mirror.mirrorRefractions,
  bottomControlGearTypes: _sierpinski.bottomControlGearTypes,
  fillRuleTypes: fillRuleTypes,
  strokeLinecapTypes: strokeLinecapTypes,
  strokeLinejoinTypes: strokeLinejoinTypes,
  curveShapeTypes: _curve2.curveShapeTypes,
  strokeModeTypes: strokeModeTypes
};
// 参数顺序
exports.typesMap = typesMap;
var keysOrder = ['shape', 'n', 'r', 'a', 'm', 'transform', 'pathModel', 'curve', 'edge', 'vertex', 'centre', 'excircle', 'incircle', 'labels', 'linkCross', 'radius', 'links', 'pattern', 'color', 'colorful', 'alpha', 'fill', 'props_.*?', 'axis', 'layout', 'axiom', 'rules_.*?', 'props', 'mirror', 'fractal', 'animation', 'motion'];
// 默认参数
exports.keysOrder = keysOrder;
var defaultProps = [{
  field: 'shape',
  type: 'string',
  disabled: true
}, {
  field: 'n',
  type: 'number',
  min: 1,
  max: 999
}, {
  field: 'r',
  type: 'number',
  min: 1,
  max: 999999
}, {
  field: 'a',
  type: 'number'
}, {
  field: 'm',
  min: 1,
  max: 999
}];
// 多层级属性
exports.defaultProps = defaultProps;
var multiLevelProps = ['color', 'curve', 'edge', 'edgeExtension', 'vertex', 'centre', 'excircle', 'incircle', 'labels', 'rules', 'links', 'radius', 'props', 'mirror', 'fractal', 'animation', 'motion', 'layout', 'axis', 'mark', 'fill'];
// 分割符
exports.multiLevelProps = multiLevelProps;
var propsSplitChar = '_';
// 属于多层级属性
exports.propsSplitChar = propsSplitChar;
var getMulitLevelPropRoot = function getMulitLevelPropRoot(field) {
  var flag = multiLevelProps.some(function (t) {
    var pref = "".concat(t).concat(propsSplitChar);
    return field.indexOf(pref) === 0;
  });
  if (flag) {
    return field.split(propsSplitChar)[0];
  }
  return '';
};
var _groupField = function _groupField(field, item) {
  var group = getMulitLevelPropRoot(field);
  if (group) {
    Object.assign(item, {
      group: group,
      label: field.replace(new RegExp("".concat(group, "_")), '')
    });
  }
};
// 下拉选项字段
exports._groupField = _groupField;
var selectFields = ['fractal', 'transform', 'pattern', 'color', 'stroke', 'fill', 'axis', 'layout', 'treeCurve', 'pathMode', 'curve', 'mirror', 'mirror_type', 'mirror_refraction', 'animation_type', 'motion_type', 'motion_origin', 'curve_type', 'labels_type', 'color_type', 'layout_type', 'layout_angle', 'axis_type', 'fill_pattern', 'bottomControlGear', 'fillRule', 'strokeLinecap', 'strokeLinejoin', 'heartType', 'curveShape', 'strokeMode'];
exports.selectFields = selectFields;
var _selectOptions = function _selectOptions(field, item) {
  // 下拉选项
  selectFields.forEach(function (t) {
    var reg = new RegExp("([a-z]+_)?".concat(t, "$"));
    if (reg.test(field)) {
      //field === t
      var types;
      if (typesMap["".concat(t, "Types")]) {
        types = typesMap["".concat(t, "Types")] || [];
      } else if (field.indexOf('_') >= 0) {
        types = typesMap["".concat((0, _utils.snake2camel)(field), "s")] || [];
      }
      if (Array.isArray(types)) {
        item.options = types.map(function (t) {
          return {
            label: t,
            value: t
          };
        });
        item.type = 'select';
      }
    }
  });
};
// 能旋转的图形
// 会自动加角度a参数
exports._selectOptions = _selectOptions;
var rotatableShape = ['polygon', 'ray', 'triangle', 'square', 'pentagon', 'hexagon', 'heptagon', 'spiral', 'polar', 'star', 'leaf', 'gridRect', 'gridPolygon'];
// 需要layout和axis的图形
exports.rotatableShape = rotatableShape;
var layoutableShape = ['polygon', 'ray', 'triangle', 'square', 'pentagon', 'hexagon', 'heptagon', 'spiral', 'polar', 'star', 'leaf', 'wanhuachi', 'curve', 'wave', 'elliptical', 'sin', 'tree', 'plant', 'dragon', 'isometric', 'koch', 'mitsubishi', 'peano', 'polarShape', 'spiralShape', 'ringShape', 'rayShape', 'isometricShape', 'polygonShape', 'lsystem', 'lsystemPlant'];
exports.layoutableShape = layoutableShape;