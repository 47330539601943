"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/store");
var _ToolAbstract = require("./tools/ToolAbstract");
var _screen2 = (0, _store._screen)(),
  width = _screen2.width,
  height = _screen2.height;
var editorDefaultConfig = {
  svgRootW: width,
  svgRootH: height,
  svgStageW: width,
  svgStageH: height,
  tool: _ToolAbstract.ToolType.Select,
  fill: '#fff',
  stroke: '#000',
  strokeWidth: '1px',
  frameSelectFill: 'rgba(200, 200, 200, .2)',
  frameSelectStroke: '#888',
  outlineColor: '#5183fb',
  scaleGridSize: 7
};
var _default = editorDefaultConfig;
exports.default = _default;