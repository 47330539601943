"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fibonacciSequence = exports.fibonacciPoints = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.object.assign.js");
var _math = require("@/math");
/**
 * // 斐波那契螺旋线 黄金螺旋线
斐波那契螺旋线，也称“黄金螺旋”，是斐波那契数列画出来螺旋曲线，
自然界中存在许多斐波那契螺旋线的图案，是自然界最完美的经典黄金比例。
作图规则是在以斐波那契数为边的正方形拼成的长方形中画一个90度的扇形，连起来的弧线就是斐波那契螺旋线。
它来源于斐波那契数列（FibonacciSequence），又称黄金螺旋分割。
注意斐波那契数列是【1，1，2，3，5，8，13，21，34，55，89，144，233，377，610，987】
,F表示数）
 */
//优化的斐波那契数列计算，把数列结果用数组保存
//用传统递归太慢了太费内存了
var fibonacciSequence = function fibonacciSequence(n) {
  var fib_val = [0, 1];
  var fib = function fib(n) {
    var len = fib_val.length;
    for (var i = len; i <= n; i++) {
      fib_val.push(fib_val[i - 1] + fib_val[i - 2]);
    }
    return fib_val[n];
  };
  fib(n);
  return fib_val;
};
exports.fibonacciSequence = fibonacciSequence;
var fibonacciPoints = function fibonacciPoints(options) {
  var _fn = function _fn(options) {
    var o = options.o,
      r = options.r,
      a = options.a,
      n = options.n,
      _options$increasedAgn = options.increasedAgnle,
      increasedAgnle = _options$increasedAgn === void 0 ? 90 : _options$increasedAgn;
    var fs = fibonacciSequence(n);
    var p0 = (0, _math._polar)(o, r, a);
    var points = [p0];
    for (var i = 0; i < n; i++) {
      var p = (0, _math._polar)(o, r * fs[i + 1], -1 * increasedAgnle * (i + 1) + a);
      points.push(p);
    }
    return points;
  };
  var m = options.m;
  return Array.from({
    length: m
  }).map(function (t, i) {
    var a = i * 360 / m;
    return _fn(Object.assign(Object.assign({}, options), {
      a: a
    }));
  });
};
exports.fibonacciPoints = fibonacciPoints;