"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.xlinkns = exports.toTextOptions = exports.svgns = exports.createSvg = exports._use = exports._text = exports._symbol = exports._rect = exports._patternId = exports._path = exports._marker = exports._line = exports._g = exports._defs = exports._circle = void 0;
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.concat.js");
var _utils = require("@/utils");
var _dom = require("@/ui/core/dom");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var svgns = 'http://www.w3.org/2000/svg';
exports.svgns = svgns;
var xlinkns = 'http://www.w3.org/1999/xlink';
exports.xlinkns = xlinkns;
var svgKebabProps = ['fillRule', 'fontSize', 'textAnchor', 'dominantBaseline', 'strokeWidth', 'strokeDasharray', 'strokeDashoffset', 'strokeLinecap', 'strokeLinejoin', 'strokeMiterlimit'];
// 属性设置规则：
// 默认值排除父节点影响情况下
// 1、默认值相同，缺省删除属性 opacity="1" stroke-dasharray="0" （）
// 2、排除fill ，其他属性。值为none时，删除该属性
// 3、关联属性，当strokeLinejoin===''miter' 时strokeMiterlimit才有效
var _shouldRemoveDefaultProps = function _shouldRemoveDefaultProps(key, val) {
  // 默认值为1
  if (key === 'opacity' && Number(val) === 1) {
    return true;
  }
  // 默认值为0
  else if (['strokeDasharray', 'strokeDashoffset', 'strokeMiterlimit'].includes(key) && Number(val) === 0) {
    return true;
  }
  // 排除fill ，其他属性。值为none时，删除该属性
  else if (!['fill'].includes(key) && val === 'none') {
    return true;
  }
  return false;
};
var createSvg = function createSvg(tag, props, events, children) {
  var el = document.createElementNS(svgns, tag);
  var _props = function _props(props) {
    var keys = Object.keys(props);
    keys.forEach(function (t) {
      var key = svgKebabProps.includes(t) ? (0, _utils.camel2kebab)(t) : t;
      var val = props[t];
      if (val !== undefined) {
        switch (key.toLocaleLowerCase()) {
          case 'class':
            // el.className = val
            el.setAttribute('classname', val);
            break;
          case 'innertext':
          case 'id':
          case 'innerhtml':
          case 'value':
          case 'textcontent':
            el[key] = val;
            break;
          // case 'pattern':
          //     el.setAttribute('fill', `url("#${val}")`)
          //     break;
          case 'click':
            el.addEventListener('click', val, false);
            break;
          case 'xlink:href':
            //case 'marker-end':
            el.setAttributeNS(xlinkns, key, val);
            break;
          default:
            if (_shouldRemoveDefaultProps(t, val)) {
              el.removeAttribute(key);
            } else {
              el.setAttribute(key, val);
            }
            // el.setAttributeNS(xlinkns, key, val);
            break;
        }
      }
    });
  };
  props && _props(props);
  // 事件
  events && (0, _dom._events)(el, events);
  children && (0, _dom._children)(el, children);
  return el;
};
// 全局定义
exports.createSvg = createSvg;
var _defs = function _defs(children, props) {
  return createSvg('defs', props, {}, children);
};
//  use 需要在defs之后才有效
// 使用use标签时，注意use的结尾标签，不要在</use>结尾标签之前再写其他标签，会显现不出来，就是所谓的被吞了。
exports._defs = _defs;
var _use = function _use(_ref) {
  var id = _ref.id,
    _ref$x = _ref.x,
    x = _ref$x === void 0 ? 0 : _ref$x,
    _ref$y = _ref.y,
    y = _ref$y === void 0 ? 0 : _ref$y;
  return createSvg('use', {
    x: x,
    y: y,
    // x: 0,
    // y: 0,
    // width: '100%',
    // height: '100%',
    'xlink:href': '#' + id
    // xlink:href=“#id”
  });
};
exports._use = _use;
var _symbol = function _symbol(children) {
  return createSvg('symbol', {
    id: 'shape'
  }, null, children);
};
exports._symbol = _symbol;
var _circle = function _circle(options, props, events, children) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 100 : _options$r,
    name = options.name,
    id = options.id;
  return createSvg('circle', Object.assign({
    cx: o[0],
    cy: o[1],
    r: r,
    name: name,
    id: id
  }, props), events, children);
};
exports._circle = _circle;
var getPoints = function getPoints(options) {
  var points = [];
  if (Array.isArray(options)) {
    points = options;
  } else if ((0, _utils._type)(options) === 'object') {
    points = options.points || [];
  }
  return points;
};
// { stroke: 'black', fill: 'none' }
var _line = function _line(options, props, events, children) {
  var points = getPoints(options);
  if (points.length === 2) {
    var _points = _slicedToArray(points, 2),
      p1 = _points[0],
      p2 = _points[1];
    return createSvg('line', Object.assign({
      x1: p1[0],
      y1: p1[1],
      x2: p2[0],
      y2: p2[1]
    }, props), events, children);
  } else {
    return createSvg('polyline', Object.assign({
      points: points.join(',')
    }, props));
  }
  // todo
};
exports._line = _line;
var _rect = function _rect(options, props, events, children) {
  var points = getPoints(options);
  var renderRect = function renderRect(p1, p2) {
    return createSvg('rect', Object.assign({
      x: Math.min(p1[0], p2[0]),
      y: Math.min(p1[1], p2[1]),
      width: Math.abs(p2[0] - p1[0]),
      height: Math.abs(p2[1] - p1[1])
    }, props), events, children);
  };
  if (points.length === 2) {
    var _points2 = _slicedToArray(points, 2),
      p1 = _points2[0],
      p2 = _points2[1];
    return renderRect(p1, p2);
  }
  // todo
};
exports._rect = _rect;
var _path = function _path(d, props, events, children) {
  return createSvg('path', Object.assign({
    d: d
  }, props), events, children);
};
exports._path = _path;
var toTextOptions = function toTextOptions(_ref2, text) {
  var _ref3 = _slicedToArray(_ref2, 2),
    x = _ref3[0],
    y = _ref3[1];
  return {
    x: x,
    y: y,
    text: text
  };
};
// 文本
exports.toTextOptions = toTextOptions;
var _text = function _text(options, props, events, children) {
  var fn = function fn(x, y, text, props, events, children) {
    return createSvg('text', Object.assign({
      x: x,
      y: y,
      textContent: String(text)
    }, props), events, children);
  };
  // if (Array.isArray(options)) {
  //     let [x, y, text] = options
  //     return fn(x, y, text, props, events, children)
  // } else {
  var x = options.x,
    y = options.y,
    text = options.text;
  return fn(x, y, text, props, events, children);
  // }
};
exports._text = _text;
var _g = function _g(children, props) {
  return createSvg('g', Object.assign({}, props), {}, children);
};
exports._g = _g;
var _marker = function _marker(children, props) {
  return createSvg('marker', Object.assign({}, props), {}, children);
};
exports._marker = _marker;
var _patternId = function _patternId(id, name) {
  return "".concat(name, "_").concat(id);
};
exports._patternId = _patternId;