"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._footPoint = exports._disToSeg = exports._dis = exports._deg = exports._cos = exports._atan = exports._angleToRad = exports._angleRad = exports._addVec = void 0;
exports._k = _k;
exports.vertical = exports.unitMatrix = exports.translateY = exports.translateX = exports.translate = exports.rotatePoints = exports.rotate = exports.plainMatrix = exports.isPoints = exports.isPointMatrix = exports.isPoint = exports.includedAngle = exports.equals = exports._verticalVec = exports._vec = exports._tan = exports._squarePoints = exports._sin = exports._scale = exports._rotate = exports._random = exports._rad = exports._polarRad = exports._polar = exports._o = exports._moveDis = exports._move = exports._mid = exports._lerp = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/web.dom-collections.for-each.js");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
//四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
// keepTwoDecimal
function _k(num) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (decimal == null) {
    return num;
  }
  var g = Math.pow(10, decimal);
  return Math.round(num * g) / g;
}
var isPoint = function isPoint(p) {
  return Array.isArray(p);
};
// 二维数组 points [p1,p2]
// 点队列 vector
exports.isPoint = isPoint;
var isPoints = function isPoints(p) {
  return Array.isArray(p) && p.every(function (t) {
    return isPoint(t);
  });
};
// 点阵  [points1,points2]
// matrix ( a2-D array)
exports.isPoints = isPoints;
var isPointMatrix = function isPointMatrix(p) {
  return Array.isArray(p) && p.every(function (t) {
    return isPoints(t);
  });
};
// 转弧度
exports.isPointMatrix = isPointMatrix;
var _rad = function _rad(angle) {
  return angle * Math.PI / 180;
};
exports._rad = _rad;
var _deg = function _deg(r) {
  return _k(r * 180 / Math.PI);
};
// 正玄
exports._deg = _deg;
var _sin = function _sin(angle, decimal) {
  return _k(Math.sin(_rad(angle)), decimal);
};
// 余玄
exports._sin = _sin;
var _cos = function _cos(angle, decimal) {
  return _k(Math.cos(_rad(angle)), decimal);
};
exports._cos = _cos;
var _tan = function _tan(angle, decimal) {
  return _k(Math.tan(_rad(angle)), decimal);
};
// 极坐标
exports._tan = _tan;
var _polar = function _polar() {
  var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  var r = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var a = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return [o[0] + r * _cos(a), o[1] + r * _sin(a)].map(function (t) {
    return _k(t);
  });
};
exports._polar = _polar;
var _polarRad = function _polarRad() {
  var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  var r = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var a = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return [o[0] + r * Math.cos(a), o[1] + r * Math.sin(a)].map(function (t) {
    return _k(t);
  });
};
exports._polarRad = _polarRad;
var _midPoint = function _midPoint(p1, p2) {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2].map(function (t) {
    return _k(t);
  });
};
// 中点 midpoint
var _mid = function _mid(p1, p2) {
  if (isPoints(p1)) {
    var n = p1.length;
    return p1.map(function (t, index) {
      var next = p1[(index + 1) % n];
      return _midPoint(t, next);
    });
  }
  return _midPoint(p1, p2);
};
// 距离
exports._mid = _mid;
var _dis = function _dis() {
  var p1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  var p2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [0, 0];
  var decimal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var dx = p1[0] - p2[0];
  var dy = p1[1] - p2[1];
  return _k(Math.sqrt(dx * dx + dy * dy), decimal);
};
// 相等
exports._dis = _dis;
var equals = function equals(p1, p2) {
  var equals = true;
  for (var i = p1.length - 1; i >= 0; --i) {
    if (p1[i] != p2[i]) {
      equals = false;
      break;
    }
  }
  return equals;
};
// 夹角 toAngle
exports.equals = equals;
var _angleRad = function _angleRad() {
  var p1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  var p2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [0, 0];
  return Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
};
exports._angleRad = _angleRad;
var _atan = function _atan() {
  var p1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  var p2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [0, 0];
  return _deg(_angleRad(p1, p2));
};
// 三点夹角
exports._atan = _atan;
var _angleToRad = function _angleToRad(A, O, B) {
  var OA = _dis(O, A);
  var OB = _dis(O, B);
  var AB = _dis(A, B);
  var cosO = (OA * OA + OB * OB - AB * AB) / (2 * OA * OB);
  return Math.acos(cosO);
};
// 三点夹角
// angleTo
// cosO = (OA*OA + OB*OB - AB*AB ) / 2*OA*OB
exports._angleToRad = _angleToRad;
var includedAngle = function includedAngle(A, O, B) {
  return _deg(_angleToRad(A, O, B));
};
// 中心点 _o
exports.includedAngle = includedAngle;
var _o = function _o(points) {
  var n = points.length;
  var sum = points.reduce(function (sum, t) {
    sum[0] += t[0];
    sum[1] += t[1];
    return sum;
  }, [0, 0]);
  return sum.map(function (t) {
    return t / n;
  });
};
// 放大缩小
exports._o = _o;
var _scale = function _scale(p, amplitude, o) {
  if (isPoints(p)) {
    var o2 = o || _o(p);
    return p.map(function (t) {
      return _scale(t, amplitude, o2);
    });
  }
  var deta = [p[0] - o[0], p[1] - o[1]];
  return [o[0] + deta[0] * amplitude, o[1] + deta[1] * amplitude];
};
// 线段平移
// 移动  相对距离
exports._scale = _scale;
var _move = function _move(p, from, to, radio) {
  var deta = [to[0] - from[0], to[1] - from[1]];
  if (isPoints(p)) {
    if (radio) {
      var points = p.map(function (t) {
        return [t[0] + deta[0], t[1] + deta[1]];
      });
      return _scale(points, radio);
    }
    return p.map(function (t) {
      return [t[0] + deta[0], t[1] + deta[1]];
    });
  } else {
    return [p[0] + deta[0], p[1] + deta[1]];
  }
};
// // 移动 绝对距离r
exports._move = _move;
var _moveDis = function _moveDis(p, o, r) {
  var a = _angleRad(o, p);
  return _polarRad(p, r, a);
};
exports._moveDis = _moveDis;
var translateX = function translateX(points) {
  var x = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  if (isPoints(points)) {
    return points.map(function (t) {
      return translateX(t, x);
    });
  }
  return [points[0] + x, points[1]];
};
exports.translateX = translateX;
var translateY = function translateY(points) {
  var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  if (isPoints(points)) {
    return points.map(function (t) {
      return translateY(t, y);
    });
  }
  return [points[0], points[1] + y];
};
// 平移
// [number, number] | IPoint | number
exports.translateY = translateY;
var translate = function translate(points, options) {
  if (isPoints(points)) {
    return points.map(function (t) {
      return translate(t, options);
    });
  }
  if (Array.isArray(options)) {
    var _options = _slicedToArray(options, 2),
      x = _options[0],
      y = _options[1];
    return [points[0] + x, points[1] + y];
  } else if (_typeof(options) === 'object') {
    var _x = options.x,
      _y = options.y;
    return [points[0] + _x, points[1] + _y];
  }
  if (typeof options === 'number') {
    return translateX(points, options);
  }
};
// 旋转 围绕o点旋转a角度
exports.translate = translate;
var rotate = function rotate(points, o, a) {
  if (isPoints(points)) {
    return points.map(function (t) {
      return rotate(t, o, a);
    });
  }
  var r = _dis(points, o);
  return _polar(o, r, a);
};
// 其中（rx0，ry0）是旋转中心，（x，y）待旋转点，（x0，y0）旋转后的新坐标。
exports.rotate = rotate;
var _rotate = function _rotate(p, o, a) {
  if (a === 0) return p;
  var _p = _slicedToArray(p, 2),
    x = _p[0],
    y = _p[1];
  var _o2 = _slicedToArray(o, 2),
    rx0 = _o2[0],
    ry0 = _o2[1];
  var x0 = (x - rx0) * _cos(a) - (y - ry0) * _sin(a) + rx0;
  var y0 = (x - rx0) * _sin(a) + (y - ry0) * _cos(a) + ry0;
  return [x0, y0].map(function (t) {
    return _k(t);
  });
};
// 线段中点旋转
exports._rotate = _rotate;
var rotatePoints = function rotatePoints(points, a, o) {
  var o2 = o || _o(points);
  return points.map(function (t) {
    return _rotate(t, o2, a);
  });
};
exports.rotatePoints = rotatePoints;
var _lerp = function _lerp(p1, p2, d) {
  if (Array.isArray(p1)) {
    return p1.map(function (t, i) {
      return _k(t + (p2[i] - t) * d);
    });
  } else if (typeof p1 === 'number' && typeof p2 === 'number') {
    return _k(p1 + (p2 - p1) * d);
  }
};
// 垂直,转90度
exports._lerp = _lerp;
var vertical = function vertical(points) {
  var v = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  if (isPoints(points)) {
    var len = points.length;
    return points.map(function (t, index) {
      return vertical(t, index === 0 || index === len ? 1 : -1);
    });
  }
  return [points[1], v * points[0]];
};
// 数组降维
exports.vertical = vertical;
var plainMatrix = function plainMatrix(matrix) {
  var infinite = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var points = [];
  matrix.forEach(function (t) {
    return points.push.apply(points, _toConsumableArray(t));
  });
  return points;
  // if (isPoints(matrix)) {
  //     let ps = []
  //     if (infinite) {
  //         // 降至1维
  //         matrix.forEach(t => {
  //             if (isPoints(t)) {
  //                 ps.push(...plainMatrix(t, infinite))
  //             } else {
  //                 ps.push(t)
  //             }
  //         });
  //     } else {
  //         // 降1维
  //         matrix.forEach(t => ps.push(...t))
  //     }
  //     return ps
  // } else if (Array.isArray(matrix)) {
  //     return matrix
  // }
};
// 坐标
// 给点阵标记单位坐标
exports.plainMatrix = plainMatrix;
var unitMatrix = function unitMatrix(matrix) {
  var result = [];
  if (isPoints(matrix)) {
    matrix.forEach(function (t, y) {
      return result.push(t.map(function (t, x) {
        return [x, y];
      }));
    });
  } else if (Array.isArray(matrix)) {
    result = matrix.map(function (t, i) {
      return i;
    });
  }
  return result;
};
// 随机数
exports.unitMatrix = unitMatrix;
var _random = function _random(start, end) {
  if (Array.isArray(start)) {
    var index = Math.floor(Math.random() * start.length);
    return start[index];
  }
  if (typeof start === 'number') {
    if (typeof end === 'number') {
      return Math.floor(Math.random() * (end !== null && end !== void 0 ? end : 1 - start) + start);
    }
    return Math.floor(Math.random() * (1 - start) + start);
  }
  return undefined;
};
// 转向量
exports._random = _random;
var _vec = function _vec() {
  var p1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var p2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return p1.map(function (t, i) {
    return p2[i] - t;
  });
};
exports._vec = _vec;
var _rotateVec = function _rotateVec(v1, radians) {
  var s = Math.sin(radians),
    c = Math.cos(radians);
  var _v = _slicedToArray(v1, 2),
    x = _v[0],
    y = _v[1];
  return [x * c - y * s, y * c + x * s];
};
var _verticalVec = function _verticalVec() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return _rotateVec(v, -90);
};
exports._verticalVec = _verticalVec;
var _addVec = function _addVec() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var p = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return v.map(function (t, i) {
    return t + p[i];
  });
};
// 知一边，求正方形四点
exports._addVec = _addVec;
var _squarePoints = function _squarePoints(p1, p2) {
  var a = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var v = _vec(p1, p2);
  var vv = _rotateVec(v, -90 + a);
  return [p1, p2, _addVec(vv, p2), _addVec(vv, p1)];
};
// 点到线段的距离
exports._squarePoints = _squarePoints;
var _disToSeg = function _disToSeg(p, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    o = _ref2[0],
    t = _ref2[1];
  var a = _angleToRad(p, o, t);
  return _dis(p, o) * Math.sin(a);
};
// 点到线段的垂足点
exports._disToSeg = _disToSeg;
var _footPoint = function _footPoint(p, _ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    o = _ref4[0],
    t = _ref4[1];
  var a = _angleToRad(p, o, t);
  var r = _dis(p, o) * Math.cos(a);
  return _polarRad(o, r, a + _angleRad(o, p));
};
exports._footPoint = _footPoint;