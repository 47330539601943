"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colorsConfig = exports.colorTypes = exports.colorCircle = exports._colors = void 0;
exports.createColorList = createColorList;
exports.rgbaWrapper = exports.randomColor = exports.genColors = exports.genColor = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.keys.js");
var _math = require("@/math");
var _utils = require("@/utils");
var _colorTransfer = require("./colorTransfer");
var _color = require("@/types/color");
var _colorsConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var rgbaWrapper = function rgbaWrapper(rgbaArr) {
  //[1,1,1,1]
  return (rgbaArr.length === 4 ? 'rgba(' : 'rgb(') + rgbaArr + ')';
};
//色相环
exports.rgbaWrapper = rgbaWrapper;
var colorCircle = function colorCircle() {
  var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 36;
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var a = 0;
  var step = 360 / n;
  var arr = [];
  for (var i = 0; i < n; i++) {
    a += step;
    var r = (0, _math._cos)(a) * 127 + 128 << 0;
    var g = (0, _math._cos)(a + 120) * 127 + 128 << 0;
    var b = (0, _math._cos)(a + 240) * 127 + 128 << 0;
    arr[arr.length] = rgbaWrapper((0, _utils.isUndefined)(alpha) ? [r, g, b] : [r, g, b, alpha]);
  }
  return arr;
};
exports.colorCircle = colorCircle;
var genColor = function genColor(color, alpha) {
  //分类颜色
  //红 100
  //绿 010
  //青 001
  //黄 110
  //紫 101
  //全彩 111
  //黑白灰 000
  var colorMap = {
    red: [1, 0, 0],
    green: [0, 1, 0],
    syan: [0, 0, 1],
    yellow: [1, 1, 0],
    purple: [1, 0, 1],
    gray: [0, 0, 0],
    colorful: [1, 1, 1]
  };
  var arr = color in colorMap ? colorMap[color] : colorMap['colorful'];
  var _arr = arr,
    _arr2 = _slicedToArray(_arr, 3),
    r = _arr2[0],
    g = _arr2[1],
    b = _arr2[2];
  if ((r & g & b) === 1) {
    //全彩
    arr = arr.map(function () {
      return Math.random() * 255 << 0;
    });
  } else if (r + g + b === 0) {
    //灰
    var t = Math.random() * 255 << 0;
    arr = [t, t, t];
  } else {
    var rgb = 155;
    var c = (Math.random() * (255 - rgb) << 0) + rgb;
    arr = arr.map(function (t) {
      return t === 1 ? (Math.random() * (255 - rgb) << 0) + rgb : Math.random() * (c / 2) << 0;
    });
  }
  if (alpha) arr[arr.length] = alpha;
  // this.color =this.rgbaWrapper(arr)
  return rgbaWrapper(arr);
};
exports.genColor = genColor;
var random = Math.random;
/**
 * 获取随机颜色
 * @returns { Object } 颜色对象
 */
var randomColor = function randomColor() {
  return {
    r: random() * 255,
    g: random() * 255,
    b: random() * 255,
    a: random() * 1
  };
};
// 生成颜色
exports.randomColor = randomColor;
var genColors = function genColors(color) {
  var n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var alpha = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var colors = [];
  for (var i = 0; i < n; i++) {
    colors[colors.length] = genColor(color, alpha);
  }
  return colors;
};
// const redColors = (n, alpha) => {
//     return genColors('red', n, alpha)
// }
// const greenColors = (n, alpha) => {
//     return genColors('green', n, alpha)
// }
exports.genColors = genColors;
var colorsConfig = (_colorsConfig = {}, _defineProperty(_colorsConfig, _color.Color.None, ''), _defineProperty(_colorsConfig, _color.Color.ColorCircle, colorCircle), _colorsConfig);
exports.colorsConfig = colorsConfig;
['red', 'green', 'syan', 'purple', 'gray', 'random'].forEach(function (t) {
  colorsConfig["".concat(t, "Colors")] = function (n, alpha) {
    return genColors(t, n, alpha);
  };
});
var colorTypes = Object.keys(colorsConfig);
exports.colorTypes = colorTypes;
var _colors = function _colors(color, n) {
  var alpha = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return colorsConfig[color] ? colorsConfig[color](n, alpha) : colorCircle(n, alpha);
};
// let colors = colorsConfig[color] ? colorsConfig[color](n, 0.5) : colorCircle(n, 0.5)
//生成11*11的二维数组（颜色）要求：色卡左上角为白色，左下角为黑色，右上角为输入的颜色，右下角亮度为0输入颜色
exports._colors = _colors;
function createColorList(h, n, m) {
  var list = [];
  for (var i = 0; i <= 10; i++) {
    for (var y = 0; y <= 10; y++) {
      if (y === 0) {
        list[i] = [];
      }
      var s = y * (n / 10);
      var l = 100 - i * 10;
      var _m = m - m / 10 * i;
      l = l - y * ((l - _m) / 10);
      var color = (0, _colorTransfer.hsvToRgb)([h, s / 100, l / 100]);
      var item = 'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')';
      list[i].push(item);
    }
  }
  return list;
}