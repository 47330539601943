"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._layout = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.assign.js");
var _dom = require("./dom");
// 根据css选择器 创建节点
// .a .b
var _layout = function _layout(selector) {
  var root = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.body;
  if (Array.isArray(selector) && selector.length) {
    return selector.map(function (t) {
      return _layout(t, root);
    });
  } else if (typeof selector === 'string') {
    var index = selector.indexOf(' ');
    var first = index > 0 ? selector.slice(0, index) : selector;
    var rest = index > 0 ? selector.slice(index + 1) : '';
    var el = (0, _dom._query)(first, root);
    if (!el && first) {
      el = _dom.dom.div('', Object.assign({}, (0, _dom.toProp)(first)));
      (0, _dom._append)(root, el);
    }
    if (rest && el) {
      _layout(rest, el);
    }
    return (0, _dom._query)(selector, root);
  }
  return selector;
};
exports._layout = _layout;