"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mitsubishiPoints = exports.mitsubishi = exports.kochPoints = exports.koch = void 0;
require("core-js/modules/es.array.map.js");
var _polar = require("@/algorithm/polar");
var _math = require("@/math");
var _pathShape = require("../pathShape");
var _utils = require("@/utils");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var turnout = function turnout(p1, p2) {
  var _p = _slicedToArray(p1, 2),
    x1 = _p[0],
    y1 = _p[1];
  var _p2 = _slicedToArray(p2, 2),
    x2 = _p2[0],
    y2 = _p2[1];
  var x3 = (x2 - x1) / 3 + x1;
  var y3 = (y2 - y1) / 3 + y1;
  var x4 = (x2 - x1) / 3 * 2 + x1;
  var y4 = (y2 - y1) / 3 * 2 + y1;
  var p5 = (0, _math._rotate)([x4, y4], [x3, y3], -60);
  return [p1, [x3, y3], p5, [x4, y4], p2];
  // return [p1, [x3, y3], [...p5,x4, y4], p2]
};

var kochPoints = function kochPoints(options) {
  var _options$o = options.o,
    o = _options$o === void 0 ? [300, 200] : _options$o,
    _options$r = options.r,
    r = _options$r === void 0 ? 200 : _options$r,
    _options$n = options.n,
    n = _options$n === void 0 ? 3 : _options$n,
    _options$depth = options.depth,
    depth = _options$depth === void 0 ? 3 : _options$depth,
    _options$a = options.a,
    a = _options$a === void 0 ? 0 : _options$a;
  var points = (0, _polar.polarPoints)({
    o: o,
    r: r,
    n: n,
    a: a
  })[0];
  var _iter = function _iter(ps, depth) {
    var len = ps.length;
    if (depth === 0) {
      var result = ps.map(function (t, index) {
        var next = ps[(index + 1) % len];
        return turnout(t, next);
      });
      return (0, _math.plainMatrix)(result);
    } else {
      var _result = ps.map(function (t, index) {
        var next = ps[(index + 1) % len];
        return _iter(turnout(t, next), depth - 1);
      });
      return (0, _math.plainMatrix)(_result);
    }
  };
  return _iter(points, depth);
};
exports.kochPoints = kochPoints;
var koch = (0, _pathShape.createPathFn)(kochPoints);
// 内拐
exports.koch = koch;
var turnin = function turnin(p1, p2) {
  var _p3 = _slicedToArray(p1, 2),
    x1 = _p3[0],
    y1 = _p3[1];
  var _p4 = _slicedToArray(p2, 2),
    x2 = _p4[0],
    y2 = _p4[1];
  var x3 = (x2 - x1) / 3 + x1;
  var y3 = (y2 - y1) / 3 + y1;
  var x4 = (x2 - x1) / 3 * 2 + x1;
  var y4 = (y2 - y1) / 3 * 2 + y1;
  // let x5 = x3 + ((x2 - x1) - (y2 - y1) * Math.sqrt(3)) / 6;
  // let y5 = y3 + ((x2 - x1) * Math.sqrt(3) + (y2 - y1)) / 6;
  // return [p1, [x3, y3], [x5, y5], [x4, y4], p2]
  var p5 = (0, _math._rotate)([x4, y4], [x3, y3], 60);
  return [p1, [x3, y3], p5, [x4, y4], p2];
};
// 三菱
var mitsubishiPoints = function mitsubishiPoints(options) {
  var _options$o2 = options.o,
    o = _options$o2 === void 0 ? [300, 200] : _options$o2,
    _options$r2 = options.r,
    r = _options$r2 === void 0 ? 200 : _options$r2,
    _options$n2 = options.n,
    n = _options$n2 === void 0 ? 3 : _options$n2,
    _options$depth2 = options.depth,
    depth = _options$depth2 === void 0 ? 3 : _options$depth2,
    _options$a2 = options.a,
    a = _options$a2 === void 0 ? 0 : _options$a2;
  var points = (0, _polar.polarPoints)({
    o: o,
    r: r,
    n: n,
    a: a
  });
  var result = [];
  var _iter = function _iter(ps, depth) {
    if (depth === 0) {
      (0, _utils.eachNext)(ps, function (t, index, next) {
        result.push.apply(result, _toConsumableArray(turnin(t, next)));
      });
    } else {
      (0, _utils.eachNext)(ps, function (t, index, next) {
        _iter(turnin(t, next), depth - 1);
      });
    }
  };
  _iter(points, depth);
  return result;
};
exports.mitsubishiPoints = mitsubishiPoints;
var mitsubishi = (0, _pathShape.createPathFn)(mitsubishiPoints);
exports.mitsubishi = mitsubishi;