"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._tree = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _dom = require("./dom");
var _tree = function _tree(treeData, render) {
  var levelLimited = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
  var fn = function fn(treeData, render, level) {
    var items = [];
    Array.isArray(treeData) && treeData.forEach(function (t) {
      var title = render ? render(t, level) : t.title;
      var children = [title];
      if (levelLimited === -1 || level < levelLimited) {
        if (t.children) {
          children.push(fn(t.children, render, level + 1));
        }
      }
      items.push(_dom.dom.li(children));
    });
    var ul = _dom.dom.ul(items);
    return ul;
  };
  return fn(treeData, render, 0);
};
exports._tree = _tree;