"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.regexp.exec.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.kochPoints = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.slice.js");
var _math = require("../math");
var _controlPoints3 = require("./controlPoints");
var _traversal2 = require("./traversal");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _koch = function _koch(_ref, depth, skew, amplitude) {
  var _ref2 = _slicedToArray(_ref, 2),
    from = _ref2[0],
    to = _ref2[1];
  var _controlPoints = (0, _controlPoints3.controlPoints)([from, to], skew, amplitude),
    _controlPoints2 = _slicedToArray(_controlPoints, 2),
    c1 = _controlPoints2[0],
    c2 = _controlPoints2[1];
  var p1 = (0, _math._lerp)(from, to, 1 / 3);
  var p2 = (0, _math._lerp)(from, to, 2 / 3);
  var ps = [from, p1, c1, p2, to];
  if (depth === 0) {
    return [from];
  } else if (depth === 1) {
    return ps;
  } else {
    depth -= 1;
    var ps2 = [];
    ps.forEach(function (t, i) {
      var next = ps[i + 1];
      if (next) {
        var arr = _koch([t, next], depth, skew, amplitude);
        if (i === 0) {
          ps2.push.apply(ps2, _toConsumableArray(arr));
        } else {
          ps2.push.apply(ps2, _toConsumableArray(arr.slice(1)));
        }
      }
    });
    return ps2;
  }
};
var kochPoints = function kochPoints(_ref3) {
  var points = _ref3.points,
    _ref3$depth = _ref3.depth,
    depth = _ref3$depth === void 0 ? 0 : _ref3$depth,
    _ref3$skew = _ref3.skew,
    skew = _ref3$skew === void 0 ? 0 : _ref3$skew,
    _ref3$amplitude = _ref3.amplitude,
    amplitude = _ref3$amplitude === void 0 ? 1 : _ref3$amplitude,
    _ref3$loop = _ref3.loop,
    loop = _ref3$loop === void 0 ? false : _ref3$loop,
    _ref3$discrete = _ref3.discrete,
    discrete = _ref3$discrete === void 0 ? false : _ref3$discrete;
  var len = points.length;
  var result = [];
  var iter = function iter(_ref4) {
    var _ref4$points = _slicedToArray(_ref4.points, 2),
      t = _ref4$points[0],
      next = _ref4$points[1],
      i = _ref4.index;
    var ps = _koch([t, next], depth, skew, amplitude);
    if (i === 0) {
      result.push.apply(result, _toConsumableArray(ps));
    } else if (i === len - 1) {
      result.push.apply(result, _toConsumableArray(ps.slice(1, ps.length - 1)));
    } else {
      result.push.apply(result, _toConsumableArray(ps.slice(1)));
    }
  };
  var n = 2;
  (0, _traversal2._traversal)({
    points: points,
    n: n,
    loop: loop,
    iter: iter,
    discrete: discrete
  });
  return result;
};
exports.kochPoints = kochPoints;