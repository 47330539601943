"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._stripe = exports._diagonalStripe = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var _svg = require("../core/svg");
var _path2 = require("../core/path");
// 条纹 竖直
var _stripe = function _stripe(options) {
  var name = options.name,
    _options$id = options.id,
    id = _options$id === void 0 ? 'shape-stripe' : _options$id,
    _options$r = options.r,
    r = _options$r === void 0 ? 10 : _options$r,
    _options$color = options.color1,
    color1 = _options$color === void 0 ? 'red' : _options$color,
    _options$color2 = options.color2,
    color2 = _options$color2 === void 0 ? 'green' : _options$color2,
    _options$radio = options.radio,
    radio = _options$radio === void 0 ? 0.2 : _options$radio,
    _options$skewX = options.skewX,
    skewX = _options$skewX === void 0 ? 0 : _options$skewX;
  id = (0, _svg._patternId)(id, name);
  // let d = "M0,0 H10 L 20,10  V-10 Z"
  // let d = "M0,0 H10  V10 Z"
  // 三角形
  // let d = `M0,0 h${r} v${r} Z`
  // _path(d, {
  //     fill: stripeColor1
  // }, stripe)
  // let d2 = `M${r},${r} h${r} v${r} Z`
  // _path(d2, {
  //     fill: stripeColor2
  // }, stripe)
  var d = "M0,0 h".concat(r * radio, " v").concat(r, " h").concat(r * radio * -1, " Z");
  var path1 = (0, _svg._path)(d, {
    fill: color1,
    transform: "skewX(".concat(skewX, ")")
  });
  var d2 = "M".concat(r, ",0 h").concat(r * radio, " v").concat(r, " h").concat(r * radio * -1, " Z");
  var path2 = (0, _svg._path)(d2, {
    fill: color2,
    transform: "skewX(".concat(skewX, ")")
  });
  var stripe = (0, _svg.createSvg)('pattern', {
    id: id,
    x: 0,
    y: 0,
    width: r * 2,
    height: r,
    patternUnits: 'userSpaceOnUse'
  }, {}, [path1, path2]);
  var defs = (0, _svg._defs)(stripe);
  return defs;
};
// 斜条纹线
exports._stripe = _stripe;
var _diagonalStripe = function _diagonalStripe(options) {
  var name = options.name,
    _options$id2 = options.id,
    id = _options$id2 === void 0 ? 'shape-diagonalStripe' : _options$id2,
    _options$size = options.size,
    size = _options$size === void 0 ? 10 : _options$size,
    _options$color3 = options.color1,
    color1 = _options$color3 === void 0 ? 'red' : _options$color3,
    _options$offset = options.offset,
    offset = _options$offset === void 0 ? 0 : _options$offset;
  id = (0, _svg._patternId)(id, name);
  var r = size;
  var d = (0, _path2._d)([[[0, 0], [r * 2, r * 2], [r + offset, r * 2], [0, r - offset]], [[r + offset, 0], [r * 2, 0], [r * 2, r - offset]]]);
  var path = (0, _svg._path)(d, {
    fill: color1
    // stroke: color1
  });

  var stripe = (0, _svg.createSvg)('pattern', {
    id: id,
    x: 0,
    y: 0,
    width: r * 2,
    height: r * 2,
    patternUnits: 'userSpaceOnUse'
  }, {}, [path]);
  var defs = (0, _svg._defs)(stripe);
  return defs;
};
exports._diagonalStripe = _diagonalStripe;