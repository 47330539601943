"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.peachHeartPoints = exports.heartPoints = exports.cartesianCardioidPoints = void 0;
var _traversal = require("./traversal");
// 桃心型线
// x=16 * (sin(t)) ^ 3
// y=13 * cos(t) - 5 * cos(2 * t) - 2 * cos(3 * t) - cos(4 * t)
var peachHeartPoints = function peachHeartPoints(_ref) {
  var _ref$r = _ref.r,
    r = _ref$r === void 0 ? 15 : _ref$r,
    o = _ref.o,
    a = _ref.a,
    _ref$n = _ref.n,
    n = _ref$n === void 0 ? 200 : _ref$n,
    _ref$m = _ref.m,
    m = _ref$m === void 0 ? 1 : _ref$m;
  var _x = function _x(t) {
    return 16 * Math.pow(Math.sin(t), 3);
  };
  var _y = function _y(t) {
    return -1 * (13 * Math.cos(t) - 5 * Math.cos(2 * t) - 2 * Math.cos(3 * t) - Math.cos(4 * t));
  };
  var _r = function _r(i) {
    return r * (m - i) / m;
  };
  return (0, _traversal._multiplePolar)({
    o: o,
    r: _r,
    a: a,
    n: n,
    m: m,
    _x: _x,
    _y: _y
  });
};
// i = np.linspace(0,2*math.pi,500)
// x=np.cos(i)
// y=np.sin(i)+np.power(np.power(np.cos(i),2),1/3)
exports.peachHeartPoints = peachHeartPoints;
var heartPoints = function heartPoints(_ref2) {
  var _ref2$r = _ref2.r,
    r = _ref2$r === void 0 ? 15 : _ref2$r,
    o = _ref2.o,
    a = _ref2.a,
    _ref2$n = _ref2.n,
    n = _ref2$n === void 0 ? 200 : _ref2$n,
    _ref2$m = _ref2.m,
    m = _ref2$m === void 0 ? 1 : _ref2$m;
  var _x = function _x(t) {
    return 10 * Math.cos(t); // Math.pow(Math.sin(t), 3);
  };

  var _y = function _y(t) {
    return -10 * (Math.sin(t) + Math.pow(Math.pow(Math.cos(t), 2), 1 / 3)); // -1 * (13 * Math.cos(t) - 5 * Math.cos(2 * t) - 2 * Math.cos(3 * t) - Math.cos(4 * t));
  };

  var _r = function _r(i) {
    return r * (m - i) / m;
  };
  return (0, _traversal._multiplePolar)({
    o: o,
    r: _r,
    a: a,
    n: n,
    m: m,
    _x: _x,
    _y: _y
  });
};
// 笛卡尔心形函数
// x=size*(2*sin(t)+sin(2*t))
// y=size*(2*cos(t)+cos(2*t))
// Cartesian cardioid
exports.heartPoints = heartPoints;
var cartesianCardioidPoints = function cartesianCardioidPoints(_ref3) {
  var _ref3$r = _ref3.r,
    r = _ref3$r === void 0 ? 100 : _ref3$r,
    o = _ref3.o,
    a = _ref3.a,
    _ref3$n = _ref3.n,
    n = _ref3$n === void 0 ? 200 : _ref3$n,
    _ref3$m = _ref3.m,
    m = _ref3$m === void 0 ? 1 : _ref3$m;
  var _x = function _x(t) {
    return 5 * (2 * Math.sin(t) + Math.sin(2 * t));
  };
  var _y = function _y(t) {
    return 5 * (2 * Math.cos(t) + Math.cos(2 * t));
  };
  var _r = function _r(i) {
    return r * (m - i) / m;
  };
  return (0, _traversal._multiplePolar)({
    o: o,
    r: _r,
    a: a,
    n: n,
    m: m,
    _x: _x,
    _y: _y
  });
};
exports.cartesianCardioidPoints = cartesianCardioidPoints;