"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.factory = exports.canvasConfig = void 0;
var _lsystem = require("./pental/lsystem");
var _plant2 = require("./pental/plant");
var _polygon2 = require("./core/polygon");
var _circle2 = require("./core/circle");
var _star2 = require("./core/star");
var _gougu2 = require("./pental/gougu");
var _polarShape = require("./core/polarShape");
var _shape = require("@/types/shape");
var _canvasConfig;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
// import { _rougth } from './rougth.js'
var canvasConfig = (_canvasConfig = {}, _defineProperty(_canvasConfig, _shape.Shape.Circle, _circle2._circle), _defineProperty(_canvasConfig, _shape.Shape.Rect, _polygon2._rect), _defineProperty(_canvasConfig, _shape.Shape.Polygon, _polygon2._polygon), _defineProperty(_canvasConfig, _shape.Shape.Plant, _plant2._plant), _defineProperty(_canvasConfig, _shape.Shape.LSystem, _lsystem.lsystem), _defineProperty(_canvasConfig, _shape.Shape.LSystemPlant, _lsystem.lsystem), _defineProperty(_canvasConfig, _shape.Shape.PolarShape, _polarShape.polarShape), _defineProperty(_canvasConfig, _shape.Shape.SpiralShape, _polarShape.spiralShape), _defineProperty(_canvasConfig, _shape.Shape.RingShape, _polarShape.ringShape), _defineProperty(_canvasConfig, _shape.Shape.RayShape, _polarShape.rayShape), _defineProperty(_canvasConfig, _shape.Shape.Star, _star2._star), _defineProperty(_canvasConfig, _shape.Shape.Gougu, _gougu2._gougu), _canvasConfig);
exports.canvasConfig = canvasConfig;
var factory = function factory(shape, ctx, options, props) {
  return canvasConfig[shape] && canvasConfig[shape](ctx, options, props);
};
exports.factory = factory;